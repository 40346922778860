import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { InputLabel } from '@common/ui';
import { Field, FormikValues } from 'formik';
import {
  FormControl,
  Avatar,
  CircularProgress
} from '@material-ui/core';
import { uploadSingleFile } from '@services/UploadServices/uploadService';
import { device } from '@styles';
import { Button, ButtonVariant } from '@kit/ui/Button';

const HiddenInput = styled.input`
  display: none;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  width: 100%;
  margin: 10px 0 0 0;

  ${device.sm`
    width: auto;
    margin: 0 0 0 10px;
  `};

  button {
    width: calc(50% - 10px);

    margin: 0;
    ${device.sm`
      width: auto;
      margin: 0 10px;
    `}
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
`;

const StyledAvatar = styled(Avatar)`
  && {
    width: 48px;
    height: 48px;
    border-radius: 4px;
  }
`;

type AvatarFieldType = {
  name: string;
  label: string;
  companyId: number;
};

const AvatarField: React.FC<AvatarFieldType> = ({ name, label, companyId }) => {
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);

  const onUploadBtnClick = () => {
    imageInputRef.current?.click();
  };
  const onRemoveBtnClick = (form: FormikValues) => () => {
    setIsUploading(false);
    form.setFieldValue(name, '');
  };

  const imageUpload = ({ setFieldValue }: FormikValues) => async (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    if (!event.target.files || !event.target.files[0]) return;

    setFieldValue(name, '');
    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    const { data: uploadedImage }: any = await uploadSingleFile(
      formData,
      'company',
      companyId
    );
    setFieldValue(name, uploadedImage.downloadUrl);
    setIsUploading(false);
  };

  const getNameInitials = (firstname: string, lastname: string) => {
    const firstInit = firstname ? firstname[0] : '';
    const lastInit = lastname ? lastname[0] : '';
    return firstInit.toUpperCase() + lastInit.toUpperCase();
  };

  return (
    <div>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <FormControl>
        <Field validate name={name} component={Avatar}>
          {({ field, form, meta }: FormikValues) => (
            <Wrapper>
              <StyledAvatar src={form.values[field.name] || ''}>
                {isUploading ? (
                  <CircularProgress
                    color="inherit"
                    style={{ color: '#fff', width: '30px', height: '30px' }}
                  />
                ) : (
                  getNameInitials(
                    form.values['firstName'],
                    form.values['lastName']
                  )
                )}
              </StyledAvatar>
              <HiddenInput
                onChange={imageUpload(form)}
                ref={imageInputRef}
                type="file"
                accept="image/*"
              />
              <ButtonGroup>
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={onUploadBtnClick}
                >
                  UPLOAD
                </Button>
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={onRemoveBtnClick(form)}
                >
                  REMOVE
                </Button>
              </ButtonGroup>

              {meta.error && meta.touched && (
                <p className="FormError">{meta.error}</p>
              )}
            </Wrapper>
          )}
        </Field>
      </FormControl>
    </div>
  );
};

export default AvatarField;
