import styled from 'styled-components';
import { Row } from '../styled';

export const TitleCell = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  > *:first-child {
    width: auto;

    > * {
      margin-right: 0;
    }
  }

  svg {
    flex-shrink: 0;
  }
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Created = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
`;

export const CenteredRow = styled(Row)`
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const AvatarPlaceholder = styled.div`
  &&& {
    width: 24px;
  }
  height: 24px;
  border-radius: 4px;
  border: 1px solid #dfdfe8;
  color: #dfdfe8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Meta = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    flex-shrink: 0;
  }

  overflow: visible;
`;
