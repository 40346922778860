import { defaultTheme } from '@themes';

export const avatarStyles = (props: { size: number; color: string; textColor?: string }) => {
  const { size, color, textColor } = props;

  return {
    // .MuiAvatar-root Styles applied to the root element.
    root: {
      width: size,
      height: size,
      font: size <= 16 ? `500 8px/1.25 ${defaultTheme.fonts.sans}` : `500 12px/1.25 ${defaultTheme.fonts.sans}`,
      ...(textColor
        ? {
            color: textColor
          }
        : {})
    },

    // .MuiAvatar-colorDefault Styles applied to the root element if not src or srcSet.
    colorDefault: {
      backgroundColor: color
    },

    // .MuiAvatar-circle Styles applied to the root element if variant="circle".
    circle: {},

    // .MuiAvatar-circular Styles applied to the root element if variant="circular".
    circular: {},

    // .MuiAvatar-rounded Styles applied to the root element if variant="rounded".
    rounded: {},

    // .MuiAvatar-square Styles applied to the root element if variant="square".
    square: {},

    // .MuiAvatar-img Styles applied to the img element if either src or srcSet is defined.
    img: {},

    // .MuiAvatar-fallback Styles applied to the fallback icon
    fallback: {}
  };
};
