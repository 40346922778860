import { AccessOption, AccountStatus, MemberType, SmartViewFilter, WithPrivileges } from '@types';
import {
  Project as ProjectGenerated,
  ProjectStatus as NewProjectStatus,
  ProjectRequestStatus
} from '@generated/types/graphql';
import { DealsAggregates } from '@hooks/useRecords';
import { WidgetFilters } from '@features/Analytics/types';

type IProjectViewProps = {
  path: string;
  showTableView?: boolean;
};

export default IProjectViewProps;

export enum ProjectStatus {
  active = 'Active',
  archived = 'Archived',
  all = 'All'
}

export enum AccountIsActiveStatus {
  active = 'AccountActive',
  archived = 'AccountArchived',
  all = 'AccountAll'
}

export enum DealStatus {
  active = 'DealActive',
  archived = 'DealArchived',
  all = 'DealAll'
}

export enum SearchFrom {
  grid = 'GRID',
  table = 'TABLE',
  map = 'MAP'
}

export interface FilterSort {
  columnId: number;
  desc: boolean;
}

export interface Search {
  groupBy: string | number; // id of a property OR 'blueprint_<id of a blueprint> OR 0 for no grouping'
  orderBy: FilterSort[];
  search: string;
  type: ProjectStatus | AccountIsActiveStatus | DealStatus;
  isArchivedShown: boolean;
  perPage: number;
  page: number;
  groupFilter: string;
  from: SearchFrom; // use enums,
  companyId?: number;
  /** @deprecated Was used to sort unseen projects first, but the feature was broken */
  selectedView?: number;
  startDate?: string;
  endDate?: string;
  fetchAll?: boolean;
  filters?: WidgetFilters | null;
  customer?: string;
  status?: NewProjectStatus[] | AccountStatus[] | ProjectRequestStatus[];
}

/**
 * @deprecated delete after project reducer
 */
export interface ProjectSmartView {
  id: number;
  name: string;
  search: Search;
  companyId: number;
  local: boolean;
  properties: {
    /** @deprecated exists only for reference, do not use */
    hiddenColumns: string[];
    shownColumns: number[];
    columnsOrder: {
      id: number;
      position: number;
    }[];
  };
  emoji: string;
}

const SMART_VIEW_FORM_FIELD_KEYS_AS_CONST = ['name', 'emoji', 'scope'] as const;

export const SMART_VIEW_FORM_FIELD_KEYS = [...SMART_VIEW_FORM_FIELD_KEYS_AS_CONST];
export type SmartViewFormFields = Pick<SmartViewFilter, (typeof SMART_VIEW_FORM_FIELD_KEYS_AS_CONST)[number]> & {
  access: AccessOption[];
};

export type SearchPartial = Partial<Search>;

export type FavoritesSearch = Pick<Search, 'page' | 'perPage'> & Partial<Pick<Search, 'sortCol' | 'sortDesc'>>;

export interface User {
  id: number;
  name: string;
  avatarUrl: string;
}

export type GroupValue = any;

export interface GroupProp {
  group: Group;
  isLoading?: boolean;
  page: number;
  perPage: number;
  /** @deprecated use {@link useCollapsedGroups} */
  isCollapsed?: boolean;
  isActive?: boolean;
  total: number;
  aggregates: {
    sum: {
      dealValue?: number;
    };
  };
}

export interface Group {
  /** @deprecated use value instead */
  id: string;
  value: GroupValue;
  name: string;
  position?: number;
  color?: string;
  companyId?: number;
  list: Project[];
}

// eslint-disable-next-line import/export
export type ProjectDetail = WithPrivileges &
  Pick<ProjectGenerated, 'projectStageUpdates'> & {
    id: number;
    companyId: number;
    additional: { [id: number]: string | {} };
    description: string;
    title: string;
    address: string;
    imgSrc: string;
    ownerEmail: string;
    isActive: boolean;
    ahj: string;
    phone: string;
    isFavorite: boolean;
    members?: ProjectMember[];
    overdueBy: number;
    streetViewUrl?: string;
  };

export interface ProjectMember {
  id: number;
  memberType: MemberType;
  memberId: number;
}

export interface Project {
  projectId: string | number;
  projectProgress: number;
  projectDetail: ProjectDetail;
  membersList: Member[];
}

export interface FavProject {
  id: string | number;
  name: string;
  avatarUrl: string;
  description: string;
}

export interface Member {
  id: number;
  avatarUrl: string;
  name: string;
  memberType: string;
  email: string;
  memberId?: number;
}

export interface Template {
  id: number;
  name: string;
}

export type ProjectViewChildPropsBase = {
  groups: GroupProp[];
  aggregates: DealsAggregates;
  onClickEditProject: CallableFunction;
  onArchiveHandler: (id: number, isActive: boolean) => void;
  onDeleteHandler: (id: number) => void;
  headerHeight?: number;
  isLoading: boolean;
};
