import React from 'react';
import { Field } from 'formik';
import { FormControl, makeStyles, MenuItem as MuiMenuItem } from '@material-ui/core';
import { Select } from 'formik-material-ui';
import styled from 'styled-components';
import { ChevronDown, Icon } from 'react-feather';
import { Paper } from '@common/ui';
import { ListSubheader } from '@common/ui/Select/styled';
import { BlueprintDTO, Column } from '@types';
import { colors, device, dropdown, fonts, selectMui } from '@styles';
import { UiSizes } from '@enums';

const styles = selectMui({ size: UiSizes.Small, noBorder: true });
const useStyles = makeStyles(styles);

type StyledFieldProps = {
  label: string;
};


const StyledField = styled(({ label, ...props }) => <Field {...props} />)<StyledFieldProps>`
  && {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;

    .MuiSelect-root {
      display: block;
      
      width: 100%;
      height: auto;
      
      margin: 0;
      padding: 8.5px 7px 8.5px 30px;
      
      ${device.sm`
        padding-left: 7px;
        padding-right: 30px;
      `};
      
      > div {
        display: inline-block;  
      }
      
      &:before {
        display: none;
        ${device.sm`
          display: inline-block;
        `};
        margin-right: 5px;
        content: '${(props) => props.label}';
        font: 500 12px/1.25 ${fonts.sans};
        color: ${colors.gray2};
        text-transform: initial;
      }
    }

    .MuiSelect-icon {
      display: none;
      ${device.sm`
        display: block;
      `};
    }
    
    fieldset {
      top: 0;
      border-color: ${colors.white};
    }
    
    legend {
      display: none;
    }
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  && {
    ${dropdown.item};
    min-width: auto;
    width: calc(100% - 20px);
    margin-left: 10px;
    
    > div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

const IconMobileWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 6px;
  left: 5px;

  ${device.xs`
    display: none;
  `};
`;

type FilterSelectFieldType = {
  label: string;
  columns: Partial<Column | BlueprintDTO>[];
  IconMobile?: Icon | null;
  name?: string;
  component?: React.ReactNode;
  disabled?: boolean;
  submitForm?: () => void;
  defaultValue?: any;
};

const FilterColumnSelectField: React.FC<FilterSelectFieldType> = (
  props: FilterSelectFieldType
) => {
  const {
    label,
    name = 'noop',
    component = Select,
    disabled = false,
    columns,
    IconMobile = null,
    submitForm,
    defaultValue = '',
  } = props;
  const classes = useStyles();

  return (
    <FormControl>
      <IconMobileWrapper>
        {IconMobile && <IconMobile size={20} color={colors.gray2} />}
      </IconMobileWrapper>
      <StyledField
        label={label}
        data-analyticsid={`select_${label}`}
        classes={classes}
        disabled={disabled}
        component={component}
        name={name}
        variant="outlined"
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          PaperProps: {
            component: Paper,
          }
        }}
        IconComponent={ChevronDown}
        defaultValue={defaultValue}
      >
        {columns.map((option) => {
          if (option.id === Infinity) {
            return <ListSubheader key={option.name}>{option.name}</ListSubheader>;
          }

          return [
            (
              <MenuItem
                value={option.id}
                key={option.id}
                onClick={submitForm}
              >
                {option.name}
              </MenuItem>
            )
          ];
        })}
        {columns.length === 0 ? (
          <MenuItem
            value=""
            key="empty"
            disabled
          >
            Empty
          </MenuItem>
        ) : null}
      </StyledField>
    </FormControl>
  );
};

export default FilterColumnSelectField;
