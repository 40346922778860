import { defaultTheme } from '@themes';

export const button = (props: { uppercase?: boolean }) => {
  const { uppercase } = props;

  return {
    root: {
      fontFamily: defaultTheme.fonts.sans,
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: 1.5,
      textTransform: uppercase ? 'uppercase' : 'none',
      letterSpacing: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: 'none',
      '&:hover, &:focus': {
        boxShadow: 'none'
      },
      padding: '0 10px',
      minHeight: '32px'
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap'
    },
    text: {
      background: 'transparent',
      color: defaultTheme.colors.gray2,
      '&:hover, &:focus': {
        backgroundColor: defaultTheme.colors.white3
      }
    },
    textPrimary: {
      color: defaultTheme.colors.green,
      '&:hover, &:focus': {
        color: defaultTheme.colors.white,
        backgroundColor: defaultTheme.colors.green
      }
    },
    textSecondary: {
      color: defaultTheme.colors.gray2,
      '&:hover, &:focus': {
        color: defaultTheme.colors.white,
        backgroundColor: defaultTheme.colors.gray2
      }
    },
    textSizeSmall: {
      fontSize: '12px'
    },
    textSizeLarge: {
      fontSize: '16px'
    },
    outlined: {
      borderWidth: '1px',
      borderStyle: 'solid',
      backgroundColor: 'transparent',
      borderColor: defaultTheme.colors.gray,
      color: defaultTheme.colors.gray2,
      '&:hover, &:focus': {
        backgroundColor: 'transparent',
        borderColor: defaultTheme.colors.gray3
      }
    },
    outlinedPrimary: {
      borderColor: defaultTheme.colors.green,
      color: defaultTheme.colors.green,
      '&:hover, &:focus': {
        borderColor: defaultTheme.colors.green
      }
    },
    outlinedSecondary: {
      borderColor: defaultTheme.colors.gray,
      color: defaultTheme.colors.gray2,
      '&:hover, &:focus': {
        borderColor: defaultTheme.colors.gray
      }
    },
    outlinedSizeSmall: {
      minHeight: '22px'
    },
    outlinedSizeLarge: {
      minHeight: '40px'
    },
    contained: {
      backgroundColor: defaultTheme.colors.white3,
      color: defaultTheme.colors.gray2,
      '&:hover, &:focus': {
        backgroundColor: defaultTheme.colors.white3
      }
    },
    containedPrimary: {
      backgroundColor: defaultTheme.colors.green,
      color: defaultTheme.colors.white,
      '&:hover, &:focus': {
        backgroundColor: defaultTheme.colors.green
      }
    },
    containedSecondary: {
      backgroundColor: defaultTheme.colors.black,
      color: defaultTheme.colors.white,
      '&:hover, &:focus': {
        backgroundColor: defaultTheme.colors.black
      }
    },
    containedSizeSmall: {
      minHeight: '22px'
    },
    containedSizeLarge: {
      minHeight: '40px'
    },
    disableElevation: {},
    focusVisible: {},
    disabled: {},
    colorInherit: {},
    sizeSmall: {
      fontSize: '12px',
      padding: '0 8px'
    },
    sizeLarge: {
      fontSize: '14px',
      padding: '0 16px'
    },
    fullWidth: {},
    startIcon: {
      marginLeft: '-3px',
      marginRight: '5px',
      '& > svg': {
        width: '100%',
        height: '100%'
      }
    },
    endIcon: {
      marginRight: '-3px',
      marginLeft: '5px',
      '& > svg': {
        width: '100%',
        height: '100%'
      }
    },
    iconSizeSmall: {
      width: '13px',
      height: '13px'
    },
    iconSizeMedium: {
      width: '20px',
      height: '20px'
    },
    iconSizeLarge: {
      width: '24px',
      height: '24px'
    }
  };
};
