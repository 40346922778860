import React from 'react';
import { find, isEmpty } from 'lodash';

import { SelectFormik } from '@common/ui';
import { PropertyType } from '@types';
import { DateField, InputField } from '@common';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { PersonIdField } from '@common/Formik/Form/PersonField';

type PropertyFieldProps = {
  propertyId: number;
  name: string;
  label: string;
};

/**
 * @deprecated use CustomFieldsForm
 */
export const PropertyField: React.FC<PropertyFieldProps> = (props) => {
  const { propertyId, name, label } = props;

  const { properties } = useCompanyProperties();

  if (isEmpty(properties)) {
    return null;
  }

  const property = find(properties, { id: propertyId })!;

  if (!property) {
    console.warn('property is missing', { propertyId });

    return null;
  }

  switch (property.type) {
    case PropertyType.Date:
      return <DateField name={name} label={label} />;
    case PropertyType.Dropdown:
      return (
        <SelectFormik
          name={name}
          type={property.type}
          label={label}
          options={(property.additional?.values || []).map((v) => ({ id: v, name: v }))}
        />
      );
    case PropertyType.Numeric:
      return <InputField name={name} label={label} type="number" validate />;
    case PropertyType.Text:
    case PropertyType.Link:
      return <InputField name={name} label={label} type="text" validate />;
    case PropertyType.Person:
      return <PersonIdField name={name} label={label} />;
    default:
      throw new Error(`Unrecognized column type: ${property.type}`);
  }
};
