import styled from 'styled-components';

export const StatusMark = styled.div<{ color: string }>`
  width: 4px;
  height: 16px;
  border-radius: 4px;
  flex-shrink: 0;
  background-color: ${({ color }) => color};
`;

export const NotMatchtedLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #d54855;
`;