import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EmailAccount } from '@generated/types/graphql';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { apiErrorHandler } from '@utils';
import emailAPI from '@services/api/emailAPI';
import { UpdateEmailAccountDTO } from '@services/api/types';
import { NylasAccountStatus } from '@types';

/**
 * Deprecated
 * @param companyId
 * @returns
 */
export const useEmailAccounts = (companyId: number) =>
  useQuery<EmailAccount[]>(
    ['old', ReactQueryKey.WorkspaceEmailAccounts, companyId],
    async () => {
      try {
        return (
          await postGraphql<{ emailAccounts: EmailAccount[] }>(
            gql`
              query ($companyId: Int!) {
                emailAccounts(filter: { companyId: { equalTo: $companyId } }) {
                  id
                  emailAddress
                  nylasStatus
                  nylasProvider
                  alias
                }
              }
            `,
            { companyId }
          )
        ).emailAccounts;
      } catch (e) {
        throw apiErrorHandler('Error fetching email accounts', e);
      }
    },
    {
      enabled: !!companyId,
      initialData: []
    }
  );

/**
 * Deprecated
 * @param companyId
 * @returns
 */
export const useEmailAccountsMutations = (companyId: number) => {
  const queryClient = useQueryClient();

  const disconnectMutation = useMutation<void, Error, number>(
    async (id) => {
      try {
        await emailAPI.disconnectAccount(id);
      } catch (e) {
        throw apiErrorHandler('Error disconnecting account', e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKey.WorkspaceEmailAccounts, companyId]);
      }
    }
  );

  /**
   * Not an actual mutation, just a client redirect URL getter
   */
  const connectMutation = useMutation<string, Error, { email: string; redirectTo: string; alias: string }>(
    async ({ email, redirectTo, alias }) => {
      try {
        return (await emailAPI.authorizeAccount(companyId, email, redirectTo, alias)).data;
      } catch (e) {
        throw apiErrorHandler('Error authorizing account', e);
      }
    }
  );

  const updateMutation = useMutation<void, Error, { accountId: number; dto: UpdateEmailAccountDTO }>(
    async ({ accountId, dto }) => {
      try {
        await emailAPI.updateAccount(accountId, dto);
      } catch (e) {
        throw apiErrorHandler('Error updating account', e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKey.WorkspaceEmailAccounts, companyId]);
      }
    }
  );

  return {
    disconnect: disconnectMutation,
    connect: connectMutation,
    update: updateMutation
  };
};

export const failedEmailStatuses = [
  NylasAccountStatus.invalid,
  NylasAccountStatus.sync_error,
  NylasAccountStatus.stopped
];
