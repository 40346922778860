import { ModalBody, ModalFooter, useModal } from '@common/PromiseModal';
import { AddressField, Form, FormValidationRules, SelectField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React, { useMemo } from 'react';
import { useUpdateEffect } from '@react-hookz/web';
import { AccountType, RecordType } from '@types';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { useRelatedRecords } from '@hooks/useRecords';
import { useAppSelector } from '@hooks/index';
import { selectWorkspaceId } from '@state/selectors';
import { useUpdateSystem } from '@hooks/systems/useUpdateSystem';
import { ClientForm } from '@features/ClientPortfolio/List';
import { useClientSites } from './useClientSites';
import { Description, TwoFields } from './styled';

interface Props {
  system: {
    id: number;
    number: number;
    name: string;
  };
  onClose: () => void;
}

interface FormValues {
  client: { id: number; title: string };
  site: string;
}

export const MatchToClient = ({ system, onClose }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const [, debouncedClientInputValue, setClientInputValue] = useDebouncedState('', 300);
  const { data: clients = [] } = useRelatedRecords(companyId, RecordType.ACCOUNT, debouncedClientInputValue);
  const { mutateAsync: updateSystem } = useUpdateSystem();

  const { openModal } = useModal();

  const postForm = async (values: FormValues) => {
    const { client } = values;

    await updateSystem({
      id: system.id,
      dto: {
        projectId: client.id,
        addressForced: values.site
      }
    });

    onClose();
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm
  });

  const {
    control,
    watch,
    setValue,
    formState: { isSubmitting }
  } = form;

  const client = watch('client');

  useUpdateEffect(() => {
    setValue('site', '');

    if (client?.id === 0) {
      openModal<{ id: number; title: string; address: string[] } | void>(
        ({ onClose }) => (
          <ClientForm
            initialValues={{
              primaryContact: {
                id: 0,
                name: client.title
              }
            }}
            onClose={onClose}
          />
        ),
        { title: 'Create Client' }
      ).then((newClient) => {
        setValue('client', newClient ?? null);

        if (newClient) {
          setValue('site', newClient.address[0]);
        }
      });
    }
  }, [client, setValue]);

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      client: {
        isRequired: true
      },
      site: {
        isRequired: true
      }
    }),
    []
  );

  const clientSites = useClientSites(client?.id);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody width="752px">
        <Description>
          Match #{system.number}: <b>{system.name}</b> to Client and its Site.
        </Description>

        <TwoFields>
          <SelectField
            label="Client"
            control={control}
            name="client"
            getOptionLabel={(option: any) => option.title}
            options={clients}
            isCreatable
            createButtonText={(inputValue: string) =>
              inputValue ? `+ Create "${inputValue}" as new Client` : '+ Create new Client'
            }
            buildNewOption={(inputValue: string) => {
              return { id: 0, title: inputValue, accountType: AccountType.RESIDENTIAL, address: [] };
            }}
            onInputChange={(e) => {
              if (!e) {
                return;
              }
              setClientInputValue(e.target?.value ?? '');
            }}
          />

          <AddressField predefinedOptions={clientSites} disabled={!client} label="Site" name="site" control={control} />
        </TwoFields>
      </ModalBody>
      <ModalFooter>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          Match
        </Button>
      </ModalFooter>
    </Form>
  );
};
