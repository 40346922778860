import { Instance } from 'pspdfkit';
import { useRef, useEffect, useState, useCallback } from 'react';
import { toInteger } from 'lodash';

const getZoomPercentValue = (zoomValue: number) => {
  return toInteger(Number.isFinite(zoomValue) ? zoomValue * 100 : 100);
};

export const useTools = (pspdfkitInstance: Instance) => {
  const [interactionMode, setInteractionMode] = useState<string | null>('PAN');
  const expectedInteractionMode = useRef<string | null>('PAN');
  const [zoomValue, setZoomValue] = useState<number>(100);

  const handleRotateLeft = useCallback(() => {
    pspdfkitInstance.applyOperations([
      {
        type: 'rotatePages',
        pageIndexes: [pspdfkitInstance.viewState.currentPageIndex],
        rotateBy: 270
      }
    ]);
  }, [pspdfkitInstance]);

  const handleRotateRight = useCallback(() => {
    pspdfkitInstance.applyOperations([
      {
        type: 'rotatePages',
        pageIndexes: [pspdfkitInstance.viewState.currentPageIndex],
        rotateBy: 90
      }
    ]);
  }, [pspdfkitInstance]);

  const handleFreeHand = useCallback(() => {
    if (expectedInteractionMode.current === 'PAN') {
      expectedInteractionMode.current = null;
      pspdfkitInstance.setViewState((state) => state.set('interactionMode', null));

      return;
    }
    expectedInteractionMode.current = 'PAN';
    pspdfkitInstance.setViewState((state) => state.set('interactionMode', 'PAN'));
  }, [pspdfkitInstance]);

  const handleFreeDraw = useCallback(() => {
    if (expectedInteractionMode.current === 'INK') {
      expectedInteractionMode.current = null;
      pspdfkitInstance.setViewState((state) => state.set('interactionMode', null));

      return;
    }

    expectedInteractionMode.current = 'INK';
    pspdfkitInstance.setViewState((state) => state.set('interactionMode', 'INK'));
  }, [pspdfkitInstance]);

  const handleSquare = useCallback(() => {
    if (expectedInteractionMode.current === 'SHAPE_RECTANGLE') {
      expectedInteractionMode.current = null;
      pspdfkitInstance.setViewState((state) => state.set('interactionMode', null));

      return;
    }

    expectedInteractionMode.current = 'SHAPE_RECTANGLE';

    pspdfkitInstance.setViewState((state) => state.set('interactionMode', 'SHAPE_RECTANGLE'));
  }, [pspdfkitInstance]);

  const handleCircle = useCallback(() => {
    if (expectedInteractionMode.current === 'SHAPE_ELLIPSE') {
      expectedInteractionMode.current = null;
      pspdfkitInstance.setViewState((state) => state.set('interactionMode', null));

      return;
    }

    expectedInteractionMode.current = 'SHAPE_ELLIPSE';
    pspdfkitInstance.setViewState((state) => state.set('interactionMode', 'SHAPE_ELLIPSE'));
  }, [pspdfkitInstance]);

  const handleUndo = useCallback(() => {
    pspdfkitInstance.history.undo();
  }, [pspdfkitInstance]);

  const handleRedo = useCallback(() => {
    pspdfkitInstance.history.redo();
  }, [pspdfkitInstance]);

  const handleZoomOut = useCallback(() => {
    pspdfkitInstance.setViewState((state) => state.zoomOut());
    // refreshZoomValue();
  }, [pspdfkitInstance]);

  const handleZoomIn = useCallback(() => {
    pspdfkitInstance.setViewState((state) => state.zoomIn());
    // refreshZoomValue();
  }, [pspdfkitInstance]);

  const handleResetZoom = useCallback(() => {
    pspdfkitInstance.setViewState((state) => state.set('zoom', 1));
  }, [pspdfkitInstance]);

  useEffect(() => {
    if (!pspdfkitInstance) {
      return;
    }

    pspdfkitInstance.addEventListener('viewState.change', () => {
      if (!pspdfkitInstance.viewState.interactionMode && expectedInteractionMode.current) {
        // For some reason this causes psPDFKit to crash
        // ---
        // for some reason pspdfkitInstance.viewState.interactionMode is null sometimes after undo/redo
        // so we need to set it manually
        // pspdfkitInstance.setViewState((state) => state.set('interactionMode', expectedInteractionMode.current));
        // return;
      }

      setInteractionMode(pspdfkitInstance.viewState.interactionMode);
    });

    setZoomValue(getZoomPercentValue(pspdfkitInstance.currentZoomLevel ?? 1));

    pspdfkitInstance.addEventListener('viewState.zoom.change', () => {
      setZoomValue(getZoomPercentValue(pspdfkitInstance.viewState.zoom as number));
    });
  }, [pspdfkitInstance]);

  return {
    zoomValue,
    interactionMode,
    onRotateLeft: handleRotateLeft,
    onRotateRight: handleRotateRight,
    onFreeHand: handleFreeHand,
    onFreeDraw: handleFreeDraw,
    onSquare: handleSquare,
    onCircle: handleCircle,
    onUndo: handleUndo,
    onRedo: handleRedo,
    onZoomOut: handleZoomOut,
    onZoomIn: handleZoomIn,
    onResetZoom: handleResetZoom
  };
};
