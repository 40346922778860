import styled from 'styled-components';

export const GroupName = styled.div`
  background-color: #f7f7fc;
  padding: 8px 16px;
  color: #828d9a;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 24px;
`;

export const Spacer = styled.div`
  margin-top: 16px;
`;

export const Container = styled.div<{ outsidePadding: number }>`
  padding: 0 16px ${({ outsidePadding }) => outsidePadding}px;

  border: 1px solid #dfdfe8;
  border-radius: 8px;

  background: #fff;
  border-radius: 8px;
  overflow: auto;

  ${GroupName} {
    margin-left: -${({ outsidePadding }) => outsidePadding}px;
    margin-right: -${({ outsidePadding }) => outsidePadding}px;
  }

  label {
    margin-bottom: 0;
  }

  .field-container {
    margin-bottom: 32px;
  }
`;

export const FormName = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const FieldName = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #1d1d35;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FieldNotes = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  color: #828d9a;
`;

export const FormFieldWithCollaborators = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;

  > *:first-child {
    flex-shrink: 0;
    flex: 1;
  }
`;

export const FormFieldContainer = styled.div<{ borderColor: string }>`
  &:after {
    content: '';
    display: ${({ borderColor }) => (borderColor !== 'transparent' ? 'block' : 'none')};
    position: absolute;
    top: -7px;
    left: -7px;
    right: 26px;
    bottom: 20px;
    border: 1px solid;
    border-radius: 4px;
    transition: border-color 0.2s ease-in-out;
    border-color: ${({ borderColor }) => borderColor};
  }
`;

export const Collaborators = styled.div`
  width: 24px;
  min-width: 24px;
  margin-left: 4px;
  flex-shrink: 0;
  margin-bottom: 24px;
`;
