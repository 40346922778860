import { AccountIcon } from '@common/icons/Account';
import { useAppSelector } from '@hooks/store';
import { Link } from '@reach/router';
import { selectWorkspaceId } from '@state/selectors';
import React, { useCallback } from 'react';
import { Briefcase } from 'react-feather';
import { getRecordDetailsPagePath } from '@utils/routes';
import { RecordType } from '@types';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { Container } from './styled';

const PROJECT_TYPE_ICONS: Record<RecordType, any> = {
  [RecordType.PROJECT]: Briefcase,
  [RecordType.ACCOUNT]: AccountIcon,
  [RecordType.DEAL]: DollarIcon
};

interface Props {
  project: {
    id: number;
    title: string;
    type: string;
  };

  target?: '_blank' | 'panel';
  variant?: 'chip' | 'link';
  iconSize?: string;
}

export const ProjectTitleChip = ({ project, target, variant = 'chip', iconSize = '12px' }: Props) => {
  const ProjectTypeIcon = PROJECT_TYPE_ICONS[project.type] ?? null;
  const companyId = useAppSelector(selectWorkspaceId);

  const { openDrawer } = useDrawersContext();

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      if (target === 'panel') {
        e.preventDefault();

        const entity = project.type === RecordType.ACCOUNT ? DrawerEntity.CLIENT : DrawerEntity.PROJECT;

        openDrawer(entity, project.id, [], true);
      }
    },
    [target, project, openDrawer]
  );

  if (target === '_blank') {
    return (
      <Container variant={variant}>
        <a target={target} onClick={handleClick} href={getRecordDetailsPagePath({ ...project, companyId })}>
          {ProjectTypeIcon ? <ProjectTypeIcon size={iconSize} /> : null}
          <div>{project.title}</div>
        </a>
      </Container>
    );
  }

  return (
    <Container variant={variant}>
      <Link target={target} onClick={handleClick} to={getRecordDetailsPagePath({ ...project, companyId })}>
        {ProjectTypeIcon ? <ProjectTypeIcon size={iconSize} /> : null}
        <div>{project.title}</div>
      </Link>
    </Container>
  );
};
