import React, { useState } from 'react';
import { Popover } from '@kit/ui/Popover';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { ZapIcon } from '@kit/ui/icons/Zap';
import { useUserRoleSettings } from '@hooks/useRoles';
import { useAppSelector } from '@hooks/index';
import { selectUser, selectWorkspaceId } from '@state/selectors';
import { hasAccess } from '@utils/roles';
import { AutomationsListHeading, ChipContainer, Container, ExpandAutomations } from './styled';
import { Row } from './Row';
import { useAutomations } from './useAutomations';
import { EntityType } from './types';

interface Props {
  entityId: number;
  entityType: EntityType;
}

export const Chip = ({ entityId, entityType }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { active, inactive } = useAutomations(entityId, entityType);
  const user = useAppSelector(selectUser);
  const companyId = useAppSelector(selectWorkspaceId);

  const { data: access } = useUserRoleSettings(companyId, user.userId);

  const total = active.length + inactive.length;

  if (!hasAccess(access, 'workspace', 'view', 'settings')) {
    return null;
  }

  if (total === 0) {
    return null;
  }

  return (
    <Container>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        content={
          <div>
            <AutomationsListHeading>Automations</AutomationsListHeading>

            {active.map((row) => (
              <Row key={row.automation.id} row={row} />
            ))}

            {inactive.slice(0, isExpanded ? inactive.length : 3).map((row) => (
              <Row key={row.automation.id} row={row} />
            ))}

            {inactive.length > 3 && !isExpanded && (
              <ExpandAutomations onClick={() => setIsExpanded(true)}>
                <span>{inactive.length - 3} more inactive automations</span>
                <ChevronDownIcon size="16px" />
              </ExpandAutomations>
            )}

            {inactive.length > 3 && isExpanded && (
              <ExpandAutomations onClick={() => setIsExpanded(false)}>
                <span>Collapse</span>
                <ChevronUpIcon size="16px" />
              </ExpandAutomations>
            )}
          </div>
        }
      >
        <ChipContainer>
          {active.length === 0 && <ZapIcon size="16px" fill="#737382" color="#737382" />}
          {active.length > 0 && <ZapIcon size="16px" fill="#F7A902" color="#F7A902" />}
          {total} automation{total > 1 ? 's' : ''}
        </ChipContainer>
      </Popover>
    </Container>
  );
};
