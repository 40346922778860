import React, { useMemo } from 'react';
import { Button as ButtonMui, makeStyles, ButtonProps as MuiButtonProps } from '@material-ui/core';
import { button } from './styled';

export interface ButtonProps extends MuiButtonProps {
  uppercase?: boolean
}

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    uppercase = true,
    variant = 'contained',
    ...rest
  } = props;

  const makeStylesParam = useMemo(() => (
    button({ uppercase })
  ), [uppercase]);

  const classes = makeStyles(makeStylesParam)();

  return (
    <ButtonMui
      classes={classes}
      variant={variant}
      {...rest}
    />
  );
};
