import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { selectWorkspaceId } from '@state/selectors';
import { DeepPartial } from 'redux';
import { Dashboard } from '@generated/types/graphql';
import { migrateOldFormatToNew } from '@features/Analytics';
import { useAppSelector } from '..';

type WidgetFilter = {
  id: string;
  fieldId: string | number;
  operator: string;
  value: unknown;
};

type WidgetFilters = {
  id: string;
  operator: string;
  children: (WidgetFilters | WidgetFilter)[];
};

export type WidgetConfig = DeepPartial<{
  title: string;
  subtitle: string;
  module: string;
  widgetType: string;
  aggregateFunctionId: string;
  measure: { id: number; entityType: string };
  submeasureTypeId: string;
  submeasureId: string;
  dimensionId: string;
  subdimensionId: string;
  positiveTrendType: string;
  workflowId: number;
  dateRangeType: string;
  dateRangeStart: string;
  dateRangeEnd: string;
  show: string;
  filters: WidgetFilters;
}>;

export type LayoutItem = {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  widgetConfig: WidgetConfig;
};

export type DashboardListItem = {
  id: number;
  emoji: string | null;
  name: string;
  filterFolder: {
    id: number;
    name: string;
  };
  description: string;
  layout: LayoutItem[];
  updatedAt: string;
  scope: string;
  sharedRoles: { id: number; name: string }[];
  sharedTeams: { id: number; name: string }[];
  sharedUsers: { id: number; firstName: string; lastName: string; avatarUrl: string }[];
};

export const useDashboardList = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery<DashboardListItem[]>([ReactQueryKey.Analytics, companyId], async () => {
    try {
      const { dashboards } = await postGraphql<{ dashboards: Dashboard[] }>(
        gql`
          query DASHBOARD_LIST($companyId: Int!) {
            dashboards(filter: { companyId: { equalTo: $companyId } }) {
              id
              name
              emoji
              filterFolder {
                id
                name
              }
              description
              layout
              updatedAt
              scope
              dashboardSharedRolesByDashboardId {
                role {
                  id
                  name
                }
              }
              dashboardSharedTeamsByDashboardId {
                team {
                  id
                  name
                }
              }
              dashboardSharedUsersByDashboardId {
                user {
                  id
                  firstName
                  lastName
                  avatarUrl
                }
              }
            }
          }
        `,
        { companyId }
      );

      return dashboards.map((dashboard) => ({
        ...dashboard,
        sharedRoles: dashboard.dashboardSharedRolesByDashboardId?.map((item) => item.role) ?? [],
        sharedTeams: dashboard.dashboardSharedTeamsByDashboardId?.map((item) => item.team) ?? [],
        sharedUsers: dashboard.dashboardSharedUsersByDashboardId?.map((item) => item.user) ?? [],
        layout: dashboard.layout
          .filter((item) => item.widgetConfig || item.widgetData)
          .map((item) =>
            item.widgetConfig?.module ? item : { ...item, widgetConfig: migrateOldFormatToNew(item.widgetData) }
          )
      }));
    } catch (e) {
      throw apiErrorHandler('Error fetching analytics', e);
    }
  });
};
