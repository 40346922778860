import { ReactQueryKey } from '@enums';
import { Action } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

export const useActionTemplate = (id?: number) => {
  return useQuery<Action>(
    [ReactQueryKey.ActionTemplateList, id],
    async () => {
      try {
        const { action } = await postGraphql<{ action: Action }>(
          gql`
            query ($id: Int!) {
              action(id: $id) {
                id
                title
                type
                assignAllContacts
                description
                createdByUser {
                  id
                  firstName
                  lastName
                  avatarUrl
                }
              }
            }
          `,
          { id }
        );

        return action;
      } catch (e) {
        throw apiErrorHandler('Error fetching Aciton template', e);
      }
    },
    { enabled: Boolean(id) }
  );
};
