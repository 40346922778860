import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { ChartPeriod, chartPointsBrackets, chartTotalBrackets } from '@hooks/systems/constants';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import {
  EnergyConsumptionChartsConnection,
  EnergyExportChartsConnection,
  EnergyImportChartsConnection,
  EnergyProductionChartsConnection,
  PowerProductionChartsConnection,
  StorageEnergyChartsConnection
} from '@generated/types/graphql';
import { EnergyPoint, PowerPoint } from '@hooks/systems/types';

export type EnergyChart = {
  totalProduction: number | null;
  totalConsumption: number | null;
  totalExport: number | null;
  totalImport: number | null;
  totalCharge: number | null;
  totalDischarge: number | null;
  energy: EnergyPoint[];
  power: PowerPoint[];
};

export const useSystemHealthChart = (systemUuid: string, period: ChartPeriod, startAt: DateTime, endBefore: DateTime) =>
  useQuery<EnergyChart>({
    queryKey: [ReactQueryKey.System, 'useSystemHealthChart', systemUuid, { period, startAt, endBefore }],
    queryFn: async () => {
      try {
        const { seriesPeriod: totalSeriesPeriod, targetPeriod: totalTargetPeriod } = chartTotalBrackets[period];
        const { seriesPeriod: pointsSeriesPeriod, targetPeriod: pointsTargetPeriod } = chartPointsBrackets[period];

        const variables = {
          systemUuid,
          startAt: startAt?.toISO(),
          endBefore: endBefore?.toISO(),
          totalSeriesPeriod,
          totalTargetPeriod,
          pointsSeriesPeriod,
          pointsTargetPeriod
        };

        const {
          totalProduction,
          pointsProduction,
          totalConsumption,
          pointsConsumption,
          totalExport,
          totalImport,
          pointsPower,
          totalStorage
        } = await postGraphql<{
          totalProduction: EnergyProductionChartsConnection;
          pointsProduction: EnergyProductionChartsConnection;
          totalConsumption: EnergyConsumptionChartsConnection;
          pointsConsumption: EnergyConsumptionChartsConnection;
          totalExport: EnergyExportChartsConnection;
          totalImport: EnergyImportChartsConnection;
          pointsPower: PowerProductionChartsConnection;
          totalStorage: StorageEnergyChartsConnection
        }>(
          gql`
            query (
              $systemUuid: UUID!
              $startAt: Datetime
              $endBefore: Datetime
              $totalSeriesPeriod: IntervalInput!
              $totalTargetPeriod: IntervalInput!
              $pointsSeriesPeriod: IntervalInput!
              $pointsTargetPeriod: IntervalInput!
            ) {
              totalProduction: energyProductionChartsConnection(
                condition: { system: $systemUuid, seriesPeriod: $totalSeriesPeriod, targetPeriod: $totalTargetPeriod }
                filter: { time: { greaterThanOrEqualTo: $startAt, lessThan: $endBefore } }
              ) {
                aggregates {
                  sum {
                    value
                  }
                }
              }

              pointsProduction: energyProductionChartsConnection(
                condition: { system: $systemUuid, seriesPeriod: $pointsSeriesPeriod, targetPeriod: $pointsTargetPeriod }
                filter: { time: { greaterThanOrEqualTo: $startAt, lessThan: $endBefore } }
              ) {
                groupedAggregates(groupBy: [TIME]) {
                  keys
                  sum {
                    value
                  }
                }
              }

              totalConsumption: energyConsumptionChartsConnection(
                condition: { system: $systemUuid, seriesPeriod: $totalSeriesPeriod, targetPeriod: $totalTargetPeriod }
                filter: { time: { greaterThanOrEqualTo: $startAt, lessThan: $endBefore } }
              ) {
                aggregates {
                  sum {
                    value
                  }
                }
              }

              pointsConsumption: energyConsumptionChartsConnection(
                condition: { system: $systemUuid, seriesPeriod: $pointsSeriesPeriod, targetPeriod: $pointsTargetPeriod }
                filter: { time: { greaterThanOrEqualTo: $startAt, lessThan: $endBefore } }
              ) {
                groupedAggregates(groupBy: [TIME]) {
                  keys
                  sum {
                    value
                  }
                }
              }

              totalExport: energyExportChartsConnection(
                condition: { system: $systemUuid, seriesPeriod: $totalSeriesPeriod, targetPeriod: $totalTargetPeriod }
                filter: { time: { greaterThanOrEqualTo: $startAt, lessThan: $endBefore } }
              ) {
                aggregates {
                  sum {
                    value
                  }
                }
              }

              totalImport: energyImportChartsConnection(
                condition: { system: $systemUuid, seriesPeriod: $totalSeriesPeriod, targetPeriod: $totalTargetPeriod }
                filter: { time: { greaterThanOrEqualTo: $startAt, lessThan: $endBefore } }
              ) {
                aggregates {
                  sum {
                    value
                  }
                }
              }

              pointsPower: powerProductionChartsConnection(
                condition: { system: $systemUuid, targetPeriod: $pointsTargetPeriod }
                filter: { time: { greaterThanOrEqualTo: $startAt, lessThan: $endBefore } }
              ) {
                groupedAggregates(groupBy: [TIME]) {
                  keys
                  max {
                    value
                  }
                }
              }
              
              totalStorage: storageEnergyChartsConnection(
                condition: { system: $systemUuid, seriesPeriod: $totalSeriesPeriod, targetPeriod: $totalTargetPeriod }
                filter: { time: { greaterThanOrEqualTo: $startAt, lessThan: $endBefore } }
              ) {
                aggregates {
                  sum {
                    charge
                    discharge
                  }
                }
              }
            }
          `,
          variables
        );

        return {
          totalProduction: totalProduction.aggregates.sum?.value,
          totalConsumption: totalConsumption.aggregates.sum?.value,
          totalExport: totalExport.aggregates.sum?.value,
          totalImport: totalImport.aggregates.sum?.value,
          totalCharge: totalStorage.aggregates.sum?.charge,
          totalDischarge: totalStorage.aggregates.sum?.discharge,
          energy: pointsProduction.groupedAggregates.map((agg, index) => ({
            time: DateTime.fromISO(agg.keys[0]).toLocal(),
            production: agg.sum?.value,
            consumption: pointsConsumption.groupedAggregates[index]?.sum?.value
          })),
          power: pointsPower.groupedAggregates.map((agg) => ({
            time: DateTime.fromISO(agg.keys[0]).toLocal(),
            power: agg.max?.value
          }))
        };
      } catch (e) {
        throw apiErrorHandler('Error fetching system energy', e);
      }
    },
    keepPreviousData: true,
    enabled: !!systemUuid,
    initialData: {
      totalConsumption: null,
      totalProduction: null,
      totalExport: null,
      totalImport: null,
      totalCharge: null,
      totalDischarge: null,
      energy: [],
      power: []
    }
  });
