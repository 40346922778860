import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { System } from '@generated/types/graphql';

export const useSystem = (id: number) =>
  useQuery({
    queryKey: [ReactQueryKey.System, id],
    queryFn: async () => {
      try {
        return (
          await postGraphql<{ system: System }>(
            gql`
              query SYSTEM($id: Int!) {
                system(id: $id) {
                  id
                  createdAt
                  monitored
                  raw
                  providerId
                  status
                  operationalAt
                  address
                  addressForced
                  addressCity
                  addressState
                  addressStreet
                  addressZip
                  lastReportAt
                  connectionType
                  size
                  providerStatus
                  name
                  number
                  clientType
                  uuid
                  notes
                  installedAt
                  operationStatus

                  integration {
                    id
                    provider
                  }

                  project {
                    id
                    type
                    title
                    address
                    accountType

                    projectContacts(orderBy: [PROJECT_CONTACTS_CONNECTION_MIN_POSITION_ASC]) {
                      emails
                      phones
                      id
                      name
                      portalStatus
                    }
                  }
                }
              }
            `,
            {
              id
            }
          )
        ).system;
      } catch (error) {
        throw apiErrorHandler('Error fetching system', error);
      }
    },
    enabled: !!id
  });
