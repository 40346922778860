import React, { useCallback, useMemo, useState } from 'react';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUser, selectWorkspaceId } from '@state/selectors';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils/roles';
import { Button, ButtonSize, ButtonVariant, IconButton } from '@kit/ui/Button';
import { Archive, Clipboard, Edit, Plus } from 'react-feather';
import { useTaskDrawer } from '@components/Scheduler/useTaskDrawer';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { usePaginatedTasks } from '@components/Scheduler/useTasks';
import { ReactQueryKey } from '@enums';
import { mapClientSortToServerSort } from '@components/Scheduler/useClientFilterState';
import { Loader } from '@kit/ui/Loader';
import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';
import { PrivilegedTask, TaskStatus } from '@generated/types/graphql';
import { WorkOrderStatus } from '@components/WorkOrder/WorkOrderStatus';
import { ContextMenu } from '@kit/components/ContextMenu';
import { MoreVerticalIcon } from '@kit/ui/icons/MoreVertical';
import { Checkbox } from '@kit/ui/Checkbox';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { PRIORITY_OPTIONS } from '@components/Scheduler/constants';
import { PriorityIcon } from '@common/PriorityIcon';
import { UserAvatar } from '@kit/components/UserAvatar';
import moment from 'moment';
import { parseUtcDate } from '@utils/dates';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { useWorkOrderMenuActions } from '@components/Scheduler/components/WorkOrderView/Actions/Actions';
import { noop } from 'lodash';
import { XIcon } from '@kit/ui/icons/X';
import { Tooltip } from '@material-ui/core';
import { BulkModalTask } from '@components/Calendar/BulkModalTask';
import { useTaskBulk } from '@hooks/useTaskBulk';
import { useQueryClient } from 'react-query';
import { ProjectStageBadge } from '@components/ProjectStages';
import { useSelection } from './useSelection';
import { Group } from './Group';
import { TableHeader } from './TableHeader';
import {
  Assignee,
  CheckboxContainer,
  GroupCounter,
  ListHeader,
  LoaderWrapper,
  Placeholder,
  PriorityChip,
  Row,
  SelectionControl,
  TableEmptyPlaceholder,
  Td,
  TitleCell,
  TitleCellIconOrCheckbox,
  TitleCellInner
} from './styled';
import { SortByDirection, SortByField } from './types';

interface Props {
  recordId: number;
  system?: { id: number; name: string; project: { id: number; type: string } };
  isArchivedShown: boolean;
  search: string;
  sortBy: { field: SortByField; direction: SortByDirection };
}

const formatDate = (date: string, isAllDay: boolean) => {
  const format = isAllDay ? 'MM/DD/YY' : 'MM/DD/YY h:mma';

  if (!date) {
    return '...';
  }

  return moment(parseUtcDate(date)).format(format).replace(':00', '');
};
const COLUMNS: { id: string; name: string; width: string }[] = [
  { id: 'priority', name: 'Priority', width: '10%' },
  { id: 'assignee', name: 'Assignee', width: '15%' },
  { id: 'stage', name: 'Stage', width: '15%' },
  { id: 'dates', name: 'Start - Due dates', width: '20%' }
];

const WorkOrderRow = ({
  canBulkEdit,
  task,
  isSelected,
  onToggleSelected,
  onClick
}: {
  canBulkEdit: boolean;
  task: PrivilegedTask;
  isSelected: boolean;
  onToggleSelected: (id: number) => void;
  onClick: (id: number) => void;
}) => {
  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number } | null>(null);

  const priorityOption = useMemo(
    () => PRIORITY_OPTIONS.find(({ id }) => task.priority === id) ?? PRIORITY_OPTIONS[1],
    [task]
  );

  const firstVisit = task.taskVisitsByTaskIdConnection?.nodes?.[0];

  const handleToggleSelected = useCallback(() => {
    onToggleSelected(task.id);
  }, [task.id, onToggleSelected]);

  const handleClick = useCallback(() => {
    onClick(task.id);
  }, [task.id, onClick]);

  const handleContextMenu = useCallback<React.MouseEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();

    setContextMenuPosition({
      x: e.pageX,
      y: e.pageY
    });
  }, []);

  const handleCloseContextMenu = useCallback(() => {
    setContextMenuPosition(null);
  }, []);

  const menuItems = useWorkOrderMenuActions(task, noop);

  return (
    <Row isArchived={task.isArchived} isSelected={isSelected} onClick={handleClick} onContextMenu={handleContextMenu}>
      <TitleCell color={task.firstLabel?.color} isSelected={isSelected}>
        <TitleCellInner>
          <TitleCellIconOrCheckbox>
            {canBulkEdit && (
              <CheckboxContainer>
                <Checkbox isChecked={isSelected} onChange={handleToggleSelected} />
              </CheckboxContainer>
            )}
            <WorkOrderTypeIcon isField={task.isField} size="24px" />
          </TitleCellIconOrCheckbox>
          {task.isArchived && <Archive size="16px" color="#9C9CAA" />}#{task.uid}
          {' · '}
          {task.title}
        </TitleCellInner>
      </TitleCell>
      <Td width="10%">
        <PriorityChip>
          <PriorityIcon priority={task.priority} size="12px" />
          <div>{priorityOption.title}</div>
        </PriorityChip>
      </Td>
      <Td width="15%">
        {task.assignee && (
          <Assignee>
            <UserAvatar user={task.assignee} />
            <div>
              {task.assignee.firstName} {task.assignee.lastName}
            </div>
          </Assignee>
        )}
        {!task.assignee && '-'}
      </Td>
      <Td width="15%">
        {task.projectStage && <ProjectStageBadge stage={task.projectStage} />}
        {!task.projectStage && '-'}
      </Td>
      <Td width="20%">
        {!task.isField &&
          `${formatDate(task.startDate, task.startDateAllDay)} - ${formatDate(task.endDate, task.endDateAllDay)}`}
        {task.isField &&
          firstVisit &&
          `${formatDate(firstVisit.startDate, firstVisit.startDateAllDay)} - ${formatDate(firstVisit.endDate, firstVisit.endDateAllDay)}`}
      </Td>
      <Td
        width="5%"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ContextMenu point={contextMenuPosition} items={menuItems} onClose={handleCloseContextMenu}>
          <MoreVerticalIcon size="24px" color="#9C9CAA" />
        </ContextMenu>
      </Td>
    </Row>
  );
};

export const WorkOrders = ({ recordId, isArchivedShown, search, sortBy, system }: Props) => {
  const [isBulkModalShown, setIsBulkModalShown] = useState(false);
  const { data: statuses = [] } = useWorkOrderStatuses();

  const { data: record } = useRecordDetail(recordId, { refetchOnMount: false });
  const companyId = useAppSelector(selectWorkspaceId);
  const user = useAppSelector(selectCurrentUser);
  const { data: access } = useUserRoleSettings(companyId, user.userId);

  const { onAddClick } = useTaskDrawer();

  const { openDrawer } = useDrawersContext();

  const queryClient = useQueryClient();

  const { isItemSelected, toggleGroup, toggleItem, selectedIds, isGroupSelected, clearSelection } = useSelection();

  const {
    update: { mutate: bulkUpdate }
  } = useTaskBulk();

  const handleAddClick = useCallback(() => {
    onAddClick({ project: system ? system.project : record, system });
  }, [record, onAddClick, system]);

  const { tasks, isLoading } = usePaginatedTasks({
    queryKey: ReactQueryKey.TasksWork,
    filters: {
      isArchived: isArchivedShown ? undefined : { equalTo: false },
      projectId: { equalTo: recordId },
      systemId: system ? { equalTo: system.id } : undefined
    },
    orderBy: mapClientSortToServerSort(sortBy),
    perPage: 1000 // TODO use not paginated query
  });

  const handleBulkSubmit = useCallback(
    async (dto: any) => {
      bulkUpdate(
        {
          ...dto,
          ids: selectedIds
        },
        {
          onSuccess: () => {
            clearSelection();
            setIsBulkModalShown(false);
            queryClient.invalidateQueries([ReactQueryKey.Tasks]);
            queryClient.invalidateQueries([ReactQueryKey.ProjectActivity]);
          }
        }
      );
    },
    [bulkUpdate, selectedIds, clearSelection, queryClient]
  );

  const groupedByStatus = useMemo(() => {
    return tasks.reduce(
      (acc, task) => {
        if (!acc[task.taskStatus.id]) {
          acc[task.taskStatus.id] = [];
        }

        acc[task.taskStatus.id].push(task);

        return acc;
      },
      {} as Record<TaskStatus, (typeof tasks)[number][]>
    );
  }, [tasks]);

  const groupedAndFilteredTasks = useMemo(() => {
    return Object.keys(groupedByStatus).reduce(
      (acc, status) => {
        const filteredTasks = groupedByStatus[status as TaskStatus].filter((task) =>
          task.title.toLowerCase().includes(search.toLowerCase())
        );

        if (filteredTasks.length) {
          acc[status as TaskStatus] = filteredTasks;
        }

        return acc;
      },
      {} as Record<TaskStatus, (typeof tasks)[number][]>
    );
  }, [groupedByStatus, search]);

  const taskIds = useMemo(() => {
    return tasks.map((task) => task.id);
  }, [tasks]);

  const handleRowClick = useCallback(
    (id: number) => {
      openDrawer(DrawerEntity.WORK_ORDER, id, taskIds);
    },
    [taskIds, openDrawer]
  );

  const hasSelected = selectedIds.length > 0;

  const hasAnyTask = Object.keys(groupedAndFilteredTasks).length > 0;

  const canBulkEdit = hasAccess(access, 'task', 'edit');

  const handleBulkEdit = useCallback(() => {
    setIsBulkModalShown(true);
  }, []);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <div>
      <ListHeader>
        Work Orders ({tasks.length})
        {!hasSelected && hasAccess(access, 'task', 'create') && (
          <Button variant={ButtonVariant.Primary} onClick={handleAddClick}>
            <Plus size="16px" />
            Work Order
          </Button>
        )}
        {hasSelected && (
          <SelectionControl>
            <IconButton onClick={clearSelection} variant={ButtonVariant.Secondary} size={ButtonSize.Small}>
              <XIcon size="16px" />
            </IconButton>

            <div>{selectedIds.length} selected:</div>

            <Tooltip title="Edit">
              <IconButton onClick={handleBulkEdit} variant={ButtonVariant.Flat} size={ButtonSize.Small}>
                <Edit size="16px" />
              </IconButton>
            </Tooltip>
          </SelectionControl>
        )}
      </ListHeader>

      {tasks.length === 0 && <Placeholder>No Work Orders yet</Placeholder>}

      {tasks.length > 0 && (
        <TableHeader
          isAllSelected={selectedIds.length === tasks.length}
          onToggleAllSelected={(isChecked) => toggleGroup(taskIds, isChecked)}
          columns={COLUMNS}
          canBulkEdit={canBulkEdit}
        />
      )}

      {tasks.length > 0 && !hasAnyTask && <TableEmptyPlaceholder>No Work Orders found</TableEmptyPlaceholder>}

      {tasks.length > 0 &&
        statuses
          .filter((status) => groupedAndFilteredTasks[status.id])
          .map((status) => (
            <Group
              key={status.id}
              canBulkEdit={canBulkEdit}
              isAllSelected={isGroupSelected(groupedByStatus[status.id].map((task) => task.id))}
              onToggleAllSelected={(isChecked) =>
                toggleGroup(
                  groupedByStatus[status.id].map((task) => task.id),
                  isChecked
                )
              }
              renderGroupHeader={() => (
                <>
                  <WorkOrderStatus status={status} />
                  <Clipboard size="16px" color="#9C9CAA" />
                  <GroupCounter>{groupedByStatus[status.id].length}</GroupCounter>
                </>
              )}
              columns={COLUMNS}
            >
              {groupedAndFilteredTasks[status.id].map((task) => (
                <WorkOrderRow
                  canBulkEdit={canBulkEdit}
                  key={task.id}
                  task={task}
                  isSelected={isItemSelected(task.id)}
                  onToggleSelected={toggleItem}
                  onClick={handleRowClick}
                />
              ))}
            </Group>
          ))}

      {isBulkModalShown && (
        <BulkModalTask
          open
          onClose={() => setIsBulkModalShown(false)}
          companyId={companyId}
          onSubmit={handleBulkSubmit}
        />
      )}
    </div>
  );
};
