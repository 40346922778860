import React, { useMemo } from 'react';
import { File } from '@generated/types/graphql';
import { isImageFile, isPdfFile } from '@utils/files';
import { Badge } from '@common/Badge';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { FileBadge } from './FileBadge';
import { FileImageBadge } from './FileImageBadge';
import { Wrapper } from './styled';

type FilesListProps = {
  files: File[];
  onRemove?: (file: File) => void;
};

export const FilesList: React.FC<FilesListProps> = (props) => {
  const { files, onRemove } = props;

  const images = useMemo(() => files.filter(isImageFile), [files]);
  const pdfs = useMemo(() => files.filter(isPdfFile), [files]);
  const other = useMemo(() => files.filter((file) => !isImageFile(file) && !isPdfFile(file)), [files]);

  const { openDrawer } = useDrawersContext();

  const handleFileClick = (id: number) => {
    // setTimeout to first process the click event on outside component
    setTimeout(() => {
      openDrawer(
        DrawerEntity.DOC,
        id,
        files.map((file) => file.id)
      );
    }, 0);
  };

  return (
    <Wrapper>
      {!files.length && <Badge key="placeholder" color="" style={{ visibility: 'hidden' }} />}
      {other.map((file) => (
        <FileBadge
          key={file.id}
          file={file}
          onClick={() => handleFileClick(file.id)}
          onRemove={onRemove ? () => onRemove(file) : undefined}
        />
      ))}
      {pdfs.map((file) => (
        <FileBadge
          key={file.id}
          file={file}
          onRemove={onRemove ? () => onRemove(file) : undefined}
          onClick={() => handleFileClick(file.id)}
        />
      ))}
      {images.map((file) => (
        <FileImageBadge
          key={file.id}
          file={file}
          onClick={() => {
            handleFileClick(file.id);
          }}
          onRemove={onRemove ? () => onRemove(file) : undefined}
        />
      ))}
    </Wrapper>
  );
};
