import styled from 'styled-components';
import { Zap } from 'react-feather';
import { colors, dropdown, fonts } from '@styles';
import { MenuItem } from '@material-ui/core';
import { AutomationState } from './type';

const strokeColor = (state: AutomationState) => {
  switch (state) {
    case AutomationState.full:
    case AutomationState.half:
      return colors.orange2;
    case AutomationState.empty:
      return colors.gray;
    default:
      return null;
  }
};

const fillColor = (state: AutomationState) => {
  switch (state) {
    case AutomationState.full:
      return colors.orange2;
    case AutomationState.half:
      return 'transparent';
    case AutomationState.empty:
      return colors.gray;
    default:
      return null;
  }
};

export const IconAutomation = styled(Zap)<{ state: AutomationState }>`
  margin: 0 -5px 0 auto;

  stroke: ${(props) => strokeColor(props.state)};
  fill: ${(props) => fillColor(props.state)};
`;

export const IconWrapper = styled.div<{ state: AutomationState }>`
  display: flex;
  align-items: center;
  color: ${(props) => strokeColor(props.state)};
  font: 500 12px/1.35 ${fonts.sans};
`;

export const TooltipWrapper = styled.div`
  font-family: ${fonts.sans};
  padding: 12px 0px;
`;

export const TooltipHeader = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 500;
  padding-left: 7px;

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

export const TooltipAutomation = styled(MenuItem)`
  display: flex;
  align-content: space-between;
  && {
    ${dropdown.item};
    text-transform: none;
  }
`;

export const IconAndCounter = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
`;
