import styled from 'styled-components';
import { Field as FormikField } from 'formik';
import { makeStyles } from '@material-ui/core';
import { colors, device, fonts, selectMui } from '@styles';
import { UiSizes } from '@enums';

export const Wrapper = styled.div`
  width: 100px;
  ${device.sm`
    width: auto;
  `}
`;

export const useStylesSelect = makeStyles(selectMui({ size: UiSizes.Small, noBorder: true }));

export const Field = styled(FormikField)`
  && {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;

    .MuiSelect-root {
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 8.5px 30px 8.5px 7px;
      
      ${device.sm`
        max-width: 120px;
      `};
      
      overflow: hidden;
      text-overflow: ellipsis;
      
      &:before {
        display: none;
        ${device.sm`
          display: inline-block;
        `};
        margin-right: 5px;
        content: '${(props) => props.label}';
        font: 500 12px/1.25 ${fonts.sans};
        color: ${colors.gray2};
        text-transform: initial;
      }
    }
    
    fieldset {
      top: 0;
      border-color: ${colors.white};
    }
    
    legend {
      display: none;
    }
  }
`;

export const Label = styled.label`
  display: block;
  font:  500 12px/1.25 ${fonts.sans};
  color: ${colors.gray};
  padding: 0 16px;
  margin: 8px 0;
`;
