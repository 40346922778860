import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { System, SystemFilter, SystemsConnection } from '@generated/types/graphql';
import { DeepPartial } from 'redux';
import { UseQueryOptions } from 'react-query/types/react/types';
import { apiErrorHandler } from '@utils';

export const useClientSystemList = (
  args: {
    filter?: DeepPartial<SystemFilter>;
  },
  opts: UseQueryOptions<System[]> = {}
) =>
  useQuery<System[]>(
    [ReactQueryKey.System, 'useClientSystemList', args],
    async () => {
      const { filter } = args;

      try {
        return (
          await postGraphql<{ systemsConnection: SystemsConnection }>(
            gql`
              query CLIENT_SYSTEM_LIST($filter: SystemFilter) {
                systemsConnection(filter: $filter) {
                  nodes {
                    id
                    name
                    addressForced
                  }
                }
              }
            `,
            {
              filter
            }
          )
        ).systemsConnection.nodes;
      } catch (error) {
        throw apiErrorHandler('Error fetching systems', error);
      }
    },
    {
      keepPreviousData: true,
      initialData: [],
      ...opts
    }
  );
