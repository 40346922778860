import React, { useEffect, useState } from 'react';
import { Badge } from '@kit/ui/Badge';
import { WidgetSettings } from '../../types';
import { ChipsContainer } from './styled';
import { useChips } from './useChips';

const createSpan = (text: string) => {
  const span = document.createElement('span');
  span.innerText = text;
  span.style.display = 'inline-block';
  span.style.visibility = 'hidden';
  span.style.position = 'absolute';
  span.style.whiteSpace = 'nowrap';
  span.style.fontSize = '14px';
  span.style.fontWeight = '400';
  span.style.fontFamily = '"Rubik", sans-serif';
  span.style.padding = '0 12px';

  return span;
};

interface Props {
  settings: WidgetSettings;
}

export const WidgetChips = ({ settings }: Props) => {
  const chips = useChips(settings);

  const [visibleChips, setVisibleChips] = useState<string[]>(chips);

  useEffect(() => {
    setVisibleChips(chips);
  }, [chips]);

  const containerNodeRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerNodeRef.current) {
      return () => {};
    }

    const containerNode = containerNodeRef.current;

    const handleResize = () => {
      const containerWidth = containerNode.offsetWidth;

      const chipsWidth =
        visibleChips.reduce((sum, chip) => {
          const span = createSpan(chip);

          document.body.appendChild(span);

          const width = span.offsetWidth;

          document.body.removeChild(span);

          return sum + width;
        }, 0) +
        8 * (visibleChips.length - 1);

      if (chipsWidth > containerWidth) {
        setVisibleChips((prev) => prev.slice(0, prev.length - 1));
      } else {
        setVisibleChips((prev) => {
          const nextChip = chips[prev.length];

          if (nextChip) {
            const span = createSpan(nextChip);

            document.body.appendChild(span);

            const width = span.offsetWidth;

            document.body.removeChild(span);

            if (chipsWidth + width + 8 > containerWidth) {
              return prev;
            }

            return [...prev, nextChip];
          }

          return prev;
        });
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chips, visibleChips]);

  if (chips.length === 0) {
    return null;
  }

  return (
    <div>
      <ChipsContainer ref={containerNodeRef}>
        {visibleChips.map((chip, index) => (
          <Badge key={index} color="#1D1D35" bgColor="#DBE5FF">
            {chip}
          </Badge>
        ))}
      </ChipsContainer>
    </div>
  );
};
