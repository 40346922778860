import styled, { css } from 'styled-components';
import { User } from 'react-feather';
import { colors, scroll } from '@styles';
import { defaultTheme } from '@themes';

export const Wrapper = styled.div<{ hideToolbar?: boolean; hideToolbarCompletly?: boolean; error?: boolean }>`
&&& {  
position: relative;
width: 100%;
  border-radius: 4px;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.red : '#dfdfe8')};
  background-color: ${({ theme }) => theme.colors.white};

  &:focus {
    outline: none;
  }

  .ql-container {
    border: none;
    border-radius: 4px;
  }

  .ql-editor {
    overflow: auto;
    height: auto;
    max-height: auto;
    min-height: 40px;
    padding: 8px;
    font: 300 14px/1.75 ${({ theme }) => theme.fonts.sans};
    word-break: break-word;

    a {
      word-break: break-word;
    }

    ${({ theme }) => theme.devices.md`
      max-height: 170px;        
    `};

    ${scroll};

    &.ql-blank::before {
      color: ${({ theme }) => theme.colors.gray};
      font: italic 12px/40px ${({ theme }) => theme.fonts.sans};
      left: 8px;
      right: 8px;
      top: 0;
    }
  }

  .ql-toolbar.ql-snow {
    border: none;
 
    visibility: ${({ hideToolbar }) => (hideToolbar ? 'hidden' : 'visible')};

    ${({ hideToolbarCompletly }) =>
      hideToolbarCompletly &&
      css`
        display: none;
      `};
  }

  .ql-snow {
    .ql-stroke {
      stroke: ${({ theme }) => theme.colors.gray2};
    }
    .ql-fill {
      fill: ${({ theme }) => theme.colors.gray2};
    }
    &.ql-toolbar button:hover .ql-stroke {
      stroke: ${({ theme }) => theme.colors.black};
    }
    &.ql-toolbar button:hover .ql-fill {
      fill: ${({ theme }) => theme.colors.black};
    }

    .ql-tooltip {
      z-index: 1;
      left: -20px !important;
      top: -40px !important;
      border-radius: 4px;
      border: none;

      &:before {
        color: ${({ theme }) => theme.colors.black};
        font: 12px/30px ${({ theme }) => theme.fonts.sans};
      }

      input[type='text'] {
        border-radius: 4px;
        border: 1px solid #e8ecef;
        outline: none;
        height: 30px;
        &:hover,
        &:focus {
          border-color: #c7cbce;
        }
      }

      .ql-preview {
        color: #06c;
        text-decoration: underline;
        font: 300 14px/30px ${({ theme }) => theme.fonts.sans};
        margin-right: 20px;
      }

      .ql-action,
      .ql-remove {
        line-height: 26px;
        padding: 0;
        margin-left: 5px;
        text-align: center;
        border-radius: 4px;
        height: 30px;
        display: inline-block;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;

        &:after,
        &:before {
          border: none;
          padding: 0;
          margin: 0;
        }
      }

      .ql-action {
        background-color: #f2f4f6;
        color: #778ca4;
      }
      .ql-remove {
        background-color: ${({ theme }) => theme.colors.red};
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

export const WrapperAsInput = styled.div<{ isEmpty?: boolean }>`
  ${Wrapper} {
    .ql-editor {
      min-height: 40px;
      max-height: 40px;
      ${({ isEmpty }) =>
        isEmpty
          ? `
        line-height: 40px;
      `
          : `
        display: flex;
        align-items: center;
      `}
      padding: 0 8px;
      overflow: hidden;
    }
  }
}
`;

export const OptionItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  font: 14px/1.25 ${defaultTheme.fonts.sans};
  color: ${defaultTheme.colors.black};
`;

export const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 10px;
  object-fit: cover;
`;

export const Icon = styled(User)`
  border-radius: 4px;
  margin-right: 10px;
  background-color: ${defaultTheme.colors.gray3};
  color: ${defaultTheme.colors.gray};
  padding: 2px;
`;

export const ValueSecondPart = styled.strong`
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline;
  max-width: 210px;
  white-space: nowrap;
  padding-left: 2px;
  font-weight: 500;
`;

export const AttachmentToolbarButton = styled.button`
  &&& {
    color: #778ca2;

    &:hover,
    &:focus {
      color: #1d1d35;
    }
  }
`;

export const HiddenFileInput = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  display: none;
`;

export const FilesGrid = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  ${scroll};
  gap: 8px;
`;

export const RemoveFileButton = styled.div`
  cursor: pointer;
  color: #d54855;
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: 20px;
  height: 20px;

  opacity: 0;
  transition: opacity 0.15s ease-in-out;
`;

export const ThumbnailContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  position: relative;

  &:hover ${RemoveFileButton} {
    opacity: 1;
  }
`;

export const Thumbnail = styled.div`
  flex-shrink: 0;
  min-width: 68px;
  width: 68px;
  height: 68px;
  background-image: url(${(props) => props.url});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #e8ecef;
`;

export const Spinner = styled.div`
  min-width: 68px;
  height: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const ActiveFileDropZone = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(255, 255, 255, 0.85);
  font-size: 24px;
  z-index: 2;
  border: 2px dashed ${colors.green};

  display: flex;
  align-items: center;
  justify-content: center;
`;
