import React from 'react';
import { X, User } from 'react-feather';

import styled from 'styled-components';
import { colors, fonts } from '../../../../styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 24px;
  margin: 4px 2px;

  border-radius: 4px;
  background-color: ${colors.white3};

  font: 400 12px/1.25 ${fonts.sans};
  white-space: nowrap;
  color: ${colors.black};
  user-select: none;
`;

const Remove = styled(X)`
  color: ${colors.gray};
  margin: 0 7px 0 5px;
  cursor: pointer;
  transition: 0.3s ease-out;

  &:hover,
  &:focus {
    color: ${colors.green};
  }
`;

const Avatar = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin: 0 5px;
  object-fit: cover;
`;

const Icon = styled(User)`
  margin: 0 5px;
`;

interface Props {
  id: number;
  name: string;
  avatarUrl: string;
  removeUserHandler: (id: number) => void;
}

const UserPill: React.FC<Props> = (props: Props) => {
  const { id, name, avatarUrl, removeUserHandler } = props;
  const thumb = avatarUrl ? (
    <Avatar src={avatarUrl} alt={name} />
  ) : (
    <Icon size={20} color={colors.gray} />
  );

  return (
    <Wrapper>
      {thumb}
      {name}
      <Remove size={15} onClick={() => removeUserHandler(id)} />
    </Wrapper>
  );
};

export default UserPill;
