import React, { useMemo } from 'react';
import { Checkbox as CheckboxMui, CheckboxProps as CheckboxPropsMui, makeStyles } from '@material-ui/core';
import { checkbox } from './styled';
import { CheckboxOff } from './icons/CheckboxOff';
import { CheckboxOn } from './icons/CheckboxOn';
import { CheckboxIndeterminate } from './icons/CheckboxIndeterminate';
import { SizeEnum } from './type';

interface CheckboxProps extends CheckboxPropsMui {
  indeterminate?: boolean
  size?: SizeEnum
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const {
    indeterminate = false,
    size = SizeEnum.MEDIUM,
    ...rest
  } = props;

  const makeStylesParam = useMemo(() => (checkbox({ size })), [size]);
  const classes = makeStyles(makeStylesParam)();

  return (
    <CheckboxMui
      classes={classes}
      color="primary"
      indeterminate={indeterminate}
      disabled={indeterminate}
      checkedIcon={<CheckboxOn />}
      icon={<CheckboxOff />}
      indeterminateIcon={<CheckboxIndeterminate />}
      {...rest}
    />
  );
};
