import { ReactQueryKey } from '@enums';
import { FormsConnection, Form } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

export type FormTemplateListItem = Pick<Form, 'id' | 'name' | 'fileId'>;

export const useFormTemplateList = () => {
  const companyId = useSelector(selectWorkspaceId);

  return useQuery<FormTemplateListItem[]>([ReactQueryKey.FormTemplateList], async () => {
    try {
      const { formsConnection } = await postGraphql<{ formsConnection: FormsConnection }>(
        gql`
          query ($companyId: Int!) {
            formsConnection(
              filter: { isTemplate: { equalTo: true }, companyId: { equalTo: $companyId } }
              orderBy: CREATED_AT_DESC
            ) {
              nodes {
                id
                name
                fileId
              }
            }
          }
        `,
        { companyId }
      );

      return formsConnection.nodes;
    } catch (e) {
      throw apiErrorHandler('Error fetching form templates', e);
    }
  });
};
