import styled, { css } from 'styled-components';
import { MessageBannerTypeEnum } from './type';

export const Wrapper = styled.div<{ type: MessageBannerTypeEnum}>`
  ${({ theme: { colors }, type }) => css`
    ${() => {
      switch (type) {
        case MessageBannerTypeEnum.info:
          return `background-color: ${colors.green}; color: ${colors.white};`;
        case MessageBannerTypeEnum.warning:
          return `background-color: ${colors.yellow}; color: ${colors.black};`;
        case MessageBannerTypeEnum.danger:
          return `background-color: ${colors.red}; color: ${colors.black};`;
        default:
          return 'background-color: #fff';
      }
    }}
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    padding: 15px 30px;
    
    svg {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }
  `}
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: auto;
`;

export const Title = styled.div`
  font: 500 16px/1.25 ${({ theme }) => theme.fonts.sans};
  margin-bottom: 5px;
`;

export const Subtitle = styled.span`
  font: 300 14px/1.25 ${({ theme }) => theme.fonts.sans};
`;
