import { ReactQueryKey } from '@enums';
import { ProjectStage } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

export type StageListItem = Pick<
  ProjectStage,
  'id' | 'name' | 'description' | 'createdAt' | 'type' | 'scope' | 'redSla' | 'yellowSla'
>;

export const useStageList = () => {
  const companyId = useSelector(selectWorkspaceId);

  return useQuery<StageListItem[]>([ReactQueryKey.WorkspaceStagesGql], async () => {
    try {
      const { projectStages } = await postGraphql<{ projectStages: ProjectStage[] }>(
        gql`
          query ($companyId: Int!) {
            projectStages(filter: { companyId: { equalTo: $companyId } }) {
              id
              name
              type
              scope
              createdAt
              redSla
              yellowSla
              description
            }
          }
        `,
        { companyId }
      );

      return projectStages;
    } catch (e) {
      throw apiErrorHandler('Error fetching workspace stages', e);
    }
  });
};
