import { ReactQueryKey } from '@enums';
import { PaginationResult } from '@types';
import api from '@services/api/formTemplateApi';
import { useTemplatesGeneric, makeStates } from '@hooks/useTemplatesGeneric';
import { useFileMutation } from '@hooks/useFiles';
import { useRoutes } from '@hooks/useRoutes';
import { useQueryClient, useQuery } from 'react-query';
import { Form, FormsConnection } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { useFormMutations } from '@hooks/useForms';

const states = makeStates();

/** @deprecated, use {@link useFormTemplates} for the template list */
export const useTemplatesForm = () => {
  const result = useTemplatesGeneric<Form>({
    api: {
      ...api,
      find: async (params) => ({
        data: (
          await postGraphql<{ formsConnection: PaginationResult<Form> }>(
            gql`
              query ($companyId: Int!, $search: String) {
                formsConnection(
                  orderBy: [CREATED_AT_DESC]
                  filter: {
                    companyId: { equalTo: $companyId }
                    isTemplate: { equalTo: true }
                    name: { includesInsensitive: $search }
                  }
                ) {
                  results: nodes {
                    id
                    name
                    description
                    fileId
                    isTemplate
                    createdAt
                    formAssignees {
                      projectMember {
                        member {
                          id
                          firstName
                          lastName
                          avatarUrl
                        }
                      }
                    }
                  }
                }
              }
            `,
            {
              companyId: params.companyId,
              ...(params?.filters[0]?.val ? { search: params?.filters[0]?.val?.trim() } : {})
            }
          )
        ).formsConnection
      })
    },
    entityName: 'form template',
    cacheKey: ReactQueryKey.FormTemplate,
    states,
    initialFetch: true,
    fetchAll: true,
    invalidateKeys: [ReactQueryKey.FormTemplate, ReactQueryKey.FormTemplates]
  });
  const queryClient = useQueryClient();
  const { removePublic: fileRemove } = useFileMutation();
  const { create } = useFormMutations();

  const remove = {
    mutate: (id: number) =>
      fileRemove.mutate(
        {
          fileId: id
        },
        {
          onSuccess: () =>
            Promise.all([
              [ReactQueryKey.FormTemplate, ReactQueryKey.FormTemplates].map((key) => queryClient.invalidateQueries(key))
            ])
        }
      )
  };

  return {
    ...result,
    remove,
    create: {
      mutate: (dto) =>
        create.mutate(
          {
            dto
          },
          {
            onSuccess: () =>
              Promise.all([
                [ReactQueryKey.FormTemplate, ReactQueryKey.FormTemplates].map((key) =>
                  queryClient.invalidateQueries(key)
                )
              ])
          }
        )
    },
    normalizedItems:
      result?.fetch?.data?.results.map(({ id, name, formAssignees }) => ({
        id,
        name,
        assignees: formAssignees.map(
          ({
            projectMember: {
              member: { lastName, firstName, ...memberProps }
            }
          }) => ({
            ...memberProps,
            name: `${firstName} ${lastName}`
          })
        )
      })) ?? []
  };
};

export const useFormTemplates = () => {
  const { companyId } = useRoutes();

  return useQuery<Form[]>(
    [ReactQueryKey.FormTemplates],
    async () => {
      const {
        formsConnection: { nodes: forms }
      } = await postGraphql<{ formsConnection: FormsConnection }>(
        gql`
          query ($companyId: Int!) {
            formsConnection(
              filter: { isTemplate: { equalTo: true }, companyId: { equalTo: $companyId } }
              orderBy: [CREATED_AT_DESC]
            ) {
              nodes {
                id
                name
                description
                templateId
                file {
                  id
                  projectId
                }
                fileId
              }
            }
          }
        `,
        { companyId }
      );

      return forms;
    },
    {
      enabled: !!companyId,
      initialData: []
    }
  );
};
