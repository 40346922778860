import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { selectWorkspaceId } from '@state/selectors';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import userApi from '@services/api/userApi';
import { InvitationStatus } from '@types';
import { useToast } from '..';

type InviteUserPayload = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  roleId: number;
  isCollaborator: boolean;
};

export const useInviteUser = () => {
  const queryClient = useQueryClient();
  const { showSuccess, showError, showWarning } = useToast();
  const companyId = useSelector(selectWorkspaceId);

  return useMutation<InvitationStatus[], Error, InviteUserPayload>(
    async (req) => {
      try {
        return (await userApi.inviteUser(companyId, req)).data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: (response) => {
        const { status, email } = response[0];

        switch (status) {
          case 'success':
            showSuccess(`User <b>${email}</b> invited successfully`);
            break;
          case 'exists':
            showSuccess(`User <b>${email}</b> is already invited.`);
            break;
          case 'failed':
            showError(`Failed to invite user <b>${email}</b>`);
            break;
          case 'processing':
            showWarning(`Something went wrong while inviting <b>${email}</b>`);
            break;
          default:
            throw new Error(`Unrecognized invite status ${status satisfies never}`);
        }

        queryClient.invalidateQueries([ReactQueryKey.CompanyMembersList, companyId]);
      }
    }
  );
};
