import styled from 'styled-components';
import { fonts } from '@styles';

export const AddButtonContainer = styled.div`
  border-top: 1px solid #dfdfe8;
`;

export const AddButton = styled.div`
  font: 500 12px ${fonts.sans};
  color: #009688;
  cursor: pointer;
  padding: 12px;
`;

export const FormOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    flex-shrink: 0;
  }
`;
