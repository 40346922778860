import {
  AnalyticsMeasureEntityType,
  AnalyticsModule,
  AnalyticsSubmeasureType,
  AnalyticsWidgetType,
  DimensionType,
  RecordDimensionType,
  StandardPropertyId,
  TimeGranularity,
  WidgetSettings,
  WorkOrderDimensionType
} from '@features/Analytics/types';
import {
  DatetimeFilter,
  ProjectReportCondition,
  ProjectReportFilter,
  TaskReportCondition,
  TaskReportFilter,
  TaskStatus
} from '@generated/types/graphql';
import { DeepPartial } from 'redux';
import { Property } from '@types';
import { InternalConfigutationError } from '../InternalConfigurationError';
import { VISITS_PER_WORK_ORDER_PROPERTY_ID } from '../constants';
import { buildDateRangeFilterByTimeGranularity, buildUserDrilldownFilter } from './helpers';
import { WidgetSettingsError } from '../WidgetSettingsError';
import { isStatusLifecycleWorkflow } from '../helpers';

const workOrdersDrilldownFilterBuilders: Record<
  WorkOrderDimensionType,
  (args: { segment: string | number | number[] | null; settings: WidgetSettings; timezone: string }) => {
    filter?: DeepPartial<TaskReportFilter>;
    condition?: DeepPartial<TaskReportCondition>;
  }
> = {
  [DimensionType.STATUS]: ({ segment }) => {
    if (segment === null) {
      return {
        filter: {
          status: {
            isNull: true
          }
        }
      };
    }

    return {
      filter: {
        status: {
          equalTo: segment as TaskStatus
        }
      }
    };
  },
  [DimensionType.PRIORITY]: ({ segment }) => {
    if (segment === null) {
      return {
        filter: {
          priority: {
            isNull: true
          }
        }
      };
    }

    return {
      filter: {
        priority: {
          equalTo: segment as number
        }
      }
    };
  },
  [DimensionType.TEMPLATE]: ({ segment }) => {
    if (segment === null) {
      return {
        filter: {
          templateTaskExists: false
        }
      };
    }

    return {
      filter: {
        templateTaskId: {
          equalTo: segment as number
        }
      }
    };
  },
  [DimensionType.ASSIGNEE]: ({ segment, settings }) => ({
    filter: buildUserDrilldownFilter<TaskReportFilter, 'assigneeId'>(segment, settings, 'assigneeId')
  }),
  [DimensionType.LABEL]: (segment) => {
    if (segment === null) {
      return {
        filter: {
          taskLabelsByTaskIdExist: false
        }
      };
    }

    return {
      filter: {
        taskLabelsByTaskId: {
          some: {
            label: {
              label: {
                equalTo: segment as string
              }
            }
          }
        }
      }
    };
  },
  [DimensionType.TIME]: ({ segment, settings, timezone }) => {
    if (segment === null) {
      throw new InternalConfigutationError('Time dimension drilldown segment should not be null');
    }

    const granularity = settings.subdimensionId as TimeGranularity;

    const dateRangeFilter: DeepPartial<DatetimeFilter> = buildDateRangeFilterByTimeGranularity(
      segment as string,
      granularity,
      timezone
    );

    if (settings.submeasureId) {
      if (settings.submeasureTypeId === AnalyticsSubmeasureType.ENTERED) {
        return {
          condition: {
            timelineStartedStartDate: dateRangeFilter.greaterThanOrEqualTo,
            timelineStartedEndDate: dateRangeFilter.lessThanOrEqualTo
          }
        };
      } else {
        return {
          condition: {
            timelineEndedStartDate: dateRangeFilter.greaterThanOrEqualTo,
            timelineEndedEndDate: dateRangeFilter.lessThanOrEqualTo
          }
        };
      }
    }

    if (settings.measure.id.toString() === VISITS_PER_WORK_ORDER_PROPERTY_ID) {
      return {
        filter: {
          isCompleted: {
            equalTo: true
          },
          completionDate: dateRangeFilter
        }
      };
    }

    if (settings.measure.entityType === AnalyticsMeasureEntityType.VISIT) {
      return {
        filter: {
          visitStartDate: dateRangeFilter
        }
      };
    }

    return {
      filter: {
        createdAt: dateRangeFilter
      }
    };
  }
};

const recordsDrilldownFilterBuilders: Record<
  RecordDimensionType,
  (args: {
    segment: string | number | number[] | null;
    settings: WidgetSettings;
    propertiesMap: Record<number, Property>;
    timezone: string;
  }) => {
    filter?: DeepPartial<ProjectReportFilter>;
    condition?: DeepPartial<ProjectReportCondition>;
  }
> = {
  [DimensionType.CREATOR]: ({ segment, settings }) => ({
    filter: buildUserDrilldownFilter<ProjectReportFilter, 'createdBy'>(segment, settings, 'createdBy')
  }),
  [DimensionType.PROJECT_MANAGER]: ({ segment, settings }) => ({
    filter: buildUserDrilldownFilter<ProjectReportFilter, 'projectManagerId'>(segment, settings, 'projectManagerId')
  }),
  [DimensionType.OWNER]: ({ segment, settings }) => ({
    filter: buildUserDrilldownFilter<ProjectReportFilter, 'ownerId'>(segment, settings, 'ownerId')
  }),
  [DimensionType.SALES_REP]: ({ segment, settings }) => ({
    filter: buildUserDrilldownFilter<ProjectReportFilter, 'salesRepId'>(segment, settings, 'salesRepId')
  }),
  [DimensionType.DROPDOWN_PROPERTY]: ({ segment, settings, propertiesMap }) => {
    const { subdimensionId } = settings;

    if (!subdimensionId) {
      throw new WidgetSettingsError('Dropdown property is not specified');
    }

    const propertyId = parseInt(subdimensionId.replace('property_', ''), 10);

    if (Number.isNaN(propertyId)) {
      throw new InternalConfigutationError('Dropdown property id is not a number');
    }

    if (propertyId === StandardPropertyId.TRADES) {
      if (segment === null) {
        return {
          filter: {
            or: [
              {
                trades: {
                  isNull: true
                }
              },
              {
                trades: {
                  equalTo: []
                }
              }
            ]
          }
        };
      }

      return {
        filter: {
          trades: {
            overlaps: [segment]
          }
        }
      };
    }

    const property = propertiesMap[propertyId];

    if (!property) {
      throw new WidgetSettingsError('Dropdown property is not found');
    }

    if (propertyId < 0) {
      if (segment === null) {
        return {
          filter: {
            [property.mappedName]: {
              isNull: true
            }
          }
        };
      }

      return {
        filter: {
          [property.mappedName]: {
            equalTo: segment
          }
        }
      };
    }

    // custom properties
    const isMulti = property.multiple;

    if (segment === null) {
      return {
        filter: {
          or: [
            {
              not: {
                projectPropertiesValues: {
                  some: {
                    columnId: {
                      equalTo: property.id as number
                    }
                  }
                }
              }
            },
            {
              projectPropertiesValues: {
                some: {
                  columnId: {
                    equalTo: property.id as number
                  },
                  dropdownValue: {
                    equalTo: []
                  }
                }
              }
            },
            {
              projectPropertiesValues: {
                some: {
                  columnId: {
                    equalTo: property.id as number
                  },
                  dropdownValue: {
                    isNull: true
                  }
                }
              }
            }
          ]
        }
      };
    }

    return {
      filter: {
        projectPropertiesValues: {
          some: {
            columnId: {
              equalTo: property.id as number
            },
            dropdownValue: {
              [isMulti ? 'overlaps' : 'equalTo']: isMulti ? [segment] : segment
            }
          }
        }
      }
    };
  },
  [DimensionType.PRODUCT_CATEGORY]: ({ segment }) => {
    if (segment === null) {
      return {
        filter: {
          productName: {
            isNull: true
          }
        }
      };
    }

    return {
      filter: {
        productName: {
          equalTo: segment as string
        }
      }
    };
  },
  [DimensionType.TIME]: ({ segment, settings, timezone }) => {
    if (segment === null) {
      throw new InternalConfigutationError('Time dimension drilldown segment should not be null');
    }

    const granularity = settings.subdimensionId as TimeGranularity;

    const dateRangeFilter: DeepPartial<DatetimeFilter> = buildDateRangeFilterByTimeGranularity(
      segment as string,
      granularity,
      timezone
    );

    if (settings.submeasureId) {
      const isEntered = settings.submeasureTypeId === AnalyticsSubmeasureType.ENTERED;
      if (isEntered) {
        return {
          condition: {
            timelineStartedStartDate: dateRangeFilter.greaterThanOrEqualTo,
            timelineStartedEndDate: dateRangeFilter.lessThanOrEqualTo
          }
        };
      } else {
        return {
          condition: {
            timelineEndedStartDate: dateRangeFilter.greaterThanOrEqualTo,
            timelineEndedEndDate: dateRangeFilter.lessThanOrEqualTo
          }
        };
      }
    }

    return {
      filter: {
        createdAt: dateRangeFilter
      }
    };
  }
};

export const buildFilterForDrilldown = (
  settings: WidgetSettings,
  segment: string | number | number[] | null,
  propertiesMap: Record<number, Property>,
  timezone: string
) => {
  const { dimensionId, widgetType, workflowId } = settings;

  if (
    widgetType === AnalyticsWidgetType.TIMELINE ||
    widgetType === AnalyticsWidgetType.PIPELINE ||
    widgetType === AnalyticsWidgetType.FUNNEL
  ) {
    if (settings.module === AnalyticsModule.WORK_ORDERS) {
      const result: DeepPartial<TaskReportFilter> = {
        timelineStatus: {
          equalTo: segment as TaskStatus
        }
      };

      return {
        filter: result
      };
    } else {
      const isStatus = isStatusLifecycleWorkflow(workflowId);
      const result: DeepPartial<ProjectReportFilter> = {};

      if (isStatus) {
        result.timelineStatus = {
          equalTo: segment as string
        };
      } else {
        result.timelineStageId = {
          equalTo: segment as number
        };
      }

      return {
        filter: result
      };
    }
  }

  if (!dimensionId) {
    return {};
  }

  const builder =
    settings.module === AnalyticsModule.WORK_ORDERS
      ? workOrdersDrilldownFilterBuilders[dimensionId as WorkOrderDimensionType]
      : recordsDrilldownFilterBuilders[dimensionId as RecordDimensionType];

  if (!builder) {
    return {};
  }

  return builder({ segment, settings, propertiesMap, timezone });
};
