import colors from '../colors';
import fonts from '../fonts';

const button = (props) => `
  position: relative;
  
  width: auto;  
  height: 40px;
  
  padding: 0 10px;
  
  @media (min-width: 768px) {
    min-width: 120px;
    padding: 0 15px;
  }
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  border: none;
  border-radius: 4px;
  
  border: 1px solid transparent;

  opacity: ${props.disabled ? '0.6' : '1'};
  pointer-events: ${props.disabled ? 'none' : 'all'};
  cursor: ${props.disabled ? 'not-allowed' : 'pointer'};

  border-color: ${
    props.primary
      ? colors.green
      : props.secondary
      ? colors.blue
      : props.outline
      ? colors.gray3
      : 'transparent'
  };
  
  background-color: ${
    props.primary
      ? colors.green
      : props.secondary
      ? colors.blue
      : props.outline
      ? 'transparent'
      : colors.white3
  };
  
  color: ${
    props.primary || props.secondary
      ? colors.white
      : props.outline
      ? colors.gray2
      : colors.gray2
  };
  
  font: 500 14px/1.25 ${fonts.sans};
  text-transform: uppercase;
  white-space: nowrap;
  
  transition: .3s;
  
  &:hover, focus {
    opacity: ${props.disabled ? '0.6' : '0.8'};
  }
  
  &:focus {
    outline: none;
  }
    
  // Icon
    
  img {
    position: absolute;
    left: 16px;
  }
`;

export default button;
