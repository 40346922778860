import {
  Form,
  FormValidationRules,
  useForm,
  SelectField,
  InputField,
  RadioGroupField,
  AddressField,
  RichTextField
} from '@kit/components/Form';
import React, { useMemo, useEffect, useCallback } from 'react';
import { selectCurrentUser, selectWorkspaceId } from '@state/selectors';
import { useAllCompaniesUsers, useAppSelector, useCompanyPropertiesWithoutRouter } from '@hooks';
import { useRecordsMutations } from '@hooks/useRecords';
import { useContactMutations, useContactsByQuery } from '@hooks/useContacts';
import { useDebouncedState } from '@hooks/useDebouncedState';

import { AccountType, RecordType, User } from '@types';
import { AccountTypeBadge } from '@features/ClientPortfolio/components/Type';
import { getFullName } from '@utils/utils';
import DropDownItem from '@common/Selector/UserSelector/DropDownItem';
import { isEmail, validateUrl } from '@utils/validations';
import { getCustomDTOValue, isButtonOrLink } from '@utils/properties';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { ProjectColumnVisibility } from '@generated/types/graphql';
import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { CustomFields } from './CustomFields';
import { Sections, SectionTitle, TwoFields, ContactOptionTitle, ContactOptionMeta } from './styled';

interface Props {
  onClose: (newClient: { id: number; title: string; address: string[] } | void) => void;
  isPreview?: boolean;
  initialValues?: {
    primaryContact: {
      id: number;
      name: string;
    };
  };
}

interface FormValues {
  primaryContact: any; // TODO
  primaryContactPhone?: string;
  primaryContactEmail?: string;

  title: string;
  accountType: AccountType;

  owner: User;
  address: string;
  description: string;
}

export const ClientForm = ({ initialValues, onClose, isPreview }: Props) => {
  const { data: companyUsers } = useAllCompaniesUsers();
  const currentUser = useAppSelector(selectCurrentUser);
  const companyId = useAppSelector(selectWorkspaceId);
  const {
    scopeToEditableColumns,
    fetchQuery: { isFetched: propertiesLoaded }
  } = useCompanyPropertiesWithoutRouter(companyId, { recordType: RecordType.ACCOUNT });

  const additionalPropertiesById = useMemo(() => {
    return scopeToEditableColumns[RecordType.ACCOUNT]
      .filter((property) => property.isAdditional)
      .reduce((acc, property) => {
        acc[property.id] = property;

        return acc;
      }, {});
  }, [scopeToEditableColumns]);

  const customPropsValidations = useMemo(() => {
    return scopeToEditableColumns[RecordType.ACCOUNT].reduce((acc, property) => {
      if (property.isAdditional && property.internalVisibility === ProjectColumnVisibility.Required) {
        acc[`additional.${property.id}`] = {
          isRequired: true
        };
      }

      if (property.isAdditional && isButtonOrLink(property)) {
        if (!acc[`additional.${property.id}`]) {
          acc[`additional.${property.id}`] = {};
        }
        acc[`additional.${property.id}`].validate = validateUrl;
      }

      return acc;
    }, {});
  }, [scopeToEditableColumns]);

  const { create: createClient } = useRecordsMutations(companyId, RecordType.ACCOUNT);
  const { create: createContact } = useContactMutations(companyId);

  const [, debouncedNameInputValue, setContactNameInput] = useDebouncedState('', 300);

  const { data: contacts = [] } = useContactsByQuery(companyId, debouncedNameInputValue);

  const postForm = async (values: FormValues) => {
    let contact = values.primaryContact;

    if (values.primaryContact.id === 0) {
      contact = await createContact.mutateAsync({
        dto: {
          name: values.primaryContact.name.trim(),
          emails: values.primaryContactEmail ? [values.primaryContactEmail.trim()].filter(Boolean) : [],
          phones: values.primaryContactPhone ? [values.primaryContactPhone.trim()].filter(Boolean) : []
        }
      });
    }

    const dto = {
      accountType: values.accountType,
      title: values.title.trim(),
      description: values.description,
      address: [values.address],
      ownerId: values.owner?.id ?? null,
      type: RecordType.ACCOUNT,
      contacts: [{ contactId: contact.id }],
      additional: Object.keys(values.additional ?? {}).reduce((acc, propertyId) => {
        return {
          ...acc,
          [propertyId]: getCustomDTOValue(additionalPropertiesById[propertyId].type, values.additional[propertyId])
        };
      }, {})
    };

    const { id, title, address } = await createClient.mutateAsync({ dto });

    onClose({ id, title, address });
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    defaultValues: {
      accountType: AccountType.RESIDENTIAL,
      owner: currentUser,
      ...initialValues
    }
  });

  const {
    formState: { isSubmitting },
    control,
    watch,
    setValue,
    reset
  } = form;

  useEffect(() => {
    if (propertiesLoaded) {
      reset({
        additional: scopeToEditableColumns[RecordType.ACCOUNT].reduce((acc, property) => {
          if (!property.isAdditional || !property.additional?.defaultValue) {
            return acc;
          }

          return {
            ...acc,
            [property.id]: property.additional.defaultValue
          };
        }, {})
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertiesLoaded]);

  const primaryContact = watch('primaryContact');
  const title = watch('title');

  useEffect(() => {
    if (primaryContact && primaryContact.id !== 0) {
      setValue('primaryContactPhone', primaryContact.phones[0]);
      setValue('primaryContactEmail', primaryContact.emails[0]);
    }

    if (!primaryContact) {
      setValue('primaryContactPhone', '');
      setValue('primaryContactEmail', '');
    }
  }, [primaryContact, setValue]);

  useEffect(() => {
    if (primaryContact && !title) {
      setValue('title', primaryContact.name);
    }
  }, [primaryContact, title, setValue]);

  const isNewContact = primaryContact && primaryContact.id === 0;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      primaryContact: {
        isRequired: true
      },
      primaryContactEmail: {
        validate: (value) => {
          if (!value || !value.trim()) {
            return undefined;
          }

          return isEmail(value.trim()) ? undefined : 'Invalid email';
        }
      },
      title: {
        isRequired: true
      },
      accountType: {
        isRequired: true
      },
      address: {
        isRequired: true
      },
      owner: {
        isRequired: true
      },
      ...customPropsValidations
    }),
    [customPropsValidations]
  );

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody width="700px">
        <Sections>
          <div>
            <SectionTitle>Primary contact</SectionTitle>

            <TwoFields>
              <SelectField
                label="Full name"
                control={control}
                name="primaryContact"
                getOptionLabel={(option: User) => option.name}
                options={contacts}
                filterOptions={(options) => options}
                placeholder="Search by name, email or phone"
                renderOption={(contact) => (
                  <div>
                    <ContactOptionTitle>{contact.name}</ContactOptionTitle>
                    <ContactOptionMeta>
                      {[contact.emails?.[0], contact.phones?.[0]].filter(Boolean).join(', ')}
                    </ContactOptionMeta>
                  </div>
                )}
                isCreatable
                createButtonText={(inputValue: string) =>
                  inputValue ? `+ Create "${inputValue}" as new contact` : '+ Create new contact'
                }
                buildNewOption={(inputValue: string) => {
                  return { id: 0, name: inputValue };
                }}
                onInputChange={(e) => {
                  if (!e) {
                    return;
                  }
                  setContactNameInput(e.target.value);
                }}
              />
              <div />
            </TwoFields>

            <TwoFields>
              <InputField
                disabled={primaryContact && !isNewContact}
                control={control}
                label="Phone"
                name="primaryContactPhone"
              />
              <InputField
                disabled={primaryContact && !isNewContact}
                control={control}
                label="Email"
                name="primaryContactEmail"
              />
            </TwoFields>
          </div>

          <div>
            <SectionTitle>Standard properties</SectionTitle>

            <TwoFields>
              <InputField control={control} label="Title" name="title" />

              <RadioGroupField
                control={control}
                label="Type"
                name="accountType"
                layout="row"
                renderOptionLabel={(option) => <AccountTypeBadge type={option.value} />}
                options={[
                  { label: 'Commercial', value: AccountType.COMMERCIAL },
                  { label: 'Residential', value: AccountType.RESIDENTIAL }
                ]}
              />
            </TwoFields>

            <TwoFields>
              <SelectField
                label="Owner"
                name="owner"
                control={control}
                options={companyUsers}
                getOptionLabel={getFullName}
                noOptionsText="User not found"
                renderOption={(option: any) => (
                  <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={getFullName(option)} />
                )}
              />
              <div />
            </TwoFields>

            <AddressField label="Site address" name="address" control={control} />

            <RichTextField label="Description" name="description" control={control} />
          </div>

          <CustomFields type={RecordType.ACCOUNT} control={control} />
        </Sections>
      </ModalBody>
      <ModalFooter>
        <Button disabled={isPreview} variant={ButtonVariant.Secondary} onClick={handleCancel}>
          Cancel
        </Button>
        <Button disabled={isPreview || isSubmitting} variant={ButtonVariant.Primary} type="submit">
          Create
        </Button>
      </ModalFooter>
    </Form>
  );
};
