import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { SystemCondition, SystemFilter, SystemsConnection } from '@generated/types/graphql';
import { DeepPartial } from 'redux';
import { ChartMilestone, milestoneObservedInterval, SortField, sortToExpr } from '@hooks/systems/constants';
import { UseQueryOptions } from 'react-query/types/react/types';
import { SystemWithStats } from '@hooks/systems/types';
import { apiErrorHandler } from '@utils';

export const useSystems = (
  args: {
    condition?: DeepPartial<SystemCondition>;
    filter?: DeepPartial<SystemFilter>;
    first?: number;
    offset?: number;
    orderBy?: [field: SortField, desc?: boolean][];
  },
  opts: UseQueryOptions<SystemWithStats[]> = {}
) =>
  useQuery<SystemWithStats[]>(
    [ReactQueryKey.System, 'useSystems', args],
    async () => {
      const { condition, filter, first = 10, offset = 0, orderBy } = args;

      try {
        return (
          await postGraphql<{ systemsConnection: SystemsConnection }>(
            gql`
              query SYSTEM_LIST(
                $condition: SystemCondition
                $filter: SystemFilter
                $first: Int
                $offset: Int
                $orderBy: [SystemsOrderBy!]
                $yesterdayStartAt: Datetime
                $weekStartAt: Datetime
                $monthStartAt: Datetime
                $yearStartAt: Datetime
                $lifetimeStartAt: Datetime
                $yesterdayEndBefore: Datetime
                $weekEndBefore: Datetime
                $monthEndBefore: Datetime
                $yearEndBefore: Datetime
                $lifetimeEndBefore: Datetime
              ) {
                systemsConnection(
                  condition: $condition
                  filter: $filter
                  first: $first
                  offset: $offset
                  orderBy: $orderBy
                ) {
                  nodes {
                    id
                    createdAt
                    monitored
                    raw
                    providerId
                    status
                    operationalAt
                    address
                    addressForced
                    addressCity
                    addressState
                    addressStreet
                    addressZip
                    lastReportAt
                    connectionType
                    size
                    providerStatus
                    name
                    number
                    clientType
                    uuid
                    notes
                    installedAt
                    operationStatus

                    integration {
                      id
                      provider
                    }

                    project {
                      id
                    }

                    productionYesterday: energyProductionChartsBySystemConnection(
                      condition: { seriesPeriod: { minutes: 15 }, targetPeriod: { days: 1 } }
                      filter: { time: { greaterThanOrEqualTo: $yesterdayStartAt, lessThan: $yesterdayEndBefore } }
                    ) {
                      nodes {
                        value
                      }
                    }

                    productionWeek: energyProductionChartsBySystemConnection(
                      condition: { seriesPeriod: { days: 1 }, targetPeriod: { days: 7 } }
                      filter: { time: { greaterThanOrEqualTo: $weekStartAt, lessThan: $weekEndBefore } }
                    ) {
                      nodes {
                        value
                      }
                    }

                    productionMonth: energyProductionChartsBySystemConnection(
                      condition: { seriesPeriod: { days: 1 }, targetPeriod: { days: 30 } }
                      filter: { time: { greaterThanOrEqualTo: $monthStartAt, lessThan: $monthEndBefore } }
                    ) {
                      nodes {
                        value
                      }
                    }

                    productionYear: energyProductionChartsBySystemConnection(
                      condition: { seriesPeriod: { days: 1 }, targetPeriod: { years: 1 } }
                      filter: { time: { greaterThanOrEqualTo: $yearStartAt, lessThan: $yearEndBefore } }
                    ) {
                      nodes {
                        value
                      }
                    }

                    productionLifetime: energyProductionChartsBySystemConnection(
                      condition: { seriesPeriod: { days: 1 }, targetPeriod: { years: 1 } }
                      filter: { time: { greaterThanOrEqualTo: $lifetimeStartAt, lessThan: $lifetimeEndBefore } }
                    ) {
                      aggregates {
                        sum {
                          value
                        }
                      }
                    }

                    consumptionYesterday: energyConsumptionChartsBySystemConnection(
                      condition: { seriesPeriod: { minutes: 15 }, targetPeriod: { days: 1 } }
                      filter: { time: { greaterThanOrEqualTo: $yesterdayStartAt, lessThan: $yesterdayEndBefore } }
                    ) {
                      nodes {
                        value
                      }
                    }

                    consumptionWeek: energyConsumptionChartsBySystemConnection(
                      condition: { seriesPeriod: { days: 1 }, targetPeriod: { days: 7 } }
                      filter: { time: { greaterThanOrEqualTo: $weekStartAt, lessThan: $weekEndBefore } }
                    ) {
                      nodes {
                        value
                      }
                    }

                    consumptionMonth: energyConsumptionChartsBySystemConnection(
                      condition: { seriesPeriod: { days: 1 }, targetPeriod: { days: 30 } }
                      filter: { time: { greaterThanOrEqualTo: $monthStartAt, lessThan: $monthEndBefore } }
                    ) {
                      nodes {
                        value
                      }
                    }

                    consumptionYear: energyConsumptionChartsBySystemConnection(
                      condition: { seriesPeriod: { days: 1 }, targetPeriod: { years: 1 } }
                      filter: { time: { greaterThanOrEqualTo: $yearStartAt, lessThan: $yearEndBefore } }
                    ) {
                      nodes {
                        value
                      }
                    }

                    consumptionLifetime: energyConsumptionChartsBySystemConnection(
                      condition: { seriesPeriod: { days: 1 }, targetPeriod: { years: 1 } }
                      filter: { time: { greaterThanOrEqualTo: $lifetimeStartAt, lessThan: $lifetimeEndBefore } }
                    ) {
                      aggregates {
                        sum {
                          value
                        }
                      }
                    }

                    peakPower: powerProductionChartsBySystemConnection {
                      aggregates {
                        max {
                          value
                        }
                      }
                    }
                  }
                }
              }
            `,
            {
              condition,
              filter,
              first,
              offset,
              orderBy: sortToExpr(orderBy),
              ...Object.values(ChartMilestone).reduce(
                (acc, milestone) => ({
                  ...acc,
                  [`${milestone}StartAt`]: milestoneObservedInterval(milestone)?.start.toISO()
                }),
                {}
              ),
              ...Object.values(ChartMilestone).reduce(
                (acc, milestone) => ({
                  ...acc,
                  [`${milestone}EndBefore`]: milestoneObservedInterval(milestone)?.end.toISO()
                }),
                {}
              )
            }
          )
        ).systemsConnection.nodes as SystemWithStats[];
      } catch (error) {
        throw apiErrorHandler('Error fetching systems', error);
      }
    },
    {
      keepPreviousData: true,
      initialData: [],
      ...opts
    }
  );
