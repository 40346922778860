import { colors, device, deviceMax, scroll } from '@styles';
import styled from 'styled-components';

export const ReferencesLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
  margin-bottom: 8px;
`;

export const ReferenceFileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

export const ReferenceFileRow = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;

  color: ${colors.black};
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:link {
    color: ${colors.black};
  }
`;

export const ReferenceThumbnailContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

export const ReferenceThumbnail = styled.div`
  min-width: 24px;
  width: 24px;
  height: 24px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  border-radius: 4px;
  cursor: pointer;
`;

export const FileChooseWrapper = styled.div`
  .close-button {
    top: 24px;
  }
`;

export const DropZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 80px;

  border-radius: 4px;
  background-color: white;
  border: 1px dashed #dfdfe8;

  > span,
  .ant-upload-drag-container,
  .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

export const DropZoneHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: ${colors.green};
`;

export const ThumbnailContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  position: relative;
`;

export const Thumbnail = styled.div`
  flex-shrink: 0;
  min-width: 80px;
  width: 80px;
  height: 80px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #e8ecef;
`;

export const Spinner = styled.div`
  min-width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const FilesScrollableContainer = styled.div`
  display: grid;
`;
export const FilesGrid = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  ${scroll};
  gap: 8px;
  padding-top: 4px;
  margin-top: 20px;
`;

export const RemoveFileButton = styled.div`
  cursor: pointer;
  color: #d54855;
  position: absolute;
  top: -3px;
  right: -3px;
  z-index: 1;
  display: flex;
  background-color: #fff;
  border-radius: 50%;
`;

export const FilePropertiesContainer = styled.div`
  position: fixed;
  right: 0;
  top: 48px;
  bottom: 0;
  z-index: 1001;
  background: ${colors.white};
  width: 100%;
  height: calc(100% - -38px);
  box-shadow: -16px -16px 200px 0px #1d1d3552;
  ${deviceMax.sm`
     height: 100%;
  `}

  ${device.md`
  position: absolute;
  top: initial;
      width: 80%;
      height: calc(100vh - 48px);
    `}


  @media (min-width: 1500px) {
    width: 1050px;
    max-width: 90%;
  }
`;
