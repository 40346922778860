import React from 'react';
import { useFormikContext } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import { Paper } from '@material-ui/core';
import { Search, Shield } from 'react-feather';
import { pull, map } from 'lodash/fp';

import { getFullName, normalizeRoleName } from '@utils';
import { defaultTheme } from '@themes';
import { AccessOption, EntityAccessType, UserFromApi, RoleDTO, Team } from '@types';
import { useTeams } from '@hooks/useTeams';
import { useCompanyUsers, useRoutes } from '@hooks';
import { useRoles } from '@hooks/useRoles';
import { TextField } from '@common/ui';
import { makeStyles } from '@material-ui/core/styles';
import { autocomplete } from '@common/ui/Autocomplete/styled';
import { UserAvatar } from '@kit/components/UserAvatar';
import { Avatar } from '@kit/ui/Avatar';
import { ItemName, RemoveSelectedAccessOption, SelectedAccessOption } from './styled';

const renderAccessOption = (option: AccessOption) => {
  return (
    <>
      {option.type === EntityAccessType.team && (
        <>
          <Avatar user={{ id: option.key, firstName: option.team!.name }} size={20} />
          <ItemName>{option.team!.name}</ItemName>
        </>
      )}
      {option.type === EntityAccessType.user && (
        <>
          <UserAvatar user={option.user} size={20} />
          <ItemName>{getFullName(option.user)}</ItemName>
        </>
      )}
      {option.type === EntityAccessType.role && (
        <>
          <Shield color={defaultTheme.colors.green} size={20} />
          <ItemName>{normalizeRoleName(option.role!.name)}</ItemName>
        </>
      )}
    </>
  );
};

export const mapTeamsToAccessOptions = map<Team, AccessOption>((team) => ({
  type: EntityAccessType.team,
  teamId: team.id,
  team,
  key: `${EntityAccessType.team}-${team.id}`
}));

export const mapRolesToAccessOptions = map<RoleDTO, AccessOption>((role) => ({
  type: EntityAccessType.role,
  roleId: role.id,
  role,
  key: `${EntityAccessType.role}-${role.id}`
}));

export const mapUsersToAccessOptions = map<UserFromApi, AccessOption>((user) => ({
  type: EntityAccessType.user,
  id: user.id,
  user,
  key: `${EntityAccessType.user}-${user.id}`
}));

export interface AccessSelectorProps {
  name?: string;
  types: EntityAccessType[];
  onChange?: (event: React.ChangeEvent<{}>, value: AccessOption[]) => unknown;
  renderItemControls?: (
    option: AccessOption,
    index: number,
    setFieldValue: (key: string, value: any) => unknown
  ) => React.ReactNode;
}

export const AccessSelector: React.FC<AccessSelectorProps> = (props) => {
  const { name = 'access', types, renderItemControls, onChange } = props;
  const { setFieldValue, values } = useFormikContext<{ [key: string]: AccessOption[] }>();
  const classesAutocomplete = makeStyles(autocomplete({}))();
  const { companyId } = useRoutes();
  const { data: teams } = useTeams(companyId);
  const {
    rolesQuery: { data: roles }
  } = useRoles();
  const { data: { data: { results: companyUsers } = { results: [] as UserFromApi[] } } = {} } =
    useCompanyUsers(companyId);

  const accessOptions = [
    ...((types.includes(EntityAccessType.team) && teams) || []).map((team) => ({
      type: EntityAccessType.team,
      teamId: team.id,
      team: {
        ...team,
        users: team.teamUsers
      },
      key: `${EntityAccessType.team}-${team.id}`
    })),
    ...((types.includes(EntityAccessType.role) && roles) || []).map((role) => ({
      type: EntityAccessType.role,
      roleId: role.id,
      role,
      key: `${EntityAccessType.role}-${role.id}`
    })),
    ...((types.includes(EntityAccessType.user) && companyUsers) || []).map((user) => ({
      type: EntityAccessType.user,
      id: user.id,
      user,
      key: `${EntityAccessType.user}-${user.id}`
    }))
  ] as AccessOption[];

  return (
    <>
      <Autocomplete<AccessOption, true, true>
        classes={classesAutocomplete}
        multiple
        filterSelectedOptions
        value={values[name]}
        options={accessOptions}
        getOptionLabel={(option) =>
          ('role' in option && option.role.name) ||
          ('team' in option && option.team.name) ||
          ('user' in option && option.user && getFullName(option.user))
        }
        getOptionSelected={(op, val) => op.key === val.key}
        groupBy={(option) =>
          (option.type === EntityAccessType.team && 'Teams') ||
          (option.type === EntityAccessType.role && 'Roles') ||
          (option.type === EntityAccessType.user && 'Users') ||
          ''
        }
        onChange={(_event, value) => {
          if (onChange) {
            onChange(_event, value as AccessOption[]);
          } else {
            setFieldValue(name, value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={`Add by ${types.join('/')}...`}
            InputProps={{
              ...params.InputProps,
              endAdornment: <Search cursor="pointer" />
            }}
          />
        )}
        renderOption={(option) => renderAccessOption(option, accessOptions.indexOf(option))}
        renderTags={() => <></>}
        PaperComponent={({ children, ...props }) => <Paper {...props}>{children}</Paper>}
        forcePopupIcon={false}
        disableClearable
        style={{ marginBottom: '8px' }}
      />
      {((values[name] as AccessOption[]) || []).map((option, index) => (
        <SelectedAccessOption key={option.key}>
          {renderAccessOption(option, index)}
          {renderItemControls?.(option, index, setFieldValue) || null}
          <RemoveSelectedAccessOption onClick={() => setFieldValue(name, pull(option, values[name]))} size={20} />
        </SelectedAccessOption>
      ))}
    </>
  );
};
