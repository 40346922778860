import React from 'react';
import { ReactQueryKey } from '@enums';
import { ActionStatus, TaskStatus } from '@generated/types/graphql';
import { ProgressIcon } from '@kit/ui/icons/Progress';
import { ArrowRight } from 'react-feather';
import { useActionItemList } from '@hooks/actionItems/useActionItemList';
import { useTasks } from './useTasks';
import { Container, Placeholder } from './styled';

import { List } from './List';

interface Props {
  projectId: number;
  system?: { id: number; name: string };
  onExpandDetails: () => void;
}

export const Work = ({ projectId, onExpandDetails, system }: Props) => {
  const {
    tasks: plannedWorkOrders,
    totalCount: plannedWorkOrdersTotalCount,
    isLoading: isPlannedLoading
  } = useTasks({
    queryKey: ReactQueryKey.TasksWidget,
    filters: {
      isArchived: { equalTo: false },
      isCompleted: { equalTo: false },
      status: { in: [TaskStatus.Queued, TaskStatus.Assigned, TaskStatus.Scheduled, TaskStatus.Dispatched] },
      projectId: { equalTo: projectId },
      systemId: system ? { equalTo: system.id } : undefined
    },
    perPage: 10
  });

  const {
    tasks: inProgressWorkOrders,
    totalCount: inProgressWorkOrdersTotalCount,
    isLoading: isInProgressLoading
  } = useTasks({
    queryKey: ReactQueryKey.TasksWidget,
    filters: {
      isArchived: { equalTo: false },
      isCompleted: { equalTo: false },
      status: { notIn: [TaskStatus.Queued, TaskStatus.Assigned, TaskStatus.Scheduled, TaskStatus.Dispatched] },
      projectId: { equalTo: projectId },
      systemId: system ? { equalTo: system.id } : undefined
    },
    perPage: 10
  });

  const { data: actionItems = [], isLoading: actionItemsLoading } = useActionItemList(projectId);

  const inProgressActionItems = actionItems.filter(
    (item) => item.status !== ActionStatus.Cancelled && item.status !== ActionStatus.Completed
  );

  if (
    !isInProgressLoading &&
    !isPlannedLoading &&
    !actionItemsLoading &&
    inProgressWorkOrdersTotalCount === 0 &&
    plannedWorkOrdersTotalCount === 0 &&
    inProgressActionItems.length === 0
  ) {
    return <Placeholder>No work yet</Placeholder>;
  }

  return (
    <Container>
      {!isInProgressLoading && !actionItemsLoading && (
        <List
          onExpandDetails={onExpandDetails}
          workOrders={inProgressWorkOrders}
          actionItems={inProgressActionItems}
          totalCount={inProgressWorkOrdersTotalCount + inProgressActionItems.length}
          title={
            <>
              <ProgressIcon size="16px" color="#4D7CFE" /> In progress (
              {inProgressWorkOrdersTotalCount + inProgressActionItems.length})
            </>
          }
        />
      )}
      {!isPlannedLoading && (
        <List
          onExpandDetails={onExpandDetails}
          workOrders={plannedWorkOrders}
          totalCount={plannedWorkOrdersTotalCount}
          title={
            <>
              <ArrowRight size="16px" color="#4D7CFE" /> Planning ({plannedWorkOrdersTotalCount})
            </>
          }
        />
      )}
    </Container>
  );
};
