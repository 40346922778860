import fonts from '../fonts';
import { colors } from '../colors';

const checkbox = (props) => `
  display: flex;
  align-items: center;
  
  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }
  
  label {
    cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
    padding-left: 10px;
    font: 14px/17px ${fonts.sans};
    color: ${colors.black};
    font-weight: 500;
  }
  
  figure {
    position: relative;
    cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
    
    height: 20px;
    width: 20px;
     
    border: 1px solid ${props.checked ? colors.green : colors.gray};
    border-radius: 4px;
  
    background-color: ${props.checked ? colors.green : colors.white};
    transition: .4s;
  
    &:hover, &:focus {
      border-color: ${colors.green};
    }
  
    &:after {
      position: absolute;
      display: ${props.checked ? 'block' : 'none'};
      content: '';
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid white;
      transform: rotate(45deg);
      border-width: 0 3px 3px 0;
    }
  }
 
`;

export default checkbox;
