import databankReducer from '@components/Project/DataBank/redux/reducer';
import userReducer from './user/userReducer';
import kanbanReducer from './project/Kanban/kanbanBoardReducer';
import userDataReducer from './userData/userDataReducer';
import authenticationReducer from './authentication/authenticationReducer';
import accountSetting from './settings/accountReducer';
import usersSetting from './settings/usersReducer';
import alertReducer from './alert/alertReducer';
import projectViewReducer from '../../components/Project/redux/reducer';
import calendarReducer from '../../components/Calendar/redux/reducer';
import notificationSettingsReducer from './settings/notificationReducer';
import workspaceSettingsReducer from './settings/workspaceSettingsReducer';
import { reducer as companiesReduces } from './companies';
import { reducer as uiReducer } from './ui';

export const reducers = {
  ui: uiReducer,
  user: userReducer,
  kanban: kanbanReducer,
  databank: databankReducer,
  calendar: calendarReducer,
  userData: userDataReducer,
  authentication: authenticationReducer,
  accountSetting,
  usersSetting,
  alert: alertReducer,
  notificationSettings: notificationSettingsReducer,
  companies: companiesReduces,
  workspaceSettings: workspaceSettingsReducer,
  ...projectViewReducer
};
