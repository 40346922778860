import { FavProject, GroupProp, Project, Search, } from '../../ProjectView/types';
import * as actions from '../actions';
import { ProjectViewReducer } from './types';

const normalizeFavoriteProjects = (
  groups: GroupProp[],
  favoriteProjectList: FavProject[]
): GroupProp[] => {
  const copyGroups = groups.map((group) => ({
    ...group,
    group: {
      ...group.group,
      list: group.group.list.map((project: Project) => ({
        ...project,
        projectDetail: {
          ...project.projectDetail,
          isFavorite: !!favoriteProjectList.find(
            (p) => p.id === project.projectId
          )
        }
      }))
    }
  }));
  return copyGroups;
};

// FAVORITES PART
const fetchFavProjectsRequestReducer = (
  state: ProjectViewReducer
): ProjectViewReducer => ({
  ...state,
  isLoadingFavoriteProject: true
});

type FetchFavProjectsSuccessResponse = {
  payload: { projects: FavProject[]; search: Search; total: number };
};
const fetchFavProjectsSuccessReducer = (
  state: ProjectViewReducer,
  { payload }: FetchFavProjectsSuccessResponse
): ProjectViewReducer => {
  const { projects, search, total } = payload;
  const { groups } = state;

  return {
    ...state,
    favoriteProjectList: projects,
    isLoadingFavoriteProject: false,
    favoriteSearch: search,
    totalFavoriteProject: total,
    groups: normalizeFavoriteProjects(groups, projects)
  };
};

const fetchFavProjectsFailureReducer = (
  state: ProjectViewReducer
): ProjectViewReducer => ({
  ...state,
  isLoadingFavoriteProject: false
});

const $actions = actions as { [key: string]: any };

export const handleGroupsActions = {
  [$actions.fetchFavProjectsRequest]: fetchFavProjectsRequestReducer,
  [$actions.fetchFavProjectsSuccess]: fetchFavProjectsSuccessReducer,
  [$actions.fetchFavProjectsFailure]: fetchFavProjectsFailureReducer
};
