import React, { useState } from 'react';
import { Info } from 'react-feather';
import { FormControlLabel, Checkbox, Tooltip, makeStyles } from '@material-ui/core';
import { Field } from 'formik';
import styled from 'styled-components';
import { colors, checkboxMui } from '@styles';
import { InputLabel } from '@common/ui';
import LabelSelector from '../../Selector/LabelSelector';

const useStylesCheckbox = makeStyles(checkboxMui({ width: '30px', height: '30px' }));

const Wrapper = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SetColored = styled.div`
  display: flex;
  align-items: center;
`;

type LabelInputTypes = {
  label?: string;
  name?: string;
  companyId?: number;
  isColored?: boolean;
  setIsColored?: (value: boolean) => void;
  isDisabled?: boolean;
};

const LabelInput: React.FC<LabelInputTypes> = (props) => {
  const { label, name, companyId, isColored, setIsColored, onFocus, onBlur, isDisabled } = props;

  const [error, setError] = useState(false);

  const classesCheckbox = useStylesCheckbox();

  return (
    <Wrapper data-testid="tags">
      <Row>
        <InputLabel htmlFor={name} required={error}>
          {label}
        </InputLabel>
      </Row>
      <Field name={name}>
        {({ field, form: { setFieldValue, values, errors } }) => {
          setError(!!errors.labels);

          return (
            <LabelSelector
              selectedLabels={values[field.name]}
              ownerId={values?.['id']}
              setSelectedLabels={(labels) => setFieldValue(field.name, labels)}
              removeLabelPill={(tag) => {
                const idToDelete = tag?.id || tag;

                setFieldValue(field.name, [...values[field.name].filter(({ id }) => id !== idToDelete)]);
              }}
              companyId={companyId}
              error={!!errors.labels}
              onFocus={onFocus}
              onBlur={onBlur}
              isDisabled={isDisabled}
            />
          );
        }}
      </Field>

      {setIsColored ? (
        <SetColored>
          <FormControlLabel
            control={
              <Checkbox
                classes={classesCheckbox}
                checked={isColored}
                data-analyticsid="addBackgroundColorCheckbox"
                onChange={() => setIsColored(!isColored)}
                style={{ color: colors.gray }}
              />
            }
            label="Add background color"
          />
          <Tooltip title="This uses the color of the first label as the card color" placement="bottom">
            <Info size={16} color={colors.gray} />
          </Tooltip>
        </SetColored>
      ) : null}
    </Wrapper>
  );
};

export default LabelInput;
