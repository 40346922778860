export enum FormFieldType {
  CHECKBOX = 'CHECKBOX',
  ATTACHMENT = 'ATTACHMENT',
  MULTIPLE = 'MULTIPLE',
  NUMERIC = 'NUMERIC',
  TEXT = 'TEXT',
  SEPARATOR = 'SEPARATOR',
  PROJECT = 'PROJECT' // field connected directly to custom/base property of project
}

export default FormFieldType;
