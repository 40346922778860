import { ReactQueryKey } from '@enums';
import { PrivilegedTask, PrivilegedTasksConnection } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { FormTemplateListItem, useFormTemplateList } from '../forms/useFormTemplateList';

export type WorkOrderTemplateListItem = Pick<
  PrivilegedTask,
  'id' | 'title' | 'isField' | 'createdByUser' | 'assignee'
> & {
  forms: FormTemplateListItem[];
};

export const useWorkOrderTemplateList = () => {
  const companyId = useSelector(selectWorkspaceId);

  const { data: formTemplates, isLoading: isFormTemplatesLoading } = useFormTemplateList();

  const queryResult = useQuery<PrivilegedTask[]>(
    [ReactQueryKey.WorkOrderTemplateList],
    async () => {
      try {
        const { privilegedTasksConnection } = await postGraphql<{
          privilegedTasksConnection: PrivilegedTasksConnection;
        }>(
          gql`
            query ($companyId: Int!) {
              privilegedTasksConnection(
                filter: { isTemplate: { equalTo: true }, companyId: { equalTo: $companyId } }
                orderBy: CREATED_AT_DESC
              ) {
                nodes {
                  id
                  title
                  isField
                  forms
                  assignee {
                    id
                    firstName
                    lastName
                    avatarUrl
                  }
                  createdByUser {
                    id
                    firstName
                    lastName
                    avatarUrl
                  }
                }
              }
            }
          `,
          { companyId }
        );

        return privilegedTasksConnection.nodes;
      } catch (e) {
        throw apiErrorHandler('Error fetching work order templates', e);
      }
    },
    { refetchOnMount: false, enabled: Boolean(formTemplates && !isFormTemplatesLoading) }
  );

  return useMemo(() => {
    return {
      ...queryResult,
      data: (queryResult.data ?? []).map((node) => ({
        ...node,
        forms: node.forms.map((formId) => formTemplates?.find((form) => form.id === formId)).filter(Boolean)
      })) as WorkOrderTemplateListItem[],
      isLoading: queryResult.isLoading || isFormTemplatesLoading
    };
  }, [queryResult, formTemplates, isFormTemplatesLoading]);
};
