import React, { useCallback, useMemo } from 'react';
import { useFullForm } from '@hooks/useForms';
import { useDocDetails } from '@features/ClientPortfolio/Client/Overview/Docs/useDocDetails';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { File, FormLayout } from '@generated/types/graphql';
import { FormLayoutColumnType, FormLayoutType } from '@features/Platform/Templates/FormBuilder/helpers';
import { getAdditionalPropertyValue } from '@utils/properties';
import { Tooltip } from '@material-ui/core';
import { Eye } from 'react-feather';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { Container, DocPreview, FieldDocList, FieldTitle, Selected } from './styled';

type FieldAttachments = {
  title: string;
  files: File[];
};

const isFilesValue = (value: any): value is File[] => {
  return Array.isArray(value) && value.some((item) => item?.id && item?.metaData?.thumbnailUrl);
};

const EMPTY_ARRAY = [];

export const useFormAttachments = (formFileId: number): FieldAttachments[] => {
  const { doc: formDoc } = useDocDetails({ fileId: formFileId, refetchOnMount: false });
  const { data: form } = useFullForm(formDoc?.form?.id, { refetchOnMount: false });
  const { data: project } = useRecordDetail(formDoc?.projectId, { refetchOnMount: false });

  const extractFilesFromColumn = (formLayout: FormLayout): File[] => {
    return (getAdditionalPropertyValue({
      ...formLayout.column.projectPropertyValuesByColumnId[0],
      column: formLayout.column
    }) ?? []) as File[];
  };

  const extractAttachmentsFromFormLayout = useCallback(
    (formLayout: FormLayout): FieldAttachments[] => {
      if (!formLayout.column) {
        return [];
      }

      if (formLayout.column.type === FormLayoutColumnType.File) {
        const files = extractFilesFromColumn(formLayout);

        if (files.length > 0) {
          return [
            {
              title: formLayout.column.name,
              files
            }
          ];
        }
      }

      if (formLayout.column.type === FormLayoutColumnType.Project) {
        const column = formLayout.column.projectColumn;

        if (column) {
          const value = column.isAdditional
            ? project.additional[column.id]
            : project[column.objectName ?? column.mappedName];

          if (isFilesValue(value) && value.length > 0) {
            return [
              {
                title: formLayout.column.name,
                files: value
              }
            ];
          }
        }
      }

      return [];
    },
    [project]
  );

  return useMemo(() => {
    if (!form || !project) {
      return EMPTY_ARRAY as FieldAttachments[];
    }

    return form.formLayouts.reduce((acc, formLayout) => {
      if (formLayout.type === FormLayoutType.GROUP) {
        formLayout.childFormLayouts.forEach((childFormLayout) => {
          acc.push(...extractAttachmentsFromFormLayout(childFormLayout));
        });
      } else {
        acc.push(...extractAttachmentsFromFormLayout(formLayout));
      }

      return acc;
    }, [] as FieldAttachments[]);
  }, [form, project, extractAttachmentsFromFormLayout]);
};

interface Props {
  isFullScreen: boolean;
  currentFieldId: number;
  formFileId: number;
}

const FilePreview = ({
  file,
  isCurrent,
  onClick
}: {
  file: File;
  isCurrent: boolean;
  onClick: (fileId: number) => void;
}) => {
  const handleClick = useCallback(() => {
    onClick(file.id);
  }, [file.id, onClick]);

  return (
    <DocPreview onClick={handleClick} isSelected={isCurrent} src={file.metaData?.thumbnailUrl}>
      {isCurrent && (
        <Selected>
          <Eye size="24px" color="#fff" />
        </Selected>
      )}
    </DocPreview>
  );
};

export const FormDocsSidebar = ({ isFullScreen, currentFieldId, formFileId }: Props) => {
  const attachments = useFormAttachments(formFileId);
  const { openDrawer } = useDrawersContext();

  const allAttachmentIds = useMemo(() => {
    return attachments.reduce((acc, attachment) => {
      return [...acc, ...attachment.files.map((file) => file.id)];
    }, [] as number[]);
  }, [attachments]);

  const handleFileClick = useCallback(
    (fileId: number) => {
      openDrawer(DrawerEntity.FILE, fileId, allAttachmentIds);
    },
    [openDrawer, allAttachmentIds]
  );

  return (
    <Container isFullScreen={isFullScreen}>
      {attachments.map((attachment, index) => (
        <div key={index}>
          <Tooltip title={attachment.title}>
            <FieldTitle>{attachment.title}</FieldTitle>
          </Tooltip>

          <FieldDocList isTwoColumns={isFullScreen}>
            {attachment.files.map((file) => (
              <FilePreview key={file.id} file={file} isCurrent={file.id === currentFieldId} onClick={handleFileClick} />
            ))}
          </FieldDocList>
        </div>
      ))}
    </Container>
  );
};
