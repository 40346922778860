import styled from 'styled-components';
import { fonts, scroll } from '@styles';

export const TooltipContent = styled.div`
  font-family: ${fonts.sans};
  padding: 8px 4px;
  font-size: 14px;
  font-weight: 400;
  max-height: 50vh;
  overflow-y: auto;
  ${scroll};
`;

export const Counter = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ListItem = styled.div<{ shadowCount?: number }>`
  border: 1px solid #dfdfe8;
  border-radius: 8px;
  overflow: hidden;

  > div:first-of-type > * {
    border-top: 0;
  }

  position: relative;

  transition: box-shadow 0.1s;

  box-shadow: ${({ shadowCount }) => {
    switch (shadowCount) {
      case 1:
        return '0px 4px 0px #F7F7FC, 0px 5px 0px #DFDFE8';
      case 2:
        return '0px 4px 0px #F7F7FC, 0px 5px 0px #DFDFE8, 0px 8px 0px #F7F7FC, 0px 9px 0px #DFDFE8';
      default:
        return 'none';
    }
  }};
`;

export const Clickable = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
`;
