import { ReactQueryKey } from '@enums';
import { Blueprint } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

export type WorkflowItem = Pick<Blueprint, 'id' | 'name' | 'blueprintTimeslice' | 'blueprintProjectStages'>;

export const useProjectWorkflow = (id: number) => {
  return useQuery<WorkflowItem>(
    [ReactQueryKey.WorkflowList, id],
    async () => {
      try {
        const { blueprint } = await postGraphql<{ blueprint: Blueprint }>(
          gql`
            query ($id: Int!) {
              blueprint(id: $id) {
                id
                name
                blueprintTimeslice {
                  blueprintTasksBySliceId {
                    task {
                      id
                      title
                    }
                  }
                }
                blueprintProjectStages {
                  projectStage {
                    id
                    name
                  }
                }
              }
            }
          `,
          { id }
        );

        return blueprint;
      } catch (e) {
        throw apiErrorHandler('Error fetching workflow', e);
      }
    },
    {
      enabled: !!id
    }
  );
};
