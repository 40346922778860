import styled from 'styled-components';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

export const PeriodArrows = styled.div`
  display: flex;
  gap: 16px;
  color: #009688;
`;

export const PeriodSelector = styled(ToggleButtonGroup)`
  &.MuiToggleButtonGroup-root {
    background-color: #f7f7fc;
    border: 1px solid #dfdfe8;
    border-radius: 8px;
  }
`;

export const PeriodButton = styled(ToggleButton)`
  &.MuiToggleButton-root {
    padding: 7px 16px;
    border: none;
    text-transform: none;
    font-family: Rubik;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #1d1d35;
  }

  &.MuiToggleButton-root.Mui-selected {
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 74, 0.12);
    background-color: #fff;
    font-weight: 500;
    color: #1d1d35;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const ContentSeparator = styled.div`
  width: 1px;
  background-color: #dfdfe8;
`;

export const PowerFlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PowerFlowLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1d1d35;
`;

export const PowerFlowGrid = styled.div`
  display: grid;
  grid:
    '. top .'
    'left center right'
    '. bottom .';
  grid-template-columns: max-content auto max-content;
  justify-items: center;
  align-items: center;
  gap: 8px;
`;

export const PowerFlowItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px
`;

export const PowerFlowItemTop = styled(PowerFlowItem)`
  grid-area: top;
  max-width: 92px;
`;

export const PowerFlowItemLeft = styled(PowerFlowItem)`
  grid-area: left;
  max-height: 92px;
`;

export const PowerFlowItemCenter = styled(PowerFlowItem)`
  grid-area: center;
`;

export const PowerFlowItemRight = styled(PowerFlowItem)`
  grid-area: right;
  max-height: 92px;
`;

export const PowerFlowItemBottom = styled(PowerFlowItem)`
  grid-area: bottom;
  max-width: 92px;
`;

export const PowerFlowItemValues = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PowerFlowItemValue = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1d1d35;
`;

export const PowerFlowItemName = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const ChartTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1D1D35;
`;

export const ChartControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PeriodControls = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Chart = styled.div`
  min-height: 283px;
  height: 283px;
  width: 100%;
`;

export const ChartLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ChartLegendLabels = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const ChartLegendLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  
  font-size: 12px;
  font-weight: 400;
  color: #1D1D35;
`;

export const ChartDateLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #828D9A;
`;