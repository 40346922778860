import { ProjectAdapted, ProjectFromProjectListApi } from '@types';
import { Project } from '@generated/types/graphql';
import { propertiesValuesToAdditional } from '@utils/properties';

export const projectAdapter = (project: ProjectFromProjectListApi): ProjectAdapted => {
  return {
    projectId: project.id,
    projectProgress: project.progress,
    projectDetail: {
      ...project,
      createdAt: new Date(project.createdAt).toLocaleDateString('en-US'),
      imgSrc: project.imageUrl || '',
      ownerEmail: project.homeOwnerEmail,
    },
    membersList: project.members?.map(({ member, memberType }) => {
      return {
        id: member.id,
        name: `${member.firstName} ${member.lastName}`,
        avatarUrl: member.avatarUrl,
        email: member.email,
        memberType,
      };
    })
  };
};

export const favProjectsAdapter = (data) => {
  if (!data) {
    return [];
  }

  return data.map((project) => {
    return {
      ...project,
      id: project.id,
      name: project.title,
      avatarUrl: project.imageUrl || project.streetViewUrl,
      description: `${project.address}`,
    };
  });
};

// see also groupsAdapterNew
export const projectAdapterNew = (project: Project): ProjectAdapted => ({
  projectId: project.id,
  projectProgress: project.progress,
  projectDetail: {
    ...project,
    createdAt: project.createdAt,
    imgSrc: project.imageUrl || '',
    ownerEmail: project.homeOwnerEmail,
    additional: propertiesValuesToAdditional(project.projectPropertiesValues)
  },
  membersList: project.projectMembers?.map(({ member }) => {
    return {
      id: member!.id,
      name: `${member!.firstName} ${member!.lastName}`,
      avatarUrl: member!.avatarUrl,
      email: member!.email,
      memberType: 'WORKER'
    };
  })
});

export const projectsAdapterNew = (data: Project[]): ProjectAdapted[] =>
  (data || []).map(projectAdapterNew);
