import React, { useMemo } from 'react';
import { ChevronDown } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';
import AutoComplete from '@material-ui/lab/Autocomplete';
import { useMembers, useCompanyProperties, useAllCompaniesUsers, useAppSelector } from '@hooks';
import { getFullName } from '@utils';
import { autocomplete } from '@common/ui/Autocomplete/styled';
import { TextField } from '@common/ui';
import { PropertyType } from '@types';
import { convertFromPropertyIdToTemplateMemberId } from '@utils/properties';

import { useTeams } from '@hooks/useTeams';
import { selectWorkspaceId } from '@state/selectors';
import { Team } from '@generated/types/graphql';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { DropDownItem } from './DropDownItem';
import UserPill from './UserPill';
import Skeleton from './Skeleton/Skeleton';
import { User, Option, Member } from './types';

const userToOption = (userOrMember: User | Member, isMember?: boolean): Option => {
  const user: User = (userOrMember as Member).member || userOrMember;

  return {
    ...user,
    type: isMember || userOrMember !== user ? 'member' : 'user',
    name: user.name || getFullName(user)
  };
};

// do not distinguish users from members, but separate options
const equals = (o1: Option, o2: Option) => {
  if (!o2 || !o1) {
    return false;
  }

  return o1.id === o2.id && (o1.type === o2.type || [o1.type, o2.type].every((t) => t in ['user', 'member']));
};

/**
 * Mustiselect input + autocomplete for selecting users of a company, optionally with members of a project.
 *
 * Internal model is options, not users! Options include Workspace members, Project Members (if projectId is provided),
 * and Teams. Returns plain users (flattening teams) in {@link UserSelectorProps.selectedUsers}
 */
const UserSelector: React.FC<Props> = (props: Props) => {
  const {
    projectId,
    membersOnly,
    selectedUsers,
    setSelectedUsers,
    hidePills,
    allowTeams = false,
    allowUserProperties = false,
    isMultiple = true,
    excludeIds = [],
    onBlur,
    onFocus
  } = props;

  const companyId = useAppSelector(selectWorkspaceId);

  const [inputValue, debouncedSearch, setInputValue] = useDebouncedState('', 300);

  const { data: users, isLoading } = useAllCompaniesUsers({ search: debouncedSearch });
  const { list: members } = useMembers(projectId);
  const { data: teams, isLoading: isTeamsLoading } = useTeams(companyId, allowTeams);

  const {
    allProperties = [],
    fetchQuery: { isLoading: isLoadingProperties = false }
  } = useCompanyProperties({ enabled: allowUserProperties });

  const allowedProperties = useMemo(
    () =>
      allProperties.filter(
        (property) =>
          (property.isAdditional && property.type === PropertyType.Person && !property.virtual) ||
          [-31, -40, -41].includes(property.id)
      ),
    [allProperties]
  );

  const options: Option[] = [
    ...((projectId && members) || []).map((member: Member) => userToOption(member)),
    ...((!membersOnly && users) || [])
      .filter((user: User) => !selectedUsers.some(({ id }) => id === user.id))
      .map((user: User) => userToOption(user)),
    ...((allowTeams && teams) || [])
      .filter((team: Team) => team.teamUsers.length > 0)
      .map((team: Team) => ({ ...team, type: 'team' })),
    ...((allowUserProperties && allowedProperties) || [])
      .map((property) => ({
        ...property,
        id: convertFromPropertyIdToTemplateMemberId(property.id),
        type: 'property',
        columnType: property.type
      }))
      .filter((user) => !selectedUsers.some(({ id }) => id === user.id))
  ].filter((user) => !excludeIds.includes(user.id));

  const selectedOptions = useMemo(() => selectedUsers.map((user) => userToOption(user, true)), [selectedUsers]);

  // avoid Autocomplete's 'None of the options match with' error - put selected ones into options while all options are
  // loading

  selectedOptions.forEach((selected) => {
    if (!options.some((option) => equals(option, selected))) {
      options.push(selected);
    }
  });

  const classes = makeStyles(autocomplete({}))();

  const handleChange = (value: Option[] | Option | null) => {
    const valueAsArray = value && Array.isArray(value) ? value : [value].filter(Boolean);
    const selected = valueAsArray
      .map((option) =>
        option.type === 'team'
          ? (option as Team).teamUsers.filter(
              (user) => !valueAsArray.some((selectedUser) => user.id === selectedUser.id)
            )
          : option
      )
      .flat();

    setSelectedUsers(selected);
  };

  const handleRemoveSelected = (id: number) => {
    setSelectedUsers(selectedOptions.filter((user) => user.id !== id));
  };

  const sectionsGrouping = (option: Option) => {
    switch (option.type) {
      case 'user':
        return 'Worskpace members';
      case 'member':
        return 'Project members';
      case 'team':
        return 'Teams';
      case 'property':
        return 'Project members';
      default:
        return '';
    }
  };

  return (
    <AutoComplete
      multiple={isMultiple}
      openOnFocus
      renderTags={(tags) => {
        if (hidePills) {
          return [];
        }

        return tags.map((tag, idx) => (
          <UserPill
            key={`user-pill-key-${idx}`}
            id={tag.id}
            avatarUrl={tag.avatarUrl}
            name={tag.name}
            removeUserHandler={handleRemoveSelected}
          />
        ));
      }}
      classes={classes}
      inputValue={inputValue}
      onInputChange={(_event, value) => {
        setInputValue(value);
      }}
      options={options}
      value={isMultiple ? selectedOptions : selectedOptions[0]}
      onChange={(_event, value) => {
        handleChange(value);
      }}
      getOptionSelected={equals}
      getOptionLabel={(option: Option) => option.name}
      loading={isLoading || isLoadingProperties || isTeamsLoading}
      loadingText={<Skeleton />}
      noOptionsText="User not found"
      renderOption={(option) => (
        <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={option.name} data-testid="userSelectOption" />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          name="assignees"
          data-testid="userSelectInput"
          variant="outlined"
          placeholder="Search by name..."
        />
      )}
      popupIcon={<ChevronDown />}
      groupBy={allowTeams || allowUserProperties ? sectionsGrouping : undefined}
      filterSelectedOptions
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default UserSelector;
