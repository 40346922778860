import styled from 'styled-components';
import { colors, scroll } from '@styles';
import { PlusSquare } from 'react-feather';

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
`;

export const Tab = styled.div<{ isActive: boolean }>`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? colors.green : '#828D9A')};
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;

  border-color: ${({ isActive }) => (isActive ? colors.green : 'transparent')};
`;

export const AddButton = styled(PlusSquare)`
  cursor: pointer;
`;

export const List = styled.div`
  overflow: auto;
  max-height: calc(100% - 50px);
  ${scroll};
`;

export const MemberRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  padding: 8px 4px;
  border-bottom: 1px solid #dfdfe8;
  transition: background-color 0.15s ease-in-out;
  font-size: 14px;
  font-weight: 400;

  svg {
    flex-shrink: 0;
  }

  &:last-child {
    border-bottom: 0;
  }

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }
`;

export const UserCell = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
`;

export const ContactRow = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr auto;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 4px;
  border-bottom: 1px solid #dfdfe8;
  transition: background-color 0.15s ease-in-out;
  font-size: 14px;
  font-weight: 400;

  svg {
    flex-shrink: 0;
  }

  &:last-child {
    border-bottom: 0;
    margin-left: auto;
  }

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }
`;

export const Copyable = styled.div<{ isEnabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.gray2};
  white-space: nowrap;

  > *:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > *:last-child {
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    margin: 0;
  }

  &:hover > *:last-child {
    opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0)};
  }
`;

export const PortalIconWrapper = styled.div`
  left: 20px;
  top: 4px;
  position: absolute;
  z-index: 1;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #ffbd13;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
