import colors from '../colors';
import fonts from '../fonts';

export const menu = () => `

  min-width: 164px;

  margin: 0;
  padding: 14px 7px;

  background-color: ${colors.white};
  color: #252631;

  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

export const item = (props) => `
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  min-width: 100%;
  min-height: 32px;

  padding: 3px 7px;

  border-radius: 4px;

  font: 500 12px/1.35 ${fonts.sans};
  text-transform: uppercase;
  color: ${props.color ? props.color : colors.black};
  
  transition: 0.3s ease-out;
  cursor: pointer;

  &:hover, &:focus {
    background-color: #f2f4f6;
  }

  &:last-child {
    margin-bottom: 0;
  }

  > svg {
    margin-right: 7px;
    min-width: 20px;
  }

  > img {
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }
  
  span {
    min-width: 100%;

    &:empty {
      display: none;
    }
  }
  
`;

export default {
  menu,
  item
};
