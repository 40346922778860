import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Checkbox } from '@material-ui/core';
import { colors } from '@styles';
import IProps from './types';
import { Wrapper, Task, RightWrapper, DateText, Input, Remove, useStyles } from './styled';

const SUB_TASK_TYPE = 'SUBTASK_FIELD';
const SubTaskItem: React.FC<IProps> = (props: IProps) => {
  const { errors, task, onTaskRename, onPositionChange, index, isDisabled = false } = props;
  const { id, isCompleted, detail } = task;
  const [taskTitle, setTaskTitle] = useState(detail);
  const [editTitle, setEditTitle] = useState(false);

  const onToggleTitleEdit = () => {
    setEditTitle(!editTitle);
  };

  const onInputChange = (event) => {
    setTaskTitle(event.target.value);
  };

  const onChangeDeskTitle = (event) => {
    const { value } = event.target;
    if (value.trim() !== '') {
      onTaskRename(id, value);
      onToggleTitleEdit();
    } else {
      setTaskTitle(detail);
      onToggleTitleEdit();
    }
  };

  const onInputKeyDown = (event) => {
    if (event.key == 'Enter') onChangeDeskTitle(event);
    if (event.key == 'Escape' || event.key == 'Esc') {
      setTaskTitle(detail);
      onToggleTitleEdit();
    }
  };

  const onInputFocus = (event) => {
    event.target.select();
  };

  const classes = useStyles();
  const wrapperRef = useRef<any>();
  const [{ isDragging }, drag] = useDrag({
    item: { type: SUB_TASK_TYPE, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [, drop] = useDrop({
    accept: [SUB_TASK_TYPE],
    hover: (item: { index: number }, monitor) => {
      if (!wrapperRef.current) {
        return;
      }

      const dragIndex = item.index;
      if (dragIndex === index) {
        return;
      }

      const hoveredRect = wrapperRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
      const mousePosition = monitor.getClientOffset();
      const hoverClientY = mousePosition.y - hoveredRect.top;

      if (
        (dragIndex < index && hoverClientY < hoverMiddleY) ||
        (dragIndex > index && hoverClientY > hoverMiddleY)
      ) {
        return;
      }

      onPositionChange(dragIndex, index);
      item.index = index;
    }
  });

  drag(drop(wrapperRef));

  const completedAt = task.isCompleted ? new Date(task.date ?? new Date()).toLocaleDateString() : '';

  return (
    <Wrapper ref={wrapperRef} isDragging={isDragging} isCompleted={isCompleted} errors={errors}>
      <Checkbox
        classes={classes}
        checked={isCompleted}
        onClick={() => props.markComplete(id)}
        style={{ color: colors.gray }}
        disabled={isDisabled}
      />
      {editTitle ? (
        <Input
          autoFocus
          onBlur={onChangeDeskTitle}
          value={taskTitle}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          onFocus={onInputFocus}
          tableInput
          disabled={isDisabled}
        />
      ) : (
        <Task onClick={isDisabled ? undefined : onToggleTitleEdit}>{detail}</Task>
      )}
      <RightWrapper>
        {isCompleted && <DateText>{completedAt}</DateText>}
        {!isDisabled && <Remove size={20} onClick={() => props.delSubTask(id)} />}
      </RightWrapper>
    </Wrapper>
  );
};

export default SubTaskItem;
