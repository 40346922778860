import { useMemo } from 'react';
import { TaskReport } from '@generated/types/graphql';
import { formatDate } from '@utils/dates';
import {
  AnalyticsMeasureEntityType,
  PredefinedWidgetFilterFieldId,
  WidgetFilter,
  WidgetFilters,
  WidgetSettings,
  WorkOrderFilterFieldId
} from '../types';

type Column = {
  id: WorkOrderFilterFieldId;
  name: string;
  getValue: (workOrderReport: TaskReport) => string;
};

const formatDateValue = (value?: string | null, isAllDay = false) =>
  value ? formatDate(value, isAllDay ? 'MM/DD/YYYY' : 'MM/DD/YYYY hh:mm A') : '-';

const DYNAMIC_COLUMN_CONFIG: { [key in WorkOrderFilterFieldId]?: Column } = {
  [PredefinedWidgetFilterFieldId.WORK_ORDER_CREATED_AT]: {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_CREATED_AT,
    name: 'Created at',
    getValue: (workOrderReport) => formatDateValue(workOrderReport.task?.createdAt)
  },
  [PredefinedWidgetFilterFieldId.WORK_ORDER_DESCRIPTION]: {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_DESCRIPTION,
    name: 'Description',
    getValue: (workOrderReport) => workOrderReport.task?.description || '-'
  },
  [PredefinedWidgetFilterFieldId.WORK_ORDER_COMPLETED_AT]: {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_COMPLETED_AT,
    name: 'Completed at',
    getValue: (workOrderReport) => formatDateValue(workOrderReport.task?.completionDate)
  },
  [PredefinedWidgetFilterFieldId.WORK_ORDER_START_DATE]: {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_START_DATE,
    name: 'Start date',
    getValue: (workOrderReport) =>
      formatDateValue(workOrderReport.task?.startDate, workOrderReport.task?.startDateAllDay)
  },
  [PredefinedWidgetFilterFieldId.WORK_ORDER_END_DATE]: {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_END_DATE,
    name: 'End date',
    getValue: (workOrderReport) => formatDateValue(workOrderReport.task?.endDate, workOrderReport.task?.endDateAllDay)
  },
  [PredefinedWidgetFilterFieldId.WORK_ORDER_NEXT_VISIT_DATE]: {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_NEXT_VISIT_DATE,
    name: 'Next visit date',
    getValue: (workOrderReport) => {
      const nextNotCompletedVisit = workOrderReport.task?.taskVisitsByTaskId?.find((visit) => !visit.isCompleted);

      return formatDateValue(nextNotCompletedVisit?.startDate);
    }
  },
  [PredefinedWidgetFilterFieldId.WORK_ORDER_TEMPLATE]: {
    id: PredefinedWidgetFilterFieldId.WORK_ORDER_TEMPLATE,
    name: 'Template',
    getValue: (workOrderReport) => workOrderReport.task?.templateTask?.title || '-'
  }
};

export const useWorkOrdersDynamicColumns = (settings: WidgetSettings): Column[] => {
  return useMemo(() => {
    const result: Column[] = [];
    const usedFields = new Set<PredefinedWidgetFilterFieldId | number>([
      PredefinedWidgetFilterFieldId.WORK_ORDER_TITLE,
      PredefinedWidgetFilterFieldId.WORK_ORDER_STATUS,
      PredefinedWidgetFilterFieldId.WORK_ORDER_PRIORITY,
      PredefinedWidgetFilterFieldId.WORK_ORDER_LABEL,
      PredefinedWidgetFilterFieldId.WORK_ORDER_ASSIGNEE,
      PredefinedWidgetFilterFieldId.WORK_ORDER_TYPE
    ]);

    if (settings.filters) {
      const applySingleFilter = (filter: WidgetFilters | WidgetFilter) => {
        if ('children' in filter) {
          filter.children.forEach(applySingleFilter);

          return;
        }

        if (usedFields.has(filter.fieldId) || !DYNAMIC_COLUMN_CONFIG[filter.fieldId as WorkOrderFilterFieldId]) {
          return;
        }

        if (
          filter.fieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_NEXT_VISIT_DATE &&
          settings.measure?.entityType === AnalyticsMeasureEntityType.VISIT
        ) {
          return;
        }

        result.push(DYNAMIC_COLUMN_CONFIG[filter.fieldId as WorkOrderFilterFieldId]);
      };

      settings.filters.children.forEach(applySingleFilter);
    }

    return result;
  }, [settings]);
};
