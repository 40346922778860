import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { SystemCondition, SystemFilter, SystemsConnection } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import { UseQueryResult } from 'react-query/types/react/types';
import {
  ChartMilestone,
  GroupField,
  groupToExpr,
  milestoneObservedInterval,
  SortField,
  sortToExpr
} from '@hooks/systems/constants';
import { DeepPartial } from 'redux';
import { SystemWithStats } from '@hooks/systems/types';

export type SystemsGroup = {
  // Value of grouping field
  label: string;
  // Single page of systems according to given pagination
  systems: SystemWithStats[];
  // Ids of all systems in the group disregarding pagination
  ids: number[];
};

export type SystemsGroups = {
  // filtered and paginated groups
  groups: SystemsGroup[];
  // filtered and NON-paginated ids
  allIds: number[];
};

export type Result = Pick<UseQueryResult<SystemsGroups, Error>, 'data' | 'isLoading' | 'isError' | 'error'>;

export const useSystemsGroups = (args: {
  condition?: DeepPartial<SystemCondition>;
  filter?: DeepPartial<SystemFilter>;
  groupBy?: GroupField;
  orderBy?: [field: SortField, desc: boolean][];
  first?: number;
  offset?: number;
}): Result => {
  const { condition, filter, groupBy, orderBy, first = 10, offset = 0 } = args;

  const {
    data: idsGroups,
    isSuccess: isIdsSuccess,
    isLoading: isIdsLoading,
    isError: isIdsError,
    error: idsError
  } = useQuery<{ systemsConnection: SystemsConnection }, Error>(
    [ReactQueryKey.System, 'useSystemsGroups_ids', args],
    async () => {
      try {
        return await postGraphql<{ systemsConnection: SystemsConnection }>(
          gql`
            query (
              $condition: SystemCondition
              $filter: SystemFilter
              $groupBy: [SystemGroupBy!]!
              $orderBy: [SystemsOrderBy!]!
            ) {
              systemsConnection: systemsConnection(condition: $condition, filter: $filter, orderBy: $orderBy) {
                groupedAggregates(groupBy: $groupBy) {
                  keys
                  arrayAgg {
                    id(orderBy: $orderBy)
                  }
                }
              }
            }
          `,
          {
            condition,
            filter,
            groupBy: groupToExpr(groupBy),
            orderBy: sortToExpr(orderBy)
          }
        );
      } catch (e) {
        throw apiErrorHandler('Error fetching systems', e);
      }
    },
    {
      keepPreviousData: true
    }
  );

  const paginatedGroupsIds = (idsGroups?.systemsConnection?.groupedAggregates || []).reduce(
    (acc, group) => {
      acc[group.keys[0] || null] = (group.arrayAgg.id || []).slice(offset, offset + first);
      return acc;
    },
    {} as { [group: string | null]: number[] }
  );

  const allPaginatedGroupsIds = uniq(Object.values(paginatedGroupsIds).flat());

  const {
    data: systems,
    isLoading: isPagesLoading,
    isError: isPagesError,
    error: pagesError
  } = useQuery<{ systemsConnection: SystemsConnection }, Error>(
    [ReactQueryKey.System, 'useSystemsGroups_pages', idsGroups],
    async () => {
      try {
        return await postGraphql<{ systemsConnection: SystemsConnection }>(
          gql`
            query (
              $ids: [Int!]!
              $yesterdayStartAt: Datetime
              $weekStartAt: Datetime
              $monthStartAt: Datetime
              $yearStartAt: Datetime
              $lifetimeStartAt: Datetime
              $yesterdayEndBefore: Datetime
              $weekEndBefore: Datetime
              $monthEndBefore: Datetime
              $yearEndBefore: Datetime
              $lifetimeEndBefore: Datetime
            ) {
              systemsConnection(filter: { id: { in: $ids } }) {
                nodes {
                  id
                  createdAt
                  monitored
                  raw
                  providerId
                  status
                  operationalAt
                  address
                  addressCity
                  addressState
                  addressStreet
                  addressZip
                  lastReportAt
                  connectionType
                  size
                  providerStatus
                  name
                  number
                  clientType
                  uuid
                  notes
                  installedAt
                  operationStatus

                  integration {
                    id
                    provider
                  }

                  project {
                    id
                  }

                  productionYesterday: energyProductionChartsBySystemConnection(
                    condition: { seriesPeriod: { minutes: 15 }, targetPeriod: { days: 1 } }
                    filter: { time: { greaterThanOrEqualTo: $yesterdayStartAt, lessThan: $yesterdayEndBefore } }
                  ) {
                    nodes {
                      value
                    }
                  }

                  productionWeek: energyProductionChartsBySystemConnection(
                    condition: { seriesPeriod: { days: 1 }, targetPeriod: { days: 7 } }
                    filter: { time: { greaterThanOrEqualTo: $weekStartAt, lessThan: $weekEndBefore } }
                  ) {
                    nodes {
                      value
                    }
                  }

                  productionMonth: energyProductionChartsBySystemConnection(
                    condition: { seriesPeriod: { days: 1 }, targetPeriod: { days: 30 } }
                    filter: { time: { greaterThanOrEqualTo: $monthStartAt, lessThan: $monthEndBefore } }
                  ) {
                    nodes {
                      value
                    }
                  }

                  productionYear: energyProductionChartsBySystemConnection(
                    condition: { seriesPeriod: { days: 1 }, targetPeriod: { years: 1 } }
                    filter: { time: { greaterThanOrEqualTo: $yearStartAt, lessThan: $yearEndBefore } }
                  ) {
                    nodes {
                      value
                    }
                  }

                  productionLifetime: energyProductionChartsBySystemConnection(
                    condition: { seriesPeriod: { days: 1 }, targetPeriod: { years: 1 } }
                    filter: { time: { greaterThanOrEqualTo: $lifetimeStartAt, lessThan: $lifetimeEndBefore } }
                  ) {
                    aggregates {
                      sum {
                        value
                      }
                    }
                  }

                  consumptionYesterday: energyConsumptionChartsBySystemConnection(
                    condition: { seriesPeriod: { minutes: 15 }, targetPeriod: { days: 1 } }
                    filter: { time: { greaterThanOrEqualTo: $yesterdayStartAt, lessThan: $yesterdayEndBefore } }
                  ) {
                    nodes {
                      value
                    }
                  }

                  consumptionWeek: energyConsumptionChartsBySystemConnection(
                    condition: { seriesPeriod: { days: 1 }, targetPeriod: { days: 7 } }
                    filter: { time: { greaterThanOrEqualTo: $weekStartAt, lessThan: $weekEndBefore } }
                  ) {
                    nodes {
                      value
                    }
                  }

                  consumptionMonth: energyConsumptionChartsBySystemConnection(
                    condition: { seriesPeriod: { days: 1 }, targetPeriod: { days: 30 } }
                    filter: { time: { greaterThanOrEqualTo: $monthStartAt, lessThan: $monthEndBefore } }
                  ) {
                    nodes {
                      value
                    }
                  }

                  consumptionYear: energyConsumptionChartsBySystemConnection(
                    condition: { seriesPeriod: { days: 1 }, targetPeriod: { years: 1 } }
                    filter: { time: { greaterThanOrEqualTo: $yearStartAt, lessThan: $yearEndBefore } }
                  ) {
                    nodes {
                      value
                    }
                  }

                  consumptionLifetime: energyConsumptionChartsBySystemConnection(
                    condition: { seriesPeriod: { days: 1 }, targetPeriod: { years: 1 } }
                    filter: { time: { greaterThanOrEqualTo: $lifetimeStartAt, lessThan: $lifetimeEndBefore } }
                  ) {
                    aggregates {
                      sum {
                        value
                      }
                    }
                  }

                  peakPower: powerProductionChartsBySystemConnection {
                    aggregates {
                      max {
                        value
                      }
                    }
                  }
                }
              }
            }
          `,
          {
            ids: allPaginatedGroupsIds,
            ...Object.values(ChartMilestone).reduce(
              (acc, milestone) => ({
                ...acc,
                [`${milestone}StartAt`]: milestoneObservedInterval(milestone)?.start.toISO()
              }),
              {}
            ),
            ...Object.values(ChartMilestone).reduce(
              (acc, milestone) => ({
                ...acc,
                [`${milestone}EndBefore`]: milestoneObservedInterval(milestone)?.end.toISO()
              }),
              {}
            )
          }
        );
      } catch (e) {
        throw apiErrorHandler('Error fetching systems', e);
      }
    },
    {
      keepPreviousData: true,
      enabled: isIdsSuccess
    }
  );

  const systemsMap = useMemo(
    () =>
      (systems?.systemsConnection?.nodes || []).reduce(
        (acc, system) => {
          acc[system.id] = system as SystemWithStats;
          return acc;
        },
        {} as { [id: number]: SystemWithStats }
      ),
    [systems]
  );

  const groups: SystemsGroup[] = useMemo(
    () =>
      (idsGroups?.systemsConnection?.groupedAggregates || []).map((group) => ({
        label: group.keys[0] || null,
        ids: group.arrayAgg.id || [],
        systems: paginatedGroupsIds[group.keys[0] || null].map((id) => systemsMap[id])
      })),
    [systemsMap]
  );

  const allGroupsIds = useMemo(
    () => uniq((idsGroups?.systemsConnection?.groupedAggregates || []).flatMap((group) => group.arrayAgg.id)),
    [idsGroups]
  );

  const result: SystemsGroups = {
    groups,
    allIds: allGroupsIds
  };

  return {
    isLoading: isIdsLoading || isPagesLoading,
    isError: isIdsError || isPagesError,
    error: idsError || pagesError,
    data: result
  };
};
