import { Form, FormValidationRules, SelectField, useForm } from '@kit/components/Form';
import React, { useCallback, useMemo, useState } from 'react';
import { Contact } from '@generated/types/graphql';
import { useContactMutations, useContactsByQuery } from '@hooks/useContacts';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { ReactQueryKey } from '@enums';
import { useQueryClient } from 'react-query';
import { debounce } from 'lodash';
import { Paper } from '@material-ui/core';
import { Button, ButtonVariant } from '@kit/ui/Button';
import {
  FieldsRow,
  FormBody,
  FooterActions,
  AddButton,
  AddButtonContainer,
  ContactOptionTitle,
  ContactOptionMeta
} from './styled';
import { ContactForm } from './ContactForm';

const useContactOptions = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  const [nameFilter, setNameFilter] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchValueChange = useCallback(debounce(setNameFilter, 300), []);

  const { data = [] } = useContactsByQuery(companyId, nameFilter);

  return {
    options: data,
    onSearch: handleSearchValueChange
  };
};

interface ExistingContactFormValues {
  contact: Contact;
}

export const CreateContactForm = ({ recordId, onClose }: { recordId: number; onClose: () => void }) => {
  const [isNewContact, setIsNewContact] = useState(false);
  const companyId = useAppSelector(selectWorkspaceId);

  const { options, onSearch } = useContactOptions();

  const {
    update: { mutateAsync: updateContact }
  } = useContactMutations(companyId);
  const queryClient = useQueryClient();

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries([ReactQueryKey.WorkspaceContacts]);
    queryClient.invalidateQueries([ReactQueryKey.RecordDetail]);
  }, [queryClient]);

  const postForm = async (values: ExistingContactFormValues) => {
    const { contact } = values;

    await updateContact({
      id: contact.id,
      dto: {
        ...contact,
        relatedProjects: [...contact.contactProjects.map(({ id }) => id), recordId]
      }
    });

    invalidateCache();

    onClose();
  };
  const { handleSubmit, form } = useForm<ExistingContactFormValues>({
    onSubmit: postForm
  });

  const rules = useMemo<FormValidationRules<ExistingContactFormValues>>(
    () => ({
      contact: {
        isRequired: true
      }
    }),
    []
  );

  const {
    formState: { isSubmitting },
    control
  } = form;

  if (isNewContact) {
    return <ContactForm recordId={recordId} onClose={onClose} />;
  }

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <FormBody>
        <FieldsRow>
          <SelectField
            name="contact"
            control={control}
            label="Select Contact"
            options={options}
            renderOption={(contact) => (
              <div>
                <ContactOptionTitle>{contact.name}</ContactOptionTitle>
                <ContactOptionMeta>
                  {[contact.emails?.[0], contact.phones?.[0]].filter(Boolean).join(', ')}
                </ContactOptionMeta>
              </div>
            )}
            filterOptions={(options) => options}
            getOptionLabel={(contact) => contact.name}
            placeholder="Search by name, email or phone"
            onInputChange={(_, value) => onSearch(value)}
            // eslint-disable-next-line
            PaperComponent={({ children, ...props }) => (
              <Paper {...props}>
                {children}
                <AddButtonContainer>
                  <AddButton onMouseDown={() => setIsNewContact(true)}>+ Create new contact</AddButton>
                </AddButtonContainer>
              </Paper>
            )}
          />
          <div />
        </FieldsRow>
      </FormBody>

      <FooterActions>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          Create
        </Button>
      </FooterActions>
    </Form>
  );
};
