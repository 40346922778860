import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiErrorHandler } from '@utils';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { CompanyPhone } from '@generated/types/graphql';
import phoneAPI from '@services/api/phoneAPI';
import { BuyPhoneDto, UpdateCompanyPhoneDto } from '@services/api/types';
import { gql } from 'graphql-request';

export const useCompanyPhones = (companyId: number) => {
  return useQuery<CompanyPhone[]>(
    [ReactQueryKey.CompanyPhones, companyId],
    async () => {
      try {
        return (await postGraphql<any>(gql`
            query($companyId: Int!) {
                companyPhones(
                    filter: {
                        companyId: { equalTo: $companyId },
                        isPermanentlyDeleted: { equalTo: false }
                    }
                ) {
                    id
                    phone
                    twilioSid
                    companyId
                    alias
                    createdAt
                    createdByUser {
                        id
                        lastName
                        lastName
                    }
                }
            }
        `, { companyId })).companyPhones;
      } catch (e) {
        throw apiErrorHandler('Error fetching company phones', e);
      }
    },
    {
      enabled: !!companyId,
      initialData: []
    }
  );
};

export const useCompanyPhonesMutations = (companyId: number) => {
  const queryClient = useQueryClient();

  const createMutation = useMutation<void, Error, BuyPhoneDto>(
    async (dto) => {
      try {
        await phoneAPI.buyPhone(companyId, dto);
      } catch (e) {
        throw apiErrorHandler('Error creating phone', e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKey.CompanyPhones, companyId]);
      }
    }
  );

  const updateMutation = useMutation<void, Error, { id: number, dto: UpdateCompanyPhoneDto }>(
    async ({ id, dto }) => {
      try {
        await phoneAPI.updatePhone(companyId, id, dto);
      } catch (e) {
        throw apiErrorHandler('Error updating phone', e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKey.CompanyPhones, companyId]);
      }
    }
  );

  const removeMutation = useMutation<void, Error, number>(
    async (id) => {
      try {
        await phoneAPI.deletePhone(companyId, id);
      } catch (e) {
        throw apiErrorHandler('Error removing phone', e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKey.CompanyPhones, companyId]);
      }
    }
  );

  return {
    create: createMutation,
    update: updateMutation,
    remove: removeMutation
  };
};
