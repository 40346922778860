import styled from 'styled-components';
import { device, fonts } from '@styles';

export const FormBody = styled.div`
  padding: 24px;
`;

export const FieldsRow = styled.div`
  display: flex;
  gap: 40px;
  > * {
    flex: 1;
  }
`;

export const FooterActions = styled.div`
  background: #ffffff;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;

  ${device.sm`
    min-width: 700px;
    `}

  .delete-button {
    margin-right: auto;
  }
`;

export const AddButtonContainer = styled.div`
  border-top: 1px solid #dfdfe8;
`;

export const AddButton = styled.div`
  text-transform: uppercase;
  font: 500 12px ${fonts.sans};
  color: #009688;
  cursor: pointer;
  padding: 12px;
`;

export const RelatedRecords = styled.div`
  border-radius: 4px;
  margin-top: 24px;
  border: 1px solid #dfdfe8;
  padding: 16px;
  background: #ffffff;

  h3 {
    font-size: 16px;
    font-weight: 500;
    color: #828d9a;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const RelatedRecordList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
  max-width: 500px;
`;

export const NoRelatedRecords = styled.div`
  color: #9e9e9e;
  font-size: 14px;
`;

export const RelatedRecordBadge = styled.div`
  border: 1px solid #dfdfe8;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  color: #009688;
  background-color: transparent;

  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  height: 24px;

  & svg {
    margin-right: 4px;
  }

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ContactOptionTitle = styled.div`
  font-weight: 500;
`;
export const ContactOptionMeta = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #9c9caa;
`;
