import React from 'react';
import { MenuItem as MUiMenuItem, Select as MUiSelect, Checkbox as MUiCheckbox } from '@material-ui/core';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@common/ui';
import { device, colors, selectMui, checkboxMui, dropdown, fonts, scroll } from '@styles';
import { UiSizes } from '@enums';

const stylesSelect = selectMui({ size: UiSizes.Small, noBorder: true });
const stylesCheckbox = checkboxMui({ size: '30px' });

export const useStylesSelect = makeStyles(stylesSelect);
export const useStylesCheckbox = makeStyles(stylesCheckbox);

const Menu = styled.div<{ isWorkspaces: boolean }>`
  && {
    width: 200px;
    ul {
      display: flex;
      flex-direction: column;
      max-height: 240px;
      overflow-y: auto;

      ${scroll};

      &:before {
        display: ${(props) => (props.isWorkspaces ? 'block' : 'none')};
        content: 'My workspaces';
        order: 0;
        font: 500 12px/1.25 ${fonts.sans};
        color: ${colors.gray};
        padding: 0 16px;
        margin: 8px 0;
      }

      &:after {
        display: ${(props) => (props.isWorkspaces ? 'block' : 'none')};
        content: 'Shared with me';
        order: 2;
        font: 500 12px/1.25 ${fonts.sans};
        color: ${colors.gray};
        padding: 0 16px;
        margin: 8px 0;
      }
    }
  }
`;

export const Paper = React.forwardRef((props, ref) => <Menu {...props} ref={ref} />);

export const MenuItem = styled(MUiMenuItem)<{ order?: number }>`
  && {
    ${dropdown.item};

    padding: 0 0 0 7px;
    min-width: 180px;
    width: calc(100% - 20px);
    margin-left: 10px;
    order: ${(props) => props.order};

    > div {
      text-overflow: ellipsis;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    &.Mui-selected {
      background-color: ${colors.white2};

      &:hover,
      &:focus {
        background-color: ${colors.white3};
      }
    }
  }
`;

export const Select = styled(MUiSelect)<{ label: string; selectedCount: string }>`
  && {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    margin: 4px;

    .MuiSelect-root {
      padding-left: 8px;
      padding-right: 52px;

      ${device.sm`
        background-color: transparent;
      `}

      &:before {
        display: block;
        content: '${(props) => props.label}';
        font: 500 12px/14px ${fonts.sans};
        white-space: nowrap;
        color: ${colors.gray2};
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 70px;
        text-transform: capitalize;

        ${device.sm`
          max-width: 100%;
        `}
      }

      &:after {
        position: absolute;
        z-index: 1;
        right: 28px;
        min-width: 20px;
        height: 20px;
        border-radius: 50px;
        display: ${(props) => (props.selectedCount ? 'flex' : 'none')};
        justify-content: center;
        align-items: center;
        content: '${(props) => (props.selectedCount ? `(${props.selectedCount})` : props.selectedCount)}';
        font: 500 13px/1.25 ${fonts.sans};
      }
    }

    fieldset {
      top: 0;
      border-color: ${colors.white};
    }

    legend {
      display: none;
    }
  }
`;

export const Checkbox = styled(MUiCheckbox)`
  && {
    margin-left: auto;
    min-width: 0 !important;
  }
`;

export const InputWrapper = styled.div`
  position: sticky;
  top: -8px;
  background-color: #ffffff;
  z-index: 10;
  margin-top: -8px;
  width: 100%;
  padding: 8px 10px;
  outline: none;

  input::placeholder {
    font-style: italic !important;
  }
`;

export const Input = styled(TextField)``;

export const MenuLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 32px;
`;
