import { useQuery } from 'react-query';
import { gql } from 'graphql-request';
import { apiErrorHandler } from '@utils';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { EmailAccount } from '@generated/types/graphql';

export const useCompanyEmailAccounts = (companyId: number) => {
  return useQuery<EmailAccount[]>(
    [ReactQueryKey.CompanyEmailAccounts, companyId],
    async () => {
      try {
        return (await postGraphql<any>(gql`
            query($companyId: Int!) {
                emailAccounts(
                    filter: {
                        companyId: { equalTo: $companyId },
                    }
                ) {
                    id
                    companyId
                    emailAddress
                    nylasAccessToken
                    nylasAccountId
                }
            }
        `, { companyId })).emailAccounts;
      } catch (e) {
        throw apiErrorHandler('Error fetching company email accounts', e);
      }
    },
    {
      enabled: !!companyId,
      initialData: []
    }
  );
};
