import { device, scroll } from '@styles';
import styled, { css } from 'styled-components';

export const PropertiesGrid = styled.div<{ maxHeight?: string }>`
  margin-top: 16px;
  display: grid;
  gap: 24px;
  row-gap: 16px;
  grid-template-columns: 1fr;
  > div {
    grid-column: 1;
  }

  ${device.md`
  grid-template-columns: repeat(3, minmax(0, 1fr));
  > div {
    grid-column: auto;
  }
  `};

  ${({ maxHeight }) =>
    maxHeight &&
    `
    max-height: ${maxHeight};
    overflow-y: auto;
    ${scroll};
  `};
`;

export const Container = styled.div`
  position: relative;
  background-color: white;
  border-radius: 8px;
`;

export const Chips = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ProjectContacts = styled.div`
  display: flex;
  gap: 16px;

  > * {
    flex: 1;
    min-width: 0;
  }
`;

export const ProjectContact = styled.div`
  flex: 1;
  min-width: 0;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const FullDescriptionBody = styled.div`
  height: 100%;
  overflow-y: auto;
  font-size: 14px;
  line-height: 130%;
  max-height: 180px;
  color: #1d1d35;
  ${scroll};
`;

export const EditableWrapper = styled.div`
  cursor: pointer;
  padding: 4px;
  margin-left: -4px;
  margin-right: 4px;
  border: 1px solid transparent;
  min-width: 40px;
  width: 100%;

  transition: border-color 0.15s ease-in-out;

  &:hover {
    border: 1px solid #c8c8d3;
    border-radius: 4px;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const PropertyContainer = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  
  ${(props) => props.fullWidth && css`
    grid-column: 1 / -1 !important;
  `}
`;

export const PropertyName = styled.label`
  font-size: 12px;
  color: #828d9a;
`;

export const PropertyValue = styled.label`
  font-size: 14px;
  color: #1d1d35;
`;
