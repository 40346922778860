import React from 'react';
import { defaultTheme } from '@themes';

export const CheckboxOn = () => (
  <svg
    width={20}
    height={20}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="2" fill={defaultTheme.colors.green} />
    <path d="M8.6437 13.8429L5.1571 10.3563C4.94763 10.1468 4.94763 9.80718 5.1571 9.59769L5.91567 8.8391C6.12514 8.62961 6.46479 8.62961 6.67426 8.8391L9.023 11.1878L14.0537 6.1571C14.2632 5.94763 14.6029 5.94763 14.8123 6.1571L15.5709 6.91569C15.7804 7.12516 15.7804 7.46479 15.5709 7.67428L9.40229 13.8429C9.1928 14.0524 8.85317 14.0524 8.6437 13.8429Z"
          fill={defaultTheme.colors.white}
    />
  </svg>
);
