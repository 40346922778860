import React, { useCallback, useMemo } from 'react';
import { Loader } from '@kit/ui/Loader';
import { useModal } from '@common/PromiseModal';
import { AnalyticsWidgetType, StandardPropertyId, WidgetSettings } from '../types';
import { useWidgetData } from '../useWidgetData';
import { BarChart } from './BarChart';
import { LineChart } from './LineChart';
import { PieChart } from './PieChart';
import { Point } from './types';
import { Drilldown } from '../Drilldown';
import { Kpi } from './Kpi';
import { Leaderboard } from './Leaderboard';
import { Funnel } from './Funnel';

interface Props {
  settings: WidgetSettings;
}

export const WidgetContent = ({ settings }: Props) => {
  const { data, isLoading, error } = useWidgetData(settings);

  const { openModal } = useModal();

  const isCurrency = useMemo(() => {
    return (
      settings.measure?.id === StandardPropertyId.PROJECT_VALUE ||
      settings.measure?.id === StandardPropertyId.REQUEST_VALUE
    );
  }, [settings]);

  const handleBarLikeSliceClick = useCallback(
    (point: Point) => {
      openModal<void>(() => <Drilldown settings={settings} segment={point.isEmpty ? null : point.originalX} />, {
        title: `Drill Down · ${point.x}`
      });
    },
    [openModal, settings]
  );

  const handleKpiClick = useCallback(() => {
    openModal<void>(() => <Drilldown settings={settings} />, {
      title: 'Drill Down'
    });
  }, [openModal, settings]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Something went wrong</div>;
  }

  if (!data) {
    return null;
  }

  if ('error' in data) {
    return <div>Error: {data.error}</div>;
  }

  const numberFormat = isCurrency ? 'currency' : 'number';

  switch (data.type) {
    case AnalyticsWidgetType.KPI:
      return <Kpi positiveTrendType={settings.positiveTrendType} {...data} onClick={handleKpiClick} />;
    case AnalyticsWidgetType.BAR:
      return <BarChart numberFormat={numberFormat} points={data.points} onClick={handleBarLikeSliceClick} />;
    case AnalyticsWidgetType.LINE:
      return <LineChart numberFormat={numberFormat} points={data.points} onClick={handleBarLikeSliceClick} />;
    case AnalyticsWidgetType.TIMELINE:
    case AnalyticsWidgetType.PIPELINE:
    case AnalyticsWidgetType.FUNNEL:
      return (
        <Funnel
          widgetType={settings.widgetType}
          module={settings.module}
          workflowId={settings.workflowId}
          {...data}
          onClick={handleBarLikeSliceClick}
        />
      );
    case AnalyticsWidgetType.PIE:
      return <PieChart numberFormat={numberFormat} points={data.points} onClick={handleBarLikeSliceClick} />;
    case AnalyticsWidgetType.DOUGHNUT:
      return (
        <PieChart
          numberFormat={numberFormat}
          points={data.points}
          onClick={handleBarLikeSliceClick}
          innerRadius="60%"
        />
      );
    case AnalyticsWidgetType.LEADERBOARD:
      return (
        <Leaderboard
          positiveTrendType={settings.positiveTrendType}
          points={data.points}
          numberFormat={numberFormat}
          onClick={handleBarLikeSliceClick}
        />
      );
    default:
      return <div>{`${settings.widgetType} not implemented yet`}</div>;
  }
};
