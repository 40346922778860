import {
  Paging,
  UserFromApi,
  CreateDashboardDTO,
  UpdateDashboardDto,
  InviteUserDto,
  UpdateUserDto,
  InvitationStatus
} from '@types';
import { getProjectMembersUsersURL } from '@services/service.config';
import { Dashboard } from '@generated/types/graphql';
import { get, post, patch, deleteRequest, put } from './base';
import { Urls } from './base/axios';

/* eslint-disable max-len */
export default {
  getAssigneesAll: ({ queryKey: [, companyId] }) =>
    get<Paging<UserFromApi>>(
      `${Urls.user}/users/options?filters[0][col]=isActive&filters[0][op]==&filters[0][val]=true&fetchAll=true&companyId=${companyId}`
    ),
  getAssignees: (search: string, companyId?: number) =>
    get<Paging<UserFromApi>>(
      `${
        Urls.user
      }/users/options?filters[0][col]=isActive&filters[0][op]==&filters[0][val]=true&paging[limit]=10&paging[skip]=0&searchKey=${encodeURIComponent(
        search
      )}&${companyId ? `&companyId=${companyId}` : ''}`
    ),
  getAssignee: (id: number, companyId?: number) =>
    get<Paging<UserFromApi>>(
      `${Urls.user}/users/options?filters[0][col]=id&filters[0][op]==&filters[0][val]=${encodeURIComponent(id)}${
        companyId ? `&companyId=${companyId}` : ''
      }`
    ),
  getProjectMembers: (projectId: number, query: string) =>
    get(
      getProjectMembersUsersURL(
        {
          page: 0,
          limit: 10,
          skip: 0,
          name: query
        },
        projectId
      )
    ),
  createDashboard: (companyId: number, dto: CreateDashboardDTO) =>
    post<Dashboard>(`${Urls.user}/dashboard`, dto, { companyId }),
  updateDashboard: (companyId: number, id: number, dto: UpdateDashboardDto) =>
    patch<Dashboard>(`${Urls.user}/dashboard/${id}`, dto, { companyId }),
  deleteDashboard: (companyId: number, id: number) =>
    deleteRequest<Dashboard>(`${Urls.user}/dashboard/${id}`, {}, { companyId }),
  deactivateUser: ({ companyId, userId }: { companyId: number; userId: number }) =>
    put(`${Urls.user}/users/${userId}?companyId=${companyId}`, {
      id: userId,
      companies: [{ id: companyId, isActive: false }]
    }),
  inviteUser: (companyId: number, dto: InviteUserDto) =>
    post<InvitationStatus[]>(`${Urls.user}/users/invite?companyId=${companyId}`, {
      invites: [
        {
          email: dto.email,
          phone: dto.phone,
          firstName: dto.firstName,
          lastName: dto.lastName,
          roles: [dto.roleId]
        }
      ],
      inviteUrl: `${window.location.origin}/invite`,
      isCollaborator: dto.isCollaborator
    }),
  updateUser: (companyId: number, userId: number, dto: UpdateUserDto) =>
    put<{ id: number }>(`${Urls.user}/users/${userId}?companyId=${companyId}`, {
      ...dto,
      companies: [{ id: companyId, roles: [{ id: dto.roleId }] }]
    })
};
