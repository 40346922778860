import { handleActions } from 'redux-actions';

import {
  fetchGoeLocationRequest,
  fetchGoeLocationSuccess,
  fetchGoeLocationFailure
} from '../mapViewActions'


interface ProjectMapViewReducer {
  isLoading: boolean;
  center: [number, number]
  // will figure out later what should be inside
}

const projectMapViewDefaultReducer = {
  isLoading: false,
  center: [0 , 0]
 // will figure out later what should be inside
};

// fetch favorite projects
const fetchGoeLocationRequestReducer = (state: ProjectMapViewReducer, { payload }): ProjectMapViewReducer => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchGoeLocationSuccessReducer = (state: ProjectMapViewReducer, { payload }): ProjectMapViewReducer => {
  const {features} = payload;
  const center = features && features.length > 0 ? features[0].center : [0, 0];
  return {
    ...state,
    center: center,
    isLoading: false,
  };
};

const fetchGoeLocationFailureReducer = (state: ProjectMapViewReducer, { payload }): ProjectMapViewReducer => {
  return {
    ...state,
    isLoading: false,
  };
};

const projectsMapViewReducer = handleActions({
  [fetchGoeLocationRequest]: fetchGoeLocationRequestReducer,
  [fetchGoeLocationSuccess]: fetchGoeLocationSuccessReducer,
  [fetchGoeLocationFailure]: fetchGoeLocationFailureReducer,
}, projectMapViewDefaultReducer);

export default projectsMapViewReducer;
