import { ReactQueryKey } from '@enums';
import { FeedInbox, FeedInboxFilter, FeedInboxesConnection, FeedInboxesOrderBy } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useInfiniteQuery } from 'react-query';
import { DeepPartial } from 'redux';
import { merge } from 'lodash';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector, useAuth } from '..';

const PER_PAGE = 50;

const fetchNotifications = async ({
  userId,
  companyId,
  pageParam,
  filters = {},
  orderBy = FeedInboxesOrderBy.CreatedAtDesc
}: {
  companyId: number;
  filters?: DeepPartial<FeedInboxFilter>;
  orderBy?: FeedInboxesOrderBy;
  userId: number;
  pageParam: number;
}) => {
  const feedInboxFilter: DeepPartial<FeedInboxFilter> = merge(
    {
      userId: { equalTo: userId },
      feedExists: true,
      feed: {
        companyId: { equalTo: companyId }
      }
    },
    filters
  );

  try {
    const {
      feedInboxesConnection: { nodes: notifications, totalCount }
    } = await postGraphql<{ feedInboxesConnection: FeedInboxesConnection }>(
      gql`
        query ($offset: Int, $first: Int, $orderBy: [FeedInboxesOrderBy!], $filter: FeedInboxFilter) {
          feedInboxesConnection(offset: $offset, first: $first, orderBy: $orderBy, filter: $filter) {
            nodes {
              id
              entityType
              eventType
              createdAt
              read

              feed {
                id
                event
                eventType
                virtualCreatedAt
                parent {
                  id
                  virtualCreatedAt
                }
                payload

                task {
                  uid
                  isField
                }

                reminder {
                  id
                  type
                }

                createdByContact {
                  id
                  name
                  portalStatus
                }

                createdByUser {
                  id
                  firstName
                  lastName
                  avatarUrl
                  phone
                }

                project {
                  id
                  uid
                  title
                  type
                  source
                }

                emailMessage {
                  id
                  from
                  to
                  subject
                  body
                  bodyCleaned
                }

                relatedComment {
                  id
                  comment
                }

                smsActivity {
                  text
                  fromPhone
                  fromUser {
                    id
                    firstName
                    lastName
                    avatarUrl
                    phone
                  }
                  fromContact {
                    id
                    name
                    phones
                    emails
                    status
                  }
                  fromCompanyPhone {
                    id
                    alias
                    phone
                  }
                  fromJurisdiction {
                    id
                    name
                    phones
                    emails
                    address
                  }
                  toUser {
                    id
                    firstName
                    lastName
                    avatarUrl
                    phone
                  }
                  toContact {
                    id
                    name
                    phones
                    emails
                    status
                  }
                  toCompanyPhone {
                    id
                    alias
                    phone
                  }
                  toJurisdiction {
                    id
                    name
                    phones
                    emails
                    address
                  }
                  text
                  status
                  isIncoming
                }
              }
            }
            totalCount
          }
        }
      `,
      { filter: feedInboxFilter, orderBy, offset: pageParam * PER_PAGE, first: PER_PAGE }
    );

    return { notifications, totalCount, pageParam };
  } catch (e) {
    throw apiErrorHandler(`Error fetching notifications`, e);
  }
};

export type PaginatedNotifications = {
  notifications: FeedInbox[];
  totalCount: number;
  pageParam: number;
};

export const useNotificationList = ({
  filters,
  orderBy
}: { filters?: DeepPartial<FeedInboxFilter>; orderBy?: FeedInboxesOrderBy } = {}) => {
  const { user } = useAuth();
  const companyId = useAppSelector(selectWorkspaceId);

  return useInfiniteQuery<PaginatedNotifications>(
    [ReactQueryKey.InboxNotifications, companyId, user.userId, { filters, orderBy }],
    ({ pageParam = 0 }) => fetchNotifications({ companyId, userId: user.userId, pageParam, filters, orderBy }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.notifications.length === PER_PAGE ? lastPage.pageParam + 1 : undefined;
      },
      enabled: user.userId !== 0,
      keepPreviousData: true
    }
  );
};
