import React from 'react';
import { Field } from 'formik';
import { MenuItem, FormControl } from '@material-ui/core';
import { Select } from 'formik-material-ui';
import { BoxCell } from '.';
import { InputLabel } from '@common/ui';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { dropdown, selectMui } from '@styles';
import { ChevronDown } from 'react-feather';
import { useRoles } from '@hooks/useRoles';
import { RoleDTO } from '@types';
import { normalizeRoleName } from '@utils';

const styles = selectMui({});
const useStyles = makeStyles(styles);

const StyledMenuItem = styled(MenuItem)`
  && {
    ${dropdown.item};
    min-width: auto;
    width: calc(100% - 20px);
    margin-left: 10px;
  }
`;

type AccountTypeField = {
  label: string;
  name: string;
  component?: React.ReactNode;
  disabled?: boolean;
};

const AccountTypeField: React.FC<AccountTypeField> = ({
  label,
  name,
  component = Select,
  disabled = false
}) => {
  const classes = useStyles();

  const { rolesQuery: { data: roles } } = useRoles();

  return (
    <BoxCell>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <FormControl fullWidth>
        <Field
          classes={classes}
          disabled={disabled}
          component={component}
          name={name}
          variant="outlined"
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          }}
          IconComponent={ChevronDown}
        >
          {roles!.map((role: RoleDTO) => (
            <StyledMenuItem value={role.id} key={role.id}>
              {normalizeRoleName(role.name)}
            </StyledMenuItem>
          ))}
        </Field>
      </FormControl>
    </BoxCell>
  );
};

export default AccountTypeField;
