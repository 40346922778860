import { ReactQueryKey } from '@enums';
import { Invoice } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

export const useInvoiceList = (recordId: number) => {
  return useQuery<Invoice[]>([ReactQueryKey.InvoiceList, recordId], async () => {
    try {
      const data = await postGraphql<{ invoices: Invoice[] }>(
        gql`
          query ($recordId: Int) {
            invoices(filter: { projectId: { equalTo: $recordId } }) {
              id
              title
              dueDate
              description
              uid
              amount
              amountPaid
              status
              createdAt
              updatedAt
              issueDate
              isBill
              project {
                id
                type
                title
                projectsByParentProjectId {
                  invoicesByProjectId {
                    id
                    title
                    dueDate
                    description
                    uid
                    amount
                    amountPaid
                    status
                    createdAt
                    updatedAt
                    issueDate
                    isBill

                    project {
                      id
                      type
                      title
                    }

                    createdByUser {
                      id
                      firstName
                      lastName
                      email
                      phone
                      avatarUrl
                    }
                  }
                }
              }

              createdByUser {
                id
                firstName
                lastName
                email
                phone
                avatarUrl
              }
            }
          }
        `,

        { recordId }
      );

      return data.invoices;
    } catch (e) {
      throw apiErrorHandler('Error fetching invoices', e);
    }
  });
};
