import { fonts, scroll } from '@styles';
import styled from 'styled-components';

export const List = styled.div`
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dfdfe8;
`;

export const Scrollable = styled.div`
  max-height: 320px;
  overflow-y: auto;
  ${scroll};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dfdfe8;
  > * {
    padding: 0 20px;
    flex: 1;

    &:last-child {
      flex: 0 0 64px;
      width: 64px;
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const Member = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
`;

export const Header = styled(Row)`
  height: 48px;
  color: #98a9bc;
  font-size: 12px;
  font-weight: 400;
`;

export const PlaceholderRow = styled(Header)`
  border-bottom: none;
`;

export const MemberRow = styled(Row)`
  height: 64px;
`;

export const OptionTitle = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
  padding-top: 4px;
`;

export const OptionDescription = styled.div`
  font-size: 12px;
  color: #828d9a;
  padding-bottom: 8px;
`;

export const Remove = styled.div`
  cursor: pointer;
`;

export const SharedWithGroupTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 400;
  font-family: ${fonts.sans};
  padding: 0 12px;
  color: #828d9a;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  svg {
    flex-shrink: 0;
  }

  svg:last-child {
    margin-left: auto;
  }
`;

export const OptionNoAccessWarning = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
`;

export const TeamWarning = styled.div`
  margin-left: 40px;
  font-size: 12px;
  font-weight: 400;

  a {
    color: #235dff;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 20px;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:link {
      color: #235dff;
    }
  }
`;

export const TeamWarningTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #d54855;
`;
