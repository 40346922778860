import React, { useState, useMemo, useCallback } from 'react';
import { get, filter, uniqBy } from 'lodash';
import { CopyButton } from '@common/ui';
import UserTile from '@common/UserTile';

import { RecordDetail, useAppSelector, useRecordDetail } from '@hooks';
import { ContextMenu } from '@kit/components/ContextMenu';
import { Contact } from '@generated/types/graphql';
import { EditIcon } from '@kit/ui/icons/Edit';
import { useRecordsMutations } from '@hooks/useRecords';
import { RecordType } from '@types';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { ReactQueryKey } from '@enums';
import { useQueryClient } from 'react-query';
import { Plus, Users, XCircle } from 'react-feather';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Tooltip } from '@material-ui/core';
import { isCustomerPortalEnabled } from '@state/selectors';
import { UserAvatar } from '@kit/components/UserAvatar';
import { PortalIcon } from '@kit/ui/icons/Portal';
import { PhoneNumber } from '@kit/ui/anchors/PhoneNumber';
import { EmailAddress } from '@kit/ui/anchors/EmailAddress';
import { Tabs, Tab, List, ContactRow, MemberRow, Copyable, UserCell, PortalIconWrapper } from './styled';
import { CreateWork, Widget, WidgetHeader, WidgetIcon, WidgetTitle } from '../styled';
import { ContactForm, CreateContactForm } from './ContactForm';

interface Props {
  projectId: number;
  isFullWidth?: boolean;
  title?: string;
  addButtonText?: string;
}

const TABS = [
  {
    id: 'contacts',
    title: 'Contacts'
  },
  {
    id: 'members',
    title: 'Owner & Members'
  }
];

const RECORD_TYPE_TITLE_MAP: Record<RecordType, string> = {
  [RecordType.ACCOUNT]: 'Client',
  [RecordType.PROJECT]: 'Project',
  [RecordType.DEAL]: 'Request'
};

const ContactMenu = ({ contact, record }: { contact: Contact; record: RecordDetail }) => {
  const {
    update: { mutateAsync: updateRecord }
  } = useRecordsMutations(record?.companyId || 0, record?.type as RecordType);
  const confirmDelete = useConfirmDeleteModal();
  const queryClient = useQueryClient();
  const { openModal } = useModal();

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries([ReactQueryKey.WorkspaceContacts]);
    queryClient.invalidateQueries([ReactQueryKey.RecordDetail]);
  }, [queryClient]);

  const items = useMemo(
    () => [
      {
        title: 'Edit',
        icon: <EditIcon size="16px" color="#9C9CAA" />,
        onClick: () => {
          openModal<void>(
            ({ onClose }) => <ContactForm recordId={record.id} initialValues={contact} onClose={onClose} />,
            { title: 'Edit Contact' }
          );
        }
      },
      {
        title: `Remove from ${RECORD_TYPE_TITLE_MAP[record.type as RecordType]}`,
        icon: <XCircle size="16px" color="#9C9CAA" />,
        onClick: async () => {
          if (
            await confirmDelete(
              `Are you sure you want to remove this Contact from ${RECORD_TYPE_TITLE_MAP[record.type as RecordType]}?`
            )
          ) {
            await updateRecord({
              id: record.id,
              dto: {
                contacts: record.projectContacts
                  .filter((related) => related.id !== contact.id)
                  .map((related) => ({ contactId: related.id }))
              }
            });

            invalidateCache();
          }
        }
      }
    ],
    [contact, invalidateCache, openModal, confirmDelete, record, updateRecord]
  );

  return <ContextMenu items={items} />;
};

export const Stakeholders = ({
  title = 'Stakeholders',
  addButtonText = 'Stakeholder',
  projectId,
  isFullWidth = false
}: Props) => {
  const [activeTab, setActiveTab] = useState('contacts');
  const { data: record } = useRecordDetail(projectId, { refetchOnMount: false });
  const { openModal } = useModal();
  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);

  const projectMembers = useMemo(
    () =>
      uniqBy(
        filter(
          [
            { member: record.salesRep, ...(record.salesRep || {}) },
            { member: record.projectManager, ...(record.projectManager || {}) },
            { member: record.owner, ...(record.owner || {}) },
            ...filter(record.projectMembers, (item) => get(item, 'member.id') !== record.ownerId)
          ],
          ({ member }) => member && member?.id
        ),
        ({ member }) => member.id
      ),
    [record.owner, record.ownerId, record.projectManager, record.projectMembers, record.salesRep]
  );

  const handleAdd = useCallback(() => {
    openModal<void>(({ onClose }) => <CreateContactForm recordId={record.id} onClose={onClose} />, {
      title: 'Create Contact'
    });
  }, [openModal, record.id]);

  return (
    <Widget isFullWidth={isFullWidth}>
      <WidgetHeader>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#ECE3FF">
            <Users size="16px" color="#7256AC" />
          </WidgetIcon>
          {title}
          <CreateWork>
            <Button onClick={handleAdd} variant={ButtonVariant.Secondary}>
              <Plus size="16px" />
              {addButtonText}
            </Button>
          </CreateWork>
        </WidgetTitle>
      </WidgetHeader>

      <Tabs>
        {TABS.map((tab) => (
          <Tab key={tab.id} onClick={() => setActiveTab(tab.id)} isActive={activeTab === tab.id}>
            {tab.title}
          </Tab>
        ))}
      </Tabs>

      {activeTab === 'contacts' && (
        <List>
          {record.projectContacts.map((contact) => (
            <ContactRow key={contact.id}>
              <UserTile title={contact.name} userName={contact.name} userId={contact.id} imgSrc="" disableInfoPopper />
              {isPortalEnabled && contact.portalStatus === 'INVITED' && (
                <Tooltip title="Contact is invited to Portal">
                  <PortalIconWrapper>
                    <PortalIcon size="12px" color="#FFF" />
                  </PortalIconWrapper>
                </Tooltip>
              )}
              <Copyable isEnabled={Boolean(contact.phones[0])}>
                <PhoneNumber value={contact.phones[0]} />
                <CopyButton value={contact.phones[0]} />
              </Copyable>
              <Copyable isEnabled={Boolean(contact.emails[0])}>
                <EmailAddress value={contact.emails[0]} />
                <CopyButton value={contact.emails[0]} />
              </Copyable>

              <ContactMenu contact={contact} record={record} />
            </ContactRow>
          ))}
        </List>
      )}

      {activeTab === 'members' && (
        <List>
          {projectMembers.map(({ member }) => (
            <MemberRow key={member.id}>
              <UserCell>
                <UserAvatar user={member} />
                <div>
                  {member.firstName} {member.lastName}
                </div>
              </UserCell>
              {member.email && (
                <Copyable isEnabled={Boolean(member.email)}>
                  <EmailAddress value={member.email} />
                  <CopyButton value={member.email} />
                </Copyable>
              )}
            </MemberRow>
          ))}
        </List>
      )}
    </Widget>
  );
};
