import { useQuery } from 'react-query';
import { EmailAccount } from '@generated/types/graphql';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { apiErrorHandler } from '@utils';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '@hooks/store';

export const useEmailAccountList = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery([ReactQueryKey.WorkspaceEmailAccounts, companyId], async () => {
    try {
      const data = await postGraphql<{ emailAccounts: EmailAccount[] }>(
        gql`
          query ($companyId: Int!) {
            emailAccounts(filter: { companyId: { equalTo: $companyId } }) {
              id
              emailAddress
              nylasStatus
              nylasProvider
              alias
              emailAccountUsers {
                canSend
                canReceiveAll
                user {
                  id
                  firstName
                  lastName
                  email
                }
              }
            }
          }
        `,
        { companyId }
      );

      return data.emailAccounts;
    } catch (e) {
      throw apiErrorHandler('Error fetching email accounts', e);
    }
  });
};
