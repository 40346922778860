import fonts from '../fonts';
import colors from '../colors';

const redirectLink = () => `
  font: 500 14px/20px ${fonts.sans};
  color: ${colors.green};
  cursor: pointer;
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: underline;
  }
`;

export default redirectLink;
