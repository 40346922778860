import { colors, device, deviceMax, fonts, progress } from '@styles';
import styled from 'styled-components';

export const FileProgress = styled.div`
  display: flex;
  align-items: center;
  color:${colors.black};
  font: 14px/1.25 ${fonts.sans};

  width: 70px;
  ${device.sm`
    width: 100px;
  `}
  
  ${deviceMax.md`
    margin-left: 8px;
  `}

  ${progress};
  
  progress {
    margin-right: 8px;
  }
`;
