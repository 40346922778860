import { colors, scroll } from '@styles';
import styled, { css } from 'styled-components';

const highlightAnimation = (endColor = 'transparent') => css`
  background-color: #e3f6f4;
  animation: backgroundChange 5s forwards;
  animation-delay: 3s;
  @keyframes backgroundChange {
    from {
      background-color: #e3f6f4;
    }
    to {
      background-color: ${endColor};
    }
  }
`;

export const Container = styled.div<{ isHighlighted?: boolean }>`
  padding: 0 16px;
  ${({ isHighlighted }) => isHighlighted && highlightAnimation()};

  b {
    font-weight: 500;
  }
`;

export const GroupItemContainer = styled(Container)<{ hideTopBorder?: boolean; noRightPadding?: boolean }>`
  ${({ hideTopBorder }) => hideTopBorder && 'border-top: none; padding-top: 0px;'}
  ${({ noRightPadding }) => noRightPadding && 'padding-right: 0px;'}
  form {
    width: 100%;
  }
`;

export const GroupItemTemplateContainer = styled.div`
  padding: 0 16px;
`;

export const CommentItemContainer = styled.div`
  ${GroupItemContainer} {
    border-top: 0;
    padding-top: 0;
  }

  border-top: 1px solid #dfdfe8;
  padding-top: 16px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;

  a {
    cursor: pointer;
    color: #235dff;
    text-decoration: none;

    a:focus,
    a:visted,
    a:active,
    &:hover,
    &:focus {
      text-decoration: none;
      color: #235dff;
    }
  }
`;

export const TitleLeft = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  > *:last-child {
    padding-top: 3px;
  }
`;

export const CommentTitle = styled(Title)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
`;

export const GroupHeader = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;

  b {
    font-weight: 500;
  }

  ${Title} {
    margin-bottom: 0;
    line-height: 24px;
  }
`;

export const GroupContent = styled.div`
  padding-left: 16px;
`;

export const Content = styled.div`
  width: 100%;
  flex: 1;
  font-size: 14px;
  overflow: hidden;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const IconAsAuthor = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9c9caa;
  background-color: #fff;
  border: 1px solid #dfdfe8;
  border-radius: 4px;
  flex-shrink: 0;
`;

export const Footer = styled.div`
  margin-top: 8px;
`;

export const CreatedAt = styled.div<{ noPaddingTop?: boolean }>`
  font-size: 12px;
  color: #828d9a;
  white-space: nowrap;
  line-height: 18px;
  padding-top: ${({ noPaddingTop }) => (noPaddingTop ? '0' : '4px')};
`;

export const ValuesWithArrow = styled.div<{ isVertical?: boolean }>`
  display: flex;
  align-items: ${(props) => (props.isVertical ? 'flex-start' : 'center')};
  gap: 16px;
  font-size: 12px;
  flex-direction: ${(props) => (props.isVertical ? 'column' : 'row')};
  svg {
    flex-shrink: 0;
  }
`;

export const OldValue = styled.div`
  color: #828d9a;
`;

export const StageName = styled.div`
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ChildItems = styled.div`
  padding-left: 36px;
`;

export const ItemActionButton = styled.div`
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #9c9caa;
`;

export const Clamped = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const MemberList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Member = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const UpdatedPropertes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  word-break: break-word;
`;

export const MetaInfo = styled.div`
  margin-bottom: 8px;
`;

export const MetaLabel = styled.span`
  color: #828d9a;
  font-weight: 500;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Thumbnail = styled.img<{ size: number }>`
  object-fit: fill;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 4px;
`;

export const FileTitle = styled.div`
  display: flex;
  gap: 8px;
`;

export const FeedItemIconContainer = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  flex-shrink: 0;
  background-color: ${({ color }) => color};
`;

export const InlineAvatar = styled.div`
  display: inline-block;

  > * {
    display: inline-flex;
    vertical-align: text-bottom;
  }
`;

export const ExpandRepliesButton = styled.div`
  cursor: pointer;
  color: #235dff;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 8px;
  padding-left: 48px;
`;

export const FailedInfoAndRetryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`;

export const FailedInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #d54855;
  font-size: 12px;
  font-weight: 400;
`;

export const RetryButton = styled.div`
  cursor: pointer;
  color: ${colors.green};
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const RetriedAt = styled.span`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
`;

export const TruncatedComment = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
`;

export const MoreButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #009688;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  background-color: #fff;
  padding-left: 5px;
  right: 5px;
  bottom: 0px;
`;

export const CommentWrapper = styled.div<{ withPadding: boolean }>`
  padding-bottom: ${({ withPadding }) => (withPadding ? '0' : '0')};
`;

export const EmailWrapper = styled(CommentWrapper)`
  margin-left: 0;
  font-size: 14px;
`;

export const BorderContainer = styled.div<{ hideTopBorder?: boolean }>`
  padding: 16px 0px;
  border-top: ${({ hideTopBorder }) => !hideTopBorder && '1px solid #dfdfe8'};

  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const CommentFeedItem = styled.div<{ isHighlighted?: boolean; withGrayBg?: boolean }>`
  padding: 0 16px;
  background-color: ${({ withGrayBg }) => (withGrayBg ? '#F7F7FC' : 'transparent')};
  ${({ isHighlighted, withGrayBg }) => isHighlighted && highlightAnimation(withGrayBg ? '#F7F7FC' : 'transparent')};

  font-size: 14px;
  font-weight: 400;

  b {
    font-weight: 500;
  }

  a {
    cursor: pointer;
    color: #235dff;
    text-decoration: none;

    a:focus,
    a:visted,
    a:active,
    &:hover,
    &:focus {
      text-decoration: none;
      color: #235dff;
    }
  }
`;

export const ChildFeedItems = styled.div<{ withGrayBg?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ withGrayBg }) => (withGrayBg ? '#F7F7FC' : 'transparent')};

  ${CommentFeedItem} {
    padding-left: 48px;
  }

  ${GroupItemContainer} {
    padding-left: 48px;
  }
`;

export const CommentContent = styled.div`
  flex: 1;
  min-width: 0;
`;

export const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;

  margin-bottom: 8px;
`;

export const CommentHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding-top: 3px;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;

  a {
    cursor: pointer;
    color: #235dff;
    text-decoration: none;

    a:focus,
    a:visted,
    a:active,
    &:hover,
    &:focus {
      text-decoration: none;
      color: #235dff;
    }
  }
`;

export const CommentHeaderPart = styled.div<{ verticalAlign: string }>`
  display: flex;
  align-items: ${({ verticalAlign }) => verticalAlign};
  gap: 8px;
`;

export const FileNameAndThumbnail = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  word-break: break-word;
  cursor: pointer;
  color: #235dff;
`;

export const AvatarPlaceholder = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #dfdfe8;
  color: #dfdfe8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InlineReplyForm = styled.div<{ withGrayBg?: boolean }>`
  padding-left: 48px;
  padding-right: 16px;
  background-color: ${({ withGrayBg }) => (withGrayBg ? '#F7F7FC' : 'transparent')};
`;

export const EmailFeedItemContainer = styled.div<{ isHighlighted?: boolean }>`
  padding: 0 16px;
  ${({ isHighlighted }) => isHighlighted && highlightAnimation()};

  > ${BorderContainer} {
    padding-bottom: 0;

    > div {
      flex: 1;
    }
  }
`;

export const EmailFeedItemHeader = styled.div`
  display: flex;
  gap: 8px;

  b {
    font-weight: 500;
  }

  ${Title} {
    margin-bottom: 0;
    line-height: 24px;
  }
`;

export const EmailFeedItemContent = styled.div`
  padding-left: 16px;
`;

export const AddOrRemoveLabel = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`;

export const CallMetaData = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 8px;

  a {
    cursor: pointer;
    color: #235dff;
    text-decoration: none;

    a:focus,
    a:visted,
    a:active,
    &:hover,
    &:focus {
      text-decoration: none;
      color: #235dff;
    }
  }
`;

export const SecondaryText = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
`;

export const EmailFrom = styled.span`
  color: #828d9a;
`;

export const RemoveFileButton = styled.div`
  cursor: pointer;
  color: #d54855;
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: 20px;
  height: 20px;

  opacity: 0;
  transition: opacity 0.15s ease-in-out;
`;

export const CommentFileThumbnailContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  position: relative;

  &:hover ${RemoveFileButton} {
    opacity: 1;
  }
`;

export const CommentFileThumbnail = styled.div`
  flex-shrink: 0;
  min-width: 68px;
  width: 68px;
  height: 68px;
  background-image: url(${(props) => props.url});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #e8ecef;
`;

export const CommentFilesGrid = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  ${scroll};
  gap: 8px;
  margin-top: 8px;
`;

export const ActionItemStatusRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ActionItemFilesAndStatus = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ActionAssignee = styled.div<{ isRemoved?: boolean }>`
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;

  ${({ isRemoved }) =>
    isRemoved &&
    `
    text-decoration: line-through;
    color: #828D9A;
    font-weight: 400;

    > *:first-child {
      opacity: 0.5;
    }
  `}
`;

export const ActionAssigneeList = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
`;
