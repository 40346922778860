import { RootState } from '@state/configureStore';
import { parseWorkspaceIdFromUrl, isBrowser, getRecordTypeFromUrl } from '@utils';

type ProjectId = number | string;

export const selectFormId = (state: RootState) => state.formBuilder.id;

export const selectFormName = (state: RootState) => state.formBuilder.name;

export const selectFormFields = (state: RootState) => state.formBuilder.fields;

export const selectCompanies = (state: RootState) => state.companies.data || ([] as typeof state.companies.data);

export const selectCompanyById = (state: RootState, id?: number) =>
  selectCompanies(state).find((company) => company.id === id);

export const selectCompanyIdBySelectedProject = (state: RootState) => state.project.selectedProject.companyId;

export const selectRouterLocation = (state: RootState) => {
  const isInitialPhase = !state.router;

  if (isInitialPhase && isBrowser) {
    return window.location;
  }

  const isOldLocation = state.router.location?.location;
  if (isOldLocation) {
    return state.router.location.location;
  }

  return state.router.location;
};

export const selectWorkspaceIdFromUi = (state: RootState) => state.ui.selectedCompany;

export const selectIsProjectPage = (state: RootState) =>
  !!selectRouterLocation(state)?.pathname?.match(/(\d+)\/projects(\/)?(map|table)?(\/)?$/);

export const selectIsActivityPage = (state: RootState) => !!selectRouterLocation(state)?.pathname?.match(/\/activity/);

export const selectIsKanbanPage = (state: RootState) => !!selectRouterLocation(state)?.pathname?.match(/\/kanban/);

export const selectIsDatabankPage = (state: RootState) => !!selectRouterLocation(state)?.pathname?.match(/\/databank/);

export const selectIsProjectRelatedPage = (state: RootState) =>
  !!selectRouterLocation(state)?.pathname?.match(/\/projects/);

export const selectIsAccountRelatedPage = (state: RootState) =>
  !!selectRouterLocation(state)?.pathname?.match(/\/(accounts|clients)/);

export const selectIsDealRelatedPage = (state: RootState) => !!selectRouterLocation(state)?.pathname?.match(/\/deals/);

export const selectIsInboxPage = (state: RootState) => !!selectRouterLocation(state)?.pathname?.match(/\/inbox/);

export const selectIsRecordRelatedPage = (state: RootState) =>
  selectIsProjectRelatedPage(state) || selectIsAccountRelatedPage(state) || selectIsDealRelatedPage(state);

export const selectIsWorkspaceRelatedPage = (state: RootState) =>
  !!selectRouterLocation(state)?.pathname?.match(/\/workspace/);

export const selectIsAnalyticsRelatedPage = (state: RootState) =>
  !!selectRouterLocation(state)?.pathname?.match(/\/analytics/);

export const selectIsContactsRelatedPage = (state: RootState) =>
  !!selectRouterLocation(state)?.pathname?.match(/\d+\/contacts\/?.*$/);

export const selectIsInsideCompanyPage = (state: RootState) =>
  selectIsAccountRelatedPage(state) ||
  selectIsDealRelatedPage(state) ||
  selectIsProjectRelatedPage(state) ||
  selectIsAnalyticsRelatedPage(state) ||
  selectIsContactsRelatedPage(state) ||
  selectIsWorkspaceRelatedPage(state) ||
  selectIsInboxPage(state);

export const selectWorkspaceIdFromLocation = (state: RootState): number | undefined => {
  return parseWorkspaceIdFromUrl(selectRouterLocation(state)?.pathname) ?? undefined;
};

export const selectCompanyName = (state: RootState) =>
  selectCompanyById(
    state,
    selectWorkspaceIdFromUi(state) || selectCompanyIdBySelectedProject(state) || selectWorkspaceIdFromLocation(state)
  )?.name;

export const selectCompanyTimezone = (state: RootState) =>
  selectCompanyById(
    state,
    selectWorkspaceIdFromUi(state) || selectCompanyIdBySelectedProject(state) || selectWorkspaceIdFromLocation(state)
  )?.timezone;

export const selectUiSelectedCompany = (state: RootState) => state.ui.selectedCompany;

export const selectWorkspaceId = (state: RootState) => {
  const isUiSelected = typeof state.ui.selectedCompany !== 'undefined';

  return ((isUiSelected && state.ui.selectedCompany) || selectWorkspaceIdFromLocation(state)) as number;
};

export const selectCompanySubdomain = (state: RootState) => {
  const id = selectWorkspaceId(state);

  return selectCompanyById(state, id)?.subdomainName;
};

export const selectIsCalendarRelatedPage = (state: RootState) =>
  !!selectRouterLocation(state)?.pathname?.match(/\/(calendar|tasks|scheduler|dispatcher|list)/);

export const selectIsCalendarPage = (state: RootState) =>
  !!selectRouterLocation(state)?.pathname?.match(/\/calendar(\/)?$/);

export const selectCompanyIdByTaskId = (state: RootState, taskId: number) =>
  state.calendar?.events?.filter(({ id }) => id === taskId)?.[0]?.companyId;

export const selectProjectById = (state: RootState, id: ProjectId) =>
  state.project.groups.flatMap(({ group }) => group.list.filter(({ projectId }) => id === projectId))?.[0];

export const selectCompanyByProjectId = (state: RootState, projectId: ProjectId) => {
  const project = selectProjectById(state, projectId);
  const companyId = project ? project.projectDetail.companyId : null;

  return companyId ? selectCompanyById(state, companyId) : undefined;
};

export const selectCompanyIdBySelectedPage = (state: RootState) => {
  const isProjectDetailsPage = selectIsActivityPage(state) || selectIsDatabankPage(state) || selectIsKanbanPage(state);
  const urlHasCompanyId =
    selectIsProjectPage(state) || selectIsWorkspaceRelatedPage(state) || selectIsContactsRelatedPage(state);

  return (
    (!isProjectDetailsPage && urlHasCompanyId ? selectWorkspaceId(state) : selectCompanyIdBySelectedProject(state)) ||
    selectWorkspaceId(state)
  );
};

export const selectUserRolesByCompanyId = (state: RootState, id: number) => selectCompanyById(state, id)?.roles;

export const selectUser = (state: RootState) => state.authentication.user;

export const selectSettingsByCompanyId = (companyId: number) => (state: RootState) =>
  selectCompanyById(state, companyId)?.settings;

export const selectCompanySettings = (state: RootState) => selectSettingsByCompanyId(selectWorkspaceId(state))(state);

export const isCustomerPortalEnabled = (state: RootState) => {
  const companyId = selectWorkspaceId(state);

  return selectCompanyById(state, companyId)?.customerPortalEnabled;
};

export const selectRecordType = (state: RootState) => getRecordTypeFromUrl(selectRouterLocation(state));
