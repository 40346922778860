import { createAction } from 'redux-actions';
import download from 'downloadjs';
import moment, { Moment } from 'moment';

import { AsyncActionReturn } from '@state/reducers/types';
import {
  selectCompanyById,
  selectCompanyIdBySelectedPage,
  selectWorkspaceId,
} from '@state/selectors';

import projectApi from '@services/api/projectApi';
import { FavoritesSearch } from '../ProjectView/types';
import { favProjectsAdapter } from '../../../adapters/ProjectAdapters/ProjectAdapter';

// archive project
export const archiveProjectRequest = createAction('ARCHIVE_PROJECT_REQUEST');
export const archiveProjectSuccess = createAction('ARCHIVE_PROJECT_SUCCESS');
export const archiveProjectFailure = createAction('ARCHIVE_PROJECT_ERROR');

// TODO: param type
export const archiveProject = (
  id: number,
  params: any,
  cb: () => void
): AsyncActionReturn => async (dispatch) => {
  try {
    await dispatch(archiveProjectRequest());

    const { data } = await projectApi.archiveProject(id, params);
    if (data) {
      await dispatch(archiveProjectSuccess());
      cb();
    } else {
      await dispatch(archiveProjectFailure());
    }
  } catch (error) {
    dispatch(archiveProjectFailure());
  }
};

// delete project
export const deleteProjectRequest = createAction('DELETE_PROJECT_REQUEST');
export const deleteProjectSuccess = createAction('DELETE_PROJECT_SUCCESS');
export const deleteProjectFailure = createAction('DELETE_PROJECT_ERROR');

export const deleteProject = (
  id: number,
  cb: () => void
): AsyncActionReturn => async (dispatch) => {
  try {
    await dispatch(deleteProjectRequest());
    const { data } = await projectApi.deleteProject(id);

    if (data) {
      await dispatch(deleteProjectSuccess(id));
      cb();
    } else {
      dispatch(archiveProjectFailure());
    }
  } catch (error) {
    console.error('Error on project delete: ', error);
    dispatch(archiveProjectFailure());
  }
};

// delete selected project
export const deleteSelectedProjectSuccess = createAction('DELETE_SELECTED_PROJECT_SUCCESS');

export const updateSelectedProjectSuccess = createAction(
  'UPDATE_SELECTED_PROJECT_SUCCESS'
);

export const toggleFavOnSelectedProjectSuccess = createAction(
  'TOGGLE_FAV_ON_SELECTED_PROJECT_SUCCESS'
);

export const fetchFavProjectsRequest = createAction(
  'FETCH_FAV_PROJECT_REQUEST'
);
export const fetchFavProjectsSuccess = createAction(
  'FETCH_FAV_PROJECT_SUCCESS'
);
export const fetchFavProjectsFailure = createAction('FETCH_FAV_PROJECT_ERROR');

export const fetchFavoriteProjects = (
  search: FavoritesSearch
): AsyncActionReturn => async (dispatch, getState) => {
  const state = getState();
  const companyId = selectCompanyIdBySelectedPage(state);

  if (!companyId) {
    return;
  }

  try {
    dispatch(fetchFavProjectsRequest());
    const { data } = await projectApi.fetchFavoriteProjects(companyId);
    if (data && data.results) {
      const projects = favProjectsAdapter(data.results);
      dispatch(
        fetchFavProjectsSuccess({
          projects,
          search,
          total: data.total
        })
      );
    } else {
      dispatch(fetchFavProjectsFailure());
    }
  } catch (error) {
    console.error('Error on favorite projects fetch', error);
    dispatch(fetchFavProjectsFailure());
  }
};
// add project to favorite list
export const addFavProjectsRequest = createAction('ADD_FAV_PROJECT_REQUEST');
export const addFavProjectsSuccess = createAction('ADD_FAV_PROJECT_SUCCESS');
export const addFavProjectsFailure = createAction('ADD_FAV_PROJECT_ERROR');

export const addFavProject = (id: number | string): AsyncActionReturn => async (
  dispatch
) => {
  try {
    dispatch(addFavProjectsRequest());
    const response = await projectApi.addFavoriteProject(+id);
    if (response) {
      await dispatch(addFavProjectsSuccess());
      const search = {
        page: 1,
        perPage: 10
      };
      await dispatch(fetchFavoriteProjects(search));
    } else {
      dispatch(addFavProjectsFailure());
    }
  } catch (error) {
    console.error('Error on add project to favorites', error);
    dispatch(addFavProjectsFailure());
  }
};

// delete project to favorite list
export const deleteFavProjectsRequest = createAction(
  'DELETE_FAV_PROJECT_REQUEST'
);
export const deleteFavProjectsSuccess = createAction(
  'DELETE_FAV_PROJECT_SUCCESS'
);
export const deleteFavProjectsFailure = createAction(
  'DELETE_FAV_PROJECT_ERROR'
);

export const deleteFavProject = (id: number | string): AsyncActionReturn => async (
  dispatch
) => {
  try {
    dispatch(deleteFavProjectsRequest());
    const { data } = await projectApi.removeFavoriteProject(+id);
    if (data) {
      dispatch(deleteFavProjectsSuccess(id));
    } else {
      dispatch(deleteFavProjectsFailure());
    }
  } catch (error) {
    console.error('Error on remove project from favorites', error);
    dispatch(deleteFavProjectsFailure());
  }
};

// fetch single project
export const fetchSingleProjectRequest = createAction(
  'FETCH_SINGLE_PROJECT_REQUEST'
);
export const fetchSingleProjectSuccess = createAction(
  'FETCH_SINGLE_PROJECT_SUCCESS'
);
export const fetchSingleProjectFailure = createAction(
  'FETCH_SINGLE_PROJECT_ERROR'
);

export const fetchSingleProject = (id: number): AsyncActionReturn => async (
  dispatch
) => {
  try {
    dispatch(fetchSingleProjectRequest());
    const { data: project } = await projectApi.fetchSingleProject(id);

    if (project) {
      dispatch(fetchSingleProjectSuccess({ project }));
    } else {
      dispatch(fetchSingleProjectFailure());
    }
  } catch (error) {
    console.error('Error on fetch single project ', error);
    dispatch(fetchSingleProjectFailure());
  }
};

type DownloadExportValues = {
  startDate: string | Moment | Date;
  endDate?: string | Date | Moment;
};

export const downloadExport = (values: DownloadExportValues): AsyncActionReturn => async (
  dispatch,
  getState
) => {
  const state = getState();
  const companyId = selectWorkspaceId(state);
  const company = selectCompanyById(state, companyId);

  try {
    const { data } = await projectApi.downloadExport({ ...values, companyId });

    const dateRange = [values.startDate, (values.endDate || Date.now())]
      .map((value) => moment(value).format('MM_DD_YYYY'))
      .join('_');
    const filename = `${company?.name || 'your_company'}_${dateRange}.csv`;

    download(data, filename);
  } catch (err) {
    console.error('Error on CVS downloading', err);
  }
};
