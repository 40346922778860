import styled, { css } from 'styled-components';
import { Modal as ModalMui } from '@material-ui/core';
import { UiSizes } from '@enums';
import { scroll, modal } from '@styles';

export const Wrapper = styled<{ wrapperCssMixin: ReturnType<typeof css>; }>(ModalMui)`
  ${modal.modal};
  
  ${({ wrapperCssMixin }) => wrapperCssMixin || ''}
`;

export const Inner = styled.div<{ size?: UiSizes }>`
  ${({ theme: { devices }, size }) => css`
    width: 100vw;
    height: var(--app-height);
    outline: none;
   
    ${devices.sm`
      height: auto;
      min-height: auto;
      max-width: ${() => {
        switch (size) {
          case UiSizes.Small:
            return '360px';
          case UiSizes.Medium:
            return '600px';
          case UiSizes.Large:
            return '80vw';
          default:
            return '750px';
        }
      }};
    `}
  `}
`;

export const Header = styled.div`
  ${({ theme: { colors, devices } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 15px;
    border-bottom: 1px solid ${colors.gray3};
    background-color: ${colors.white};

    ${devices.sm`
      padding: 0 30px;
      border-radius: 4px 4px 0 0;
    `}
  
    svg {
      cursor: pointer;
      color: ${colors.green};
    }  
  `}
`;

export const Title = styled.div`
  ${({ theme: { colors, fonts } }) => css`
    font: 500 20px/1.25 ${fonts.sans};
    color: ${colors.green};  
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `}
`;

export const Body = styled.div`
  ${({ theme: { colors, devices } }) => css`
    height: var(--modal-body-height);
    overflow: auto;
    padding: 15px;
    background-color: ${colors.white2};
  
    ${devices.sm`
      height: auto;
      max-height: calc(100vh - 300px);
      padding: 20px 30px;
    `}
    
    ${scroll};
  `}
`;

export const Footer = styled.div`
  ${({ theme: { colors, devices } }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 15px 10px;
    border-top: 1px solid ${colors.gray3}; 
    background-color: ${colors.white};
  
    ${devices.sm`
      padding: 20px;
      border-radius: 0 0 4px 4px;
    `}
    
    button {
      margin: 0 5px;
      @media (min-width: 768px) {
        margin: 0 10px;
      }
    }  
  `}
`;
