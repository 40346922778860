import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks';
import { errorHandler } from '@services/api/helpers';
import { ReactQueryKey } from '@enums';
import systemsAPI from '@services/api/systemsAPI';

type UpdateSystemDto = {
  notes?: string;
  projectId?: number;
  addressForced?: string | null;
};

export const useUpdateSystem = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<void, Error, { id: number; dto: UpdateSystemDto }>(
    async ({ id, dto }) => {
      try {
        return (await systemsAPI.update(id, dto)).data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: async (_, { id }) => {
        showSuccess(`System updated successfully`);
        await queryClient.invalidateQueries([ReactQueryKey.System, id]);
      }
    }
  );
};
