import { useRecordDetail } from '@hooks/useRecordDetail';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import { useWorkOrderList } from '@hooks/workOrders/useWorkOrderList';
import { ReactQueryKey } from '@enums';

export const useClientSites = (clientId?: number) => {
  const { data: client } = useRecordDetail(clientId, { refetchOnMount: false });
  const { data: workOrders } = useWorkOrderList({
    queryKey: ReactQueryKey.TasksInSites,
    filters: {
      projectId: { equalTo: clientId }
    },
    isEnabled: !!clientId
  });

  return useMemo<string[]>(
    () =>
      uniq([
        ...(client.address ?? []),
        ...(client.projects ?? []).flatMap((child) => child.address ?? []),
        ...(client.deals ?? []).flatMap((child) => child.address ?? []),
        ...(workOrders ?? []).flatMap((workOrder) => workOrder.address ?? [])
      ]),
    [client.address, client.deals, client.projects, workOrders]
  );
};
