export enum MsgType {
  updateResponse = 'updateResponse',
  responseUpdated = 'responseUpdated',
  projectUpdated = 'projectUpdated',
  leftField = 'leftField',
  enterField = 'enterField',
  currentCollaborationState = 'currentCollaborationState'
}

export enum MsgUpdateAction {
  add = 'add'
}

export default MsgType;
