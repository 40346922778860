import React, { useState, useCallback } from 'react';
import { ChevronDown } from 'react-feather';
import { debounce } from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import AutoComplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField } from '@common/ui';
import {
  fetchLabelsPagination,
  clearFetchedLabels,
  fetchLabelsPaginationStart
} from '@components/Project/DataBank/redux/actions';
import { makeStyles } from '@material-ui/core/styles';
import { autocomplete } from '@common/ui/Autocomplete/styled';
import Props, { Label, Pager } from './types';
import DropDownItem from './DropDownItem';
import LabelPill from './LabelPill';
import Skeleton from './Skeleton/Skeleton';

const getLabelValue = ({ label, labelName }: Label) => labelName || label || '';

const LabelSelector: React.FC<IProps> = (props) => {
  const filter = createFilterOptions();

  const {
    setSelectedLabels,
    selectedLabels,
    removeLabelPill,
    labels,
    loadingFetchLabels,
    onFetchLabels,
    clearLabels,
    setLabelLoading,
    ownerId,
    companyId,
    error = false,
    onFocus,
    onBlur,
    isDisabled
  } = props;

  const [showLabelSuggestions, setShowLabelSuggestions] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [notFoundedLabel, setNotFoundedLabel] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFetchLabelsDebounce = useCallback(
    debounce((pager: Pager, _companyId?: number) => {
      onFetchLabels(pager, ownerId, _companyId);
    }, 700),
    []
  );

  const startFilterLabels = (pager: Pager) => {
    onFetchLabelsDebounce(pager, companyId);
  };

  const dropDownFiller = (value: string) => {
    // if it is not undefined, it would trigger dropdown
    if (value !== undefined) {
      setLabelLoading();
      setSearchTerm(value);
      clearLabels();
      startFilterLabels({
        page: 0,
        skip: 0,
        name: String(value).trim(),
        limit: 10
      });
      setShowLabelSuggestions(true);
    }
  };

  const handleTermChange = (e) => {
    dropDownFiller(e.target.value);
  };

  const handleTermFocus = (e) => {
    dropDownFiller(e.target.value);
    onFocus?.();
  };

  const handleClose = () => {
    setShowLabelSuggestions(false);
    setSearchTerm('');
    clearLabels();
  };

  const onOpenAutoCompleteHandler = (_: React.ChangeEvent<{}>) => {
    dropDownFiller('');
  };

  const getOptionSelected = (option: Label, value: Label) => {
    return option.id === value.id;
  };

  const SelectLabelOption = (option: Label) => {
    let copyLabels;

    if (option.id !== -1) {
      copyLabels = selectedLabels.filter((current) => current.id !== option.id);
    } else {
      copyLabels = selectedLabels.filter((current) => {
        if (current.id !== -1) {
          return true;
        }
        if (current.labelName.toLowerCase() !== option.labelName.toLowerCase()) {
          return true;
        }

        return false;
      });
    }

    let selectedOption = { ...option };

    if (option.id === -1) {
      const prefix = option.labelName.indexOf(`" as new tag`);
      selectedOption = {
        ...option,
        labelName: option.labelName.slice(8, prefix)
      };
    }

    copyLabels.push(selectedOption);
    setSelectedLabels(copyLabels);
  };

  const classes = makeStyles(autocomplete({}))();

  return (
    <AutoComplete
      multiple
      disableClearable
      openOnFocus
      disabled={isDisabled}
      open={showLabelSuggestions}
      renderTags={(tags) => {
        return tags.map((tag, idx) => <LabelPill key={idx} label={tag} removeLabelPill={removeLabelPill} />);
      }}
      classes={classes}
      ChipProps={{ onDelete: () => {} }}
      onOpen={onOpenAutoCompleteHandler}
      inputValue={searchTerm}
      onInputChange={handleTermChange}
      onClose={handleClose}
      onFocus={handleTermFocus}
      onBlur={onBlur}
      options={
        labels.map((item) => ({
          ...item,
          labelName: getLabelValue(item)
        })) as Label[]
      }
      value={selectedLabels as Label[]}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getLabelValue}
      loading={loadingFetchLabels}
      loadingText={<Skeleton />}
      noOptionsText={`Label ${notFoundedLabel} doesn't exist`}
      renderOption={(option) => {
        return (
          <DropDownItem
            id={option.id}
            labelName={option.labelName}
            onClick={() => SelectLabelOption(option)}
            data-testid="labelSelectOption"
            color={option.color}
          />
        );
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (!loadingFetchLabels) {
          setNotFoundedLabel(params.inputValue);
        }

        return filtered;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name="tags"
          type="text"
          variant="outlined"
          error={error}
          data-testid="labelSelectInput"
        />
      )}
      popupIcon={<ChevronDown />}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    labels: state.databank.formLabels,
    labelPager: state.databank.labelPager,
    totalLabels: state.databank.totalLabels,
    loadingFetchLabels: state.databank.loadingFetchLabels
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchLabels: (pager: Pager, ownerId: number, companyId?: number) => {
      dispatch(fetchLabelsPagination(pager, undefined, ownerId, companyId));
    },
    clearLabels: () => {
      dispatch(clearFetchedLabels({ formLabels: [] }));
    },
    setLabelLoading: () => {
      dispatch(fetchLabelsPaginationStart());
    }
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type IProps = PropsFromRedux & Props;

export default connector(LabelSelector);
