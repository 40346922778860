import { fonts } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 8px;
  min-height: 34px;
`;

export const Right = styled.div`
  margin-left: auto;
`;

export const SelectionControl = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  min-height: 34px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 400;
`;

export const PopoverMenuItem = styled.div`
  padding: 4px;
  cursor: pointer;

  font-family: ${fonts.sans};
  font-size: 14px;

  transition: background-color 0.2s;

  &:hover {
    background-color: #f2f2f2;
  }
`;
