import { css } from 'styled-components';
import { scroll, getColorWithOpacity } from '@styles';

// @ts-ignore
const wrapper = ({ theme: { colors, fonts }, visibleTimeList }) => css`
  position: relative;
  padding: 16px;
  background-color: #fff;
  border-radius: 4px;

  .react-datepicker {
    border: none;

    &__navigation {
      position: absolute;
      top: 20px;
      width: 20px;
      height: 20px;
      background: none;
      border: none;
      color: ${colors.gray};
      cursor: pointer;

      &:hover {
        color: ${colors.gray2};
      }

      &--previous {
        left: 14px;
      }
      &--next {
        right: 14px;
      }
    }

    &__month-container {
      .react-datepicker {
        &__header {
          background: none;
          border: none;
          padding: 0;

          &--has-time-select {
          }
        }

        &__month {
          margin: 0;

          &--selecting-range {
          }

          &-container {
            float: none;
          }
        }

        &__current-month {
          font: 14px/1.25 ${fonts.sans};
          margin: 8px 0 0 0;
          text-align: center;
        }

        &__day-names {
          display: flex;
          margin: 15px 0px 0 0;
        }

        &__day-name {
          text-align: center;
        }

        &__week {
          display: flex;
        }

        &__day {
          font: 14px/1.25 ${fonts.sans};
          color: ${colors.black};
          border-radius: 4px;
          width: 30px;
          line-height: 30px;
          margin: 3px;
          text-align: center;
          cursor: pointer;

          &:hover {
            color: ${colors.black};
            background-color: ${getColorWithOpacity(colors.gray, 21)};
          }

          &--in-range,
          &--in-selecting-range {
            background-color: ${getColorWithOpacity(colors.green, 21)};
          }
          &--selected {
            background-color: ${colors.green};
            color: ${colors.white};
          }
          &--disabled {
            color: ${colors.gray};
            &:hover {
              background-color: ${colors.white};
              color: ${colors.gray};
              cursor: not-allowed;
            }
          }

          &-name {
            font: 12px/1.25 ${fonts.sans};
            color: #778ca2;
            font-weight: 300;
            width: 30px;
            line-height: 20px;
            text-align: center;
            margin: 3px;
          }
        }
      }
    }

    &__time-container {
      display: ${visibleTimeList ? 'block' : 'none'};
      z-index: 10000;
      background-color: ${colors.white};
      border: none;
      width: 85px;
      position: absolute;
      margin: 0;
      border-radius: 4px;
      box-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
      border-radius: 4px;
      overflow: hidden;
      bottom: 0;
      right: -95px;
      height: 270px;

      .react-datepicker {
        &__header {
          &--time {
            display: none;
          }
        }

        &__time {
          .react-datepicker__time-box {
            ul.react-datepicker__time-list {
              height: 270px;
              overflow-y: auto;
              ${scroll};

              li.react-datepicker__time-list-item {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                font: 12px/1.5 ${fonts.sans};
                color: ${colors.black};
                cursor: pointer;

                &:hover {
                  color: ${colors.green};
                }

                &--selected {
                  background-color: ${colors.green};
                  color: ${colors.white};

                  &:hover,
                  &:focus {
                    background-color: ${colors.green};
                    color: ${colors.white};
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }

                &--disabled {
                  color: ${colors.gray};

                  &:hover {
                    color: ${colors.gray};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default wrapper;
