import { ReactQueryKey } from '@enums';
import { TaskStatusEntity } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

export const useWorkOrderStatuses = () =>
  useQuery(
    [ReactQueryKey.TaskStatuses],
    async () => {
      try {
        return (
          await postGraphql<{ taskStatuses: TaskStatusEntity[] }>(gql`
            query WORK_ORDER_STATUSES {
              taskStatuses {
                id
                label
                isAutomatic
                fieldOnly
                officeOnly
                possibleTransitions {
                  id
                  label
                  fieldOnly
                  officeOnly
                }
              }
            }
          `)
        ).taskStatuses;
      } catch (error) {
        throw apiErrorHandler('Error fetching Work Order statuses', error);
      }
    },
    {
      refetchOnMount: false
    }
  );
