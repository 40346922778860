import React, { useState } from 'react';
import { X, Edit as EditIcon } from 'react-feather';
import moment from 'moment';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { formSVG } from '@assets/svg';
import { colors, device, fonts } from '@styles';
import { FileDate, FileProgress } from '@common/File';
import FormSelect from '@common/Selector/FormSelect/FormSelect';
import { Form } from '@components/Project/DataBank/types';
import { useFormProgress } from '@hooks/useForms';
import { CircularProgress } from '@material-ui/core';
import { defaultTheme } from '@themes';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 40px;

  background: ${colors.white};
  border: 1px solid ${colors.gray3};
  border-radius: 4px;

  color: ${colors.black};

  padding-right: 12px;
  margin-bottom: 8px;

  overflow: hidden;

  &:hover,
  &:focus {
    background-color: ${colors.white3};
  }

  > div {
    flex: 1;
  }

  & ${FileDate} {
    margin: 0 10px;
  }

  & ${FileProgress} {
    min-width: 30px;

    progress {
      display: none;
      ${({ theme }) => theme.devices.sm`
        display: block;
      `}
    }
  }
`;

const FormText = styled.span`
  display: block;
  font: 500 14px/1.25 ${fonts.sans};
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  flex: 2;
  cursor: pointer;
`;

const Remove = styled(X)`
  min-width: 20px;
  color: ${colors.gray};
  cursor: pointer;
  margin-left: 10px;

  ${device.sm`
    opacity: 0;
    pointer-events: none;
  `};

  ${Wrapper}:hover &, ${Wrapper}:focus & {
    opacity: 1;
    pointer-events: all;
  }

  &:hover,
  &:focus {
    color: ${colors.green};
  }
`;

const Edit = styled(EditIcon)`
  min-width: 20px;
  color: ${colors.gray};
  cursor: pointer;

  ${device.sm`
    opacity: 0;
    pointer-events: none;
  `};

  ${Wrapper}:hover &, ${Wrapper}:focus & {
    opacity: 1;
    pointer-events: all;
  }

  &:hover,
  &:focus {
    color: ${colors.green};
  }
`;

const FormIcon = styled.img.attrs({
  src: formSVG
})`
  width: 20px;
  height: 20px;
  margin: 10px;
`;

interface FormLinkItemProps {
  inEditState?: boolean;
  onBlur?: (e) => void;
  onSelect: (form: Form) => void;
  onRemove?: (formId: number) => void;
  form?: Form;
  hideForms: number[];
  projectId: number;
  companyId: number;
  isReadOnly?: boolean;
  isCreatedAtShown?: boolean;
  onFormClick?: (form: Form) => void;
  onCreateNewForm?: (formIdToReplace?: number) => void;
}

export const FormLinkItem: React.FC<FormLinkItemProps> = ({
  inEditState = false,
  onBlur,
  onSelect,
  onRemove,
  form = {
    id: 0,
    name: '',
    createdAt: new Date(),
    isTemplate: false
  },
  hideForms = [],
  projectId,
  companyId,
  isReadOnly = false,
  isCreatedAtShown = true,
  onFormClick,
  onCreateNewForm
}: FormLinkItemProps) => {
  const [editingForm, setEditingForm] = useState(inEditState);
  const { data: formProgress, isLoading } = useFormProgress(form.id);
  const onToggleEditForm = (e?) => {
    setEditingForm(!editingForm);
    onBlur?.(e);
  };

  const onRemoveHandler = () => onRemove?.(form.id);

  const filled = formProgress?.filled ?? 0;
  const total = formProgress?.total ?? 0;

  const progress = `${filled}/${total}`;
  const progressPercentage = total > 0 ? (filled / total) * 100 : 0;

  const createdAt = moment(form.createdAt).fromNow();
  const redirectToForm = () => {
    if (onFormClick) {
      onFormClick(form);

      return;
    }

    if (!form.isTemplate) {
      navigate(`/${companyId}/projects/${projectId}/databank?fileId=${form.fileId}`);
    }
  };

  const onSelectHandler = (selectedForm: Form) => {
    onSelect(selectedForm);
    onToggleEditForm();
  };

  const handleCreateNewForm = () => {
    if (onCreateNewForm) {
      onCreateNewForm(form.id);
    }
  };

  return (
    <Wrapper>
      <FormIcon />
      {editingForm ? (
        <FormSelect
          onBlur={onToggleEditForm}
          onSelect={onSelectHandler}
          selectedForm={form}
          hideForms={hideForms}
          projectId={projectId}
          onCreateNewForm={onCreateNewForm ? handleCreateNewForm : undefined}
        />
      ) : (
        <>
          <FormText className="link" onClick={redirectToForm}>
            {form.name}
          </FormText>
          <FileProgress>
            {!form?.isTemplate &&
              (!isLoading ? (
                <>
                  <progress max="100" value={progressPercentage} />
                  {progress}
                </>
              ) : (
                <CircularProgress size={20} style={{ color: defaultTheme.colors.green }} />
              ))}
          </FileProgress>
          {isCreatedAtShown && <FileDate>{createdAt}</FileDate>}
          {!isReadOnly && <Edit size={20} onClick={onToggleEditForm} />}
          {!isReadOnly && <Remove size={20} onClick={onRemoveHandler} />}
        </>
      )}
    </Wrapper>
  );
};
