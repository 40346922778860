import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { checkboxMui, colors, device, fonts, getColorWithOpacity, input } from '@styles';
import { X } from 'react-feather';

export const useStyles = makeStyles(checkboxMui({ size: '20px' }));

export const Wrapper = styled.div<{ isDragging: boolean; isCompleted: boolean; errors: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  background-color: ${(props) => (props.isDragging ? getColorWithOpacity(colors.green, 50) : colors.white)};
  border-radius: 4px;
  color: ${colors.black};
  padding: 8px 8px 8px 0px;
  overflow: hidden;

  ${(props) =>
    !props.isDragging
      ? ` &:hover,
          &:focus {
            background-color: ${colors.white3};
          }`
      : ''}
`;

export const Input = styled.input`
  ${input};
`;

export const Remove = styled(X)`
  color: ${colors.gray};
  cursor: pointer;

  ${device.sm`
    opacity: 0;
    pointer-events: none;
  `};

  ${Wrapper}:hover &, ${Wrapper}:focus & {
    opacity: 1;
    pointer-events: all;
  }
  &:hover,
  &:focus {
    color: ${colors.green};
  }
`;

export const Task = styled.span`
  font: 500 14px/1.5 ${fonts.sans};
  word-break: break-word;
`;

export const RightWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 110px;
  min-width: 110px;
  gap: 16px;
  flex-shrink: 0;

  svg {
    flex-shrink: 0;
  }
`;

export const DateText = styled.div`
  color: ${colors.gray};

  font: 12px/1.25 ${fonts.sans};
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-align: right;
`;
