import { IntervalInput, SystemFilter, SystemGroupBy, SystemsOrderBy } from '@generated/types/graphql';
import { DateTime, Duration, DurationLike, Interval } from 'luxon';
import { DeepPartial } from 'redux';
import { DateTimeUnit } from 'luxon/src/datetime';

export enum GroupField {
  status = 'status'
}

export const groupToExpr = (field: GroupField = null): SystemGroupBy[] => {
  switch (field) {
    case null:
      return [];
    case GroupField.status:
      return [SystemGroupBy.Status];
    default:
      throw new Error(field satisfies never);
  }
};

export enum SortField {
  id = 'id',
  name = 'name',
  operationalAt = 'operationalAt',
  number = 'number'
}

export const sortToExpr = (sortBy: [field: SortField, desc?: boolean][] = []): SystemsOrderBy[] => {
  const orderByExpr: SystemsOrderBy[] = sortBy.map(([field, desc]) => {
    switch (field) {
      case SortField.id:
        return desc ? SystemsOrderBy.IdDesc : SystemsOrderBy.IdAsc;
      case SortField.name:
        return desc ? SystemsOrderBy.NameDesc : SystemsOrderBy.NameAsc;
      case SortField.operationalAt:
        return desc ? SystemsOrderBy.OperationalAtDesc : SystemsOrderBy.OperationalAtAsc;
      case SortField.number:
        return desc ? SystemsOrderBy.NumberDesc : SystemsOrderBy.NumberAsc;
      default:
        throw new Error(field satisfies never);
    }
  });

  orderByExpr.push(SystemsOrderBy.IdAsc);

  return orderByExpr;
};

export const queryToExpr = (query: string): DeepPartial<SystemFilter> =>
  query
    ? {
        or: [
          { number: { equalTo: +query } },
          { providerId: { equalTo: query } },
          { name: { includesInsensitive: query } }
        ]
      }
    : {};

/**
 * Set of available granularities of chart data, also defines proper bracket of series data in DB.
 */
export enum ChartPeriod {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
  lifetime = 'lifetime'
}

export const chartPeriodDuration: { [period in ChartPeriod]: Duration | null } = {
  [ChartPeriod.day]: Duration.fromObject({ day: 1 }),
  [ChartPeriod.week]: Duration.fromObject({ day: 7 }),
  [ChartPeriod.month]: Duration.fromObject({ day: 30 }),
  [ChartPeriod.year]: Duration.fromObject({ month: 12 }),
  [ChartPeriod.lifetime]: null
};

/**
 * Unit of {@link DateTime.startOf} to align data periods to compare them.
 */
export const chartPeriodAlign: { [period in ChartPeriod]: DateTimeUnit } = {
  [ChartPeriod.day]: 'day',
  [ChartPeriod.week]: 'day',
  [ChartPeriod.month]: 'day',
  [ChartPeriod.year]: 'month',
  [ChartPeriod.lifetime]: 'year'
};

export const chartPeriodName: { [period in ChartPeriod]: string } = {
  [ChartPeriod.day]: 'Day',
  [ChartPeriod.week]: '7 days',
  [ChartPeriod.month]: '30 days',
  [ChartPeriod.year]: '12 months',
  [ChartPeriod.lifetime]: 'Lifetime'
};

/**
 * Chart granularity = duration between data points, corresponds to {@link chartPointsBrackets}
 */
export const chartPeriodStep: { [period in ChartPeriod]: DurationLike | null } = {
  [ChartPeriod.day]: { minutes: 15 },
  [ChartPeriod.week]: { day: 1 },
  [ChartPeriod.month]: { day: 1 },
  [ChartPeriod.year]: { month: 1 },
  [ChartPeriod.lifetime]: { year: 1 }
};

/**
 * Unit of {@link DateTime.startOf} to align data points to compare them. The goal is to have data for days/months/years
 * to match accross different timzones. So e.g. production for Jan 10 is the same for users in UTC-7 and UTC+4 despite
 * starts/ends of Jan 10 in different timezones are different points in time.
 */
export const chartPeriodStepAlign: { [period in ChartPeriod]: DateTimeUnit } = {
  [ChartPeriod.day]: 'minute',
  [ChartPeriod.week]: 'day',
  [ChartPeriod.month]: 'day',
  [ChartPeriod.year]: 'month',
  [ChartPeriod.lifetime]: 'year'
};

/**
 * Target time brackets when aggregating total statistics, for available brackets see the definition of production_chart
 * view in series DB.
 */
export const chartTotalBrackets: {
  [milestone in ChartPeriod]: {
    seriesPeriod: Partial<IntervalInput>;
    targetPeriod: Partial<IntervalInput>;
  };
} = {
  [ChartPeriod.day]: { seriesPeriod: { minutes: 15 }, targetPeriod: { days: 1 } },
  [ChartPeriod.week]: { seriesPeriod: { days: 1 }, targetPeriod: { days: 1 } },
  [ChartPeriod.month]: { seriesPeriod: { days: 1 }, targetPeriod: { days: 1 } },
  [ChartPeriod.year]: { seriesPeriod: { days: 1 }, targetPeriod: { months: 1 } },
  [ChartPeriod.lifetime]: { seriesPeriod: { days: 1 }, targetPeriod: { years: 1 } }
};

/**
 * Target time brackets when collecting data points, for available brackets see the definition of production_chart view
 * in series DB.
 */
export const chartPointsBrackets: {
  [milestone in ChartPeriod]: {
    seriesPeriod: Partial<IntervalInput>;
    targetPeriod: Partial<IntervalInput>;
  };
} = {
  [ChartPeriod.day]: { seriesPeriod: { minutes: 15 }, targetPeriod: { minutes: 15 } },
  [ChartPeriod.week]: { seriesPeriod: { days: 1 }, targetPeriod: { days: 1 } },
  [ChartPeriod.month]: { seriesPeriod: { days: 1 }, targetPeriod: { days: 1 } },
  [ChartPeriod.year]: { seriesPeriod: { days: 1 }, targetPeriod: { months: 1 } },
  [ChartPeriod.lifetime]: { seriesPeriod: { days: 1 }, targetPeriod: { years: 1 } }
};

/**
 * Returns aligned time interval which includes given base time: `[aligned base - period, aligned base)`.
 * E.g. if base is now and period is day, then observed interval is `[start of today, start of tomorrow)`.
 */
export const getObservedInterval = (base: DateTime<true>, period: ChartPeriod): Interval | null => {
  const duration = chartPeriodDuration[period];
  if (!duration) {
    return null;
  }

  const end = base.endOf(chartPeriodAlign[period]).plus(1);
  return end.minus(duration).until(end);
};

/**
 * Set of predefined periods for widgets with system(s)' performance.
 * __If you update this - also update use* hooks with generated graphql variables names based on this enum.__
 */
export enum ChartMilestone {
  yesterday = 'yesterday',
  week = 'week',
  month = 'month',
  year = 'year',
  lifetime = 'lifetime'
}

export const chartMilestoneNames: { [milestone in ChartMilestone]: string } = {
  [ChartMilestone.yesterday]: 'Yesterday',
  [ChartMilestone.week]: 'Last 7 days',
  [ChartMilestone.month]: 'Last 30 days',
  [ChartMilestone.year]: 'Last 12 months',
  [ChartMilestone.lifetime]: 'Lifetime'
};

export const chartMilestoneToChartPeriod: { [milestone in ChartMilestone]: ChartPeriod } = {
  [ChartMilestone.yesterday]: ChartPeriod.day,
  [ChartMilestone.week]: ChartPeriod.week,
  [ChartMilestone.month]: ChartPeriod.month,
  [ChartMilestone.year]: ChartPeriod.year,
  [ChartMilestone.lifetime]: ChartPeriod.lifetime
};

export const chartMilestoneBaseTime: { [milestone in ChartMilestone]: () => DateTime } = {
  [ChartMilestone.yesterday]: () => DateTime.now().minus({ day: 1 }),
  [ChartMilestone.week]: () => DateTime.now().minus({ day: 1 }),
  [ChartMilestone.month]: () => DateTime.now().minus({ day: 1 }),
  [ChartMilestone.year]: () => DateTime.now(),
  [ChartMilestone.lifetime]: () => DateTime.now()
};

export const milestoneObservedInterval = (milestone: ChartMilestone) =>
  getObservedInterval(chartMilestoneBaseTime[milestone](), chartMilestoneToChartPeriod[milestone]);
