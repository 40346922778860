import { createAction } from 'redux-actions';
import * as config from '@services/service.config';
import databankApi from '../../../services/api/databankApi';

export const fetchWorkspaceLabelsRequest = createAction(
    'FETCH_WORKSPACE_LABELS_REQUEST'
);
export const fetchWorkspaceLabelsSuccess = createAction(
    'FETCH_WORKSPACE_LABELS_SUCCESS'
);
export const fetchWorkspaceLabelsError = createAction(
    'FETCH_WORKSPACE_LABELS_ERROR'
);

export const fetchWorkspaceLabels = (...rest: Parameters<typeof config.getLabelUrl>) => async (dispatch) => {
    dispatch(fetchWorkspaceLabelsRequest());
    try {
        const { data } = await databankApi.fetchLabelsPagination(...rest);
        // normalize label
        const labels = data.results?.map((label) => {
            return { ...label, name: label.label, type: 'tag' };
        });
        dispatch(fetchWorkspaceLabelsSuccess({
            list: labels,
            isFetching: false
        }));
    } catch (error) {
        dispatch(fetchWorkspaceLabelsError());
    }
};

export const createWorkspaceLabelsRequest = createAction(
    'CREATE_WORKSPACE_LABELS_REQUEST'
);
export const createWorkspaceLabelsSuccess = createAction(
    'CREATE_WORKSPACE_LABELS_SUCCESS'
);
export const createWorkspaceLabelsError = createAction(
    'CREATE_WORKSPACE_LABELS_ERROR'
);

export const createWorkspaceLabel = (...rest: Parameters<typeof databankApi.createLabel>) => async (dispatch) => {
    dispatch(createWorkspaceLabelsRequest());
    try {
        const { data } = await databankApi.createLabel(...rest);
        // normalize label
        const label = { ...data, name: data.label, type: 'tag' };
        dispatch(createWorkspaceLabelsSuccess(label));
    } catch (error) {
        dispatch(createWorkspaceLabelsError());
    }
};

export const updateWorkspaceLabelsRequest = createAction(
    'UPDATE_WORKSPACE_LABELS_REQUEST'
);
export const updateWorkspaceLabelsSuccess = createAction(
    'UPDATE_WORKSPACE_LABELS_SUCCESS'
);
export const updateWorkspaceLabelsError = createAction(
    'UPDATE_WORKSPACE_LABELS_ERROR'
);

export const updateWorkspaceLabel = (...rest: Parameters<typeof databankApi.updateLabel>) => async (dispatch) => {
    dispatch(updateWorkspaceLabelsRequest());
    try {
        const { data } = await databankApi.updateLabel(...rest);
        // normalize label
        const label = { ...data, name: data.label, type: 'tag' };
        dispatch(updateWorkspaceLabelsSuccess(label));
    } catch (error) {
        dispatch(updateWorkspaceLabelsError());
    }
};

export const removeWorkspaceLabelsRequest = createAction(
    'REMOVE_WORKSPACE_LABELS_REQUEST'
);
export const removeWorkspaceLabelsSuccess = createAction(
    'REMOVE_WORKSPACE_LABELS_SUCCESS'
);
export const removeWorkspaceLabelsError = createAction(
    'REMOVE_WORKSPACE_LABELS_ERROR'
);

export const removeWorkspaceLabel = (...rest: Parameters<typeof databankApi.removeLabel>) => async (dispatch) => {
    dispatch(removeWorkspaceLabelsRequest());
    try {
        await databankApi.updateLabel(...rest, { isActive: false });
        // normalize label
        const [id] = rest;
        dispatch(removeWorkspaceLabelsSuccess({
            id
        }));
    } catch (error) {
        dispatch(removeWorkspaceLabelsError());
    }
};
