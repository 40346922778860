import styled from 'styled-components';
import { colors, fonts } from '@styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 255px;

  border-radius: 4px;
  background-color: white;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 30px;

  p {
    text-align: center;
    font-size: 14px;
    color: ${colors.gray};

    strong {
      color: ${colors.green};
    }
  }
`;

export const Button = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 288px;
  height: 46px;

  margin-bottom: 10px;
  padding-right: 20px;

  font: 12px/1.25 ${fonts.sans};
  text-transform: uppercase;

  color: #778ca2;

  background-color: #f2f4f6;
  border-radius: 4px;

  overflow: hidden;
  cursor: not-allowed;

  img {
    margin-right: 10px;
  }

  &:before {
    content: 'Coming soon';
    position: absolute;
    right: -33px;
    top: 8px;
    width: 120px;
    height: 20px;
    font: 500 9px/30px ${fonts.sans};
    transform: rotate(30deg);
    text-transform: capitalize;
    background-color: #d54855;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Actions = styled.div`
  background-color: ${colors.white};
  padding: 16px 24px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
`;

export const Body = styled.div`
  padding: 24px;
  background-color: #f7f7fc;
`;

export const UploadTo = styled.div`
  max-width: 332px;
  margin-bottom: 24px;
`;

export const FieldLabel = styled.label`
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
  display: flex;
  align-items: center;
  gap: 4px;
`;
