import { useRemoteSearch, useAppSelector } from '@hooks';
import { MutableRefObject, useCallback, useRef } from 'react';
import { postGraphql } from '@services/api/base/graphql';
import { FormsConnection, FormFilter } from '@generated/types/graphql';
import { gql } from 'graphql-request';
import { DeepPartial } from 'redux';
import { selectWorkspaceId } from '@state/selectors';

const fetchForms = async (projectId: number, searchString: string, withTemplates: boolean, companyId: number) => {
  const filter: DeepPartial<FormFilter> = {
    or: [
      ...(projectId ? [{ file: { projectId: { equalTo: projectId } } }] : [{}]),
      ...(withTemplates ? [{ isTemplate: { equalTo: withTemplates } }] : [{}])
    ],
    ...(searchString ? { name: { includesInsensitive: searchString } } : {}),
    file: {
      isArchived: {
        equalTo: false
      }
    },
    companyId: {
      equalTo: companyId
    }
  };

  const {
    forms: { nodes: forms }
  } = await postGraphql<{ forms: FormsConnection }>(
    gql`
      query ($filter: FormFilter) {
        forms: formsConnection(filter: $filter, orderBy: [IS_TEMPLATE_ASC, NAME_ASC]) {
          nodes {
            id
            name
            fileId
            file {
              id
            }
            companyId
            isTemplate
            createdAt
            templateId
          }
        }
      }
    `,
    { filter }
  );

  return forms;
};

type UseFormsReturn<T> = {
  items: T[];
  refetch: () => void;
  search: (query: string) => void;
  isLoading: boolean;
  projectIdRef: MutableRefObject<{
    value: number;
  }>;
};

export const useForms = <T extends { id: number }>(projectId: number, withTemplates = false): UseFormsReturn<T> => {
  const projectIdRef = useRef({ value: projectId });

  const companyId = useAppSelector(selectWorkspaceId);

  const getItems = useCallback(
    ({ query }: { query: string }) => {
      return fetchForms(projectIdRef.current.value, query, withTemplates, companyId);
    },
    [withTemplates, companyId]
  );

  const { items, search, isLoading, refetch } = useRemoteSearch({
    getItems,
    prefetch: false
  });

  return {
    items,
    refetch,
    search,
    isLoading,
    projectIdRef
  } as unknown as UseFormsReturn<T>;
};
