import React, { useState } from 'react';
import styled from 'styled-components';
import { Subtask } from '@state/reducers/project/Kanban/type';
import IProps from './types';
import { SubTaskItem } from './SubTaskItem';
import AddSubTask from './AddSubTask';

const Wrapper = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font: 400 14px/1.25 ${({ theme }) => theme.fonts.sans};
  color: ${({ theme }) => theme.colors.black};
`;

const Progress = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 140px;
  margin-left: 16px;

  font: 400 14px/1.25 ${({ theme }) => theme.fonts.sans};

  progress {
    -webkit-appearance: none;
    width: 100px;
    height: 4px;
    border-radius: 4px;
  }
  progress::-webkit-progress-bar {
    -webkit-appearance: none;
    width: 100px;
    height: 4px;
    border-radius: 4px;
  }
  progress::-webkit-progress-value {
    -webkit-appearance: none;
    width: 100px;
    height: 4px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.green};
  }
  progress::-moz-progress-bar {
    -webkit-appearance: none;
    width: 100px;
    height: 4px;
    border-radius: 4px;
  }

  progress[value]::-webkit-progress-bar {
    background-color: #ededef;
    background-image: #009688;
    border-radius: 4px;
  }
`;

const SubTask: React.FC<IProps> = (props: IProps) => {
  const { errors, subtasks, isDisabled = false } = props;

  const [uniqueId, setUniqueId] = useState(-1);

  const completed = subtasks.reduce((counter, obj) => {
    if (obj.isCompleted) counter++;

    return counter;
  }, 0);

  const progressNumber = `${completed}/${subtasks.length}`;

  const onSubTaskAdded = (task: Subtask) => {
    props.addSubTask(task);
    setUniqueId(uniqueId - 1);
  };

  return (
    <Wrapper>
      {subtasks.length > 0 && (
        <>
          <Header>
            <Title></Title>
            {false && <Progress>
              <progress
                max="100"
                value={(completed / subtasks.length) * 100}
              />
              {progressNumber}
            </Progress>}
          </Header>
            {subtasks.map((subtask, idx) => (
              <SubTaskItem
                errors={Object.entries(errors).length > 0}
                key={`subtask-${subtask.id}`}
                index={idx}
                isDisabled={isDisabled}
                task={subtask}
                markComplete={() => props.markAsComplete(subtask.id)}
                delSubTask={() => props.removeSubTask(subtask.id)}
                onTaskRename={props.renameSubtask}
                onPositionChange={props.positionChange}
              />
            ))}
        </>
      )}

      {!isDisabled && <AddSubTask onSubTaskAdded={onSubTaskAdded} uniqueId={uniqueId} />}
    </Wrapper>
  );
};

export default SubTask;
