import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { System } from '@generated/types/graphql';
import { SystemWithStats } from '@hooks/systems/types';
import { ChartMilestone, milestoneObservedInterval } from '@hooks/systems/constants';

type SystemOnMap = System & Pick<SystemWithStats, 'peakPower' | 'productionYesterday'>;

export const useSystemOnMap = (id: number) =>
  useQuery<SystemOnMap>({
    queryKey: [ReactQueryKey.System, 'useSystemOnMap', id],
    queryFn: async () => {
      try {
        const variables = {
          id,
          yesterdayStartAt: milestoneObservedInterval(ChartMilestone.yesterday)?.start.toISO(),
          yesterdayEndBefore: milestoneObservedInterval(ChartMilestone.yesterday)?.end.toISO()
        };

        return (
          await postGraphql<{ system: SystemOnMap }>(
            gql`
              query ($id: Int!, $yesterdayStartAt: Datetime, $yesterdayEndBefore: Datetime) {
                system(id: $id) {
                  id
                  status
                  providerStatus
                  name
                  number
                  
                  integration {
                    id
                    provider
                  }

                  project {
                    accountType
                    address
                    imageUrl
                    streetViewUrl
                    title
                  }

                  peakPower: powerProductionChartsBySystemConnection {
                    aggregates {
                      max {
                        value
                      }
                    }
                  }

                  productionYesterday: energyProductionChartsBySystemConnection(
                    condition: { seriesPeriod: { minutes: 15 }, targetPeriod: { days: 1 } }
                    filter: { time: { greaterThanOrEqualTo: $yesterdayStartAt, lessThan: $yesterdayEndBefore } }
                  ) {
                    nodes {
                      value
                    }
                  }
                }
              }
            `,
            variables
          )
        ).system;
      } catch (error) {
        throw apiErrorHandler('Error fetching system', error);
      }
    },
    enabled: !!id
  });
