import { HTMLAttributes } from 'react';
import { get, identity, isEmpty, isInteger, isNumber, isString, map, pickBy } from 'lodash';

import { UserSettings } from '@state/reducers/authentication/types';
import { AccountIsActiveStatus, DealStatus, ProjectStatus } from '@components/Project/ProjectView/types';
import { RecordType, RecordTypeToDefaultSearchType, Search } from '@types';
import UserPreferencesService from '@services/UserPreferences';
import { RoleType } from '@state/reducers/settings/accountReducer';
import { parseWorkspaceIdFromUrl } from '@utils/strings';
import versionAPI from '@services/api/versionAPI';
import { UserRole } from '@enums';
import { ProjectStageUpdate } from '@generated/types/graphql';
import { DrawerEntity } from '../contexts/DrawersContext';

export const isBrowser = typeof window !== 'undefined';

export const getUserToken = () => {
  return isBrowser ? JSON.parse(localStorage.getItem('token') as string).accessToken.token : '';
};

export const getMainRole = (roles: RoleType[]): RoleType | undefined => {
  const admin = roles.find((r) => r.name === UserRole.ADMIN || r.id === 1);
  if (admin) {
    return admin;
  }
  const projectManager = roles.find((r) => r.name === UserRole.PROJECT_MANAGER || r.id === 2);
  if (projectManager) {
    return projectManager;
  }
  const worker = roles.find((r) => r.name === UserRole.WORKER || r.id === 3);
  if (worker) {
    return worker;
  }
  const sales = roles.find((r) => r.name === UserRole.SALES || r.id === 4);
  if (sales) {
    return sales;
  }

  return undefined;
};

export const getBreadCrumbUrlParams = (breadcrumb: any, folder?: { id: number; title: string; type?: string }) => {
  let queryUrl = '';
  breadcrumb.forEach((crumb) => {
    if (crumb.type && crumb.type === 'form') {
      return;
    }
    if (crumb.id !== -1) {
      queryUrl += `&folderId=${crumb.id}&folderTitle=${crumb.title}`;
    }
  });
  if (folder) {
    queryUrl += `&folderId=${folder.id}&folderTitle=${folder.title}`;
  }

  return queryUrl.substring(1);
};

export const getInitials = (value: string | unknown) => {
  if (!value || typeof value !== 'string') {
    return 'CQ';
  }

  const names = value.split(' ');
  const initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    return initials + names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials + value[value.length - 1].toUpperCase();
};

export const getUserSettings = (userId: number): UserSettings => {
  return (
    UserPreferencesService.settings.get(userId) || {
      downloadPhotosAutomatically: true
    }
  );
};

export const setUserSettings = (userId: number, settings: Partial<UserSettings>): UserSettings => {
  return UserPreferencesService.settings.update(userId, settings);
};

export const getRecordTypeFromUrl = ($location?: Window['location']) => {
  if (!isBrowser) {
    return RecordType.PROJECT;
  }

  const location = $location || window.location;
  const { pathname } = location;

  return [
    {
      type: RecordType.PROJECT,
      pattern: /projects/
    },
    {
      type: RecordType.DEAL,
      pattern: /(deals)|(requests)/
    },
    {
      type: RecordType.ACCOUNT,
      pattern: /(accounts)|(clients)/
    }
  ].find(({ pattern }) => pathname.match(pattern))?.type as RecordType;
};

export const isSearchTypeMatchedToUrl = (search: Search, $recordType?: RecordType) => {
  const recordType = $recordType || getRecordTypeFromUrl();

  if (recordType === RecordType.PROJECT) {
    return [ProjectStatus.active, ProjectStatus.archived, ProjectStatus.all].includes(search?.type);
  }

  if (recordType === RecordType.ACCOUNT) {
    return [AccountIsActiveStatus.active, AccountIsActiveStatus.archived, AccountIsActiveStatus.all].includes(
      search?.type
    );
  }

  if (recordType === RecordType.DEAL) {
    return [DealStatus.active, DealStatus.archived, DealStatus.all].includes(search?.type);
  }

  return true;
};

export const rehydrateSearch = (defaultValue: Search) => {
  if (!isBrowser) {
    return defaultValue;
  }

  const {
    location: { pathname }
  } = window;
  const companyId = parseWorkspaceIdFromUrl(pathname)!;
  const recordType = getRecordTypeFromUrl();

  const prevSearch = UserPreferencesService.searchQuery.get({
    companyId,
    recordType
  });

  if (!isSearchTypeMatchedToUrl(prevSearch)) {
    return {
      ...defaultValue,
      type: (recordType && RecordTypeToDefaultSearchType[recordType]) || undefined
    };
  }

  return prevSearch;
};

export const getFullName = ({ firstName, lastName }: { firstName?: string; lastName?: string } | undefined = {}) =>
  `${firstName || ''} ${lastName || ''}`;

export const removeFalsyObjectValues = <T extends any>(obj: T): T => pickBy(obj, identity) as unknown as T;

export const stopPropagationClick = {
  onClick: (event) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
  }
} as HTMLAttributes<HTMLElement>;

export function extractIds<T>(list: T[]) {
  return map(list, (item) => (isString(item) || isNumber(item) ? (item as number) : (+get(item, 'id') as number)));
}

export const showImageInNewTabFromUrl = async (url: string) => {
  try {
    const image = await fetch(url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    if (isBrowser) {
      const newTab = window.open();
      if (newTab) {
        newTab.document.body.innerHTML = `<img src='${imageURL}'>`;
      }
    }
  } catch (e) {
    console.error("couldn't show image on new tab", e);
  }
};

// if release version is latest in localstorage no update => return false, otherwise return true
export const updateReleaseVersion = async () => {
  if (isBrowser) {
    const current = localStorage.getItem('releaseVersion');
    const actual = (await versionAPI.getAppVersion()).data;
    const isLatest = current === actual;

    if (!isLatest) {
      localStorage.setItem('releaseVersion', actual);
    }

    return !isLatest;
  }

  return false;
};

export const formatCurrency = (value: number) =>
  value.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'USD'
  });

export const formatCurrencyWithoutCents = (value: number) =>
  value.toLocaleString('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
    currency: 'USD'
  });

export const formatCents = (value: number) =>
  (value / 100.0).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'USD'
  });

export type ValueOrSetter<T> = T | ((value: T) => T);

export const withTimeout = <T>(promise: Promise<T>, timeout: number): Promise<T> => {
  const wait = new Promise((_, reject) => {
    setTimeout(() => reject(new Error('timeout')), timeout);
  });

  return Promise.race([wait, promise]) as unknown as Promise<T>;
};

/**
 * Compares arrays in the most obvious way:
 * * [1, 2] < [1, 3]
 * * [1, 2] < [1, 13]
 * * [1] < [1, 2]
 */
export const compareArrays = <T>(a: T[], b: T[]): number => {
  for (let i = 0; i < a.length; i += 1) {
    const valA = a[i];
    const valB = b[i];

    if (valA && !valB) {
      return 1;
    } else if (valA !== valB) {
      return valA < valB ? -1 : 1;
    }
  }

  // eslint-disable-next-line no-nested-ternary
  return isEmpty(a) ? (isEmpty(b) ? 0 : -1) : 0;
};

export const extractStageOccasions = (
  stageId: number,
  updates: ProjectStageUpdate[]
): { startDate: string; endDate?: string }[] => {
  const result = updates.reduce(
    (acc, update, index) =>
      update.stage?.id === stageId
        ? [
            ...acc,
            {
              startDate: update.createdAt,
              endDate: updates[index + 1]?.createdAt
            }
          ]
        : acc,
    []
  );

  // impossible condition for the stages project never been on
  if (!result.length) {
    return [
      {
        startDate: new Date(9999999999999),
        endDate: new Date(99999999999999)
      }
    ];
  }

  return result;
};

// eslint-disable-next-line
export const sleep = (timeInMS: number) => new Promise((resolve) => setTimeout(resolve, timeInMS));
export const notNumberToZero = (value: number) => (Number.isFinite(value) ? value : 0);
export const formatMoney = (value = 0, precision = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision
  });

  return formatter.format(value);
};

export const formatNumber = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });

  return formatter.format(value);
};

export const cutFileName = (fileName: string, maxLength = 20) => {
  if (fileName.length > maxLength) {
    const partMaxLength = Math.floor(maxLength / 2);

    return fileName.substr(0, partMaxLength) + '...' + fileName.substr(-partMaxLength);
  }

  return fileName;
};

export const is32BitInteger = (numberRaw) => {
  if (numberRaw === '' || numberRaw === null || numberRaw === undefined) {
    return false;
  }

  const value = +numberRaw;

  return isInteger(value) && value >= -2147483648 && value <= 2147483647;
};

export function cleanHtml(emailContent: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(emailContent, 'text/html');
  const { body } = doc;

  if (!body) {
    return '';
  }

  const extractedText: string[] = [];

  const traverseNodes = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      extractedText.push(node.textContent.trim());
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      Array.from(node.childNodes).forEach((childNode) => {
        traverseNodes(childNode);
      });
    }
  };

  traverseNodes(body);

  return extractedText.join('\n').trim();
}

export const extractEntityIdFromUrl = (url: string, entityType: DrawerEntity) => {
  const parts = url.split('/');
  const index = parts.indexOf(`${entityType}s`);

  if (index === -1) {
    return null;
  }

  return parseInt(parts[index + 1], 10);
};