import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '@services/api/databankApi';
import { useToast } from '@hooks/useToast';
import { errorHandler } from '@services/api/helpers';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { ReactQueryKey } from '@enums';
import { useRoutes } from '@hooks/useRoutes';
import { Form, FormLayout, ProjectProperty } from '@generated/types/graphql';
import { FormLayoutDTO, CreateFormColumnDto } from '@types';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '.';

export const useForm = (formId: number) =>
  useQuery<Form>(
    [ReactQueryKey.Form, formId],
    async () => {
      const { form } = await postGraphql<{ form: Form }>(
        gql`
          query ($formId: Int!) {
            form(id: $formId) {
              id
              name
              description
              templateId
              members
              isTemplate
            }
          }
        `,
        { formId }
      );

      return form;
    },
    { enabled: !!formId }
  );

export const useFormProgress = (formId: number) =>
  useQuery<Form['progress']>(
    [ReactQueryKey.FormProgress, formId],
    async () =>
      (
        await postGraphql<{ form: Form }>(
          gql`
            query ($formId: Int!) {
              form(id: $formId) {
                progress {
                  filled
                  total
                }
              }
            }
          `,
          { formId }
        )
      ).form.progress,
    { enabled: !!formId }
  );

export type FormTemplateLayoutColumn = Pick<
  ProjectProperty,
  | 'id'
  | 'name'
  | 'type'
  | 'multiple'
  | 'isRequired'
  | 'isReadOnly'
  | 'projectColumnId'
  | 'additional'
  | 'isAdditional'
  | 'notes'
  | 'projectPropertyFileReferencesByColumnId'
  | 'projectColumn'
>;

export type FormTemplateLayout = Pick<FormLayout, 'id' | 'name' | 'type' | 'columnId'> & {
  column: FormTemplateLayoutColumn;
  childFormLayouts: FormTemplateLayout[];
};

export type FormTemplate = Pick<Form, 'id' | 'name' | 'updatedAt'> & {
  formLayouts: FormTemplateLayout[];
};

export const useFormTemplate = (formTemplateId?: number) => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery<FormTemplate>(
    [ReactQueryKey.FormTemplateList, companyId, formTemplateId],
    async () => {
      const { forms } = await postGraphql<{ forms: FormTemplate[] }>(
        gql`
          query SINGLE_FORM_TEMPLATE($formTemplateId: Int!, $companyId: Int!) {
            forms(
              filter: {
                id: { equalTo: $formTemplateId }
                companyId: { equalTo: $companyId }
                isTemplate: { equalTo: true }
              }
            ) {
              id
              name
              updatedAt
              formLayouts(orderBy: [POSITION_ASC], filter: { parentExists: false }) {
                id
                name
                type
                columnId

                column {
                  id
                  name
                  type
                  multiple
                  isRequired
                  isReadOnly
                  projectColumnId
                  additional
                  isAdditional
                  notes
                  projectColumn {
                    additional
                    type
                  }
                  projectPropertyFileReferencesByColumnId {
                    file {
                      id
                      name
                      metaData
                      downloadUrl
                    }
                  }
                }

                childFormLayouts(orderBy: [POSITION_ASC]) {
                  id
                  name
                  type
                  columnId
                  column {
                    id
                    name
                    type
                    multiple
                    isRequired
                    isReadOnly
                    projectColumnId
                    additional
                    isAdditional
                    notes
                    projectColumn {
                      additional
                      type
                    }
                    projectPropertyFileReferencesByColumnId {
                      file {
                        id
                        name
                        metaData
                        downloadUrl
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        { formTemplateId, companyId }
      );

      return forms[0];
    },
    { enabled: !!formTemplateId }
  );
};

export const useFullForm = (formId?: number, { refetchOnMount }: { refetchOnMount?: boolean } = {}) =>
  useQuery<Form>(
    [ReactQueryKey.FormFull, formId],
    async () => {
      const { form } = await postGraphql<{ form: Form }>(
        gql`
          query ($formId: Int!) {
            form(id: $formId) {
              id
              name
              description
              templateId
              updatedAt
              file {
                id
                projectId
              }
              formLayouts(orderBy: [POSITION_ASC], filter: { parentExists: false }) {
                id
                type
                name
                columnId
                column {
                  id
                  additional
                  name
                  notes
                  projectPropertyFileReferencesByColumnId {
                    file {
                      id
                      name
                      metaData
                      downloadUrl
                    }
                  }
                  projectPropertyValuesByColumnId {
                    textValue
                  }
                  projectColumnId
                  projectColumn {
                    id
                    isAdditional
                    objectName
                    mappedName
                    displayValuePath
                    type
                  }
                  type
                  multiple
                  isRequired
                  isReadOnly
                  projectPropertyValuesByColumnId {
                    dropdownValue
                    textValue
                    dateValue
                    files {
                      name
                      id
                      metaData
                      annotations
                      downloadUrl
                    }
                    numericValue
                    userByWorkerValue {
                      id
                      avatarUrl
                      firstName
                      lastName
                      email
                    }
                  }
                }

                childFormLayouts(orderBy: [POSITION_ASC]) {
                  id
                  type
                  name
                  columnId
                  column {
                    id
                    additional
                    name
                    notes
                    projectPropertyFileReferencesByColumnId {
                      file {
                        id
                        name
                        metaData
                        downloadUrl
                      }
                    }
                    projectPropertyValuesByColumnId {
                      textValue
                    }
                    projectColumnId
                    projectColumn {
                      id
                      isAdditional
                      objectName
                      mappedName
                      displayValuePath
                      type
                    }
                    type
                    multiple
                    isRequired
                    isReadOnly
                    projectPropertyValuesByColumnId {
                      dropdownValue
                      textValue
                      dateValue
                      files {
                        name
                        id
                        metaData
                        annotations
                        downloadUrl
                      }
                      numericValue
                      userByWorkerValue {
                        id
                        avatarUrl
                        firstName
                        lastName
                        email
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        { formId }
      );

      return form;
    },
    {
      enabled: !!formId,
      refetchOnMount
    }
  );

export const useFormMutations = () => {
  const { showError, showSuccess } = useToast();
  const { companyId } = useRoutes();
  const queryClient = useQueryClient();

  const update = useMutation<void, Error, { formId: number; dto: Form }>(
    async ({ formId, dto }) => {
      try {
        await api.updateForm(dto, formId, companyId);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async (data, variables) => {
        showSuccess('Successfully updated the form');

        if (!variables.dto.isTemplate) {
          await queryClient.invalidateQueries([ReactQueryKey.DatabankTree]);
          await queryClient.invalidateQueries([ReactQueryKey.RecordDocList]);
          await queryClient.invalidateQueries([ReactQueryKey.Files, ReactQueryKey.FileDetails]);
        } else {
          await queryClient.invalidateQueries(ReactQueryKey.FormTemplate);
          await queryClient.invalidateQueries([ReactQueryKey.TaskTemplate]);
          await queryClient.invalidateQueries([ReactQueryKey.FormTemplateList]);
        }
        await queryClient.invalidateQueries([ReactQueryKey.Form, variables.formId]);
      }
    }
  );

  const create = useMutation<Form, Error, { dto: Form }>(
    async ({ dto }) => {
      try {
        return (await api.createForm(dto, companyId)).data as unknown as Form;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async (response, variables) => {
        showSuccess('Successfully created the form');
        if (!variables.dto.isTemplate) {
          await queryClient.invalidateQueries([ReactQueryKey.DatabankTree]);
          await queryClient.invalidateQueries([ReactQueryKey.RecordDocList]);
          await queryClient.invalidateQueries([ReactQueryKey.Files, ReactQueryKey.FilesWidget]);
        } else {
          await queryClient.invalidateQueries(ReactQueryKey.FormTemplate);
          await queryClient.invalidateQueries([ReactQueryKey.TaskTemplate]);
          await queryClient.invalidateQueries([ReactQueryKey.FormTemplateList]);
        }
      }
    }
  );

  const updateLayout = useMutation<Form, Error, { dto: { formId: number; layout: FormLayoutDTO[] } }>(
    async ({ dto }) => {
      try {
        return (await api.updateFormLayout(dto.formId, companyId, dto.layout)).data as unknown as Form;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => showSuccess('Successfully updated the form layout')
    }
  );

  const createFieldColumn = useMutation<ProjectProperty, Error, { formId: number; dto: CreateFormColumnDto }>(
    async ({ formId, dto }) => {
      try {
        return (await api.createFormColumn(formId, companyId, dto)).data as unknown as ProjectProperty;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => showSuccess('Successfully created the form column')
    }
  );

  const updateFieldColumn = useMutation<
    ProjectProperty,
    Error,
    { formId: number; columnId: number; dto: Partial<CreateFormColumnDto> }
  >(
    async ({ formId, columnId, dto }) => {
      try {
        return (await api.updateFormColumn(formId, columnId, companyId, dto)).data as unknown as ProjectProperty;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => showSuccess('Successfully updated the form column')
    }
  );

  return {
    update,
    create,
    updateLayout,
    updateFieldColumn,
    createFieldColumn
  };
};
