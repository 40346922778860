import { AxiosResponse } from 'axios';
import { File, Reminder, SmsActivity, LineItem, Invoice } from '@generated/types/graphql';
import { Search, Member } from '@components/Project/ProjectView/types';
import {
  CreateProjectDTO,
  ProjectId,
  ProjectBulkInsertResponse,
  Project,
  CreateReminderDTO,
  UpdateReminderDTO,
  SendSmsDTO,
  LineItemDto,
  SendBulkSmsDto,
  StartImportPayload
} from '@types';

import { post, put, get, deleteRequest, patch } from './base';
import { generateParams, generateQueryParams } from './helpers';

import { Urls } from './base/axios';
import { ProjectCommentRequest, ListParams, BulkUpdateDto, BulkInsertDto } from './types';

type GenerateParamsArgumentsType = Parameters<typeof generateParams>[0];

const mapUrl =
  `json?types=address&access_token=` +
  `pk.eyJ1IjoidHV2c2hpbnR1ciIsImEiOiJja2h6aTJ4YTkwcXAxMnFvZDl` +
  `zc2YwaTkxIn0.rvLp0ow7j0SzjXiS0fimqQ`;

export default {
  createProject: (project: CreateProjectDTO, params: GenerateParamsArgumentsType) =>
    post<CreateProjectDTO, Project>(`${Urls.project}/projects`, project, generateParams(params)),

  updateProject: (id: ProjectId, project: CreateProjectDTO) =>
    put<CreateProjectDTO, Project>(`${Urls.project}/projects/${id}`, project),

  uploadCompanyFile: (fileForm: FormData, companyId?: number) =>
    post<File>(`${Urls.dataBank}/file/upload/company${companyId ? `?companyId=${companyId}` : ''}`, fileForm),

  archiveProject: (id, data) => put(`${Urls.project}/projects/${id}/archive`, data),

  deleteProject: (id: number) => deleteRequest(`${Urls.project}/projects/${id}`),

  getGoeLocation: (address) => get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.${mapUrl}`),

  addFavoriteProject: (id: number) => put(`${Urls.project}/projects/${id}/favorite`, {}),

  removeFavoriteProject: (id: number) => deleteRequest(`${Urls.project}/projects/${id}/favorite`),

  updateProjectMember: (projectId: number, members: Member[]) =>
    put(`${Urls.project}/projects/${projectId}/members`, members),

  getProjectMember: (projectId: number) => get(`${Urls.project}/projects/${projectId}/members`),

  fetchFavoriteProjects: (companyId?: number) =>
    get(`${Urls.project}/projects/favorite?paging[limit]=10&paging[skip]=0&companyId=${companyId}`),

  fetchSingleProject: (projectId: number) => get(`${Urls.project}/projects/${projectId}?relations=timeline`),

  fetchSingleProjectDetailed: (projectId: number, companyId: number) =>
    get(
      `${Urls.project}/projects`,
      generateQueryParams(
        {
          filters: [{ col: 'id', val: `${projectId}`, op: '=' }]
        },
        companyId
      )
    ),

  fetchStagesByProject: (projectId: number, params: any, companyId: number) =>
    get(
      `${Urls.project}/projects/${projectId}/stages`,
      generateParams({
        search: params,
        companyId
      })
    ),
  fetchTasksByProject: (projectId: number) =>
    get(
      `${Urls.project}/tasks?projectId=${projectId}`,
      generateParams({
        ...generateQueryParams({
          filters: [{ col: 'isArchived', val: 'false', op: '=' }]
        })
      })
    ),
  createProjectComment: (projectId: number, body: ProjectCommentRequest) =>
    post(`${Urls.project}/projects/${projectId}/comments`, body),
  updateProjectComment: (projectId: number, commentId: number, body: ProjectCommentRequest) =>
    put(`${Urls.project}/projects/${projectId}/comments/${commentId}`, body),
  deleteProjectComment: (projectId: number, commentId: number) =>
    deleteRequest(`${Urls.project}/projects/${projectId}/comments/${commentId}`),
  getProjectComments: (projectId: number, search: Partial<Search>, params: ListParams) =>
    get(
      `${Urls.project}/projects/${projectId}/comments`,
      generateParams({
        search,
        ...generateQueryParams(params)
      })
    ),

  bulkUpdate: (dto: BulkUpdateDto, search: Partial<Search> = {}) =>
    put(
      `${Urls.project}/projects/bulk-update`,
      dto,
      generateParams({
        search
      })
    ),
  bulkInsert: (insertProjects: BulkInsertDto[], companyId: number) =>
    post<AxiosResponse<ProjectBulkInsertResponse>>(
      `${Urls.project}/projects/bulk-insert?companyId=${companyId}`,
      insertProjects
    ),
  bulkRemove: (projects: BulkUpdateDto['projects'], companyId: number) =>
    put(`${Urls.project}/projects/bulk-remove?companyId=${companyId}`, { projects }),
  createReminder: (projectId: ProjectId, dto: CreateReminderDTO, companyId: number) =>
    post<Reminder>(`${Urls.project}/project/${projectId}/reminder?companyId=${companyId}`, dto),
  updateReminder: (id: number, dto: UpdateReminderDTO, companyId: number) =>
    put<Reminder>(`${Urls.project}/reminder/${id}?companyId=${companyId}`, dto),
  deleteReminder: (id: number, companyId: number) =>
    deleteRequest(`${Urls.project}/reminder/${id}?companyId=${companyId}`),
  sendSms: (recordId: number, dto: SendSmsDTO, companyId: number) =>
    post<SmsActivity>(`${Urls.project}/projects/${recordId}/sms-activity/send?companyId=${companyId}`, dto),
  sendBulkSms: ({ companyId, dto }: { dto: SendBulkSmsDto; companyId: number }) =>
    post<{ status: string }>(`${Urls.project}/projects/sms-activity/send-bulk?companyId=${companyId}`, dto),
  updateLineItems: (projectId: number, dto: LineItemDto[], companyId: number) =>
    put<LineItem[]>(`${Urls.project}/projects/${projectId}/line-items?companyId=${companyId}`, dto),

  createInvoice: (dto: {
    title: string;
    description: string;
    dueDate: Date;
    amount: number;
    projectId: number;
    status: string;
    isBill: boolean;
    amountPaid: number;
  }) => post<Invoice>(`${Urls.project}/invoices`, dto),
  updateInvoice: (
    id: number,
    dto: {
      title?: string;
      description?: string;
      dueDate?: Date;
      amount?: number;
      projectId?: number;
      status?: string;
      amountPaid?: number;
    }
  ) => patch<Invoice>(`${Urls.project}/invoices/${id}`, dto),
  deleteInvoice: (id: number) => deleteRequest(`${Urls.project}/invoices/${id}`),
  startImport: (dto: StartImportPayload) =>
    post<{ historyId: number; totalRows: number; status: string }>(`${Urls.project}/projects/import`, dto),
  downloadFailedRows: (historyId: number) => get(`${Urls.project}/projects/import/${historyId}/failed-rows`),
  downloadSkippedRows: (historyId: number) => get(`${Urls.project}/projects/import/${historyId}/skipped-rows`),
  getOneSchemaToken: () => get<{ token: string }>(`${Urls.project}/projects/import/oneschema-token`)
};
