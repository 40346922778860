import { UserRole, UserCompaniesEntity, User, UserFromApi, Company } from '@state/reducers/authentication/types';
import { getMainRole } from '../../utils/utils';

export const userDataAdapterFromService = (data: UserFromApi) => {
  const userData = {
    id: data.id,
    email: data.email,
    username: `${data.firstName} ${data.lastName}`,
    profileImgUrl: data.avatarUrl
  };

  return userData;
};

export const getMainCompany = (companies: UserCompaniesEntity[] | Company[]): UserCompaniesEntity | Company =>
  companies.find((c) => !c.isCollaborator) || companies[0];

export const userResponseToAuthUser = (user: UserFromApi): { user: User; companies: UserCompaniesEntity[] } => {
  const mainCompany = getMainCompany(user.companies);

  const userData = {
    userId: user.id,
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    avatarUrl: user.avatarUrl,
    roles: mainCompany.roles,
    company: mainCompany,
    companies: user.companies
  };

  const companiesData = user.companies.map((c) => ({
    ...c,
    role: getMainRole(mainCompany.roles) as UserRole
  }));

  return { user: userData, companies: companiesData };
};
