import { colors } from '@styles';
import styled from 'styled-components';

export const ToolbarAction = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;

  color: ${({ isActive }) => (isActive ? colors.white : colors.green)};
  background-color: ${({ isActive }) => (isActive ? colors.green : colors.white)};
`;

export const ActionGroup = styled.div<{ withBorder?: boolean; minWidth?: string; isWide: boolean; isPaired?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  ${({ withBorder }) =>
    withBorder &&
    `
    padding: 6px 20px;
      border-radius: 20px;
      border: 1px solid ${colors.gray3};

      > * {
        border-radius: 50%;
      }
  `}

  @media (min-width: 1400px) {
    gap: ${({ isWide, isPaired }) => {
      if (isWide) {
        return isPaired ? '16px' : '32px';
      }

      return '12px';
    }};
  }

  @media (min-width: 1600px) {
    gap: ${({ isWide, isPaired }) => {
      if (isWide) {
        return isPaired ? '16px' : '32px';
      }

      return '24px';
    }};
  }
`;

export const ZoomActionGroup = styled(ActionGroup)`
  gap: 0px;
  font-size: 14px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: ${({ minWidth }) => minWidth || 'auto'};
  justify-content: space-between;

  > * {
    &:nth-child(2) {
      min-width: 42px;
    }
  }

  @media (min-width: 1400px) {
    gap: 8px;
  }
`;

export const TopToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

export const RightActionsGroup = styled.div<{ isFullScreen: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (min-width: 1400px) {
    gap: ${({ isFullScreen }) => (isFullScreen ? '40px' : '24px')};
  }
`;
