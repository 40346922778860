import { handleActions } from 'redux-actions';
import * as actions from '../../actions/settingsAction/labelSettingAction';
import { Label } from '../../../components/templates/Workspace/Labels/LabelsList/types';

export interface LabelReducer {
    list: Label[];
    isFetching: boolean;
}

export interface WorkspaceSettingsReducer {
    label: LabelReducer
}

const initialReducer = {
    label: {
        isFetching: false,
        list: []
    }
};

interface MergeLabelPayload {
    payload: LabelReducer
}

interface LabelTrxSuccess {
    payload: Label
}

const mergePayload =
    (state: WorkspaceSettingsReducer, { payload }: MergeLabelPayload): WorkspaceSettingsReducer => {
        return {
            ...state,
            label: { ...state.label, ...payload }
        };
    };

const fetchWorkspaceLabelsRequestReducer = (
    state: WorkspaceSettingsReducer
): WorkspaceSettingsReducer => ({
    ...state,
    label: { ...state.label, isFetching: true }
});

const fetchWorkspaceLabelsErrorReducer = (
    state: WorkspaceSettingsReducer
): WorkspaceSettingsReducer => ({
    ...state,
    label: { ...state.label, isFetching: false }
});

const createWorkspaceLabelSuccessReducer =
    (state: WorkspaceSettingsReducer, { payload }: LabelTrxSuccess): WorkspaceSettingsReducer => {
        const { label: currentLabel } = state;
        const labelCopy = {
            ...currentLabel,
            list: [payload, ...currentLabel.list?.filter((label) => label.id !== payload.id)]
        };
        return {
            ...state,
            label: labelCopy
        };
    };

const updateWorkspaceLabelSuccessReducer =
    (state: WorkspaceSettingsReducer, { payload }: LabelTrxSuccess): WorkspaceSettingsReducer => {
        const { list: currentList } = state.label;
        // set new label
        const labelList = currentList.map((label) => {
            if (label.id === payload.id) return payload;
            return label;
        });
        return {
            ...state,
            label: {
                ...state.label,
                list: labelList
            }
        };
    };

const removeWorkspaceLabelSuccessReducer =
    (state: WorkspaceSettingsReducer, { payload }: LabelTrxSuccess): WorkspaceSettingsReducer => {
        const { list: currentList } = state.label;
        const newList = currentList?.filter((label) => label.id !== payload.id);
        return {
            ...state,
            label: {
                ...state.label,
                list: newList
            }
        };
    };

const workspaceSettingsReducer = handleActions(
    {
        [actions.fetchWorkspaceLabelsSuccess]: mergePayload,
        [actions.fetchWorkspaceLabelsError]: fetchWorkspaceLabelsErrorReducer,
        [actions.fetchWorkspaceLabelsRequest]: fetchWorkspaceLabelsRequestReducer,
        [actions.createWorkspaceLabelsSuccess]: createWorkspaceLabelSuccessReducer,
        [actions.updateWorkspaceLabelsSuccess]: updateWorkspaceLabelSuccessReducer,
        [actions.removeWorkspaceLabelsSuccess]: removeWorkspaceLabelSuccessReducer
    },
    initialReducer
);

export default workspaceSettingsReducer;
