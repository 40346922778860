import React from 'react';
import { Tag, PlusCircle } from 'react-feather';
import styled from 'styled-components';
import { colors, fonts } from '@styles';

const Wrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 0;

  font: 14px/1.25 ${fonts.sans};
  color: ${colors.black};

  border-radius: 4px;

  svg {
    margin-right: 5px;
    padding: 2px;
    color: ${props => props.color};
    fill: ${props => props.color};
  }
`;

interface IProps {
  labelName: string;
  id: number;
  handlePress?(label: Label): any;
  newLabel?: boolean;
  onClick?: () => void;
  color: string;
}

interface Label {
  labelName: string;
  id: number;
}

const DropDownItem: React.FC<IProps> = (props: IProps) => {
  const { id, newLabel, labelName, onClick, color } = props;
  const icon = newLabel ? (
    <PlusCircle size={20} color={colors.gray} />
  ) : (
    <Tag size={20} />
  );

  return (
    <Wrapper onClick={onClick} color={color}>
      {icon}
      {labelName}
    </Wrapper>
  );
};

export default DropDownItem;
