import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  BlueprintDTO,
  CreateProjectDTO,
  PaginationResult,
  Project as OldProject,
  ProjectStageType,
  ProjectTrade,
  PropertyType,
  RecordType,
  Search,
  TargetGroup,
  Property,
  SmartViewFilter,
  FilterOperators,
  VirtualPropertyType
} from '@types';
import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';

import projectApi from '@services/api/projectApi';
import { apiErrorHandler, compareArrays, convertSMFilters, is32BitInteger } from '@utils';
import download from 'downloadjs';

import { ReactQueryKey } from '@enums';
import { castArray, isEqual, isNumber, isString, lowerCase, sortBy, upperFirst } from 'lodash';
import {
  Contact,
  Project,
  ProjectAggregates,
  ProjectFilter,
  ProjectGroupBy,
  ProjectRequestStatus,
  ProjectStatus,
  ProjectsConnection,
  ProjectsOrderBy,
  Scalars,
  StringFilter,
  TaskStatus,
  TaskStatusEntity
} from '@generated/types/graphql';
import { PgColumnIdHeader, postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { alertShow } from '@state/actions/alert/alertAction';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { useBlueprints } from '@hooks/useBlueprints';
import { Group, GroupPagination, NO_GROUP, Pagination } from '@common/Table';
import { useMemo } from 'react';
import { propertiesValuesToAdditional, PropertyValue, getPropertyValueById } from '@utils/properties';
import {
  AccountIsActiveStatus,
  DealStatus,
  ProjectStatus as ProjectStatusOld
} from '@components/Project/ProjectView/types';
import { UseQueryResult } from 'react-query/types/react/types';
import { sortColumnAdapter } from '@adapters/ProjectAdapters/ProjectHeaderAdapter';
import { PROJECT_STAGE_COLORS, TASK_STATUS_COLORS } from '@styles';
import { projectsAdapterNew } from '@adapters/ProjectAdapters/ProjectAdapter';
import { getPropertyValueRenderer } from '@common/Properties/PropertyValue';
import { getDefaultSearch } from '@hooks/useSearch';
import { VIRTUAL_FILTER_PROPERTIES } from '@components/Project/ProjectHeader/Filters/helpers';
import {
  AnalyticsWidgetDateRangeType,
  FilterGroupOperator,
  PredefinedWidgetFilterFieldId,
  WidgetFilter,
  WidgetFilters
} from '@features/Analytics/types';
import { applyFiltersGroup, applySingleRecordFilter } from '@features/Analytics';
import { DeepPartial } from 'redux';
import { DateTime } from 'luxon';
import { selectWorkspaceId } from '@state/selectors';

export const getRecordListKey = (type: RecordType) => {
  switch (type) {
    case RecordType.ACCOUNT:
      return ReactQueryKey.WorkspaceAccounts;
    case RecordType.PROJECT:
      return ReactQueryKey.WorkspaceProjects;
    case RecordType.DEAL:
      return ReactQueryKey.WorkspaceDeals;
    default:
      throw new Error(`Unrecognized record type ${type}`);
  }
};

/**
 * Returns first 10 records matching given title, orphaned or related to given account
 */
export const useRelatedRecords = (
  companyId: number,
  type: RecordType,
  titleFilter: string,
  relatedAccountFilter?: number
) =>
  useQuery<Project[]>(
    [
      getRecordListKey(type),
      companyId,
      'useRelatedRecords',
      {
        titleFilter,
        relatedAccountFilter
      }
    ],
    async () => {
      try {
        return (
          await postGraphql<{ projects: { results: Project[] } }>(
            gql`
            query ($title: String, $relatedAccount: Int) {
              projects: projectsConnection(
                filter: {
                  isActive: { equalTo: true }
                  type: {equalTo: "${type}"},
                  companyId: {equalTo: ${companyId}},
                  title: {includesInsensitive: $title},
                  or: [
                    {parentProjectId: {isNull: true}},
                    {parentProjectId: {equalTo: $relatedAccount}}
                  ]
                },
                first: 10
              ) {
                results: nodes {
                  id
                  title
                  type
                  accountType
                  address
                  projectContacts(orderBy: [PROJECT_CONTACTS_CONNECTION_MIN_POSITION_ASC]) {
                    emails
                    phones
                    id
                    name
                  }
                }
              }
            }
          `,
            {
              title: titleFilter,
              relatedAccount: relatedAccountFilter
            }
          )
        ).projects.results;
      } catch (e) {
        throw apiErrorHandler(`Error fetching ${lowerCase(type)} list`, e);
      }
    },
    {
      enabled: !!companyId && !!type && !!titleFilter,
      initialData: [],
      keepPreviousData: true
    }
  );

type RecordWithRelated = Project & {
  relatedAccount: Project;
  relatedProjects: Project[];
  relatedDeals: Project[];
  relatedContacts: Contact[];
};

export type ProjectUpdateMutationProps = { id: number; dto: CreateProjectDTO & { isActive?: boolean } };

/**
 * @deprecated Prefer {@link useRecordDetail}
 */
export const useRecord = (companyId: number, type: RecordType, id: number) =>
  useQuery<RecordWithRelated>(
    [getRecordListKey(type), companyId, `useRecord-${id}`],
    async () => {
      try {
        const { project } = await postGraphql<{ project: RecordWithRelated }>(gql`
          query {
            project(id: ${id}) {
              id, type, title, address, accountType, description, dealValue, dealSize, projectValue, projectSize,
              confidence, blueprintId,
              privilegeOwn, privilegeTeam, privilegeAll, stageId, imageUrl, trades
              city
              street
              zipcode
              state
              owner { id, firstName, lastName, avatarUrl },
              salesRep { id, firstName, lastName, avatarUrl },
              projectManager { id, firstName, lastName, avatarUrl },
              projectMembers { memberType, member { id, email, firstName, lastName, avatarUrl } },
              projectPropertiesValues(filter: {textValue: {isNull: false, notEqualTo: ""}}) {
                columnId, dateValueAllDay, textValue, numericValue, dateValue, workerValue, dropdownValue,
                userByWorkerValue { id, firstName, lastName, email, avatarUrl },
                column { type, multiple }
                files { id name type metaData annotations downloadUrl projectId }
              },

              relatedAccount: parentProject { id, type, title, accountType, address },

              relatedProjects: projectsByParentProjectId(
                filter: {type: {equalTo: "${RecordType.PROJECT}"}},
                orderBy: [CREATED_AT_ASC]
              ) {
                id, type, title
              },

              relatedDeals: projectsByParentProjectId(
                filter: {type: {equalTo: "${RecordType.DEAL}"}},
                orderBy: [CREATED_AT_ASC]
              ) {
                id, type, title
              },

              relatedContacts: projectContacts(orderBy: [PROJECT_CONTACTS_CONNECTION_MIN_POSITION_ASC]) {
                id, name, emails, phones
              }
            }
          }
        `);

        project.additional = propertiesValuesToAdditional(project.projectPropertiesValues);

        return project;
      } catch (e) {
        throw apiErrorHandler(`Error fetching ${lowerCase(type)}`, e);
      }
    },
    {
      enabled: !!companyId && !!type && !!id
    }
  );

export enum RecordGroupType {
  none = 'none',
  customDropdownProperty = 'customDropdownProperty',
  customDropdownMultipleProperty = 'customDropdownMultipleProperty',
  defaultDropdownMultipleProperty = 'defaultDropdownMultipleProperty',
  defaultDropdownProperty = 'defaultDropdownProperty',
  defaultTextProperty = 'defaultTextProperty',
  blueprintStage = 'blueprintStage',
  workOrderStatus = 'workOrderStatus'
}

export type RecordGroupValue = {
  type: RecordGroupType;
  key: Scalars['JSON'];
};

export type RecordIdsGroup = Group<RecordGroupValue> & {
  ids: number[];
  position: number;
  label: string;
  color?: string;
  bgColor?: string;
};

export type DealsAggregates = {
  monthWonCount?: number;
  expectedValue?: number;
  totalValue?: number;
};

type GroupsAggregates = Omit<ProjectAggregates, 'keys'> & {
  keys: [PropertyValue?];
};

type DropdownGroupAggregates = Omit<GroupsAggregates, 'keys'> & {
  keys: [string[]?];
};

type BlueprintStageGroupAggregates = Omit<GroupsAggregates, 'keys'> & {
  keys: [number?];
};

type UngroupedAggregate = Omit<ProjectAggregates, 'keys'>;

const applySingleFilter =
  (propertiesMap: Record<number, Property>) =>
  (filter: WidgetFilter): DeepPartial<ProjectFilter> => {
    // this special filter is redefined here, because in analytics it is nested in "project"
    if (filter.fieldId === PredefinedWidgetFilterFieldId.WORK_ORDER_WITH_TITLE) {
      const operator: keyof StringFilter =
        filter.operator === FilterOperators.Like ? 'includesInsensitive' : 'notIncludesInsensitive';

      return {
        tasks: {
          some: {
            isArchived: { equalTo: false },
            title: {
              [operator]: filter.value
            }
          }
        }
      };
    }

    return applySingleRecordFilter(propertiesMap)(filter);
  };

const applyMoreFilters = (propertiesMap: Record<number, Property>, filters?: WidgetFilters | null) => {
  if (!filters || !filters.children?.length) {
    return {};
  }

  const groupResult = applyFiltersGroup(filters, filters.operator, applySingleFilter(propertiesMap));

  return groupResult as DeepPartial<ProjectFilter>;
};

const generateConditionsForFetchRecordGroups = (
  groupProperty: Property,
  type: RecordType,
  companyId: number,
  search: Search,
  properties: Property[],
  groupBy: RecordGroupType,
  groupBlueprint: BlueprintDTO,
  propertiesMap: Record<number, Property>,
  workOrderStatuses: TaskStatusEntity[]
) => {
  const filters: DeepPartial<ProjectFilter> = {
    companyId: { equalTo: companyId },
    type: { equalTo: type }
  };

  if (groupBlueprint) {
    filters.blueprintId = { equalTo: groupBlueprint.id };
    filters.stageId = { in: groupBlueprint.projectStages.map(({ projectStageId }) => projectStageId) };
  }

  if (type === RecordType.PROJECT && search.status?.length) {
    filters.status = { in: search.status as ProjectStatus[] };
  }

  if (type === RecordType.ACCOUNT && search.status?.length) {
    filters.accountStatus = { in: search.status };
  }

  if (type === RecordType.DEAL && search.status?.length) {
    filters.requestStatus = { in: search.status as ProjectRequestStatus[] };
  }

  if (
    !search.type ||
    search.type === ProjectStatusOld.active ||
    search.type === AccountIsActiveStatus.active ||
    search.type === DealStatus.active
  ) {
    filters.isActive = { equalTo: true };
  }

  const searchQuery = search.search;

  const isSearchByNumber = searchQuery.includes('#') && is32BitInteger(searchQuery.replace('#', ''));

  if (isSearchByNumber) {
    filters.uid = { equalTo: parseInt(searchQuery.replace('#', ''), 10) };
  } else if (searchQuery) {
    const isJustNumber = is32BitInteger(searchQuery);

    if (isJustNumber) {
      filters.or = [
        { uid: { equalTo: parseInt(searchQuery, 10) } },
        { title: { includesInsensitive: searchQuery } },
        { address: { includesInsensitive: searchQuery } },
        {
          projectContactsIndirect: {
            some: {
              contact: {
                or: [{ phones: { includesInsensitive: searchQuery } }]
              }
            }
          }
        }
      ];
    } else {
      filters.or = [
        { title: { includesInsensitive: searchQuery } },
        { address: { includesInsensitive: searchQuery } },
        {
          projectContactsIndirect: {
            some: {
              contact: {
                or: [
                  { phones: { includesInsensitive: searchQuery } },
                  { emails: { includesInsensitive: searchQuery } },
                  { name: { includesInsensitive: searchQuery } }
                ]
              }
            }
          }
        }
      ];
    }
  }

  const moreFilters = applyMoreFilters(propertiesMap, search.filters);

  if (moreFilters.and) {
    filters.and = [...(filters.and || []), ...moreFilters.and];
  }

  if (moreFilters.or) {
    if (filters.or) {
      filters.and = [
        {
          or: filters.or
        },
        {
          or: moreFilters.or
        }
      ];
    } else {
      filters.or = moreFilters.or;
    }
  }

  const sortConditions: string[] = [];
  const sortExprs: ProjectsOrderBy[] = [];

  const addSortProperty = (sortProperty: Property, descRaw: boolean, customPropertyOrder?: number) => {
    if (sortProperty) {
      const desc = sortProperty?.reversedOrder ? !descRaw : descRaw;
      if (sortProperty.isAdditional) {
        sortConditions.push(`orderByProjectProperty${customPropertyOrder}: ${sortProperty.id}`);

        switch (sortProperty.type) {
          case PropertyType.Date:
            sortExprs.push(
              (desc
                ? `PROPERTY_VALUE_DATE_${customPropertyOrder}_DESC`
                : `PROPERTY_VALUE_DATE_${customPropertyOrder}_ASC`) as ProjectsOrderBy
            );
            break;
          case PropertyType.Numeric:
            sortExprs.push(
              (desc
                ? `PROPERTY_VALUE_NUMERIC_${customPropertyOrder}_DESC`
                : `PROPERTY_VALUE_NUMERIC_${customPropertyOrder}_ASC`) as ProjectsOrderBy
            );
            break;
          case PropertyType.Person:
            sortExprs.push(
              (desc
                ? `PROPERTY_VALUE_WORKER_${customPropertyOrder}_DESC`
                : `PROPERTY_VALUE_WORKER_${customPropertyOrder}_ASC`) as ProjectsOrderBy
            );
            break;
          case PropertyType.Dropdown:
          case PropertyType.Text:
          case PropertyType.Link:
            sortExprs.push(
              (desc
                ? `PROPERTY_VALUE_TEXT_${customPropertyOrder}_DESC`
                : `PROPERTY_VALUE_TEXT_${customPropertyOrder}_ASC`) as ProjectsOrderBy
            );
            break;
          default:
            throw new Error(`Unrecognized property type ${sortProperty.type}`);
        }
      } else {
        // pray this conversion gives existing order enum...
        sortExprs.push(
          ProjectsOrderBy[
            `${upperFirst(sortProperty.mappedName)}${desc ? 'Desc' : 'Asc'}` as keyof typeof ProjectsOrderBy
          ]
        );
      }
    }
  };

  const sortProperties = sortColumnAdapter(properties!);
  (search?.orderBy ?? []).reduce((customPropertyOrder, orderBy) => {
    const property = sortProperties.find((prop) => prop.id === orderBy.columnId);
    addSortProperty(property, orderBy.desc, customPropertyOrder);

    if (property?.isAdditional) {
      return customPropertyOrder + 1;
    }

    return customPropertyOrder;
  }, 1);

  // fallback sort to keep consisting order inside sort groups
  if (!sortExprs.includes(ProjectsOrderBy.CreatedAtDesc)) {
    sortExprs.push(ProjectsOrderBy.CreatedAtDesc);
  }

  const sortCondition = sortConditions.join('\n');

  const groupsQueryHeaders: { [header: string]: string | number } = {};

  let groupExpr: ProjectGroupBy | null = null;

  switch (groupBy) {
    case RecordGroupType.none:
      break;
    case RecordGroupType.workOrderStatus:
    case RecordGroupType.customDropdownProperty:
    case RecordGroupType.customDropdownMultipleProperty:
      groupExpr = ProjectGroupBy.IdProjectColumnValueDropdownValue;
      groupsQueryHeaders[PgColumnIdHeader] = groupProperty?.id;
      break;
    case RecordGroupType.defaultDropdownProperty:
    case RecordGroupType.defaultDropdownMultipleProperty:
    case RecordGroupType.defaultTextProperty:
      // pray this conversion gives existing group enum...
      groupExpr = ProjectGroupBy[`${upperFirst(groupProperty?.mappedName)}` as keyof typeof ProjectGroupBy];
      break;
    case RecordGroupType.blueprintStage:
      groupExpr = ProjectGroupBy.StageId;
      break;
    default:
      throw new Error(`Unrecognized record group type ${groupBy}`);
  }

  return {
    sortCondition,
    groupExpr,
    sortExprs,
    groupsQueryHeaders,
    groupProperty,
    groupBlueprint,
    type,
    companyId,
    filters,
    workOrderStatuses
  };
};

const generateGroupByForFetchRecordGroups = (search: Search, groupProperty: Property) => {
  if (search.groupBy === 0) {
    return {
      groupBy: RecordGroupType.none
    };
  }

  if (
    isNumber(search.groupBy) &&
    search.groupBy &&
    groupProperty?.type === PropertyType.Text &&
    !groupProperty?.isAdditional
  ) {
    return {
      groupBy: RecordGroupType.defaultTextProperty
    };
  }

  if (isNumber(search.groupBy) && search.groupBy) {
    if (groupProperty?.isAdditional) {
      if (groupProperty.virtualType === VirtualPropertyType.taskStatus) {
        return {
          groupBy: RecordGroupType.workOrderStatus
        };
      }

      return {
        groupBy: groupProperty?.multiple
          ? RecordGroupType.customDropdownMultipleProperty
          : RecordGroupType.customDropdownProperty
      };
    }

    return {
      groupBy: groupProperty?.multiple
        ? RecordGroupType.defaultDropdownMultipleProperty
        : RecordGroupType.defaultDropdownProperty
    };
  }

  if (isString(search.groupBy)) {
    return {
      groupBy: RecordGroupType.blueprintStage
    };
  }

  return {
    groupBy: RecordGroupType.none
  };
};

export const fetchRecordGroups = {
  generateConditions: generateConditionsForFetchRecordGroups,
  generateGroupBy: generateGroupByForFetchRecordGroups,
  fetch: async ({
    groupExpr,
    groupBy,
    sortExprs,
    groupsQueryHeaders,
    sortCondition,
    groupProperty,
    groupBlueprint,
    type,
    filters,
    workOrderStatuses
  }: ReturnType<typeof generateConditionsForFetchRecordGroups> &
    ReturnType<typeof generateGroupByForFetchRecordGroups>) => {
    try {
      const data = await postGraphql<{
        groups: ProjectsConnection;
        dealsLastMonthSum: ProjectsConnection;
        dealsExpectedValue: ProjectsConnection;
        dealsTotalValue: ProjectsConnection;
      }>(
        gql`query ($filters: ProjectFilter!) {
          groups: projectsConnection(
            filter: $filters
            condition: {
            ${sortCondition}
            }
          ) {
            ${
              groupExpr
                ? `groupedAggregates(groupBy: [${groupExpr}]) {
                keys
                arrayAgg {
                  id(orderBy: [${sortExprs.join(', ')}])
                }
              }`
                : ''
            }
            ${
              !groupExpr
                ? `aggregates {
                arrayAgg {
                  id(orderBy: [${sortExprs.join(', ')}])
                }
              }`
                : ''
            }
          }
        }`,
        {
          filters
        },
        groupsQueryHeaders
      );

      const groupsData = groupExpr ? data.groups.groupedAggregates! : [data.groups.aggregates!];

      // prefer ordering of groups like in settings (prop values/task stages/blueprint stages) + add empty groups in
      // some cases for values from settings
      let groupsList: ProjectAggregates[];
      if (groupBy === RecordGroupType.blueprintStage) {
        groupsList = sortBy(groupBlueprint!.projectStages, 'position').map(
          (stage) =>
            (groupsData as BlueprintStageGroupAggregates[]).find(({ keys }) => keys[0] === stage.projectStageId) ||
            ({
              keys: [stage.projectStageId],
              arrayAgg: { id: [] as number[] }
            } as ProjectAggregates)
        );
      } else if (groupBy === RecordGroupType.workOrderStatus) {
        const allowedStatuses = groupProperty!.additional?.values || [];
        groupsList = workOrderStatuses
          .filter((status) => allowedStatuses.includes(status.id))
          .map((status) => {
            const group = (groupsData as DropdownGroupAggregates[]).find(({ keys }) => keys[0]?.[0] === status.id) || {
              keys: [[status.id]],
              arrayAgg: { id: [] as number[] }
            };

            return group;
          }) as ProjectAggregates[];

        const noneGroup = (groupsData as DropdownGroupAggregates[]).find(
          ({ keys }) => Array.isArray(keys[0]) && keys[0].length === 0
        );

        if (noneGroup) {
          groupsList.push(noneGroup);
        }
      } else if (
        [RecordGroupType.customDropdownProperty, RecordGroupType.customDropdownMultipleProperty].includes(groupBy)
      ) {
        if (!groupProperty!.multiple) {
          (groupProperty!.additional?.values || []).forEach((propValue) => {
            if (!(groupsData as DropdownGroupAggregates[]).some(({ keys }) => keys[0]?.[0] === propValue)) {
              groupsData.push({
                keys: [[propValue]],
                arrayAgg: { id: [] as number[] }
              } as ProjectAggregates);
            }
          });
        }

        const keysSettingsIndexes = (groupsData as DropdownGroupAggregates[]).reduce(
          (acc, { keys }) => {
            const propValues = keys[0] || [];

            acc[propValues.toString()] = propValues.map((propValue) => {
              const settingsIndex = (groupProperty!.additional?.values || []).indexOf(propValue);

              return settingsIndex >= 0 ? settingsIndex : Number.MAX_SAFE_INTEGER;
            });

            return acc;
          },
          {} as { [keys: string]: number[] }
        );

        groupsList = (groupsData as DropdownGroupAggregates[]).sort((grA, grB) => {
          const bySettingsOrder = compareArrays(
            keysSettingsIndexes[grA.keys.toString()],
            keysSettingsIndexes[grB.keys.toString()]
          );

          if (bySettingsOrder !== 0) {
            return bySettingsOrder;
          }

          return compareArrays(grA.keys, grB.keys);
        });
      } else if (
        [RecordGroupType.defaultDropdownProperty, RecordGroupType.defaultDropdownMultipleProperty].includes(groupBy)
      ) {
        groupsList = (groupsData as DropdownGroupAggregates[]).sort((groupA, groupB) =>
          compareArrays(groupA.keys[0] || [], groupB.keys[0] || [])
        );
      } else {
        groupsList = groupsData;
      }

      if (!groupsList.length) {
        groupsList.push({
          keys: [null],
          arrayAgg: { id: [] as number[] }
        } as ProjectAggregates);
      }

      const groups: RecordIdsGroup[] = (
        groupsList as (GroupsAggregates | UngroupedAggregate | DropdownGroupAggregates)[]
      )
        .map((groupData, index) => {
          const key: PropertyValue | null = groupData.keys?.[0] || null;
          const value = {
            type: groupBy,
            key
          };
          let position = index;
          const ids = groupData.arrayAgg!.id as number[];

          if (groupBy === RecordGroupType.none) {
            return {
              value,
              label: NO_GROUP,
              ids,
              position: 0
            };
          }

          if (key == null || (key as string[]).length === 0) {
            return {
              value,
              label: 'None',
              ids,
              position
            };
          }

          let label: string;
          let color: string | undefined;
          let bgColor: string | undefined;

          if (groupBy === RecordGroupType.blueprintStage) {
            const stageId = key as number;
            if (!stageId) {
              return null;
            }
            const projectStage = groupBlueprint?.projectStages.find((stage) => stage.projectStageId === stageId)!;

            label = projectStage.projectStage.name;
            color = PROJECT_STAGE_COLORS[projectStage.projectStage.type as ProjectStageType];
            position = projectStage.position;
          } else if (groupBy === RecordGroupType.workOrderStatus) {
            const statusId = key[0] as TaskStatus;

            const status = workOrderStatuses.find((status) => status.id === statusId);
            if (!status) {
              label = castArray(key).join(', ');
            } else {
              label = status.label.toUpperCase();
              const statusColor = TASK_STATUS_COLORS[statusId] || { color: '#000000', background: '#FFFFFF' };
              color = statusColor.color;
              bgColor = statusColor.background;
            }
          } else {
            label = castArray(key).join(', ');
          }

          return {
            value,
            label,
            color,
            bgColor,
            ids,
            position
          };
        })
        .filter(Boolean);

      return {
        groups: sortBy(groups, (group) => group.position)
      };
    } catch (e) {
      throw apiErrorHandler(`Error fetching groups of ${lowerCase(type)}`, e);
    }
  }
};

/**
 * Returns groups of record IDs for given set of filters and sorting. First stage of getting paginated records.
 *
 * Also returns related aggregates like deals stats.
 *
 * ### Implementation details
 * We will request grouped records in two stages: firstly apply grouping and filters to request grops of IDs of ALL
 * records in the group, then apply pagination to IDs to request actual records.
 * Why:
 * * Currently the most effective way to get groups of projects is grouped aggregate `arrayAgg`. The problem is,
 *   Postgres aggregates does not support pagination at all - you can only get all the record IDs of some group.
 * * Given that, we will request the actual records data in second stage after applying pagination to IDs to avoid
 *   requesting full data for thousands of projects.
 */
export const useRecordsGroups = (
  companyId: number,
  type: RecordType,
  search: Search
): UseQueryResult<{ groups: RecordIdsGroup[] }> => {
  const { filterColumns: properties, scopeToAllColumns, allProperties } = useCompanyProperties({ recordType: type });

  const { data: workOrderStatuses = [] } = useWorkOrderStatuses();

  const propertiesMap = useMemo(
    () =>
      (scopeToAllColumns[type] || []).reduce(
        (acc, property) => {
          acc[property.id as number] = property;

          return acc;
        },
        {} as Record<number, Property>
      ),
    [type, scopeToAllColumns]
  );

  const groupProperty = useMemo(
    () => properties?.find((property) => property.id === +search.groupBy),
    [properties, search.groupBy]
  );

  const {
    fetchAll: { data: blueprints = {} as PaginationResult<BlueprintDTO> }
  } = useBlueprints();

  const groupBlueprint = useMemo(
    () => blueprints.results?.find((blueprint) => blueprint.id === +`${search.groupBy}`.replace('blueprint_', '')),
    [blueprints, search.groupBy]
  );

  const { groupBy } = useMemo(() => fetchRecordGroups.generateGroupBy(search, groupProperty), [search, groupProperty]);

  return useQuery<{ groups: RecordIdsGroup[] }>(
    [getRecordListKey(type), companyId, 'useGroupedRecords', { search, groupBy }],
    async () => {
      const { groupExpr, sortExprs, sortCondition, groupsQueryHeaders, filters } = fetchRecordGroups.generateConditions(
        groupProperty,
        type,
        companyId,
        search,
        properties,
        groupBy,
        groupBlueprint,
        propertiesMap,
        workOrderStatuses
      );

      return fetchRecordGroups.fetch({
        groupBy,
        companyId,
        type,
        groupExpr,
        sortExprs,
        sortCondition,
        groupsQueryHeaders,
        groupBlueprint,
        groupProperty,
        filters,
        workOrderStatuses
      });
    },
    {
      enabled:
        !!companyId &&
        !!type &&
        !!search &&
        !!groupBy &&
        !!allProperties.length &&
        !!workOrderStatuses.length &&
        (groupBy !== RecordGroupType.blueprintStage || !!groupBlueprint) &&
        (![RecordGroupType.customDropdownProperty, RecordGroupType.customDropdownMultipleProperty].includes(groupBy) ||
          !!groupProperty),
      initialData: {
        groups: []
      }
    }
  );
};

type PagedGroup = { group: RecordIdsGroup; page: Pagination; ids: number[]; total: number };

export const fetchGroupedRecords = {
  fetch: async (allIds: number[], type: RecordType, pagedGroups: PagedGroup[]): Promise<TargetGroup[]> => {
    try {
      const data = !allIds.length
        ? []
        : (
            await postGraphql<{ projects: Project[] }>(
              gql`
                query ($ids: [Int!]!) {
                  projects(filter: { id: { in: $ids } }) {
                    id
                    title
                    description
                    address
                    city
                    street
                    zipcode
                    state
                    geoLocation
                    createdAt
                    progress
                    homeOwnerEmail
                    imageUrl
                    isActive
                    isCompleted
                    companyId
                    ahj
                    phone
                    updatedAt
                    streetViewUrl
                    type
                    dealValue
                    dealSize
                    projectValue
                    projectSize
                    accountType
                    confidence
                    primaryEmail
                    primaryPhone
                    lastActivity
                    overdueBy
                    trades
                    privilegeOwn
                    privilegeTeam
                    privilegeAll
                    lastInboundCallDisposition
                    lastInboundCallTime
                    lastInboundEmailTime
                    lastInboundSmsTime
                    lastOutboundCallDisposition
                    lastOutboundCallTime
                    lastOutboundEmailTime
                    lastOutboundSmsTime
                    totalInboundCallsCount
                    totalOutboundCallsCount
                    createdByUser {
                      id
                      firstName
                      lastName
                      email
                      avatarUrl
                    }
                    blueprint {
                      id
                      name
                    }
                    stage {
                      id
                      name
                      type
                    }
                    owner {
                      id
                      firstName
                      lastName
                      email
                      avatarUrl
                    }
                    salesRep {
                      id
                      firstName
                      lastName
                      email
                      avatarUrl
                    }
                    projectManager {
                      id
                      firstName
                      lastName
                      email
                      avatarUrl
                    }
                    jurisdiction {
                      id
                      uuid
                      name
                    }
                    projectMembers {
                      member {
                        id
                        firstName
                        lastName
                        email
                        avatarUrl
                      }
                    }
                    projectPropertiesValues(filter: { textValue: { isNull: false, notEqualTo: "" } }) {
                      columnId
                      dateValueAllDay
                      textValue
                      numericValue
                      dateValue
                      workerValue
                      dropdownValue
                      userByWorkerValue {
                        id
                        firstName
                        lastName
                        email
                        avatarUrl
                      }
                      column {
                        type
                        multiple
                      }
                      files {
                        id
                        name
                        type
                        metaData
                        annotations
                        downloadUrl
                        projectId
                      }
                    }
                    projectStageUpdates(orderBy: CREATED_AT_DESC, first: 1) {
                      id
                      stage {
                        name
                        id
                        redSla
                        yellowSla
                      }
                      createdAt
                    }
                    accountStatus
                    status
                    requestStatus
                    parentProjectId
                    uid
                  }
                }
              `,
              { ids: allIds }
            )
          ).projects;

      return pagedGroups.map(
        ({ group, page, ids, total }) =>
          ({
            group: {
              id: JSON.stringify(group.value),
              value: group.value,
              name: group.label,
              list: projectsAdapterNew(ids.map((id) => data.find((project) => project.id === id)!)),
              color: group.color
            },
            total: total || 0,
            page: page.page,
            perPage: page.perPage,
            aggregates: { sum: { dealValue: 0 } }
          }) as unknown as TargetGroup,
        [] as TargetGroup[]
      );
    } catch (e) {
      throw apiErrorHandler(`Error fetching groups of ${lowerCase(type)}`, e);
    }
  },
  // passing undefined paging results to fetching all
  groupToPagedGroup: (groups: RecordIdsGroup[], paging?: GroupPagination<RecordGroupValue>[]) => {
    const pagedGroups = groups.reduce((acc, group) => {
      const page = paging
        ? paging.find((candidate) => isEqual(candidate.group.value, group.value))
        : ({
            group,
            perPage: group.ids.length,
            page: 1
          } as unknown as GroupPagination<RecordGroupValue>);

      if (page) {
        const start = (page.page - 1) * page.perPage;
        const end = start + page.perPage;

        acc.push({
          group,
          page,
          ids: group.ids.slice(start, end),
          total: group.ids.length
        });
      }

      return acc;
    }, [] as PagedGroup[]);

    const keyByIds: string[] = [];

    const allIds = pagedGroups.reduce((acc, group) => {
      keyByIds.push(`${group.group.value.type}_${group.group.value.key}`);
      group.ids.forEach((id) => {
        acc.push(id);
        keyByIds.push(id);
      });

      return acc;
    }, [] as number[]);

    return {
      keyByIds,
      allIds,
      pagedGroups
    };
  },
  fetchFromSmartview: async ({
    view,
    companyId,
    properties,
    propertiesMap,
    blueprints
  }: {
    view: SmartViewFilter;
    blueprints: BlueprintDTO[];
    properties: Property[];
    propertiesMap: Record<number, Property>;
    companyId: number;
  }) => {
    const search = {
      ...getDefaultSearch(view.type as RecordType, blueprints),
      type: view.conditions.search?.isArchivedShown ? ProjectStatusOld.all : ProjectStatusOld.active,
      companyId,
      ...(view.conditions.search ?? {}),
      search: view.conditions.search?.query || '',
      filters: convertSMFilters(view.conditions?.search?.filters, properties),
      selectedView: view.id
    } as Search;
    const type = view.type as RecordType;
    const groupProperty = properties?.find((property) => property.id === +search.groupBy);
    const groupBlueprint = blueprints.find(
      (blueprint) => blueprint.id === +`${search.groupBy}`.replace('blueprint_', '')
    );

    const groupBy = fetchRecordGroups.generateGroupBy(search, groupProperty);

    const conditions = fetchRecordGroups.generateConditions(
      groupProperty,
      type,
      companyId,
      search,
      properties,
      groupBy.groupBy,
      groupBlueprint,
      propertiesMap
    );
    const { groups } = await fetchRecordGroups.fetch({
      ...groupBy,
      ...conditions
    });
    const { pagedGroups, allIds } = fetchGroupedRecords.groupToPagedGroup(groups);

    return fetchGroupedRecords.fetch(allIds, type, pagedGroups);
  }
};

/**
 * Returns paginated groups of records from given records IDs. Second stage of getting paginated records.
 */
export const useGroupedRecords = (
  companyId: number,
  type: RecordType,
  groups: RecordIdsGroup[],
  paging: GroupPagination<RecordGroupValue>[]
): UseQueryResult<TargetGroup[]> & { isPagingLoading: boolean } => {
  const { keyByIds, allIds, pagedGroups } = fetchGroupedRecords.groupToPagedGroup(groups, paging);

  const recordsQuery = useQuery<TargetGroup[]>(
    [getRecordListKey(type), companyId, 'useGroupedRecords', keyByIds],
    async () => fetchGroupedRecords.fetch(allIds, type, pagedGroups),
    {
      enabled: !!companyId && !!type && !!paging?.length && !!groups,
      initialData: [],
      keepPreviousData: true
    }
  );

  const isPagingLoading =
    recordsQuery.isFetching &&
    (type === RecordType.DEAL ? paging.some(({ perPage }) => perPage > 20) : paging.some(({ page }) => page !== 1));

  return {
    ...recordsQuery,
    isPagingLoading
  };
};

/**
 * Returns first account related to given contact
 */
export const useFirstContactAccount = (companyId: number, contactId: number = 0) =>
  useQuery<Project | null>(
    [getRecordListKey(RecordType.ACCOUNT), companyId, 'useFirstContactAccount', { contactId }],
    async () => {
      try {
        return (
          (
            await postGraphql<{ contact: { contactProjects: Project[] } }>(
              gql`
              query ($contactId: Int!) {
                contact(id: $contactId) {
                  contactProjects(
                    first: 1,
                    orderBy: [PROJECT_CONTACTS_INDIRECT_CONNECTION_MAX_POSITION_ASC],
                    filter: {type: {equalTo: "${RecordType.ACCOUNT}"}}
                  ) {
                    id, title, accountType, address
                  }
                }
              }
            `,
              { contactId }
            )
          ).contact.contactProjects[0] || null
        );
      } catch (e) {
        throw apiErrorHandler(`Error fetching ${lowerCase(RecordType.ACCOUNT)} list`, e);
      }
    },
    {
      enabled: !!companyId && !!contactId,
      initialData: null
    }
  );

export const useRecentDealTrades = (companyId: number, accountId: number) =>
  useQuery<ProjectTrade[]>(
    [getRecordListKey(RecordType.DEAL), companyId, `useRecentDeal-${accountId}`],
    async () => {
      try {
        return (
          ((
            await postGraphql<{ projects: Project[] }>(
              gql`
                query ($accountId: Int!) {
                  projects(filter: { parentProjectId: { equalTo: $accountId } }, orderBy: [CREATED_AT_DESC], first: 1) {
                    trades
                  }
                }
              `,
              { accountId }
            )
          ).projects[0]?.trades as ProjectTrade[]) || []
        );
      } catch (e) {
        throw apiErrorHandler('Error fetching recent request', e);
      }
    },
    {
      enabled: !!companyId && !!accountId,
      initialData: []
    }
  );

export const useRecordsSearch = (search: string, types?: RecordType[]) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const filter: DeepPartial<ProjectFilter> = {
    companyId: { equalTo: companyId },
    isActive: { equalTo: true }
  };

  if (types) {
    filter.type = { in: types };
  }

  if (search) {
    const isSearchByNumber = search.includes('#') && is32BitInteger(search.replace('#', ''));

    if (isSearchByNumber) {
      filter.uid = { equalTo: parseInt(search.replace('#', ''), 10) };
    } else {
      const isJustNumber = is32BitInteger(search);

      if (isJustNumber) {
        filter.or = [
          { uid: { equalTo: parseInt(search, 10) } },
          { title: { includesInsensitive: search } },
          { address: { includesInsensitive: search } },
          {
            projectContactsIndirect: {
              some: {
                contact: {
                  or: [{ phones: { includesInsensitive: search } }]
                }
              }
            }
          }
        ];
      } else {
        filter.or = [
          { title: { includesInsensitive: search } },
          { address: { includesInsensitive: search } },
          {
            projectContactsIndirect: {
              some: {
                contact: {
                  or: [
                    { phones: { includesInsensitive: search } },
                    { emails: { includesInsensitive: search } },
                    { name: { includesInsensitive: search } }
                  ]
                }
              }
            }
          }
        ];
      }
    }
  }

  return useQuery<Project[]>(
    ['useRecordsSearch', companyId, search],
    async () => {
      try {
        return (
          await postGraphql<{ projects: Project[] }>(
            gql`
              query ($filter: ProjectFilter!) {
                projects(filter: $filter, first: 10, orderBy: [TYPE_DESC, CREATED_AT_DESC]) {
                  id
                  title
                  type
                  address
                  companyId
                  blueprintId
                  stageId
                  stage {
                    id
                    name
                  }
                }
              }
            `,
            {
              filter
            }
          )
        ).projects;
      } catch (e) {
        throw apiErrorHandler('Error fetching records', e);
      }
    },
    {
      enabled: !!companyId
    }
  );
};

export enum ExportMethod {
  DATE_RANGE = 'dateRange',
  SMART_VIEW = 'smartView'
}

const RECORD_NAME_BY_RECORD_TYPE = {
  [RecordType.ACCOUNT]: 'Client',
  [RecordType.PROJECT]: 'Project',
  [RecordType.DEAL]: 'Request'
};

export const useRecordsMutations = (companyId: number, type: RecordType) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const { scopeToAllColumns } = useCompanyProperties({ recordType: type });

  const propertiesMap = useMemo(
    () =>
      (scopeToAllColumns[type] || []).reduce(
        (acc, property) => {
          acc[property.id as number] = property;

          return acc;
        },
        {} as Record<number, Property>
      ),
    [type, scopeToAllColumns]
  );

  const createMutation = useMutation<OldProject, Error, { dto: CreateProjectDTO }>(
    async ({ dto }) => {
      try {
        return (
          await projectApi.createProject(
            {
              ...dto,
              type
            },
            { companyId }
          )
        ).data;
      } catch (e) {
        throw apiErrorHandler(`Error creating ${RECORD_NAME_BY_RECORD_TYPE[type]}`, e);
      }
    },
    {
      onSuccess: (data, { dto: { parentProjectId } }) => {
        Object.values(RecordType).forEach((recType) => {
          queryClient.invalidateQueries([getRecordListKey(recType), companyId]);
        });

        if (parentProjectId) {
          queryClient.invalidateQueries([ReactQueryKey.RecordDetail, parentProjectId]);
        }

        dispatch(
          alertShow([`${RECORD_NAME_BY_RECORD_TYPE[type]} <b>${data.title}</b> successfully created`], 'success')
        );
      }
    }
  );

  const updateMutation = useMutation<OldProject, Error, ProjectUpdateMutationProps>(
    async ({ id, dto }) => {
      try {
        return (
          await projectApi.updateProject(id, {
            ...dto,
            type
          })
        ).data;
      } catch (e) {
        throw apiErrorHandler(`Error updating ${RECORD_NAME_BY_RECORD_TYPE[type]}`, e);
      }
    },
    {
      onSuccess: (data, { id }) => {
        queryClient.invalidateQueries([ReactQueryKey.RecordDetail, id]);
        queryClient.invalidateQueries([ReactQueryKey.WorkspaceProjects]);

        if (type === RecordType.PROJECT) {
          queryClient.invalidateQueries([ReactQueryKey.ProjectsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.ProjectsByIds]);
        } else if (type === RecordType.ACCOUNT) {
          queryClient.invalidateQueries([ReactQueryKey.ClientsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.ClientsByIds]);
        } else if (type === RecordType.DEAL) {
          queryClient.invalidateQueries([ReactQueryKey.RequestsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsByIds]);
        }

        queryClient.invalidateQueries([ReactQueryKey.ProjectActivity]);

        dispatch(
          alertShow([`${RECORD_NAME_BY_RECORD_TYPE[type]} <b>${data.title}</b> successfully updated`], 'success')
        );
      }
    }
  );

  const deleteMutation = useMutation<void, Error, { id: number }>(
    async ({ id }) => {
      try {
        await projectApi.deleteProject(id);
      } catch (e) {
        throw apiErrorHandler(`Error deleting ${RECORD_NAME_BY_RECORD_TYPE[type]}`, e);
      }
    },
    {
      onSuccess: () => {
        if (type === RecordType.PROJECT) {
          queryClient.invalidateQueries([ReactQueryKey.ProjectsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.ProjectsByIds]);
        } else if (type === RecordType.ACCOUNT) {
          queryClient.invalidateQueries([ReactQueryKey.ClientsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.ClientsByIds]);
        } else if (type === RecordType.DEAL) {
          queryClient.invalidateQueries([ReactQueryKey.RequestsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsByIds]);
        }

        dispatch(alertShow([`${RECORD_NAME_BY_RECORD_TYPE[type]} successfully deleted`], 'success'));
      }
    }
  );

  const exportCsv = useMutation<
    void,
    Error,
    {
      exportMethod: ExportMethod;
      properties: Property[];
      view: SmartViewFilter;
      blueprints: BlueprintDTO[];
      dateRangeType: AnalyticsWidgetDateRangeType;
      customStartDate?: Date;
      customEndDate?: Date;
    }
  >(
    async (args) => {
      try {
        const dto = { ...args };

        const exportFromView = dto.exportMethod === ExportMethod.SMART_VIEW;
        if (exportFromView && !dto.view) {
          return;
        }

        const createdAtColumnId = -5;

        if (!exportFromView) {
          const filters: WidgetFilters = {
            id: 'root',
            operator: FilterGroupOperator.AND,
            children:
              dto.dateRangeType === AnalyticsWidgetDateRangeType.CUSTOM
                ? [
                    {
                      id: 'custom-filter-1',
                      fieldId: createdAtColumnId,
                      operator: FilterOperators.After,
                      value: DateTime.fromJSDate(dto.customStartDate).endOf('day').minus({ days: 1 }).toISO()
                    },
                    {
                      id: 'custom-filter-2',
                      fieldId: createdAtColumnId,
                      operator: FilterOperators.Before,
                      value: DateTime.fromJSDate(dto.customEndDate).startOf('day').plus({ days: 1 }).toISO()
                    }
                  ]
                : [
                    {
                      id: 'custom-filter-1',
                      fieldId: createdAtColumnId,
                      operator: FilterOperators.Within,
                      value: dto.dateRangeType
                    }
                  ]
          };

          dto.view = {
            // fake view for date range export
            name: 'daterange',
            type,
            conditions: {
              search: {
                from: 'GRID',
                type: 'Active',
                search: '',
                filters,
                groupBy: 0,
                orderBy: [
                  {
                    desc: true,
                    columnId: createdAtColumnId
                  }
                ],
                companyId,
                groupFilter: ''
              },
              properties: dto.properties
                .filter(
                  (property) =>
                    property.scope.includes(type) &&
                    !property.virtual &&
                    // eslint-disable-next-line eqeqeq
                    !VIRTUAL_FILTER_PROPERTIES.some(({ id }) => id == property.id)
                )
                .reduce(
                  (acc, property, index) => ({
                    columnsOrder: {
                      ...acc.columnsOrder,
                      [property.id]: property.isAdditional ? 1000 + index : -property.id // in order to resort correct order
                    },
                    shownColumns: [...acc.shownColumns, property.id]
                  }),
                  {
                    columnsOrder: {},
                    shownColumns: []
                  }
                ),
              recordType: type
            },
            companyId
          } as unknown as SmartViewFilter;
        }

        const { properties, view, blueprints } = dto;

        const shownColumns = view.conditions.properties.shownColumns
          .map((id) => properties.find((property) => property.id === id)!)
          .filter(Boolean);

        const groupedRecords = await fetchGroupedRecords.fetchFromSmartview({
          view,
          companyId,
          blueprints,
          properties,
          propertiesMap
        });

        const sanitizeRow = (row: any[]) =>
          row
            .map(String)
            .map((v) => v.replaceAll('•', '-').replaceAll('"', '""'))
            .map((v) => (v.search(/("|,|\n)/g) >= 0 ? `"${v}"` : v))
            .join(',');

        const csv = groupedRecords
          .reduce(
            (acc, group) => {
              const groupHeader = [group.group.name === 'NO_GROUP' ? 'None' : group.group.name, group.total];

              const groupProjects = group.group.list.map((record) =>
                sanitizeRow(
                  shownColumns.reduce((values, property) => {
                    const value = getPropertyValueById(record, property);
                    const { formatValue } = getPropertyValueRenderer(property);

                    if (property.mappedName === 'stageId') {
                      values.push(record.projectDetail?.stage?.name ?? '');

                      return values;
                    }

                    values.push(
                      (formatValue
                        ? formatValue({
                            value,
                            property
                          })
                        : value?.toString()) ?? ''
                    );

                    return values;
                  }, [])
                )
              );

              return acc.concat([sanitizeRow(groupHeader)].concat(groupProjects));
            },
            [sanitizeRow(shownColumns.map(({ name }) => name))]
          )
          .join('\r\n');

        download(csv, `${view.name} - ${Date.now()}.csv`, 'text/csv;charset=utf-8;');
      } catch (e) {
        throw apiErrorHandler('Could not export it. Please try again or contact the support.', e);
      }
    },
    {
      onSuccess: () => {
        dispatch(alertShow(['Successfully exported the CSV'], 'success'));
      }
    }
  );

  return {
    create: createMutation,
    update: updateMutation,
    delete: deleteMutation,
    exportCsv
  };
};
