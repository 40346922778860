import styled, { css } from 'styled-components';
import { makeStyles, Modal as MuiModal } from '@material-ui/core';
import { checkboxMui, device, modal, scroll } from '@styles';
import { Button } from '@common/ui';

export const useStyles = makeStyles(checkboxMui({}));

export const Modal = styled(MuiModal)`
  ${modal.modal};
`;

export const Wrapper = styled.div`
  ${modal.wrapper};
`;

export const Header = styled.div`
  ${modal.header};
`;

export const Title = styled.div`
  ${modal.title};
`;

export const Body = styled.div`
  ${modal.body};
  background-color: #f7f7fc;
  ${device.sm`
  padding: 24px;
  `}
`;

export const Footer = styled.div`
  ${modal.footer};
`;

export const Properties = styled.div`
  ${device.sm`
    max-height: 380px;
    margin-top: 20px;
    padding-right: 5px;
    overflow-y: scroll;
    
    ${scroll};
  `};
`;

export const Row = styled.div`
  ${({ theme }) => theme.devices.md`
    display: grid;
    gap: 24px;
    grid-template-columns: 1.6fr 1fr;
  `}
`;

export const StyledRow = styled(Row)`
  && {
    height: 100%;
    grid-template-columns: 1fr 1.2fr;
  }
`;

export const Col = styled.div`
  max-height: 100%;
  position: relative;
  overflow: auto;

  ${scroll};
`;

export const ColWrapper = styled.div``;
export const LeftCol = styled(Col)`
  border-right: 1px solid #dfdfe8;
  ${device.sm`
width: 200px;
`}
`;

export const LeftWrapper = styled.div`
  border-right: 1px solid #dfdfe8;

  height: 100%;

  ${device.sm`
  padding-right: 24px;
  `}
`;
export const RightWrapper = styled.div`
  height: 100%;
`;
export const WrapperHeader = styled.div`
  padding-bottom: 16px;
`;

export const WrapperBody = styled.div`
  height: 300px;
`;

export const StyledWrapperBody = styled(WrapperBody)`
  overflow: auto;
  height: 340px;
  ${scroll};
`;

export const WrapperTitle = styled.div`
  display: flex;
  font: 500 14px/1.25 ${({ theme }) => theme.fonts.sans};
`;

export const WrapperSearchbar = styled.div`
  margin-top: 8px;
`;

export const GroupItemCounter = styled.div`
  font: 8px/1.25 ${({ theme }) => theme.fonts.sans};
  border: 1px solid #dfdfe8;
  border-radius: 4px;
  padding: 1px 2px;
  margin-left: 12px;
  color: #1d1d35;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GroupItemName = styled.div`
  color: ${({ theme }) => theme.colors.black};
  overflow: hidden;
  width: 110px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
`;

export const GroupItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 8px;

  ${({ theme, isActive }) =>
    isActive &&
    css`
      background-color: ${theme.colors.green};

      ${GroupItemName} {
        color: ${theme.colors.white};
      }
    `};

  > button {
    border: 0;
    background: none;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    font: 500 12px/1.25 ${({ theme }) => theme.fonts.sans};
    color: ${({ theme }) => theme.colors.gray2};
    text-decoration: none;

    ${({ theme, isActive }) =>
      isActive &&
      css`
        color: ${theme.colors.white};
      `};

    svg {
      margin-right: 8px;
    }
  }

  ${GroupItemCounter} {
    margin-left: auto;

    ${({ theme, isActive }) =>
      isActive &&
      css`
        background-color: ${theme.colors.white};
        color: ${theme.colors.gray2};
      `};
  }
`;

export const ClearButton = styled(Button)`
  && {
    min-height: 1px;
    margin-left: auto;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red};
    text-transform: capitalize;
    font-weight: 400;
    padding: 0;
    > span > span,
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const PropertyItem = styled.div`
  font-size: 14px;

  > span {
    width: 32px;
    height: 32px;
  }
`;

export const PropertyGroup = styled.div``;
export const PropertyGroupName = styled.div`
  font: 500 12px/1.25 ${({ theme }) => theme.fonts.sans};
  color: ${({ theme }) => theme.colors.gray2};
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 2px;
`;
