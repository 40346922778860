import styled from 'styled-components';
import { colors, fonts, scroll } from '@styles';
import { X } from 'react-feather';

export const Body = styled.div``;

export const ErrorMsg = styled.span`
  font: 400 12px/1.25 ${fonts.sans};
  color: ${colors.red};
  width: 100%;
  text-align: end;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FileChoseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: calc(50% - 5px);
  height: 32px;

  margin: 10px 0;

  color: ${colors.green};
  background-color: ${colors.white};
  border: 1px solid ${colors.green};
  border-radius: 4px;

  transition: 0.3s ease-out;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${colors.green};
    border-color: ${colors.green};
  }

  svg {
    margin-right: 10px;
  }

  input {
    display: none;
  }
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin-bottom: 5px;
`;

export const Size = styled.div`
  margin-left: auto;

  color: ${colors.gray};
  font: 300 12px/1.25 ${fonts.sans};
`;

export const Previews = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;

  overflow-x: auto;
  ${scroll};
`;

export const Thumbnail = styled.div`
  min-width: 80px;
  width: 80px;
  height: 80px;
  margin-right: 12px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  border-radius: 4px;
  cursor: pointer;
`;

export const Name = styled.a`
  display: block;
  margin-left: 10px;
  color: ${(props) => (props.error ? colors.red : colors.black)};
  font: 500 12px/1.25 ${fonts.sans};
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 150px);
`;

export const Actions = styled.div`
  margin-left: 20px;
  height: 20px;
  width: 20px;
`;

export const Remove = styled(X)`
  color: ${colors.gray};
  transition: 0.3s ease-out;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${colors.green};
  }
`;

export const Spinner = styled.div`
  min-width: 80px;
  height: 80px;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border-radius: 4px;
  ackground-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
