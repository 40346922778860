import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../colors';
import { fonts } from '../../fonts';

type Size = {
  width?: String;
  height?: String;
};

const radio = (params: Size) => {
  return {
    root: {
      width: params.width || '40px',
      height: params.height || '35px',
      padding: '0',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    icon: {
      borderRadius: '50%',
      width: 20,
      height: 20,
      backgroundColor: '#fff',
      border: '1px solid #C8C8D3',
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5'
      },
      'input:disabled ~ &': {
        boxShadow: 'none'
      },
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2
      }
    },
    checkedIcon: {
      backgroundColor: `${colors.green}`,
      border: 0,
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 20,
        height: 20,
        backgroundImage: 'radial-gradient(#fff,#fff 5px,transparent 6px)',
        content: '""'
      },
      'input:hover ~ &': {
        backgroundColor: `${colors.green}`
      }
    },
    label: {
      color: '#1D1D35',
      font: `500 14px/17px ${fonts.sans}`
    }
  };
};

export default radio;

export const useStylesRadio = makeStyles(radio({}));
