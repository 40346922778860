import styled from 'styled-components';
import { MenuItem as MuiMenuItem } from '@material-ui/core';

import { dropdown } from '@styles';

export const MenuItem = styled(MuiMenuItem)`
  && {
    ${dropdown.item};
    min-width: auto;
    width: calc(100% - 20px);
    margin-left: 10px;
  }
`;
