import React from 'react';
import { X, Tag } from 'react-feather';

import styled from 'styled-components';
import { colors, fonts, getColorWithOpacity } from '@styles';

const Wrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 24px;
  margin: 4px 2px;

  padding: 4px 8px 4px 4px;

  border-radius: 4px;
  background-color: ${(props) => getColorWithOpacity(props.color, 20) || colors.white3};

  font: 400 12px/1.25 ${fonts.sans};
  white-space: nowrap;
  color: ${(props) => getColorWithOpacity(props.color, 80) || colors.black};
  user-select: none;
      
  svg {
    fill: ${(props) => getColorWithOpacity(props.color, 80) || colors.gray};
  }
`;

const Remove = styled(X)<{ labelcolor: string }>`
  color: ${(props) =>
    props.labelcolor
      ? getColorWithOpacity(props.labelcolor, 45)
      : getColorWithOpacity(colors.gray, 45)};
  margin-left: 5px;
  cursor: pointer;
  transition: 0.3s ease-out;

  &:hover,
  &:focus {
    color: ${(props) =>
      props.labelcolor
        ? getColorWithOpacity(props.labelcolor, 100)
        : getColorWithOpacity(colors.gray, 100)};
  }
`;

const Icon = styled(Tag)`
  margin-right: 5px;
`;

interface Props {
  label: Label;
  removeLabelPill(label: Label): void;
}

interface Label {
  labelName: string;
  id: number;
}

const LabelPill: React.FC<Props> = (props: Props) => {
  const { label, removeLabelPill } = props;

  return (
    <Wrapper color={label.color}>
      <Icon size={20} />
      {label.labelName}
      <Remove
        size={15}
        onClick={() => removeLabelPill(label)}
        labelcolor={label.color}
      />
    </Wrapper>
  );
};

export default LabelPill;
