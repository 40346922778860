// @ts-ignore
import styled from 'styled-components';
import { UiSizes, UiStates } from '@enums';
import { defaultTheme } from '@themes';
import { getColorWithOpacity } from '@styles';

// TODO clean up not necessary props
type Props = {
  height?: String;
  width?: String;
  tableInput?: boolean;
  size?: UiSizes;
  helperState?: UiStates;
  noBorder?: boolean;
};

const getColorUsingHelperState = (state:UiStates) => {
  switch (state) {
    case UiStates.WARNING:
      return defaultTheme.colors.yellow;
    case UiStates.ERROR:
      return defaultTheme.colors.red;
    case UiStates.LOADING:
      return defaultTheme.colors.green;
    default:
      return defaultTheme.colors.gray3;
  }
};

export const textField = (props: Props = {}) => {
  const { helperState = UiStates.DEFAULT, noBorder } = props;

  return {
    // TextField classes from .MuiTextField-root
    root: {
      width: '100%',

      // FormControl classes from https://v4.mui.com/api/form-control/#css
      '&.MuiFormControl-root': {

      },
      '&.MuiFormControl-marginNormal': {},
      '&.MuiFormControl-marginDense': {},

      // Input classes from https://v4.mui.com/api/input/
      '& .MuiInputBase-root': {
        background: defaultTheme.colors.white,
      },

      // OutlinedInput classes from https://v4.mui.com/api/outlined-input/
      '& .MuiOutlinedInput-root': {

        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: defaultTheme.colors.green,
            borderWidth: noBorder ? '0px' : '1px'
          }
        },
        '&.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: defaultTheme.colors.red
          }
        },
        '&.Mui-disabled': {
          backgroundColor: '#f9fafb',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: getColorWithOpacity(getColorUsingHelperState(helperState), 60),
            color: getColorWithOpacity(defaultTheme.colors.black, 30)
          }
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: defaultTheme.colors.gray
        }
      },
      '& .Mui-focused': {},

      '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '8px',
      },
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '8px !important',
      },

      '& .MuiInputAdornment-root': {
        '& .MuiIconButton-root': {
          opacity: '0',
        },
        '& > svg': {
          minWidth: '28px',
          height: '28px',
          padding: '4px',
          color: defaultTheme.colors.gray,
        }
      },
      '&:hover .MuiInputAdornment-root': {
        '& .MuiIconButton-root': {
          opacity: '1'
        },

      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: noBorder ? '0px' : '1px',
        borderColor: getColorUsingHelperState(helperState),
        transition: '.3s'
      },
      '& .MuiOutlinedInput-input': {
        font: `500 12px/1.25 ${defaultTheme.fonts.sans}`,
        color: defaultTheme.colors.black,
        padding: '0 8px',
        height: '40px',

        '&::placeholder': {
          font: `italic 12px/1.25 ${defaultTheme.fonts.sans}`,
          color: defaultTheme.colors.gray2,
          opacity: 1
        },
        '&.Mui-disabled': {
          color: getColorWithOpacity(defaultTheme.colors.gray2, 40),
        }
      },
      '& .MuiOutlinedInput-inputMarginDense': {
        height: '32px'
      },
      '& + .MuiFormHelperText-root': {
        position: 'absolute',
        '&.Mui-error': {
          color: defaultTheme.colors.red
        }
      },

      // Label classes from
      '& .MuiInputLabel-root': {

      },

    }
  };
};

export const HelperText = styled.div<{helperState: UiStates, disabled: boolean}>`
  display: flex;
  align-items: center;
  color: ${({ helperState, disabled }) =>
    getColorWithOpacity(getColorUsingHelperState(helperState), disabled ? 30 : 100)};
  

  svg {
    min-width: 10px;
    width: 10px;
    height: 10px;
    margin-right: 7px;    
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;
