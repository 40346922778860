import styled from 'styled-components';
import { colors, device, fonts, sticky } from '@styles';
import { Link } from '@reach/router';

export const Wrapper = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 10px 0 15px;
  border-bottom: 1px solid #dfdfe8;
  ${device.sm`
    padding: 0 25px;
    position: relative;
  `};
  background-color: ${colors.white};
  ${sticky};
  z-index: 101 !important;
`;

export const ToggleButton = styled.div<{ asButton: Boolean }>`
  display: ${(props) => (props.asButton ? 'flex' : 'none')};
  align-items: center;
  height: 20px;

  svg {
    display: block;
    color: ${colors.gray2};
  }

  &:hover,
  &:hover {
    border-color: ${colors.green};
    svg {
      color: ${colors.green};
    }
  }

  ${device.sm`
    display: none;
    cursor: ${(props) => (props.asButton ? 'pointer' : 'auto')};
  `};
`;

export const PageTitle = styled.span`
  font: 400 20px/1.25 ${fonts.sans};
  color: #252631;
  margin-right: auto;

  display: flex;
  align-items: baseline;
`;

export const PageSubTitle = styled.span`
  font: 12px/1.25 ${fonts.sans};

  display: flex;
`;

export const NavbarButtons = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: ${colors.gray};
  &:hover,
  &:focus {
    color: ${colors.green};
  }
`;
export const LogoutButton = styled(NavbarButtons)``;
export const NotificationButton = styled(NavbarButtons)``;

export const NotificationCounter = styled.div`
  position: absolute;
  right: 0px;
  top: 2px;
  background: #d54855;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #fff;
  z-index: 0;
  font-size: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  letter-spacing: -1px;
`;

export const Emoji = styled.div`
  margin-right: 5px;
`;

export const UserSubMenu = styled.div`
  .MuiListItem-gutters {
    padding-left: 8px;
    padding-right: 8px;
  }
  .MuiListItemIcon-root {
    min-width: 16px;
  }

  .MuiTypography-body2 {
    font: 400 12px/14px ${fonts.sans};
  }
`;

export const NavLink = styled(Link)`
  color: ${colors.black};
  text-decoration: none;
`;

export const UserTileWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  > svg {
    flex-shrink: 0;
  }

  > * > * {
    margin-right: 0;
  }
`;

export const CannyButton = styled.a`
  .Canny_BadgeContainer .Canny_Badge {
    background-color: ${colors.red};
    padding: 3px;
    border-color: transparent;
  }
`;
