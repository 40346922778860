import React, { useCallback, useMemo, useState } from 'react';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUser, selectWorkspaceId } from '@state/selectors';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils/roles';
import { Button, ButtonSize, ButtonVariant, IconButton } from '@kit/ui/Button';
import { Clipboard, Edit, Plus, Trash2 } from 'react-feather';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { Loader } from '@kit/ui/Loader';
import { Action, ActionStatus, ActionsOrderBy } from '@generated/types/graphql';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { MoreVerticalIcon } from '@kit/ui/icons/MoreVertical';
import { Checkbox } from '@kit/ui/Checkbox';
import moment from 'moment';
import { parseUtcDate } from '@utils/dates';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { XIcon } from '@kit/ui/icons/X';
import { Tooltip } from '@material-ui/core';
import { ContactAvatar } from '@kit/components/ContactAvatar';

import { ActionItemListItem, useActionItemList } from '@hooks/actionItems/useActionItemList';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { ProjectStageBadge } from '@components/ProjectStages';
import { FileUpIcon } from '@kit/ui/icons/FileUp';
import { useDeleteActionItem } from '@hooks/actionItems/useDeleteActionItem';
import { ActionItemStatus } from '@kit/components/ActionItemStatus';
import { CreateActionItemForm } from '../CreateActionItemForm';
import { SortByDirection, SortByField } from './types';
import {
  Assignee,
  CheckboxContainer,
  GroupCounter,
  ListHeader,
  LoaderWrapper,
  Placeholder,
  Row,
  SelectionControl,
  TableEmptyPlaceholder,
  Td,
  TitleCell,
  TitleCellIconOrCheckbox,
  TitleCellInner
} from './styled';
import { useSelection } from './useSelection';
import { TableHeader } from './TableHeader';
import { Group } from './Group';

interface Props {
  recordId: number;
  isArchivedShown: boolean;
  search: string;
  sortBy: { field: SortByField; direction: SortByDirection };
}

const formatDate = (date: string, isAllDay: boolean) => {
  const format = isAllDay ? 'MM/DD/YY' : 'MM/DD/YY h:mma';

  if (!date) {
    return '-';
  }

  return moment(parseUtcDate(date)).format(format).replace(':00', '');
};
const COLUMNS: { id: string; name: string; width: string }[] = [
  { id: 'assignee', name: 'Assignee', width: '20%' },
  { id: 'stage', name: 'Stage', width: '20%' },
  { id: 'dueDate', name: 'Due date', width: '20%' }
];

const ACTION_STATUSES = [ActionStatus.Requested, ActionStatus.InReview, ActionStatus.Completed, ActionStatus.Cancelled];

const ActionRow = ({
  canBulkEdit,
  action,
  isSelected,
  onToggleSelected,
  onClick
}: {
  canBulkEdit: boolean;
  action: Action;
  isSelected: boolean;
  onToggleSelected: (id: number) => void;
  onClick: (id: number) => void;
}) => {
  const { mutateAsync: remove } = useDeleteActionItem();
  const confirmDelete = useConfirmDeleteModal();
  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number } | null>(null);

  const handleToggleSelected = useCallback(() => {
    onToggleSelected(action.id);
  }, [action.id, onToggleSelected]);

  const handleClick = useCallback(() => {
    onClick(action.id);
  }, [action.id, onClick]);

  const handleContextMenu = useCallback<React.MouseEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();

    setContextMenuPosition({
      x: e.pageX,
      y: e.pageY
    });
  }, []);

  const handleCloseContextMenu = useCallback(() => {
    setContextMenuPosition(null);
  }, []);

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        title: 'Delete forever',
        icon: <Trash2 size="16px" color="#D54855" />,
        onClick: async () => {
          if (await confirmDelete(`Are you sure you want to delete this Action Item?`)) {
            await remove({ id: action.id });
          }
        }
      }
    ];
  }, [action, confirmDelete, remove]);

  const firstAssignee = action.actionAssignees[0]?.contact;

  return (
    <Row isSelected={isSelected} onClick={handleClick} onContextMenu={handleContextMenu}>
      <TitleCell isSelected={isSelected}>
        <TitleCellInner>
          <TitleCellIconOrCheckbox>
            {canBulkEdit && (
              <CheckboxContainer>
                <Checkbox isChecked={isSelected} onChange={handleToggleSelected} />
              </CheckboxContainer>
            )}

            <FileUpIcon size="24px" color="#9C9CAA" />
          </TitleCellIconOrCheckbox>
          {action.title}
        </TitleCellInner>
      </TitleCell>
      <Td width="20%">
        {firstAssignee && (
          <Assignee>
            <ContactAvatar contact={firstAssignee} />
            <div>{firstAssignee.name}</div>
          </Assignee>
        )}
        {!firstAssignee && '-'}
      </Td>
      <Td width="20%">{action.projectStage && <ProjectStageBadge stage={action.projectStage} />}</Td>
      <Td width="20%">{formatDate(action.dueDate, true)}</Td>
      <Td
        width="5%"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ContextMenu point={contextMenuPosition} items={menuItems} onClose={handleCloseContextMenu}>
          <MoreVerticalIcon size="24px" color="#9C9CAA" />
        </ContextMenu>
      </Td>
    </Row>
  );
};

const mapSortByToActionOrderBy = (sortBy: { field: SortByField; direction: SortByDirection }): ActionsOrderBy => {
  switch (sortBy.field) {
    case 'endDate':
      return sortBy.direction === 'ASC' ? ActionsOrderBy.DueDateAsc : ActionsOrderBy.DueDateDesc;
    case 'createdAt':
    case 'startDate':
      return sortBy.direction === 'ASC' ? ActionsOrderBy.CreatedAtAsc : ActionsOrderBy.CreatedAtDesc;
    case 'stage':
      return sortBy.direction === 'ASC' ? ActionsOrderBy.ProjectStageIdAsc : ActionsOrderBy.ProjectStageIdDesc;
    default:
      return ActionsOrderBy.CreatedAtDesc;
  }
};

export const ActionItems = ({ recordId, isArchivedShown: _isArchivedShown, search, sortBy }: Props) => {
  const { data: record } = useRecordDetail(recordId, { refetchOnMount: false });
  const companyId = useAppSelector(selectWorkspaceId);
  const user = useAppSelector(selectCurrentUser);
  const { data: access } = useUserRoleSettings(companyId, user.userId);

  const { openModal } = useModal();

  const { openDrawer } = useDrawersContext();

  const { isItemSelected, toggleGroup, toggleItem, selectedIds, isGroupSelected, clearSelection } = useSelection();

  const handleAddClick = useCallback(async () => {
    const result = await openModal<void | number>(
      ({ onClose }) => <CreateActionItemForm initialRecord={record} onClose={onClose} />,
      {
        title: 'Create Action Item'
      }
    );

    if (result) {
      openDrawer(DrawerEntity.ACTION_ITEM, result, []);
    }
  }, [record, openModal, openDrawer]);

  const { data: actionItems = [], isLoading } = useActionItemList(recordId, mapSortByToActionOrderBy(sortBy));

  const groupedByStatus = useMemo(() => {
    return actionItems.reduce(
      (acc, actionItem) => {
        if (!acc[actionItem.status]) {
          acc[actionItem.status] = [];
        }

        acc[actionItem.status].push(actionItem);

        return acc;
      },
      {} as Record<ActionStatus, ActionItemListItem[]>
    );
  }, [actionItems]);

  const groupedAndFiltered = useMemo(() => {
    return Object.keys(groupedByStatus).reduce(
      (acc, status) => {
        const filtered = groupedByStatus[status as ActionStatus].filter((task) =>
          task.title.toLowerCase().includes(search.toLowerCase())
        );

        if (filtered.length) {
          acc[status as ActionStatus] = filtered;
        }

        return acc;
      },
      {} as Record<ActionStatus, ActionItemListItem[]>
    );
  }, [groupedByStatus, search]);

  const actionItemIds = useMemo(() => {
    return actionItems.map((item) => item.id);
  }, [actionItems]);

  const handleRowClick = useCallback(
    (id: number) => {
      openDrawer(DrawerEntity.ACTION_ITEM, id, actionItemIds);
    },
    [actionItemIds, openDrawer]
  );

  const hasSelected = selectedIds.length > 0;

  const hasAnyItem = Object.keys(groupedAndFiltered).length > 0;

  const canBulkEdit = false; // TODO if BE will support bulk edit

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <div>
      <ListHeader>
        Action Items ({actionItems.length})
        {!hasSelected && hasAccess(access, 'task', 'create') && (
          <Button variant={ButtonVariant.Primary} onClick={handleAddClick}>
            <Plus size="16px" />
            Action Item
          </Button>
        )}
        {hasSelected && (
          <SelectionControl>
            <IconButton onClick={clearSelection} variant={ButtonVariant.Secondary} size={ButtonSize.Small}>
              <XIcon size="16px" />
            </IconButton>

            <div>{selectedIds.length} selected:</div>

            <Tooltip title="Edit">
              <IconButton variant={ButtonVariant.Flat} size={ButtonSize.Small}>
                <Edit size="16px" />
              </IconButton>
            </Tooltip>
          </SelectionControl>
        )}
      </ListHeader>

      {actionItems.length === 0 && <Placeholder>No Action Items yet</Placeholder>}

      {actionItems.length > 0 && (
        <TableHeader
          isAllSelected={selectedIds.length === actionItems.length}
          onToggleAllSelected={(isChecked) => toggleGroup(actionItemIds, isChecked)}
          columns={COLUMNS}
          canBulkEdit={canBulkEdit}
        />
      )}

      {actionItems.length > 0 && !hasAnyItem && <TableEmptyPlaceholder>No Action Items found</TableEmptyPlaceholder>}

      {actionItems.length > 0 &&
        ACTION_STATUSES.filter((status) => groupedAndFiltered[status]).map((status) => (
          <Group
            key={status}
            canBulkEdit={canBulkEdit}
            isAllSelected={isGroupSelected(groupedByStatus[status].map((action) => action.id))}
            onToggleAllSelected={(isChecked) =>
              toggleGroup(
                groupedByStatus[status].map((action) => action.id),
                isChecked
              )
            }
            renderGroupHeader={() => (
              <>
                <ActionItemStatus status={status} />
                <Clipboard size="16px" color="#9C9CAA" />
                <GroupCounter>{groupedByStatus[status].length}</GroupCounter>
              </>
            )}
            columns={COLUMNS}
          >
            {groupedAndFiltered[status].map((action) => (
              <ActionRow
                canBulkEdit={canBulkEdit}
                key={action.id}
                action={action}
                isSelected={isItemSelected(action.id)}
                onToggleSelected={toggleItem}
                onClick={handleRowClick}
              />
            ))}
          </Group>
        ))}
    </div>
  );
};
