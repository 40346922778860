import React from 'react';
import { ArrowDown, ArrowUp } from 'react-feather';

interface IUpDownNavigationGuideType {
  style?: React.CSSProperties;
}

import styled from 'styled-components';
import { colors, fonts } from '../../../styles';

const Wrapper = styled.div`
  position: sticky;
  bottom: 0;
  
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 40px;

  margin: 10px 0 0;  
  padding: 10px;
  
  background-color: ${colors.gray3};
  
  font: 12px/1.25 ${fonts.sans};
  color: ${colors.gray};
  
  span {
    color: ${colors.green};
  }
`;

const Right = styled.div`
  margin-left: auto;
`;

const UpDownNavigationGuide: React.FC<IUpDownNavigationGuideType> = ({
  style = {}
}): JSX.Element => {
  return (
    <Wrapper>
      <ArrowUp color={colors.green} size="20" />
      <ArrowDown color={colors.green} size="20" />
      to navigate
      <Right>
        <span>ESC</span> to cancel
      </Right>
    </Wrapper>
  );
};

export default UpDownNavigationGuide;
