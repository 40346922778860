import React, { useMemo, useState } from 'react';
import { Widget, WidgetHeader, WidgetIcon, WidgetLastUpdated, WidgetTitle } from '../styled';
import { SunIcon } from '@kit/ui/icons/Sun';
import {
  Chart,
  ChartContainer,
  ChartControls,
  ChartDateLabel,
  ChartLegend,
  ChartLegendLabel,
  ChartLegendLabels,
  ChartTitle,
  ContentContainer,
  ContentSeparator,
  PeriodArrows,
  PeriodButton,
  PeriodControls,
  PeriodSelector,
  PowerFlowContainer,
  PowerFlowGrid,
  PowerFlowItemBottom,
  PowerFlowItemCenter,
  PowerFlowItemLeft,
  PowerFlowItemName,
  PowerFlowItemRight,
  PowerFlowItemTop,
  PowerFlowItemValue,
  PowerFlowItemValues,
  PowerFlowLabel
} from './styled';
import { ArrowLeft, ArrowRight, BatteryCharging, Circle, Home } from 'react-feather';
import { ChartPeriod, chartPeriodDuration, chartPeriodName, getObservedInterval } from '@hooks/systems';
import { CoperniqIcon } from '@kit/ui/icons/Coperniq';
import { Box } from '@material-ui/core';
import { TransmissionTowerIcon } from '@kit/ui/icons/TransmissionTower';
import { SolarPowerIcon } from '@kit/ui/icons/SolarPower';
import { useSystemHealthChart } from '@hooks/systems/useSystemHealthChart';
import { formatEnergy, gapfillPoints } from '@features/SystemPortfolio/utils';
import { EnergyChart } from '@features/SystemPortfolio/components/EnergyChart';
import { DateTime, Interval } from 'luxon';
import { Legend } from 'recharts';
import { ButtonSize, ButtonVariant, IconButton } from '@kit/ui/Button';
import { System } from '@generated/types/graphql';
import { capitalize } from 'lodash';
import { Switch, SwitchVariant } from '@kit/ui/Switch';
import { ArrowThinIcon } from '@kit/ui/icons/ArrowThin';
import { ArrowsThinIcon } from '@kit/ui/icons/ArrowsThin';

const defaultPeriod = ChartPeriod.day;

type Props = {
  system: System;
};

export const SystemHealthWidget = ({ system }: Props) => {
  const earliestDate = DateTime.fromISO(system.operationalAt || system.createdAt);

  const [period, setPeriod] = useState<ChartPeriod>(defaultPeriod);

  const [anchorTime, setAnchorTime] = useState<DateTime>(DateTime.now().minus({ days: 1 }));

  const observedInterval = useMemo(() => getObservedInterval(anchorTime, period), [anchorTime, period]);

  const hasPrevPeriod = useMemo(
    () => observedInterval && +observedInterval.start >= +earliestDate,
    [observedInterval, earliestDate]
  );

  const hasNextPeriod = useMemo(() => observedInterval && +observedInterval.end <= +DateTime.now(), [observedInterval]);

  const goPrevInterval = () => {
    setAnchorTime(anchorTime.minus(chartPeriodDuration[period]));
  };

  const goNextInterval = () => {
    setAnchorTime(anchorTime.plus(chartPeriodDuration[period]));
  };

  const [showPower, setShowPower] = useState(false);

  const {
    data: { totalProduction, totalConsumption, totalExport, totalImport, totalCharge, totalDischarge, energy, power }
  } = useSystemHealthChart(system?.uuid, period, observedInterval?.start, observedInterval?.end);

  const gapfilledEnergy = useMemo(
    () => gapfillPoints(energy, period, observedInterval),
    [energy, period, observedInterval]
  );

  const gapfilledPower = useMemo(
    () => gapfillPoints(power, period, observedInterval),
    [power, period, observedInterval]
  );

  const actualInterval = useMemo(
    () =>
      Interval.fromDateTimes(observedInterval?.start || earliestDate, observedInterval?.end.minus(1) || DateTime.now()),
    [observedInterval, earliestDate]
  );

  if (!system) {
    return null;
  }

  return (
    <Widget size="large" isFullWidth>
      <WidgetHeader>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#f1aa12">
            <SunIcon size="16px" color="#ffffff" />
          </WidgetIcon>
          System health
        </WidgetTitle>

        <WidgetLastUpdated>
          Last updated:{' '}
          {system.lastReportAt
            ? DateTime.fromISO(system.lastReportAt).toLocaleString(DateTime.DATETIME_SHORT)
            : 'never'}
        </WidgetLastUpdated>
      </WidgetHeader>

      <ContentContainer>
        <PowerFlowContainer>
          <PowerFlowLabel>Energy flow</PowerFlowLabel>

          <PowerFlowGrid>
            <PowerFlowItemTop>
              <SolarPowerIcon size={24} color="#9c9caa" />
              <PowerFlowItemValues>
                <PowerFlowItemName>Solar</PowerFlowItemName>
                <PowerFlowItemValue>{formatEnergy(totalProduction)}</PowerFlowItemValue>
              </PowerFlowItemValues>
            </PowerFlowItemTop>

            <PowerFlowItemLeft>
              <Home size={24} color="#9c9caa" />
              <PowerFlowItemValues>
                <PowerFlowItemName>Home</PowerFlowItemName>
                <PowerFlowItemValue>{formatEnergy(totalConsumption)}</PowerFlowItemValue>
              </PowerFlowItemValues>
            </PowerFlowItemLeft>

            <PowerFlowItemCenter>
              <PowerFlowGrid>
                <ArrowThinIcon style={{ gridArea: 'top', transform: 'rotate(-90deg)' }} size={24} color="#dfdfe8" />
                <ArrowThinIcon style={{ gridArea: 'left' }} size={24} color="#dfdfe8" />
                <Box display="flex" style={{ gridArea: 'center' }}>
                  <CoperniqIcon size={28} />
                </Box>
                <ArrowsThinIcon style={{ gridArea: 'right' }} size={24} color="#dfdfe8" />
                <ArrowsThinIcon style={{ gridArea: 'bottom', transform: 'rotate(-90deg)' }} size={24} color="#dfdfe8" />
              </PowerFlowGrid>
            </PowerFlowItemCenter>

            <PowerFlowItemRight>
              <TransmissionTowerIcon size={24} color="#9c9caa" />
              <PowerFlowItemValues>
                <PowerFlowItemName>Grid</PowerFlowItemName>
                <PowerFlowItemValue>{formatEnergy(totalImport)} in</PowerFlowItemValue>
                <PowerFlowItemValue>{formatEnergy(totalExport)} out</PowerFlowItemValue>
              </PowerFlowItemValues>
            </PowerFlowItemRight>

            <PowerFlowItemBottom>
              <BatteryCharging size={24} color="#9c9caa" transform="rotate(-90)" />
              <PowerFlowItemValues>
                <PowerFlowItemName>Battery</PowerFlowItemName>
                <PowerFlowItemValue>{formatEnergy(totalCharge)} charge</PowerFlowItemValue>
                <PowerFlowItemValue>{formatEnergy(totalDischarge)} discharge</PowerFlowItemValue>
              </PowerFlowItemValues>
            </PowerFlowItemBottom>
          </PowerFlowGrid>
        </PowerFlowContainer>

        <ContentSeparator />

        <ChartContainer>
          <ChartTitle>Production & consumption</ChartTitle>

          <ChartControls>
            <Switch
              variant={SwitchVariant.TwoWay}
              label="Power"
              secondLabel="Energy"
              isActive={showPower}
              onChange={(showPower) => setShowPower(showPower)}
            />

            <PeriodControls>
              <PeriodSelector value={period} exclusive onChange={(_, value: ChartPeriod) => value && setPeriod(value)}>
                {Object.values(ChartPeriod).map((period) => (
                  <PeriodButton key={period} value={period}>
                    {chartPeriodName[period]}
                  </PeriodButton>
                ))}
              </PeriodSelector>

              <PeriodArrows>
                <IconButton
                  variant={ButtonVariant.Flat}
                  size={ButtonSize.Small}
                  onClick={goPrevInterval}
                  disabled={!hasPrevPeriod}
                >
                  <ArrowLeft size={24} />
                </IconButton>

                <IconButton
                  variant={ButtonVariant.Flat}
                  size={ButtonSize.Small}
                  onClick={goNextInterval}
                  disabled={!hasNextPeriod}
                >
                  <ArrowRight size={24} />
                </IconButton>
              </PeriodArrows>
            </PeriodControls>
          </ChartControls>

          <Chart>
            <EnergyChart
              points={showPower ? gapfilledPower : gapfilledEnergy}
              period={period}
              enabled={{ production: !showPower, consumption: !showPower, power: showPower }}
            >
              <Legend
                verticalAlign="top"
                align="center"
                content={({ payload }) => (
                  <ChartLegend>
                    <ChartLegendLabels>
                      {payload.map(({ value, color }) => (
                        <ChartLegendLabel key={value}>
                          <Circle size={8} fill={color} stroke={color} />
                          {capitalize(value)}
                        </ChartLegendLabel>
                      ))}
                    </ChartLegendLabels>

                    <ChartDateLabel>{actualInterval.toLocaleString(DateTime.DATE_SHORT)}</ChartDateLabel>
                  </ChartLegend>
                )}
              />
            </EnergyChart>
          </Chart>
        </ChartContainer>
      </ContentContainer>
    </Widget>
  );
};
