import { renderPropertyIcon } from '@common/Properties';
import { PropertyValue } from '@common/Properties/PropertyValue';
import { CopyButton } from '@common/ui';
import { ValueHoverableWrapper, ValueWrapper } from '@components/Project/Properties/styled';
import { DEFAULT_GROUP_ID, usePropertyGroups } from '@hooks/usePropertyGroup';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { useRecordsMutations } from '@hooks/useRecords';
import { ViewportList } from 'react-viewport-list';

import { RecordType } from '@types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useEventListener } from '@react-hookz/web';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import {
  isEditable,
  getCustomDTOValue,
  sortAndFilterStandardProperties,
  isReferredBy,
  isButtonOrLink
} from '@utils/properties';
import { SearchBar } from '@common/SearchBar';
import { debounce } from 'lodash';
import { Form, FormValidationRules, useForm } from '@kit/components/Form';
import { PropertyValueField } from '@features/Platform/Properties/PropertyValueField';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useModal } from '@common/PromiseModal';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { validateUrl } from '@utils/validations';
import { getDefaultPropertyValue } from './PropertyForm';
import {
  Container,
  Content,
  Group,
  GroupTitle,
  PropertiesGrid,
  PropertyName,
  EditableFieldContainer,
  PropertyContainer,
  ContentContainer,
  SearchContainer,
  HeaderButtons,
  PropertiesRow
} from './styled';
import { Details, DetailsContent, DetailsHeader, DetailsTitle } from '../../styled';
import { ConfirmClosing } from './ConfirmClosing';
import { NavSidebar } from './NavSidebar';

interface Props {
  projectId: number;
  recordType: RecordType;
  onClose: () => void;
  className?: string;
}

export const useRecordWithProperties = (projectId: number) => {
  const { data: detail } = useRecordDetail(projectId, { refetchOnMount: false });

  return useMemo(() => {
    if (!detail || !detail.id) {
      return null;
    }

    return {
      projectId,
      projectDetail: detail,
      memberList: detail.projectMembers,
      projectProgress: 0
    };
  }, [detail, projectId]);
};

// TODO types
export const Property = ({ projectId, property, control }: any) => {
  const project = useRecordWithProperties(projectId);

  return (
    <PropertyContainer isFullWidth={property.mappedName === 'description'}>
      <PropertyName>{property.name}</PropertyName>

      {isEditable(property) && (
        <PropertyValueField
          name={property.isAdditional ? `additional.${property.id}` : property.mappedName}
          property={property}
          control={control}
        />
      )}

      {!isEditable(property) && (
        <PropertyValue
          withTooltip
          noTitleInTooltip
          NameWrapper={({ children }) => children}
          project={project}
          property={property}
          // eslint-disable-next-line
          MainWrapper={(props) => {
            return (
              <EditableFieldContainer isReadOnly>
                <ValueHoverableWrapper container>
                  {property.mappedName === 'description' ? (
                    <ValueWrapper {...props} style={{ fontWeight: 400 }}>
                      <div dangerouslySetInnerHTML={{ __html: project.projectDetail.description }} />
                    </ValueWrapper>
                  ) : (
                    <ValueWrapper
                      {...props}
                      isOverflowAllowed={isReferredBy(property)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 4,
                        fontWeight: 400,
                        color: '#828d9a'
                      }}
                    >
                      {props.formattedValue
                        ? renderPropertyIcon({
                            property,
                            style: { width: 16, height: 16 }
                          })
                        : '-'}
                      {props.children}
                    </ValueWrapper>
                  )}
                  {props.formattedValue ? <CopyButton value={props.formattedValue} size={20} /> : null}
                </ValueHoverableWrapper>
              </EditableFieldContainer>
            );
          }}
        />
      )}
    </PropertyContainer>
  );
};

const GroupProperties = ({ projectId, tab, recordType, control, viewportRef, useVirtualization }: any) => {
  const nodeRef = useRef();

  const properties3PerRow = useMemo(() => {
    const result = [];

    for (let i = 0; i < tab.properties.length; i += 3) {
      result.push(tab.properties.slice(i, i + 3));
    }

    return result;
  }, [tab]);

  return (
    <Group ref={nodeRef} data-properties-group={tab.id} id={`properties_group_${tab.id}`}>
      <GroupTitle>{tab.title}</GroupTitle>

      <PropertiesGrid>
        {useVirtualization && (
          <ViewportList viewportRef={viewportRef} items={properties3PerRow}>
            {(row, index) => (
              <PropertiesRow key={index}>
                {row.map((property) => (
                  <Property
                    control={control}
                    recordType={recordType}
                    projectId={projectId}
                    key={property.id}
                    property={property}
                  />
                ))}
              </PropertiesRow>
            )}
          </ViewportList>
        )}

        {!useVirtualization &&
          properties3PerRow.map((row, index) => (
            <PropertiesRow key={index}>
              {row.map((property) => (
                <Property
                  control={control}
                  recordType={recordType}
                  projectId={projectId}
                  key={property.id}
                  property={property}
                />
              ))}
            </PropertiesRow>
          ))}
      </PropertiesGrid>
    </Group>
  );
};

const getDefaultValues = ({ project, properties, companyUsers }: any) => {
  const result = {
    additional: {}
  };

  properties.forEach((property) => {
    if (property.isAdditional) {
      result.additional[property.id] = getDefaultPropertyValue({ project, property, companyUsers });
    } else {
      result[property.mappedName] = getDefaultPropertyValue({ project, property, companyUsers });
    }
  });

  return result;
};

export const EditProperties = ({ projectId, recordType, onClose, className }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const project = useRecordWithProperties(projectId);
  const companyId = useAppSelector(selectWorkspaceId);
  const { update: updateRecord } = useRecordsMutations(companyId, recordType);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchValueChangeDebounced = useCallback(debounce(setSearchValue, 300), []);
  const { data: companyUsers } = useAllCompaniesUsers();
  const { scopeToEditableColumns } = useCompanyProperties({ recordType, fullAccess: false });
  const defaultValues = useMemo(() => {
    if (!project) {
      return {};
    }

    return getDefaultValues({ project, properties: scopeToEditableColumns[recordType], companyUsers });
  }, [project, scopeToEditableColumns, recordType, companyUsers]);

  const additionalPropertiesById = useMemo(() => {
    return scopeToEditableColumns[recordType]
      .filter((property) => property.isAdditional)
      .reduce((acc, property) => {
        acc[property.id] = property;

        return acc;
      }, {});
  }, [scopeToEditableColumns, recordType]);

  const postForm = async (values: any, methods) => {
    const dto = {};

    const changedFields = Object.keys(methods.formState.dirtyFields);

    if (changedFields.length > 0) {
      changedFields.forEach((key) => {
        if (key === 'additional') {
          if (!dto.additional) {
            dto.additional = {};
          }

          Object.keys(methods.formState.dirtyFields.additional).forEach((propId) => {
            dto.additional[propId] = getCustomDTOValue(
              additionalPropertiesById[propId].type,
              values.additional[propId]
            );
          });
        } else if (key === 'address') {
          dto[key] = values[key] ? [values[key]] : [];
        } else if (['projectManagerId', 'salesRepId', 'ownerId', 'status'].includes(key)) {
          dto[key] = values[key]?.id ?? null;
        } else {
          dto[key] = values[key];
        }
      });

      await updateRecord.mutateAsync({ id: projectId, dto });
    }
    onClose();
  };

  const { openModal } = useModal();

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    mode: 'onBlur',
    defaultValues
  });

  const {
    formState: { isSubmitting, isDirty }
  } = form;

  useEventListener(window, 'beforeunload', (e) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = '';
    }
  });

  const handleClose = useCallback(() => {
    if (!isDirty) {
      onClose();

      return;
    }

    openModal<void>(
      ({ onClose: onConfirmClose }) => (
        <ConfirmClosing
          onConfirmClosing={() => {
            onConfirmClose();
            onClose();
          }}
          onCancelClosing={onConfirmClose}
          onSave={() => {
            onConfirmClose();
            handleSubmit();
          }}
        />
      ),
      {
        title: 'Unsaved changes'
      }
    );
  }, [onClose, isDirty, openModal, handleSubmit]);

  const record = useRecordWithProperties(projectId);
  const { data: groups } = usePropertyGroups({ scope: recordType, fullAccess: false });
  const tabs = useMemo(() => {
    if (!groups || !record) {
      return [];
    }

    return groups
      .map((group) => ({
        id: group.id,
        title: group.name,
        ...group,
        properties: (group.id === DEFAULT_GROUP_ID
          ? sortAndFilterStandardProperties(group.properties)
          : group.properties
        ).filter((property) =>
          searchValue.trim() ? property.name.toLowerCase().includes(searchValue.trim().toLowerCase()) : true
        )
      }))
      .filter((group) => group.properties.length > 0);
  }, [groups, record, searchValue]);

  const totalPropertiesCount = useMemo(
    () => scopeToEditableColumns[recordType].length,
    [scopeToEditableColumns, recordType]
  );

  const rules = useMemo<FormValidationRules<any>>(
    () =>
      tabs.reduce((acc, tab) => {
        const linkProperties = tab.properties.filter(isButtonOrLink);

        return {
          ...acc,
          ...linkProperties.reduce((acc2, property) => {
            return {
              ...acc2,
              [property.isAdditional ? `additional.${property.id}` : property.mappedName]: {
                validate: validateUrl
              }
            };
          }, {})
        };
      }, {}),
    [tabs]
  );

  if (!record) {
    return null;
  }

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Details className={className}>
        <DetailsHeader>
          <DetailsTitle>Properties</DetailsTitle>
          <HeaderButtons>
            <Button
              disabled={!isDirty || isSubmitting}
              variant={ButtonVariant.Primary}
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button variant={ButtonVariant.Secondary} onClick={handleClose}>
              Close
            </Button>
          </HeaderButtons>
        </DetailsHeader>
        <DetailsContent>
          <Container>
            <SearchContainer>
              <SearchBar placeholder="Search properties..." onValueChange={handleSearchValueChangeDebounced} />
            </SearchContainer>
            <ContentContainer ref={scrollContainerRef}>
              <Content>
                {tabs.map((tab) => (
                  <GroupProperties
                    key={tab.id}
                    tab={tab}
                    projectId={record.projectId}
                    recordType={recordType}
                    control={form.control}
                    viewportRef={scrollContainerRef}
                    useVirtualization={totalPropertiesCount > 100}
                  />
                ))}
              </Content>
              <NavSidebar tabs={tabs} />
            </ContentContainer>
          </Container>
        </DetailsContent>
      </Details>
    </Form>
  );
};
