import { getColorWithOpacity } from '@styles';
import { defaultTheme } from '@themes';
import { SizeEnum } from './type';

export const checkbox = ({ size }: {size: SizeEnum }) => {
  return {
    // .MuiCheckbox-root Styles applied to the root element.
    root: {
      position: 'relative',

      '& svg': {
        borderRadius: size === SizeEnum.SMALL ? '3px' : '2px',
        transform: size === SizeEnum.SMALL ? 'scale(.7)' : 'none'
      },
      '&:hover, &:focus': {
        backgroundColor: getColorWithOpacity(defaultTheme.colors.white, 20)
      }
    },
    // .Mui-checked Pseudo-class applied to the root element if checked={true}.
    checked: {},
    // .Mui-disabled Pseudo-class applied to the root element if disabled={true}.
    disabled: {
      opacity: '0.5'
    },
    // .MuiCheckbox-indeterminate Pseudo-class applied to the root element if indeterminate={true}.
    indeterminate: {},
    // .MuiCheckbox-colorPrimary Styles applied to the root element if color="primary".
    colorPrimary: {
      color: defaultTheme.colors.gray,
      '&$checked': {
        color: defaultTheme.colors.green
      },
      '&$indeterminate': {
        color: defaultTheme.colors.red
      }
    },
    // .MuiCheckbox-colorSecondary Styles applied to the root element if color="secondary".
    colorSecondary: {}
  };
};
