import React from 'react';
import Props from './type';

import styled from 'styled-components';
import { colors, fonts } from '../../../styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const Img = styled.img`
  width: 270px;
  margin: 20px 0;
`;

const Title = styled.span`
  margin: 10px;
  font: 500 20px/1.25 ${fonts.sans};
  color: ${colors.black};
  text-align: center;
`;

const Subtitle = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  font: 400 14px/1.5 ${fonts.sans};
  color: ${colors.gray};

  strong {
    color: ${colors.green};
    padding: 0 5px;
  }
`;

const StateMessege: React.FC<Props> = (props) => {
  const { img, title, subTitle, className } = props;
  return (
    <Wrapper className={className}>
      {/*{img && <Img src={img} />}*/}
      <Title>{title}</Title>
      <Subtitle>{subTitle}</Subtitle>
    </Wrapper>
  );
};

export default StateMessege;
