import { fonts } from '@styles';
import styled from 'styled-components';
import { WorkOrderStatus as WorkOrderStatusComponent } from '@components/WorkOrder/WorkOrderStatus';

export const Container = styled.div`
  &&& {
    th {
      color: #828d9a;
    }

    th {
      font-weight: 400;
    }

    th,
    td {
      padding-left: 0;
      font-size: 12px;
      font-family: ${fonts.sans};
    }

    td {
      border-bottom: 0;
    }

    .skeleton-cell span {
      display: inline-flex;
    }
  }
`;

export const WorkOrderStatus = styled(WorkOrderStatusComponent)`
  display: inline-flex;
`;
