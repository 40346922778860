import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { ReactQueryKey } from '@enums';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import rolesAPI from '@services/api/rolesAPI';
import { errorHandler } from '@services/api/helpers';
import { RoleDTO, RoleSettingsDTO } from '@types';
import { EMPTY_ROLE_SETTINGS } from '@utils';
import { assign } from 'lodash';

const keys = {
  byCompany: (companyId?: number) =>
    [ReactQueryKey.WorkspaceRoles, `company-${companyId}`],
  byUser: (userId?: number) =>
    [ReactQueryKey.WorkspaceRoles, `user-${userId}`],
  byCompanyAndUser: (companyId?: number, userId?: number) =>
    [ReactQueryKey.WorkspaceRoles, `company-${companyId}`, `user-${userId}`],
};

export const useRoles = () => {
  const queryClient = useQueryClient();

  const companyId = useAppSelector(selectWorkspaceId)!;

  const findQuery = useQuery(
    keys.byCompany(companyId),
    async () => {
      try {
        return (await rolesAPI.findAll(companyId)).data;
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      enabled: !!companyId,
      initialData: [],
      staleTime: 1000 * 3600 * 24,
      initialDataUpdatedAt: Date.now() - 1000 * 3600 * 24,
    }
  );

  const createMutation = useMutation<RoleDTO, Error, Partial<RoleDTO>>(
    async (dto: Partial<RoleDTO>) => {
      try {
        return await rolesAPI.create(companyId, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceRoles);
      }
    }
  );

  const updateMutation = useMutation<RoleDTO, Error, { roleId: number, dto: Partial<RoleDTO> }>(
    async ({ roleId, dto }) => {
      try {
        return await rolesAPI.update(companyId, roleId, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceRoles);
      }
    }
  );

  const deleteMutation = useMutation<RoleDTO, Error, number>(
    async (roleId) => {
      try {
        return await rolesAPI.remove(companyId, roleId);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceRoles);
      }
    }
  );

  return {
    rolesQuery: findQuery,
    createRole: createMutation,
    updateRole: updateMutation,
    deleteRole: deleteMutation
  };
};

export const useUserRoleSettings = (companyId?: number, userId?: number) => {
  return useQuery(
    keys.byCompanyAndUser(companyId, userId),
    async () => {
      try {
        return (await rolesAPI.getAccessRules(companyId!)).data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      enabled: !!companyId && !!userId,
      initialData: EMPTY_ROLE_SETTINGS,
      staleTime: 1000 * 3600 * 24,
      initialDataUpdatedAt: Date.now() - 1000 * 3600 * 24,
    }
  );
};

export const useUserAllRolesSettings = (userId?: number): UseQueryResult<{ [companyId: number]: RoleSettingsDTO }> => {
  return useQuery(
    keys.byUser(userId),
    async () => {
      try {
        return (await rolesAPI.getAllAccessRules()).data
          .reduce((acc, { companyId, settings }) => assign(acc, { [companyId]: settings }), {});
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      enabled: !!userId,
      initialData: {},
      staleTime: 1000 * 3600 * 24,
      initialDataUpdatedAt: Date.now() - 1000 * 3600 * 24,
    }
  );
};
