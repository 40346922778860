import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Clipboard, Plus, X } from 'react-feather';
import { useSystem } from '@hooks/systems/useSystem';
import { SystemHealthWidget } from '@features/SystemPortfolio/System/Overview/SystemHealth';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { useUserRoleSettings } from '@hooks/useRoles';
import { useAppSelector } from '@hooks/index';
import { selectCurrentUserId, selectWorkspaceId } from '@state/selectors';
import { hasAccess } from '@utils/roles';
import { stopPropagationClick } from '@utils/utils';
import { useTaskDrawer } from '@components/Scheduler/useTaskDrawer';
import { FullWork, Work } from '@features/ProjectPortfolio/Project/Overview/Work';
import { useModal } from '@common/PromiseModal';
import { MatchToClient } from '@features/SystemPortfolio/components/MatchToClient';
import { NormalizedProperties } from './Properties';
import { OverviewSkeleton } from './Skeleton';
import {
  Container,
  CreateWork,
  Details,
  DetailsContent,
  DetailsHeader,
  DetailsTitle,
  IconButton,
  Widget,
  WidgetDetailsButton,
  WidgetHeader,
  WidgetIcon,
  WidgetTitle,
  WorkPlaceholder
} from './styled';

interface Props {
  systemId: number;
}

type TDetails = 'work';

export const Overview = ({ systemId }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const userId = useAppSelector(selectCurrentUserId);

  const { data: system, isLoading } = useSystem(systemId);
  const { data: access, isFetching: isFetchingAcesss } = useUserRoleSettings(companyId, userId);

  const canViewWork = hasAccess(access, 'task', 'view');

  const [expandedDetails, setExpandedDetails] = useState<TDetails | null>(null);

  const { onAddClick } = useTaskDrawer();

  const { openModal } = useModal();

  const handleLink = useCallback(() => {
    openModal<void>(({ onClose }) => <MatchToClient system={system} onClose={onClose} />, {
      title: `Link #${system.number}: ${system.name} to Client`
    });
  }, [openModal, system]);

  const handleCreateWorkOrderClick = useCallback(() => {
    onAddClick({
      project: system.project,
      system
    });
  }, [onAddClick, system]);

  const expandDetails = useCallback(
    (id: TDetails) => () => {
      setExpandedDetails(id);
    },
    []
  );

  useEffect(() => {
    setExpandedDetails(null);
  }, [systemId]);

  const details = useMemo(() => {
    if (!expandedDetails) {
      return null;
    }

    switch (expandedDetails) {
      case 'work':
        return {
          title: 'Work',
          content: <FullWork recordId={system.project?.id} system={system} />
        };

      default:
        return null;
    }
  }, [expandedDetails, system]);

  if (expandedDetails && details) {
    return (
      <Details>
        <DetailsHeader>
          <DetailsTitle>{details.title}</DetailsTitle>
          <IconButton onClick={() => setExpandedDetails(null)}>
            <X size="28px" />
          </IconButton>
        </DetailsHeader>
        <DetailsContent>{details.content}</DetailsContent>
      </Details>
    );
  }

  if (isLoading || isFetchingAcesss) {
    return <OverviewSkeleton />;
  }

  if (!system) {
    return null;
  }

  const isLinkedWithClient = Boolean(system.project);

  return (
    <Container>
      <SystemHealthWidget system={system} />
      <NormalizedProperties system={system} />

      {canViewWork && (
        <Widget isFullWidth size="large">
          <WidgetHeader>
            <WidgetTitle onClick={expandDetails('work')}>
              <WidgetIcon backgroundColor="#FCF2DB">
                <Clipboard size="16px" color="#F1AA12" />
              </WidgetIcon>
              Work
              {isLinkedWithClient && hasAccess(access, 'task', 'create') && (
                <CreateWork {...stopPropagationClick}>
                  <Button onClick={handleCreateWorkOrderClick} variant={ButtonVariant.Secondary}>
                    <Plus size="16px" />
                    Work Order
                  </Button>
                </CreateWork>
              )}
            </WidgetTitle>
            {isLinkedWithClient && (
              <WidgetDetailsButton onClick={expandDetails('work')}>
                <ChevronRightIcon size={24} />
              </WidgetDetailsButton>
            )}
          </WidgetHeader>

          {isLinkedWithClient && (
            <Work projectId={system.project.id} system={system} onExpandDetails={expandDetails('work')} />
          )}

          {!isLinkedWithClient && (
            <WorkPlaceholder>
              <div>You cannot create Work Orders for Systems that are not linked with Clients.</div>
              <div>
                <Button onClick={handleLink} variant={ButtonVariant.Flat}>
                  Link to Client
                </Button>
              </div>
            </WorkPlaceholder>
          )}
        </Widget>
      )}
    </Container>
  );
};
