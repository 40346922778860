import { ReactQueryKey } from '@enums';
import { TaskStatusTimelineFilter, TaskStatusTimelinesConnection } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { DeepPartial } from 'redux';

export const useTaskStatusTimelines = (taskId: number) => {
  const filter: DeepPartial<TaskStatusTimelineFilter> = {
    taskId: {
      equalTo: taskId
    }
  };

  return useQuery([ReactQueryKey.Tasks, ReactQueryKey.TaskStatusesTimelines, taskId], async () => {
    try {
      return (
        await postGraphql<{ taskStatusTimelinesConnection: TaskStatusTimelinesConnection }>(
          gql`
            query ($filter: TaskStatusTimelineFilter) {
              taskStatusTimelinesConnection(filter: $filter) {
                nodes {
                  count
                  spentTime
                  taskStatus {
                    id
                    label
                  }
                  startedAt
                  endedAt
                }
              }
            }
          `,
          { filter }
        )
      ).taskStatusTimelinesConnection.nodes;
    } catch (error) {
      throw apiErrorHandler('Error fetching task statuses timelines', error);
    }
  });
};
