import { AccountIcon } from '@common/icons/Account';
import { Badge } from '@common/ui';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import {
  BarChart2,
  Briefcase,
  Calendar,
  ChevronLeft,
  ChevronRight,
  FolderPlus,
  Inbox,
  Layers,
  LogOut,
  Sunset,
  Truck,
  Users
} from 'react-feather';
import { SubjectAccess } from '@common/RoleAccess';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { selectUser, selectWorkspaceId } from '@state/selectors';
import { useMediaSm } from '@hooks/useMedia';
import { useLocation } from '@reach/router';
import { useUnreadNotificationCount } from '@hooks/notifications/useUnreadNotificationCount';
import { useNotificationSocket } from '@hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { logOut } from '@state/actions/authentication/authenticationAction';
import { Avatar } from '@kit/ui/Avatar';
import { SmartViewList } from '@features/SmartViews/List';
import { useModal } from '@common/PromiseModal';
import { SmartViewFolderForm } from '@features/SmartViews/FolderForm';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils/roles';
import { useDrawerOpenState } from './useDrawerOpenState';
import { WorkspaceSettings } from './WorkspaceSettings';
import { ListItemWithSubMenu } from './ListItemWithSubMenu';
import {
  BottomMenuItems,
  HoverableMenu,
  IconBadge,
  NavLink,
  SeparatorMenuItem,
  SmartViewsContainer,
  Toggle,
  useDrawerStyles,
  UserContact,
  UserMenuItem
} from './styled';

const DrawerToggle = ({ isDrawerOpen, toggleDrawer }: any) => {
  const containerRef = useRef<HTMLElement>();

  useEffect(() => {
    containerRef.current = document.getElementById('app-container');
  }, []);

  if (!containerRef.current) {
    return null;
  }

  return ReactDOM.createPortal(
    <Toggle isDrawerOpen={isDrawerOpen} onClick={toggleDrawer}>
      {isDrawerOpen ? (
        <ChevronLeft className="desktop" size="12px" />
      ) : (
        <ChevronRight className="desktop" size="12px" />
      )}
    </Toggle>,
    containerRef.current
  );
};

export const MainMenu = () => {
  const { data: inboxUnreadCount = 0 } = useUnreadNotificationCount();
  const companyId = useAppSelector(selectWorkspaceId);
  const isWide = useMediaSm();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const { data: access } = useUserRoleSettings(companyId, user.userId);

  const { openModal } = useModal();

  const [isOpen, setIsOpen] = useDrawerOpenState();
  const location = useLocation();

  // const [isMouseOverOpen, setIsMouseOverOpen] = useState(false);
  // const [isFixedOpen, setIsFixedOpen] = useState(true);
  const drawerClasses = useDrawerStyles();
  const queryClient = useQueryClient();

  const handleLogOut = useCallback(() => {
    dispatch(logOut());
  }, [dispatch]);

  const toggleDrawer = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);

  useNotificationSocket({
    onNewNotification: async () => {
      await queryClient.invalidateQueries([ReactQueryKey.InboxNotificationsUnreadCounter]);
      await queryClient.removeQueries([ReactQueryKey.InboxNotifications]);
    }
  });

  const handleCreateFolderClick = useCallback(
    (e) => {
      e.stopPropagation();

      openModal(({ onClose }) => <SmartViewFolderForm onClose={onClose} />, { title: 'Create Hub' });
    },
    [openModal]
  );

  useEffect(() => {
    if (!isWide) {
      setIsOpen(false);
    }
  }, [location.pathname, location.search, isWide, setIsOpen]);

  /* const handleMouseEnter = useCallback(() => {
        if (isFixedOpen) {
            return;
        }

        setIsMouseOverOpen(true);
    }, [isFixedOpen]);

    const handleMouseLeave = useCallback(() => {
        if (isFixedOpen) {
            return;
        }

        setIsMouseOverOpen(false);
    }, [isFixedOpen]); */

  // const isOpen = isFixedOpen; // || isMouseOverOpen;

  const DrawerComponent = isWide ? Drawer : SwipeableDrawer;

  return (
    <>
      <DrawerToggle isDrawerOpen={isOpen} toggleDrawer={toggleDrawer} />

      <DrawerComponent
        open={isOpen}
        variant={isWide ? 'permanent' : 'temporary'}
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
        className={clsx(drawerClasses.drawer, {
          [drawerClasses.drawerOpen]: isOpen,
          [drawerClasses.drawerClose]: !isOpen
        })}
        classes={{
          paper: clsx(drawerClasses.paper, {
            [drawerClasses.drawerOpen]: isOpen,
            [drawerClasses.drawerClose]: !isOpen
          })
        }}
      >
        <HoverableMenu isOpen={isOpen} /* onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} */>
          <WorkspaceSettings isDrawerOpen={isOpen} />
          <List>
            <NavLink to={`/${companyId}/inbox`}>
              <ListItem button selected={location.pathname.includes(`/${companyId}/inbox`)}>
                <ListItemIcon>
                  <Inbox size="16px" />
                </ListItemIcon>
                <ListItemText primary="Inbox" />
                {inboxUnreadCount > 0 && <Badge type="smartViewsCount">{inboxUnreadCount}</Badge>}
              </ListItem>
            </NavLink>
            <SeparatorMenuItem />
            <SubjectAccess subject="project" privilege="view">
              <>
                <NavLink to={`/${companyId}/clients`}>
                  <ListItem button isOpen={isOpen} selected={location.pathname.includes(`/${companyId}/clients`)}>
                    <ListItemIcon>
                      <AccountIcon size={16} />
                    </ListItemIcon>
                    <ListItemText primary="Clients" />
                  </ListItem>
                </NavLink>
                <NavLink to={`/${companyId}/contacts`}>
                  <ListItem button isOpen={isOpen} selected={location.pathname.includes(`/${companyId}/contacts`)}>
                    <ListItemIcon>
                      <Users size="16px" />
                    </ListItemIcon>
                    <ListItemText primary="Contacts" />
                  </ListItem>
                </NavLink>
                <NavLink to={`/${companyId}/requests`}>
                  <ListItem button isOpen={isOpen} selected={location.pathname.includes(`/${companyId}/requests`)}>
                    <ListItemIcon>
                      <DollarIcon size="16px" />
                    </ListItemIcon>
                    <ListItemText primary="Requests" />
                  </ListItem>
                </NavLink>
                <NavLink to={`/${companyId}/projects`}>
                  <ListItem button isOpen={isOpen} selected={location.pathname.includes(`/${companyId}/projects`)}>
                    <ListItemIcon>
                      <Briefcase size="16px" />
                    </ListItemIcon>
                    <ListItemText primary="Projects" />
                  </ListItem>
                </NavLink>
                <NavLink to={`/${companyId}/systems`}>
                  <ListItem button isOpen={isOpen} selected={location.pathname.includes(`/${companyId}/systems`)}>
                    <ListItemIcon>
                      <Sunset size="16px" />
                      {!isOpen && <IconBadge type="success">β</IconBadge>}
                    </ListItemIcon>
                    <ListItemText>
                      <Box display="flex" alignItems="center" gridGap={8}>
                        Systems
                        <Badge type="success">Beta</Badge>
                      </Box>
                    </ListItemText>
                  </ListItem>
                </NavLink>
              </>
            </SubjectAccess>
            <SeparatorMenuItem />

            <NavLink to={`/${companyId}/scheduler`}>
              <ListItem button isOpen={isOpen} selected={location.pathname.includes(`/${companyId}/scheduler`)}>
                <ListItemIcon>
                  <Calendar size="16px" />
                </ListItemIcon>
                <ListItemText primary="Schedule" />
              </ListItem>
            </NavLink>
            <NavLink to={`/${companyId}/dispatcher-new`}>
              <ListItem button isOpen={isOpen} selected={location.pathname.includes(`/${companyId}/dispatcher-new`)}>
                <ListItemIcon>
                  <Truck size="16px" />
                </ListItemIcon>
                <ListItemText primary="Dispatch" />
              </ListItem>
            </NavLink>

            <SeparatorMenuItem />

            <SubjectAccess subject="workspace" scope="analytics" privilege="view">
              <NavLink to={`/${companyId}/analytics`}>
                <ListItem button selected={location.pathname.includes(`/${companyId}/analytics`)}>
                  <ListItemIcon>
                    <BarChart2 size="16px" />
                  </ListItemIcon>
                  <ListItemText>
                    <Box display="flex" alignItems="center" gridGap={8}>
                      Analytics
                      <Badge type="success">3.0</Badge>
                    </Box>
                  </ListItemText>
                </ListItem>
              </NavLink>
            </SubjectAccess>

            <SeparatorMenuItem />

            <SubjectAccess subject="project" privilege="view">
              <ListItemWithSubMenu
                initialIsSubMenuOpen
                isDrawnShown={isOpen}
                text="Hubs"
                icon={<Layers size="16px" />}
                rightAction={
                  hasAccess(access!, 'workspace', 'create', 'smartviewFolder') ? (
                    <Tooltip title="Create Hub">
                      <FolderPlus onClick={handleCreateFolderClick} size="16px" />
                    </Tooltip>
                  ) : undefined
                }
              >
                <SmartViewsContainer isDrawerShown={isOpen}>
                  <SmartViewList />
                </SmartViewsContainer>
              </ListItemWithSubMenu>
            </SubjectAccess>
          </List>
          <BottomMenuItems>
            <NavLink to="/settings/account">
              <ListItem button selected={location.pathname.startsWith(`/settings`)}>
                <UserMenuItem>
                  <Avatar size={32} user={user} />
                  <div>
                    <div>{user.name}</div>
                    <UserContact>{user.email}</UserContact>
                  </div>
                </UserMenuItem>
              </ListItem>
            </NavLink>

            <ListItem button onClick={handleLogOut}>
              <ListItemIcon>
                <LogOut size="16px" />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
          </BottomMenuItems>
        </HoverableMenu>
      </DrawerComponent>
    </>
  );
};
