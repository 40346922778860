import React, { useMemo } from 'react';
import { ActionEventType } from '@enums';
import { ArrowRight, File } from 'react-feather';
import { RecordType } from '@types';
import { Link } from '@reach/router';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import { FileUpIcon } from '@kit/ui/icons/FileUp';
import { ActionItemStatus } from '@kit/components/ActionItemStatus';
import { ActionStatus } from '@generated/types/graphql';
import { capitalize } from 'lodash';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { parseUtcDate } from '@utils/dates';
import moment from 'moment';
import {
  OldValue,
  UpdatedPropertes,
  ValuesWithArrow,
  FeedItemIconContainer,
  ActionItemStatusRow,
  CommentFilesGrid,
  CommentFileThumbnailContainer,
  CommentFileThumbnail,
  ActionItemFilesAndStatus,
  ActionAssignee,
  ActionAssigneeList
} from './styled';
import { BaseItemTemplate } from './BaseItemTemplate';
import { FeedItemProps } from './types';
import { AutomationAvatar } from './WorkflowFeedItem';
import { ENTITY_NAME_BY_RECORD_TYPE } from './constants';

export const ActionFeedItem = ({ item, context, contextEntityId }: FeedItemProps) => {
  const recordType: RecordType = item.payload.project?.type ?? RecordType.PROJECT;
  const { openDrawer } = useDrawersContext();

  const { title, children } = useMemo(() => {
    const author = item.createdBy ||
      item.createdByContact || { id: 0, firstName: item.userName, lastName: '', avatarUrl: item.userImage };

    const userName = author.name || [author.firstName, author.lastName].join(' ') || '';

    const entityName = ENTITY_NAME_BY_RECORD_TYPE[recordType];

    const getRecordNameWithPreposition = (preposition = '') =>
      context === 'client' && contextEntityId !== item.payload.project?.id ? (
        <>
          {' '}
          {preposition}
          {` ${entityName} `}
          <Link to={`${window.location.pathname}/projects/${item.payload.project?.id}${window.location.search}`}>
            {item.payload.project?.title}
          </Link>
        </>
      ) : null;

    const getActionItemNameWithPreposition = (preposition = '') => (
      <>
        {' '}
        {preposition}
        {' Action Item '}
        <Link to={`${window.location.pathname}/actionItems/${item.payload.id}${window.location.search}`}>
          {item.payload.name}
        </Link>
      </>
    );

    switch (item.eventType) {
      case ActionEventType.ACTION_CREATED:
        return {
          title: (
            <>
              <b>{userName}</b> created {getActionItemNameWithPreposition()}
              {getRecordNameWithPreposition('under')}
            </>
          ),
          children: (
            <ActionItemStatusRow>
              Status: <ActionItemStatus status={item.payload.status} />
            </ActionItemStatusRow>
          )
        };
      case ActionEventType.ASSIGNED_TO_CONTACT: {
        const currentAsignees = item.payload.assigneeContacts || [];
        const prevAssignees = item.payload.previousAssigneeContacts || [];

        if (!currentAsignees.length) {
          return {
            title: (
              <>
                <b>{userName}</b> removed <b>Assignee</b> {getActionItemNameWithPreposition('for')}
              </>
            ),
            children: null
          };
        }

        const assigneesWithFlag = currentAsignees.map((assignee) => ({
          ...assignee,
          isNew: !prevAssignees.find((prevAssignee) => prevAssignee.id === assignee.id)
        }));

        const removed = prevAssignees.filter(
          (assignee) => !currentAsignees.find((currentAssignee) => currentAssignee.id === assignee.id)
        );

        return {
          title: (
            <>
              <b>{userName}</b> updated <b>Assignee</b> {getActionItemNameWithPreposition('for')}
            </>
          ),
          children: (
            <ActionAssigneeList>
              {assigneesWithFlag.map((assignee) => (
                <ActionAssignee>
                  {assignee.isNew ? '+' : ''}
                  <ContactAvatar size={16} contact={assignee} />
                  <div>{assignee.name}</div>
                </ActionAssignee>
              ))}
              {removed.map((assignee) => (
                <ActionAssignee isRemoved>
                  <ContactAvatar size={16} contact={assignee} />
                  <div>{assignee.name}</div>
                </ActionAssignee>
              ))}
            </ActionAssigneeList>
          )
        };
      }

      case ActionEventType.ACTION_UPDATED: {
        const { updatedProperties, files } = item.payload;

        const isSentToReview =
          updatedProperties?.find((property) => property.property === 'status')?.newValue === ActionStatus.InReview;

        if (isSentToReview) {
          return {
            title: (
              <>
                <b>{userName}</b> sent to review {getActionItemNameWithPreposition()}
              </>
            ),
            children: (
              <ActionItemFilesAndStatus>
                {files?.length > 0 && (
                  <CommentFilesGrid>
                    {files.map((file) => (
                      <CommentFileThumbnailContainer
                        key={file.id}
                        onClick={() => {
                          openDrawer(DrawerEntity.ACTION_ITEM, item.payload.id, []);
                          openDrawer(
                            DrawerEntity.FILE,
                            file.id,
                            files.map((f) => f.id)
                          );
                        }}
                      >
                        {file?.metaData?.thumbnailUrl ? (
                          <CommentFileThumbnail url={file.metaData.thumbnailUrl} />
                        ) : (
                          <File size="16px" color="#9C9CAA" />
                        )}
                      </CommentFileThumbnailContainer>
                    ))}
                  </CommentFilesGrid>
                )}

                <ActionItemStatusRow>
                  Status: <ActionItemStatus status={ActionStatus.InReview} />
                </ActionItemStatusRow>
              </ActionItemFilesAndStatus>
            )
          };
        }

        const [updatedProperty] = updatedProperties;

        let { oldValue, newValue } = updatedProperty;

        if (updatedProperty.property === 'dueDate') {
          if (oldValue) {
            oldValue = moment(parseUtcDate(oldValue)).format('MM/DD/YYYY');
          }
          if (newValue) {
            newValue = moment(parseUtcDate(newValue)).format('MM/DD/YYYY');
          }
        }

        return {
          title: (
            <>
              <b>{userName}</b> updated <b>{capitalize(updatedProperty.property).replace('Duedate', 'Due date')}</b>{' '}
              {getActionItemNameWithPreposition('for')}
            </>
          ),
          children: (
            <div>
              {updatedProperty.property === 'status' && (
                <ActionItemStatusRow>
                  Status: <ActionItemStatus status={updatedProperty.newValue} />
                </ActionItemStatusRow>
              )}
              {updatedProperty.property !== 'status' && (
                <UpdatedPropertes>
                  <ValuesWithArrow>
                    <OldValue>{oldValue}</OldValue>
                    <ArrowRight size={12} />
                    <div>{newValue}</div>
                  </ValuesWithArrow>
                </UpdatedPropertes>
              )}
            </div>
          )
        };
      }

      case ActionEventType.ACTION_DELETED:
        return {
          title: (
            <>
              <b>{userName}</b> deleted Action Item {item.payload.name}
              {getRecordNameWithPreposition('from')}
            </>
          ),
          children: null
        };

      default:
        return {
          title: null,
          children: null
        };
    }
  }, [item, context, contextEntityId, recordType, openDrawer]);

  if (!title && !children) {
    return null;
  }

  const author = item.createdBy || { id: 0, firstName: item.userName, lastName: '', avatarUrl: item.userImage };

  const isAutomation = author.firstName === 'Coperniq' && author.lastName === 'Automation';

  let authorIcon;

  if (isAutomation) {
    authorIcon = <AutomationAvatar />;
  } else if (item.createdByContact) {
    authorIcon = <ContactAvatar isPortalStatusShown={false} contact={item.createdByContact} />;
  }

  return (
    <BaseItemTemplate
      id={item.id}
      context={context}
      author={!authorIcon ? author : undefined}
      authorIcon={authorIcon}
      icon={
        <FeedItemIconContainer color="#DFDFE8">
          <FileUpIcon color="#235DFF" size="12px" />
        </FeedItemIconContainer>
      }
      date={item.createdAt}
      title={title}
    >
      {children}
    </BaseItemTemplate>
  );
};
