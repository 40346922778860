import { ReactQueryKey } from '@enums';
import { TaskTemplate } from '@types';
import taskTemplateApi from '@services/api/taskTemplateApi';
import { makeStates, useTemplatesGeneric } from '@hooks/useTemplatesGeneric';
import { useQueryClient } from 'react-query';

export { TemplateFilterBy } from '@hooks/useTemplatesGeneric';

interface Params {
  sortCol?: string;
  sortDesc?: boolean;
}

export const useTaskTemplates = (params: Params = {}) => {
  const query = useQueryClient();
  const states = makeStates();

  return useTemplatesGeneric<TaskTemplate>({
    api: taskTemplateApi,
    entityName: 'task template',
    cacheKey: ReactQueryKey.TaskTemplate,
    states,
    initialFetch: true,
    fetchAll: true,
    findCol: 'title',
    sortCol: params.sortCol,
    sortDesc: params.sortDesc,
    /** since template change is asynchronous for virtual properties, we are going to
     * do some optimistic refetch for properties
     */
    afterMutation: () => setTimeout(() => query.invalidateQueries([ReactQueryKey.CompanyProperties]), 6000)
  });
};
