import { List, Popover as MuiPopover } from '@material-ui/core';
import { colors, fonts } from '@styles';
import styled, { css } from 'styled-components';

export const CurrentWorkspace = styled.div`
  padding-left: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const WorkspaceIcon = styled.div<{ imageUrl?: string }>`
  position: relative;
  direction: ltr;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background-image: ${(props) => (props.imageUrl ? `url(${props.imageUrl})` : 'none')};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  background-color: ${(props) => (props.imageUrl ? 'transparent' : props.colors.bg)};
  color: ${(props) => props.colors.text};
  cursor: pointer;
  flex-shrink: 0;
  ${(props) =>
    props.withBorder
      ? css`
          border: 1px solid ${colors.black};
          font-size: 14px;

          &:before {
            content: ' ';
            position: absolute;
            z-index: 1;
            border-radius: 5px;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            border: 1px solid ${colors.white};
          }
        `
      : ''};
`;

export const CurrentWorkspaceIcon = styled(WorkspaceIcon)``;

export const WorkspaceName = styled.div`
  font-size: 14px;
  font-weight: 700;
  font-family: ${fonts.sans2};
`;

export const CurrentWorkspaceName = styled(WorkspaceName)`
  transition: padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  padding-left: ${(props) => (props.isDrawerOpen ? '10px' : '16px')};
  padding-right: 12px;
`;

export const Popover = styled(MuiPopover)``;

export const Menu = styled.div`
  background-color: ${colors.black};
  color: ${colors.white};
  border: 1px solid rgba(255, 255, 255, 0.25);

  .MuiTypography-body1 {
    font: 500 12px/14px ${fonts.sans};
  }

  .MuiListItemIcon-root {
    min-width: 32px;
    color: ${colors.white};
  }

  .MuiListItemText-root {
    margin: 0;
  }

  .MuiListItem-button:hover {
    background-color: #ffffff29;
  }
`;

export const WorkspaceList = styled(List)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);

  .MuiListItem-root.Mui-selected {
    background-color: #ffffff29;
  }
`;
