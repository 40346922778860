import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { PathType } from '@hooks/useDatabank';
import { Download, Sidebar } from 'react-feather';
import { isPreviewableFile, isImageFile, isVideo } from '@utils/files';
import { useFileMutation } from '@hooks/useFiles';
import { Instance } from 'pspdfkit';
import { PSPDFKitToolbar } from '@components/Project/DataBank/DataBankContent/FileList/FileProperties/PSPDFKitToolbar';
import { useRoutes, useToast } from '@hooks';
import { colors } from '@styles';
import { StateMessege } from '@common';

import { FormInstance } from '@features/DocumentManagement/Forms/FormInstance';
import { Input } from '@kit/ui/Input';
import { useUpdateEffect } from '@react-hookz/web';
import { ExportIcon } from '@kit/ui/icons/Export';
import { Tooltip } from '@material-ui/core';
import { useModal } from '@common/PromiseModal';
import { Export } from '@components/Project/DataBank/Export';
import { useExportForm } from '@hooks/documents/forms/useExportForm';
import { useDocDetails } from '@features/ClientPortfolio/Client/Overview/Docs/useDocDetails';
import { createGlobalState } from 'react-use';
import { ContextMenu } from '@kit/components/ContextMenu';
import { useDocContextMenuItems } from '@features/DocumentManagement/DocList/useDocContextMenuItems';
import { File } from '@generated/types/graphql';
import { MoreVerticalIcon } from '@kit/ui/icons/MoreVertical';
import { useDrawersContext } from '@contexts/DrawersContext';
import {
  Action,
  Container,
  Right,
  PSPDFKitContainer,
  PSPDFKitToolbarContainer,
  FormBuilderContainer,
  Left,
  Content,
  PSPDFKitTopToolbar,
  PSPDFKitToogleSigebarButton,
  FullScreenRightTitle,
  FullScreenRightHeader,
  FeedWrapper,
  DocView,
  DocContentWrapper,
  Video
} from './styled';
import { Feed } from './Feed';
import { config } from '../../../../../../config';
import { FSInfo } from './Info/FSInfo';
import { FormDocsSidebar } from './FormDocsSidebar';

interface Props {
  fileId: number;
  file?: File;
  isLoading: boolean;
  width?: string;
  height?: string;
  isFullScreen?: boolean;
  parentDocId?: number;
}

const usePspdfkitLib = createGlobalState(null);

const FilePropertiesInner = ({
  fileId,
  isLoading,
  file: currentCachedFile,
  isFullScreen = false,
  parentDocId
}: Omit<Props, 'width' | 'height'>) => {
  const downloadFileRef = useRef(null);
  const [PSPDFKitModule, setPSPDFKitModule] = usePspdfkitLib();

  const { recordType } = useRoutes();

  const file = currentCachedFile?.id === fileId ? currentCachedFile : null;

  const { closeDrawer } = useDrawersContext();

  const [isActivityCollapsed, setIsActivityCollapsed] = useState(false);

  const toggleActivity = useCallback(() => {
    setIsActivityCollapsed((prev) => !prev);
  }, []);

  const isFormItem = file?.type === 'form';
  const isVideoFile = file && isVideo(file);

  const { mutateAsync: exportForm } = useExportForm();

  const { download, getDownloadUrl, update } = useFileMutation();
  const psdpftkitRef = useRef<HTMLDivElement>(null);

  const fileContextMenuItems = useDocContextMenuItems({ doc: file, recordType, onDeleted: closeDrawer });

  const previewableFile = useMemo(() => file && isPreviewableFile({ file }), [file]);

  const { showSuccess } = useToast();
  const [pspdfkitInstance, setPspdfkitInstance] = useState<Instance>();
  const [currentPage, setCurrentPage] = useState('1');
  const [isSidebarShown, setIsSidebarShown] = useState<boolean>(true);

  const { openModal } = useModal();

  const handleDownLoad = useCallback(() => {
    if (!file) {
      return;
    }

    if (file.form) {
      exportForm({ formId: file.form.id });

      return;
    }

    download.mutate({
      downloadFileRef: downloadFileRef.current,
      id: file?.form?.id ?? file.id,
      type: file?.form ? PathType.FORM : PathType.FILE,
      projectId: file?.projectId,
      pspdfkitInstance,
      pspdfkitModule: PSPDFKitModule
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [download, file]);

  const handleExport = useCallback(() => {
    if (!file) {
      return;
    }

    openModal(({ onClose }) => <Export recordType={recordType} formId={file?.form?.id} onClose={onClose} />, {
      title: 'Export form'
    });
  }, [file, openModal, recordType]);

  useEffect(() => {
    const container = psdpftkitRef.current;
    let PSPDFKit = PSPDFKitModule;

    if (file && previewableFile && psdpftkitRef.current && !isFormItem && !isVideoFile) {
      (async function initPspdfkit(): Promise<void> {
        // measure time to load pspdfkit
        const t0 = performance.now();
        if (!PSPDFKitModule) {
          ({ default: PSPDFKit } = await import('pspdfkit'));
          setPSPDFKitModule(PSPDFKit);
        }
        const t1 = performance.now();
        // eslint-disable-next-line no-console
        console.log('Call to import pspdfkit took ' + (t1 - t0) + ' milliseconds.');

        // measure time to load pspdfkit
        const t2 = performance.now();

        const instance = (await PSPDFKit.load({
          container,
          licenseKey: config.pspdfkitKey,
          document: getDownloadUrl(file.id, PathType.FILE),
          enableHistory: true,
          styleSheets: ['/pspdfkit.css'],
          instantJSON: file.annotations
        })) as Instance;

        const t3 = performance.now();
        // eslint-disable-next-line no-console
        console.log('Call to load pspdfkit took ' + (t3 - t2) + ' milliseconds.');

        instance.setViewState((viewState) => {
          const newState = {
            showToolbar: true,
            viewportPadding: {
              horizontal: 0,
              vertical: 0
            },
            interactionMode: PSPDFKit.InteractionMode.PAN,
            spreadSpacing: 4,
            zoom: isImageFile(file) ? PSPDFKit.ZoomMode.FIT_TO_VIEWPORT : PSPDFKit.ZoomMode.AUTO,
            enableAlwaysScrollToZoom: isImageFile(file)
          };

          if (!isImageFile(file)) {
            const sideBarOptions = {
              sidebarMode: PSPDFKit.SidebarMode.THUMBNAILS,
              sidebarWidth: 120,
              sidebarOptions: {
                [PSPDFKit.SidebarMode.ANNOTATIONS]: {
                  includeContent: [PSPDFKit.Annotations.ImageAnnotation]
                }
              }
            };

            return viewState.merge({ ...newState, ...sideBarOptions });
          }

          return viewState.merge(newState);
        });

        setPspdfkitInstance(instance);

        // save annotations
        instance.addEventListener('document.saveStateChange', async (event) => {
          if (!event.hasUnsavedChanges) {
            return update.mutateAsync(
              {
                fileId: file.id,
                annotations: await instance.exportInstantJSON(),
                showSuccessMessage: false,
                refetchDatabankTree: false
              },
              {
                onSuccess: () => {
                  showSuccess('Successfully saved annotations');
                }
              }
            );
          }

          return undefined;
        });
      })();
    }

    return () => {
      if (PSPDFKitModule && container) {
        PSPDFKitModule.unload(container);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file?.id, previewableFile, isFormItem, isVideoFile]);

  const handlePageInputChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (!pspdfkitInstance) {
        return;
      }

      const { value } = event.target;

      if (!value) {
        setCurrentPage('');

        return;
      }

      const newPage = Math.max(1, Math.min(pspdfkitInstance.totalPageCount, parseInt(value, 10)));
      pspdfkitInstance.setViewState((viewState) => viewState.set('currentPageIndex', newPage - 1));

      setCurrentPage(newPage);
    },
    [pspdfkitInstance]
  );

  const handleToogleSidebar = useCallback(() => {
    setIsSidebarShown((prev) => !prev);
  }, []);

  useUpdateEffect(() => {
    pspdfkitInstance?.setViewState((viewState) =>
      viewState.set('sidebarMode', isSidebarShown ? PSPDFKitModule.SidebarMode.THUMBNAILS : null)
    );
  }, [isSidebarShown]);

  return (
    <Container>
      {
        // eslint-disable-next-line
      }<a style={{ display: 'none' }} ref={downloadFileRef} />
      <Content>
        <Left>
          {!previewableFile && !isLoading && (
            <StateMessege subTitle="This file type is not supported yet. Consider downloading the file instead" />
          )}
          {previewableFile && isFormItem && (
            <FormBuilderContainer isFullScreen={isFullScreen}>
              <FormInstance isFullScreen={isFullScreen} projectId={file?.projectId} formId={file.form.id} />
            </FormBuilderContainer>
          )}
          {previewableFile && isVideoFile && (
            <Video controls>
              <source src={getDownloadUrl(file.id, PathType.FILE)} type={file.metaData?.mimeType} />
              Your browser does not support the video tag.
            </Video>
          )}
          {!isFormItem && !isVideoFile && (
            <>
              <DocView isFullScreen={isFullScreen}>
                {Boolean(parentDocId) && (
                  <FormDocsSidebar currentFieldId={fileId} isFullScreen={isFullScreen} formFileId={parentDocId} />
                )}
                <DocContentWrapper>
                  {file && !isImageFile(file) && (
                    <PSPDFKitTopToolbar>
                      <PSPDFKitToogleSigebarButton isSidebarShown={isSidebarShown} onClick={handleToogleSidebar}>
                        <Sidebar size="20px" />
                      </PSPDFKitToogleSigebarButton>
                      <Input
                        value={currentPage}
                        onChange={handlePageInputChange}
                        className="custom-page-switcher-input"
                        type="number"
                      />
                      {pspdfkitInstance && <span>of {pspdfkitInstance.totalPageCount}</span>}
                    </PSPDFKitTopToolbar>
                  )}

                  <PSPDFKitContainer ref={psdpftkitRef} id="pspdfkit" />
                </DocContentWrapper>
              </DocView>
              <PSPDFKitToolbarContainer>
                <PSPDFKitToolbar
                  isFullScreen={isFullScreen}
                  PSPDFKit={PSPDFKitModule}
                  pspdfkitInstance={pspdfkitInstance}
                />
              </PSPDFKitToolbarContainer>
            </>
          )}
        </Left>
        <Right isCollapsed={isActivityCollapsed}>
          <FullScreenRightHeader>
            <FullScreenRightTitle isCollapsed={isActivityCollapsed}>
              <Tooltip title={file?.name ?? ''}>
                <span className="title">{file?.name}</span>
              </Tooltip>

              {isFormItem && (
                <Tooltip title="Export">
                  <Action onClick={handleExport}>
                    <ExportIcon size={16} color={colors.green} />
                  </Action>
                </Tooltip>
              )}

              {!isFormItem && (
                <Tooltip title="Download">
                  <Action onClick={handleDownLoad}>
                    <Download size={16} color={colors.green} />
                  </Action>
                </Tooltip>
              )}

              <ContextMenu items={fileContextMenuItems}>
                <MoreVerticalIcon size="24px" color={colors.green} />
              </ContextMenu>
            </FullScreenRightTitle>

            <FSInfo file={file} />
          </FullScreenRightHeader>

          {!parentDocId && (
            <FeedWrapper>
              {file && (
                <Feed
                  isFullScreen={isFullScreen}
                  isCollapsed={isActivityCollapsed}
                  onToggleIsCollapsed={toggleActivity}
                  file={file}
                />
              )}
            </FeedWrapper>
          )}
        </Right>
      </Content>
    </Container>
  );
};

export const FileProperties = ({ isFullScreen, ...props }: Omit<Props>) => {
  return <FilePropertiesInner isFullScreen={isFullScreen} {...props} />;
};

export const FileContainer = ({
  id,
  isFullScreen,
  parentDocId
}: {
  id: number;
  isFullScreen: boolean;
  parentDocId?: number;
}) => {
  const { isLoading, doc } = useDocDetails({ fileId: id, refetchOnMount: false });

  return (
    <FileProperties
      fileId={id}
      isLoading={isLoading}
      parentDocId={parentDocId}
      isFullScreen={isFullScreen}
      file={doc}
    />
  );
};
