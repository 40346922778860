import { Button } from '@common/ui';
import styled from 'styled-components';
import { WorkOrderStatus as WorkOrderStatusComponent } from '@components/WorkOrder/WorkOrderStatus';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const SplitArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-right: 1px solid transparent;
  cursor: pointer;
`;

export const SplitButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;

  color: ${(props) => props.color};
  border: 1px solid ${(props) => (props.background ? 'transparent' : props.color)};
  background-color: ${(props) => props.background ?? '#fff'};

  ${SplitArrow} {
    border-color: ${(props) => (props.background ? '#fff' : props.color)};
  }
`;

export const StatusText = styled.div`
  padding: 9px 16px;
`;

export const Menu = styled.div`
  padding: 4px;
  width: 173px;
`;

export const TransitionGroup = styled.div`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const TransitionList = styled.div``;

export const TransitionListItem = styled.div`
  padding: 4px;
  padding-left: 8px;

  cursor: pointer;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  > * {
    margin-left: 0;
    display: inline-flex;
  }
`;

export const TransitionGroupName = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
`;

export const ShortcutButton = styled(Button)`
  &&& {
    color: ${(props) => props.color};
  }
`;

export const WorkOrderStatus = styled(WorkOrderStatusComponent)`
  max-width: 100%;
`;
