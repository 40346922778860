import { useMutation } from 'react-query';
import { apiErrorHandler } from '@utils';
import { RcFile } from 'antd/es/upload';
import { File } from '@generated/types/graphql';
import { uploadCompressedFile } from '@services/UploadServices/uploadService';

export const useUploadFileMutation = (companyId: number) => {
  const uploadFileMutation = useMutation<File, Error, { file: RcFile }>(async ({ file }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const filePromise = await uploadCompressedFile({
        formData,
        isPrivate: false,
        entityId: companyId,
        by: 'company'
      });

      return filePromise.data;
    } catch (e) {
      console.error(e)
      throw apiErrorHandler('Error uploading file', e);
    }
  });

  return {
    upload: uploadFileMutation
  };
};
