import imageCompression from 'browser-image-compression';

import { isBrowser } from '@utils';

let heic2any: any = null;

if (isBrowser) {
  // eslint-disable-next-line global-require
  heic2any = require('heic2any');
}

const ALLOWED_FORMATS = ['jpeg', 'png', 'heic'];
const options = {
  maxSizeMB: 3.5,
  maxWidthOrHeight: 5500,
  useWebWorker: true
};

const extractExtension = (file: File) => {
  const fileNameConstruction = file.name.split('.');

  return fileNameConstruction.length > 1 ? fileNameConstruction.pop() : '';
};

const compressImage = async (file: File) => {
  const extension = extractExtension(file);

  let compressFile = file;
  let { name, type } = file;
  if (extension?.toLowerCase() === 'heic') {
    type = 'image/jpeg';
    name = file.name.replace(extension, 'jpeg');
    compressFile = new File(
      [
        (await heic2any({
          toType: 'image/jpeg',
          blob: file
        })) as Blob
      ],
      name,
      {
        lastModified: file.lastModified,
        type
      }
    );
  }

  return new File([await imageCompression(compressFile, options)], name, {
    lastModified: file.lastModified,
    type
  });
};

export { compressImage, ALLOWED_FORMATS, extractExtension };
