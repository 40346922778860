import { useCallback } from 'react';
import { FeedInbox } from '@generated/types/graphql';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { useNotifications } from './List/useNotifications';

export const useNav = () => {
  const { notifications } = useNotifications();

  const { openDrawer } = useDrawersContext();

  const handleClick = useCallback(
    async (notification: FeedInbox) => {
      openDrawer(
        DrawerEntity.NOTIFICATION,
        notification.id,
        notifications.map((item) => item.id)
      );
    },
    [openDrawer, notifications]
  );

  return {
    onNotificationClick: handleClick
  };
};
