import { Pager } from '@types';
import { config } from '../config';

// Base URL
export const baseURL = config.baseHttpUrl;
// Project service
export const projectService = config.projectServiceUrl;
// Databank service
export const dataBankService = config.dataBankServiceUrl;
// User service
export const userService = config.userServiceUrl;
// Notification service
export const notificationService = config.notificationServiceUrl;
// Project base
export const projectBaseURL = `${projectService}/projects`;
// User base
export const userBaseURL = `${userService}/users`;
// Users
export const getUsersURL = (pager: Pager, companyId?: number) => {
  let extra = pager.name ? `&searchKey=${encodeURIComponent(pager.name)}` : '';

  extra += companyId ? `&companyId=${companyId}` : '';

  return `${userBaseURL}/options?sorts[0][col]=createdAt&sorts[0][desc]=true&filters[0][col]=isActive&filters[0][op]==&filters[0][val]=true&paging[limit]=${pager.limit}&paging[skip]=${pager.skip}${extra}`;
};

// Project members
export const getProjectMembersUsersURL = (pager: Pager, id: number) => {
  const extra = pager.name
    ? `&filters[1][col]=firstName&filters[1][op]=like&filters[1][val]=${encodeURIComponent(pager.name)}`
    : '';

  return `${projectBaseURL}/${id}/members?sorts[0][col]=createdAt&sorts[0][desc]=true&filters[0][col]=isActive&filters[0][op]==&filters[0][val]=true&paging[limit]=${pager.limit}&paging[skip]=${pager.skip}${extra}`;
};
export const getUserServiceURL = (userId: string | number) =>
  `${userBaseURL}/options?filters[0][col]=id&filters[0][op]==&filters[0][val]=${userId}`;

export const getUserServiceURLwithIn = (userIds: number[], companyId?: number) =>
  `${userBaseURL}/options?filters[0][col]=id&filters[0][op]=in&filters[0][val]=${userIds.join(
    ','
  )}&paging[skip]=0&paging[limit]=${userIds.length}${companyId ? `&companyId=${companyId}` : ''}`;

export const getMultipleUserServiceURL = (userIds: number[], companyId: number) => {
  let url = `${userBaseURL}/options?companyId=${companyId}&sorts[0][col]=createdAt&sorts[0][desc]=true&filters[0][col]=id&filters[0][op]=in&filters[0][val]=`;
  // eslint-disable-next-line
  userIds.forEach((userid) => (url += `${userid},`));
  url = url.slice(0, -1);

  return url + '&paging[limit]=100&paging[skip]=0';
};

export const getUserServicePUTURL = (userId: string | number, companyId?: number) =>
  `${userBaseURL}/${userId}${companyId ? `?companyId=${companyId}` : ''}`;

export const stagesURL = () => `${config.projectServiceUrl}/stages`;

// Kanban Stages
export const kanbanStagesURL = (projectId: number) => `${projectBaseURL}/${projectId}/stages`;

// Kanban Tasks
export const kanbanTasksURL = (projectId: number) => `${projectBaseURL}/${projectId}/tasks`;
export const getKanbanTaskUpdateURL = (projectId: number, taskId: string | number) =>
  `${projectBaseURL}/${projectId}/tasks/${taskId}`;
// Teams
export const teamBaseURL = `${projectService}/teams`;
export const teamURL = (pager: Pager, companyId: number) =>
  // eslint-disable-next-line max-len
  `${teamBaseURL}?companyId=${companyId}&sorts[0][col]=name&sorts[0][desc]=false${pager.skip != null ? `&paging[skip]=${pager.skip}&paging[limit]=${pager.limit}` : ''}`;
// Databank Folder
export const dataBankFetchFolderURL = (projectId: number) => {
  return `${dataBankService}/projects/${projectId}/folders`;
};
// Databank download multiple
export const dataBankDownloadMultipleURL = (projectId?: number): string =>
  `${dataBankService}/files/download${projectId ? `?projectId=${projectId}` : ''}`;

// Fetch forms by projectId
export const fetchFormsByProjectIdUrl = (projectId?: number): string => `${projectService}/projects/${projectId}/forms`;

export const dataBankCreateFolderURL = (projectId: number) => `${dataBankService}/projects/${projectId}/folders`;

export const databankUploadURL = (projectId: number) => `${dataBankService}/projects/${projectId}/files/upload`;

export const databankStorageInfoURL = () => `${dataBankService}/files/storage`;

export const databankUpdateFolderURL = (folderId: number, projectId: number) =>
  `${dataBankService}/projects/${projectId}/folders/${folderId}`;

export const databankUpdateFileURL = (fileId: number, projectId: number) =>
  `${dataBankService}/projects/${projectId}/files/${fileId}/update`;

export const databankDownloadFileURL = (fileId: number) => `${dataBankService}/files/${fileId}/download`;

export const databankDownloadFormURL = (formId: number, accessToken: string) =>
  `${dataBankService}/forms/${formId}/export?accessToken=${accessToken}`;

export const databankDeleteFileURL = (fileId: number, projectId: number) =>
  `${dataBankService}/projects/${projectId}/files/${fileId}`;

export const databankDeleteFilePublicURL = (fileId: number, companyId: number) =>
  `${dataBankService}/files/${fileId}?companyId=${companyId}`;

export const formBuilderSingleUrl = (formId: number) => `${dataBankService}/forms/${formId}`;

export const formBuilderUrl = (companyId?: number) =>
  `${projectService}/forms${companyId ? `?companyId=${companyId}` : ''}`;

export const formBuilderTemplateUrl = (formId: number, companyId: number) =>
  `${projectService}/form-templates/${formId}?companyId=${companyId}`;

export const formSocketFallbackUrl = (formId: number) => `${dataBankService}/forms/${formId}/websocket/fallback`;

export const getFromTemplaterUrl = (projectId?: number) =>
  `${projectService}/forms/templates${projectId ? `?projectId=${projectId}` : ''}`;

export const deleteFormTemplateUrl = (formTemplateId: number) => `${projectService}/forms/templates/${formTemplateId}`;

export const databankFormUrl = (projectId: number, formId: number, companyId: number) => {
  return formBuilderUrl() + (formId ? `/${formId}` : '') + `?companyId=${companyId}`;
};

export const formLoginUrl = () => {
  return `${userService}/auth/login/local`;
};

export const formLoginInviteConfirmUrl = () => {
  return `${userService}/users/invite/confirm`;
};

export const getInviteCheckUrl = (token: string, email: string, companyId: number) => {
  return `${userService}/users/invite/check?token=${token}&email=${email}&companyId=${companyId}`;
};

export const googleLoginUrl = (token: string) => {
  return `${userService}/auth/login/google?id_token=${token}`;
};

export const getUserCompaniesUrl = (companyId?: number) => {
  return `${userService}/companies${companyId ? `?companyId=${companyId}` : ''}`;
};

export const companyRegisterUrl = () => `${userService}/companies`;

export const newRefreshTokenUrl = (refreshToken: string) => {
  return `${userService}/auth/token/new?refreshToken=${refreshToken}`;
};

export const getUploadSingleFileUrl = (by?: 'project' | 'folder' | 'form' | 'company', id?: number) => {
  const url = `${dataBankService}/file/upload/company`;
  if (by && id) {
    switch (by) {
      case 'folder':
        return `${url}?folderId=${id}`;
      case 'form':
        return `${url}?formId=${id}`;
      case 'project':
        return `${url}?projectId=${id}`;
      case 'company':
        return `${url}?companyId=${id}`;
      default:
        return url;
    }
  }

  return url;
};

export const labelURL = projectService + '/labels';

export const getLabelsAllURL = (pager: Partial<Pager>) =>
  `${labelURL}?${pager.skip && `paging[skip]=${pager.skip}&`}${pager.limit && `paging[skip]=${pager.limit}&`}`;

export const updateLabelURL = (id: number | string) => `${labelURL}/${id}`;

export const getLabelUrl = (pager: Pager, companyId?: number) => {
  const extra =
    pager && pager.name !== null && pager.name !== ''
      ? `filters[0][col]=label&filters[0][op]=like&filters[0][val]=${encodeURIComponent(pager.name)}`
      : '';
  const activeSetIndex = extra ? 1 : 0;
  const activeSetFilter = `filters[${activeSetIndex}][col]=isActive&filters[${activeSetIndex}][op]==&filters[${activeSetIndex}][val]=true`;
  const workspaceFilter = `${companyId ? `&companyId=${companyId}` : ''}`;

  return `${labelURL}?sorts[0][col]=label&sorts[0][desc]=false&
    paging[skip]=${pager.skip}&paging[limit]=${pager.limit}&${extra}&${activeSetFilter}${workspaceFilter}`;
};
