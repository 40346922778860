import { ReactQueryKey } from '@enums';
import { File } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

export type RecordDocListItem = Pick<
  File,
  | 'id'
  | 'name'
  | 'stageId'
  | 'metaData'
  | 'downloadUrl'
  | 'type'
  | 'isArchived'
  | 'projectId'
  | 'privilege'
  | 'updatedAt'
  | 'form'
  | 'createdByUser'
  | 'annotations'
  | 'commentsByFileIdConnection'
  | 'sharedWithPortal'
  | 'createdByContact'
>;

export const useRecordDocList = (recordId: number, isArchivedShown = false) => {
  return useQuery<RecordDocListItem[]>([ReactQueryKey.RecordDocList, recordId, isArchivedShown], async () => {
    try {
      const { files } = await postGraphql<{ files: File[] }>(
        gql`
          query ($recordId: Int!, $isArchivedShown: Boolean!) {
            files(
              filter: {
                projectId: { equalTo: $recordId }
                isArchived: { in: [false, $isArchivedShown] }
                privilege: { notEqualTo: "none" }
              }
            ) {
              id
              name
              stageId
              projectId
              metaData
              downloadUrl
              type
              isArchived
              updatedAt
              privilege
              sharedWithPortal
              form {
                id
              }
              annotations

              createdByUser {
                id
                firstName
                lastName
                avatarUrl
              }

              createdByContact {
                id
                name
                portalStatus
              }

              commentsByFileIdConnection {
                totalCount
              }
            }
          }
        `,
        { recordId, isArchivedShown }
      );

      return files;
    } catch (e) {
      throw apiErrorHandler('Error fetching record doc list', e);
    }
  });
};
