import styled from 'styled-components';
import { colors, fonts, scroll, deviceMax } from '@styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 80px;

  border-radius: 4px;
  background-color: white;
  border: 1px dashed #dfdfe8;

  > span,
  .ant-upload-drag-container,
  .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: ${colors.green};
`;

export const Button = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 288px;
  height: 46px;

  margin-bottom: 10px;
  padding-right: 20px;

  font: 12px/1.25 ${fonts.sans};
  text-transform: uppercase;

  color: #778ca2;

  background-color: #f2f4f6;
  border-radius: 4px;

  overflow: hidden;
  cursor: not-allowed;

  img {
    margin-right: 10px;
  }

  &:before {
    content: 'Coming soon';
    position: absolute;
    right: -33px;
    top: 8px;
    width: 120px;
    height: 20px;
    font: 500 9px/30px ${fonts.sans};
    transform: rotate(30deg);
    text-transform: capitalize;
    background-color: #d54855;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const FileList = styled.div`
  max-height: 200px;
  overflow-y: auto;
  margin-top: 12px;

  ${scroll};

  ${deviceMax.md`
    max-height: initial;
  `}
`;
