import React, { useCallback } from 'react';
import { AlertTriangle, EyeOff } from 'react-feather';
import { useSystem } from '@hooks/systems/useSystem';
import { NotMatchtedLabel, StatusMark, SystemStatus } from '@features/SystemPortfolio/components';
import { Tooltip } from '@material-ui/core';
import { SYSTEM_STATUS_COLORS } from '@styles';
import { Address } from '@kit/ui/anchors/Address';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useModal } from '@common/PromiseModal';
import { MatchToClient } from '@features/SystemPortfolio/components/MatchToClient';
import { ProjectTitleChip } from '@components/Scheduler/components/ProjectTitleChip';
import { AccountTypeBadge } from '@features/ClientPortfolio/components/Type';
import { PhoneNumber } from '@kit/ui/anchors/PhoneNumber';
import { EmailAddress } from '@kit/ui/anchors/EmailAddress';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import {
  Container,
  FirstRow,
  Info,
  InfoTitle,
  NameAndAvatar,
  PreTitle,
  SecondRow,
  SecondRowItem,
  Title
} from './styled';
import { HeaderSkeleton } from './Skeleton';

interface Props {
  systemId: number;
}

export const Header = ({ systemId }: Props) => {
  const { data: system, isLoading } = useSystem(systemId);

  const { openModal } = useModal();

  const handleLink = useCallback(() => {
    openModal<void>(({ onClose }) => <MatchToClient system={system} onClose={onClose} />, {
      title: `Link #${system.number}: ${system.name} to Client`
    });
  }, [openModal, system]);

  if (isLoading) {
    return <HeaderSkeleton />;
  }

  const [primaryContact] = system.project?.projectContacts ?? [];

  return (
    <Container>
      <Info>
        <FirstRow>
          <Title>
            <StatusMark color={SYSTEM_STATUS_COLORS[system.status].color} />
            {!system.monitored && (
              <Tooltip title="System is not monitored">
                <EyeOff color="#9C9CAA" size="16px" />
              </Tooltip>
            )}
            <PreTitle dimmed={!system.monitored}>#{system.number}:</PreTitle>
            <InfoTitle dimmed={!system.monitored}>{system.name}</InfoTitle>
          </Title>

          <SystemStatus system={system} />
        </FirstRow>

        <SecondRow>
          {system.project ? (
            <>
              <Address value={system.addressForced} />
              <SecondRowItem>
                <ProjectTitleChip iconSize="16px" variant="link" target="panel" project={system.project} />
                <AccountTypeBadge type={system.project.accountType} />
              </SecondRowItem>
              {primaryContact && (
                <SecondRowItem>
                  <NameAndAvatar>
                    <ContactAvatar contact={primaryContact} />
                    {primaryContact.name}
                  </NameAndAvatar>
                  {Boolean(primaryContact.phones[0]) && (
                    <PhoneNumber iconSize="16px" value={primaryContact.phones[0]} />
                  )}
                  {Boolean(primaryContact.emails[0]) && <EmailAddress value={primaryContact.emails[0]} />}
                </SecondRowItem>
              )}
            </>
          ) : (
            <NotMatchtedLabel>
              <AlertTriangle size={16} />
              Not linked to Client
              <Button onClick={handleLink} variant={ButtonVariant.Flat}>
                Link
              </Button>
            </NotMatchtedLabel>
          )}
        </SecondRow>
      </Info>
    </Container>
  );
};
