import { Urls } from '@services/api/base/axios';
import { post, put } from '@services/api/base';

export default {
  monitorSystems: (dto: { systemIds: number[]; monitor: boolean }) => post<void>(`${Urls.fleet}/systems/monitor`, dto),

  monitorAllSystems: (dto: { integrationId: number; monitor: boolean }) =>
    post<void>(`${Urls.fleet}/systems/monitor/all`, dto),

  update: (id: number, dto: { notes?: string; addressForced?: string | null; projectId?: number }) =>
    put<void>(`${Urls.fleet}/systems/${id}`, dto)
};
