import {
  isObject,
  isUndefined,
  assign,
  identity,
  isEmpty,
  replace,
  flow,
  defaultTo,
  map,
  find,
  prop,
  join,
  cloneDeep,
  isArray as isArrayLodash,
  isDate as isDateLodash,
  isString,
  sortBy,
  camelCase,
  has
} from 'lodash/fp';

import {
  AggregateColumn,
  ProjectAdapted,
  Property,
  PropertyType,
  PropertyValueDTO,
  RecordType,
  VirtualPropertyType,
  Identified,
  FilterOperators,
  ConditionTree,
  Condition
} from '@types';
import UserPreferencesService, { queryByRecord } from '@services/UserPreferences';
import { File, ProjectPropertyValue, Project } from '@generated/types/graphql';
import { literal, literalEnum } from '@services/api/base/graphql';
import moment from 'moment';
import { Filter } from '@services/api/types';
import { getStartEndByDateRange, isDateRangeFilter } from '@services/dateRangeFilters';
import { toStartDate, toEndDate } from '@utils/dates';
import { get } from 'lodash';

/** Mirrors BE's AdditionalDTO */
export type PropertyValue =
  | string // TEXT, BOOLEAN, DATE, single DROPDOWN
  | number // NUMERIC
  | boolean // BOOLEAN (actually tracked as text value)
  | string[] // multiple DROPDOWN
  | Date // DATE (actually string as it will be converted to JSON anyway)
  | { id: number } // PERSON
  | File[]; // FILE

const ARRAY_PROPERTIES = [-3];

export const isEmptyValue = (value: any) =>
  ((isObject(value) || isString(value)) && isEmpty(value)) || value === null || isUndefined(value);

const makeComparator =
  <T = Property>(type: PropertyType) =>
  (property: any): property is T => {
    if (isEmpty(property)) {
      return false;
    }

    return (property as Property).type === type;
  };

export const isDefault = (property: Property) => property && property.id <= 0;
export const isAdditional = (property: Property) => property && !!property.isAdditional;
export const isDropdown = makeComparator(PropertyType.Dropdown);
export const isPerson = makeComparator<{ firstName: string; lastName: string; id: number }>(PropertyType.Person);
export const isText = makeComparator(PropertyType.Text);
export const isDate = makeComparator(PropertyType.Date);
export const isDateOnly = (property: Property) => isDate(property) && !property?.virtual && property.isAdditional;
export const isNumeric = makeComparator(PropertyType.Numeric);
export const isFiles = makeComparator(PropertyType.File);
export const isButtonOrLink = makeComparator(PropertyType.Link);
export const isAddress = (property: Property) => property?.mappedName === 'address';
export const isTrades = (property: Property) => property?.mappedName === 'trades';
export const isDealValue = (property: Property) => property?.mappedName === 'dealValue';
export const isProjectValue = (property: Property) => property?.mappedName === 'projectValue';
export const isStage = (property: Property) => property?.mappedName === 'stageId' || isTimelineStage(property);
export const isTimelineStage = (property: Property) => property?.mappedName === 'timelineStageId';
export const isTimelineStatus = (property: Property) => property?.mappedName === 'timelineStatus';
export const isConfidence = (property: Property) => property?.mappedName === 'confidence';
export const isOwner = (property: Property) => property?.mappedName === 'ownerId';
export const isDescription = (property: Property) => property?.mappedName === 'description';
export const isStatus = (property: Property) => property?.mappedName === 'status';
export const isRequestStatus = (property: Property) => property?.mappedName === 'requestStatus';
export const isClientStatus = (property: Property) => property?.mappedName === 'accountStatus';
export const isReferredBy = (property: Property) => property?.mappedName === 'referrerContactId';
export const isCallDisposition = (property: Property) =>
  property?.mappedName === 'lastOutboundCallDisposition' || property?.mappedName === 'lastInboundCallDisposition';
export const isPrimaryPhone = (property: Property) => property?.mappedName === 'primaryPhone';
export const isPrimaryEmail = (property: Property) => property?.mappedName === 'primaryEmail';
export const isAhj = (property: Property) => property?.mappedName === 'jurisdictionId';

export const isId = (property: Property) => property?.mappedName === 'id';
export const isUid = (property: Property) => property?.mappedName === 'uid';
export const isTaskStatus = (property: Property) =>
  property?.virtual && property?.virtualType === VirtualPropertyType.taskStatus;
export const isTaskWithTitle = (property: Identified) => property?.id === AggregateColumn.TASK_WITH_TITLE;

export const isArray = (property: Property) => ARRAY_PROPERTIES.includes(property?.id as number);

const multipleProps: PropertyType[] = [PropertyType.Dropdown];

export const isStrictMultiple = (property: Property): boolean | undefined =>
  multipleProps.includes(property.type) ? !!property.multiple : undefined;

export const isMultiple = (property: Property): boolean => !!isStrictMultiple(property);

/**
 * Indicates whether property value could be updated directly by user, e.g. in record form or by automation.
 */
export const isEditable = (p: Property): boolean =>
  (!p.readonly && !p.virtual) || (p.mappedName === 'address' && p.id === -3);

/**
 * @deprecated Must be rewritten to start making sense. Prefer <PropertyValue>.
 */
export const getPropertyValueById = (project: ProjectAdapted, property: Property, sanitize: boolean = true) => {
  if (isReferredBy(property)) {
    return project.projectDetail.referrerContact;
  }
  if (property?.displayValuePath) {
    return get(project?.projectDetail, property.displayValuePath);
  }

  const isPropAdditional = property.isAdditional || has(property.id, project.projectDetail.additional);
  const sanitizeFn =
    String(property.mappedName).toLowerCase() === 'description' && sanitize ? replace(/<[^>]+>/g, '') : identity;

  const value = isPropAdditional
    ? project.projectDetail.additional[property.id]
    : sanitizeFn(
        project.projectDetail[
          (property.objectName || property.mappedName || property.name) as keyof ProjectAdapted['projectDetail']
        ] as string
      );

  if (isPerson(property) && value) {
    return assign(
      {
        toString: () => `${value.firstName || ''} ${value.lastName || ''}`,
        companyId: project.projectDetail.companyId
      },
      value
    );
  }

  return value;
};

export const defaultOrdersByRecordType = {
  [RecordType.ACCOUNT]: {
    [-1]: 0, // Title
    [-3]: 1, // Address
    [-29]: 2, // Account type
    [-31]: 3, // Account owner
    [-2]: 4, // Description
    [-33]: 5, // Primary phone
    [-32]: 6 // Primary email
  },

  [RecordType.PROJECT]: {
    // -999 added so this ones are always at the beginning
    [-1]: -9997, // Title
    [-34]: -9996, // Trades
    [-3]: -9995, // Address
    [-36]: -9994, // Address city
    [-37]: -9993, // Address state
    [-38]: -9992, // Address stree
    [-39]: -9991, // Address zip

    // 999 added so this ones are always at the end
    [-33]: 9997, // Primary phone
    [-32]: 9998, // Primary email
    [-43]: 9999, // Project value
    [-44]: 99910, // Project size

    [-40]: 99911, // Sales Rep
    [-41]: 99912, // Project Manager
    [-31]: 99913, // Owner

    [-46]: 99914, // Status
    [-2]: 99915 // Description
  },
  [RecordType.DEAL]: {
    [-1]: 0, // Title
    [-3]: 1, // Address
    [-31]: 2, // Deal owner
    [-24]: 3, // Workflow
    [-27]: 4, // Deal value
    [-30]: 5, // Confidence
    [-2]: 6, // Description
    [-33]: 7, // Primary phone
    [-32]: 8 // Primary email
  }
};

// exclude address parts and stage
const EXCLUDE_PROPERTIES = [-36, -37, -38, -39, -25];

export const sortAndFilterStandardProperties = (properties: Property[]) => {
  const order = defaultOrdersByRecordType[RecordType.PROJECT];

  return properties
    .filter((property) => !EXCLUDE_PROPERTIES.includes(property.id))
    .sort((a, b) => (order[a.id] ?? 0) - (order[b.id] ?? 0));
};

export const saveOrder = (
  companyId: number,
  recordType: RecordType,
  properties: { id: number | string; position: number }[]
) => {
  const order = sortBy('position', properties).reduce((acc, { id }, index) => ({ ...acc, [id]: index }), {});

  UserPreferencesService.propertiesOrder.set(queryByRecord({ companyId, recordType }), order);
};

export const getStoredOrder = (companyId: number, recordType: RecordType) => {
  return (
    UserPreferencesService.propertiesOrder.get(queryByRecord({ companyId, recordType })) ||
    defaultOrdersByRecordType[recordType]
  );
};

export const applyOrder = (companyId: number, recordType: RecordType, properties: Property[]) => {
  const order = getStoredOrder(companyId, recordType);

  return properties.sort((a, b) => {
    if (recordType === RecordType.PROJECT && order.isDefaultOrder) {
      return (order[a.id] ?? 0) - (order[b.id] ?? 0);
    }

    if (a.id in order && b.id in order) {
      return order[a.id] - order[b.id];
    }

    if (a.id in order) {
      return -1;
    }

    if (b.id in order) {
      return 1;
    }

    return +a.id - +b.id;
  });
};

export const getAdditionalPropertyValue = (propertyValue: ProjectPropertyValue): PropertyValue => {
  switch (propertyValue.column!.type) {
    case PropertyType.Date:
      if (propertyValue.dateValue) {
        const date: PropertyValue = moment.utc(propertyValue.dateValue).toDate();
        date.allDay = propertyValue.dateValueAllDay;

        return date;
      }

      return propertyValue.dateValue!;
    case PropertyType.Text:
    case PropertyType.Link:
      return propertyValue.textValue!;
    case PropertyType.Numeric:
      return propertyValue.numericValue!;
    case PropertyType.Person:
      return propertyValue.userByWorkerValue!;
    case PropertyType.Dropdown:
      return propertyValue.column!.multiple ? propertyValue.dropdownValue! : propertyValue.dropdownValue?.[0];
    case PropertyType.File:
      return propertyValue.files!;
    default:
      throw new Error(`Unrecognized property type ${propertyValue.column!.type}`);
  }
};

/**
 * Convert new 'projectPropertiesValues' table holding prop values into old JSON 'additional' field
 */
export const propertiesValuesToAdditional = (values?: ProjectPropertyValue[]): { [columnId: number]: PropertyValue } =>
  (values || []).reduce(
    (acc, value) => ({
      ...acc,
      [value.columnId]: getAdditionalPropertyValue(value)
    }),
    {}
  );

export const getDefaultPropertyValue = (property: Property, value: PropertyValue) => {
  switch (property.type) {
    case PropertyType.Date:
    case PropertyType.Dropdown:
    case PropertyType.Text:
    case PropertyType.Link:
    case PropertyType.File:
      return value;
    case PropertyType.Person:
    case PropertyType.Numeric:
      return +value;
    default:
      throw new Error(`Unrecognized property type ${property.type}`);
  }
};

/**
 * Returns value of a custom property which is acceptable to BE's AdditionalDTO
 */
export const getCustomDTOValue = (type: PropertyType, value: PropertyValue | null): PropertyValueDTO => {
  if (!value) {
    return value;
  }

  switch (type) {
    case PropertyType.File:
      return (value as File[]).map(({ id }) => id);
    case PropertyType.Person:
      return { id: (value as { id: number }).id };
    default:
      return value as PropertyValueDTO;
  }
};

export const isFilterable = (filter: Filter, properties: Property[]) =>
  filter &&
  !!filter.property &&
  !!filter.op &&
  (!!filter.value ||
    properties!.find((p) => p.id === filter.property.id && p.type === PropertyType.Date && p.isAdditional));

const getDateRangeGraphQLFilter = (filter: Filter, valueFieldName: 'textValue' | 'createdAt') => {
  const [startDate, endDate] = getStartEndByDateRange(filter.val as string);

  if (!startDate && !endDate) {
    return `
      not: {
        ${valueFieldName}: {}
      }
    `;
  }

  const expression = `
    ${valueFieldName}: {
      greaterThanOrEqualTo: ${literal(toStartDate(startDate))}
      lessThanOrEqualTo: ${literal(toEndDate(endDate))}
    }
  `;

  if (filter.op === FilterOperators.NotWithin) {
    return ` not: { ${expression} }`;
  }

  return expression;
};

/**
 *
 * @return (first one is filter, second one is condition)
 */
export const propertyFilter = (
  originalFilter: Filter,
  properties: Property[],
  checkIfFilterable = false
): [string?, string?] => {
  if (checkIfFilterable && !isFilterable(originalFilter, properties)) {
    return [];
  }

  const filter = cloneDeep(originalFilter);
  const property = properties!.find((p) => p.id === filter.col);

  filter.val = (isDateLodash(filter.val) && filter.val.toISOString()) || filter.val;

  // split "op1,op2,op3" 'list' of values which comes from adv. filters select when appropriate
  let filterValList = filter.val as unknown as string[];
  if (!isArrayLodash(filter.val)) {
    filterValList = !isString(filter.val) ? [filter.val] : (filter.val as string).split(',');
  } else {
    filter.val = filter.val?.[0];
  }

  let simpleOp = '';
  let isListValue = false;
  let orNull = false;
  let isNotNull = false;
  switch (filter.op) {
    case FilterOperators.Like:
      simpleOp = 'includesInsensitive';
      break;
    case FilterOperators.EqualTo:
      simpleOp = 'equalTo';
      if (property?.type === PropertyType.Date && !filter.val) {
        orNull = true;
      }
      break;
    case FilterOperators.NotEqualTo:
      simpleOp = 'notEqualTo';
      orNull = true;
      if (property?.type === PropertyType.Date && !filter.val) {
        isNotNull = true;
        orNull = false;
      }
      break;
    case FilterOperators.In:
      simpleOp = 'in';
      isListValue = true;

      if (
        [PropertyType.Person, PropertyType.Dropdown].includes(property?.type) &&
        (((Array.isArray(filter.val) || isString(filter.val)) && isEmpty(filter.val)) || !filter.val)
      ) {
        orNull = true;
      }
      break;
    case FilterOperators.NotIn:
      simpleOp = 'notIn';
      isListValue = true;
      orNull = true;

      if (
        [PropertyType.Person, PropertyType.Dropdown].includes(property?.type) &&
        (((Array.isArray(filter.val) || isString(filter.val)) && isEmpty(filter.val)) || !filter.val)
      ) {
        orNull = false;
      }
      break;
    case FilterOperators.LessThan:
    case FilterOperators.Before:
      simpleOp = 'lessThan';
      break;
    case FilterOperators.GreaterThanOrEqualTo:
      simpleOp = 'greaterThanOrEqualTo';
      break;
    case FilterOperators.LessThanOrEqualTo:
      simpleOp = 'lessThanOrEqualTo';
      break;
    case FilterOperators.GreaterThan:
    case FilterOperators.After:
      simpleOp = 'greaterThan';
      break;
    case FilterOperators.NotWithin:
      simpleOp = '__foobar__';
      // orNull = true;
      break;
    case FilterOperators.Within:
      simpleOp = '__foobar__';
      break;
    default:
      simpleOp = '';
  }

  if (filter.col === AggregateColumn.TASK_WITH_TITLE) {
    if (!simpleOp) {
      throw new Error(`Unrecognized filter operator ${filter.op}`);
    }

    if (simpleOp === 'includesInsensitive') {
      return [
        `{tasks: {some: {
          title: {${simpleOp}: ${literal(filter.val)}},
          isArchived: {equalTo: false}
        }}}`
      ];
    }

    return [
      `{tasks: {every: {
          title: {${simpleOp}: ${literal(filter.val)}},
          isArchived: {equalTo: false}
        }}}`
    ];
  }

  // special filter 'stage' which has no property exposed to user, so it won't be in `useCompanyProperties`
  if (filter.col === -25) {
    if (!simpleOp) {
      throw new Error(`Unrecognized filter operator ${filter.op}`);
    }

    const filterValIds = filterValList.map(Number);

    return [
      `{or: [
      {stageId: {${simpleOp}: ${literal(isListValue ? filterValIds : filter.val)}}},
      {${orNull ? 'stageId: {isNull: true}' : ''}}                
    ]}`
    ];
  }

  if (!property) {
    // throw new Error(`Unrecognized property ${filter.col}`);
    console.error(`Unrecognized property ${filter.col}`);

    return '';
  }

  // if (property.multiple || isAddress(property)) {
  if (true) {
    switch (filter.op) {
      case FilterOperators.Contains:
        if (!isDropdown(property)) {
          throw new Error(`Filter operator ${filter.op} is only supported for multiple dropdowns`);
        }

        if (property.isAdditional) {
          return [
            `{projectPropertiesValues: {some: {
            columnId: {equalTo: ${property.id}},
            dropdownValue: {overlaps: ${literal(filterValList)}}
          }}}`
          ];
        }

        return [`{${property.mappedName}: {overlaps: ${literal(filterValList)}}}`];
      case FilterOperators.NotContains:
        if (!isDropdown(property)) {
          throw new Error(`Filter operator ${filter.op} is only supported for multiple dropdowns`);
        }

        if (property.isAdditional) {
          return [
            `{projectPropertiesValues: {none: {
            columnId: {equalTo: ${property.id}},
            dropdownValue: {overlaps: ${literal(filterValList)}}
          }}}`
          ];
        }

        return [`{not: {${property.mappedName}: {overlaps: ${literal(filterValList)}}}}`];
      case FilterOperators.ContainedBy:
        if (!isDropdown(property)) {
          throw new Error(`Filter operator ${filter.op} is only supported for multiple dropdowns`);
        }

        if (property.isAdditional) {
          return [
            `{projectPropertiesValues: {some: {
            columnId: {equalTo: ${property.id}},
            dropdownValue: {contains: ${literal(filterValList)}}
          }}}`
          ];
        }

        return [`{${property.mappedName}: {contains: ${literal(filterValList)}}}`];

      case FilterOperators.NotContainedBy:
        if (!isDropdown(property)) {
          throw new Error(`Filter operator ${filter.op} is only supported for multiple dropdowns`);
        }

        if (property.isAdditional) {
          return [
            `{projectPropertiesValues: {none: {
            columnId: {equalTo: ${property.id}},
            dropdownValue: {contains: ${literal(filterValList)}}
          }}}`
          ];
        }

        return [`{not: {${property.mappedName}: {contains: ${literal(filterValList)}}}}`];
      case FilterOperators.ContainsLike:
        // 'any array element like' only implemented for address in `condition` instead of `filter`
        if (property.id !== -3) {
          throw new Error(`Filter operator ${filter.op} is only supported for address`);
        }

        return [
          `{
          projectAddressesByProjectId: {
            some: {
              address: {
                includesInsensitive: ${literal(filter.val)}
              }
            }
          }
        }`,
          ''
        ];
      default:
        break;
      //   throw new Error(`Unrecognized filter operator ${filter.op} for multiple=${property.multiple}`);
    }
  }

  // normal filter over single value props
  if (!simpleOp) {
    throw new Error(`Unrecognized filter operator ${filter.op}`);
  }

  if (property.isAdditional) {
    return [
      `{or: [${
        isNotNull
          ? `{projectPropertiesValues: {some: {
              columnId: {equalTo: ${property.id}},
              ${
                isDropdown(property)
                  ? 'or: [{dropdownValue: {isNull: false}}, {dropdownValue: {notEqualTo: []}}]'
                  : 'textValue: {isNull: false}'
              }
           }}}`
          : `{projectPropertiesValues: {some: {
              columnId: {equalTo: ${property.id}},
              ${
                isDropdown(property)
                  ? // normally we are here when it's 1) single dropdown, 2) list value, 3) 'in' or 'notIn'
                    (simpleOp === 'in' && `dropdownValue: {containedBy: ${literal(filterValList)}}`) ||
                    (simpleOp === 'notIn' && `not: {dropdownValue: {containedBy: ${literal(filterValList)}}}`) ||
                    `dropdownValue: {${simpleOp}: ${literal(isListValue ? filterValList : filter.val)}}`
                  : (isDateRangeFilter(filter) && getDateRangeGraphQLFilter(filter, 'textValue')) ||
                    `${isPerson(property) ? 'workerValue' : 'textValue'}: {${simpleOp}: ${literal(
                      isListValue ? filterValList : filter.val
                    )}}`
              }
           }}},
       ${
         orNull
           ? `{projectPropertiesValues: {none: {
              columnId: {equalTo: ${property.id}},
              ${
                isDropdown(property)
                  ? 'or: [{dropdownValue: {isNull: false}}, {dropdownValue: {notEqualTo: []}}]'
                  : 'textValue: {isNull: false}'
              }
           }}}`
           : ''
       }`
      }]}`
    ];
  } else {
    const value = isListValue
      ? filterValList.map((listValue) => getDefaultPropertyValue(property, listValue))
      : getDefaultPropertyValue(property, filter.val);

    if (property.type === PropertyType.Date && ['equalTo', 'notEqualTo'].includes(simpleOp)) {
      const equalTo = `{${property.mappedName}: {greaterThanOrEqualTo: ${literal(
        moment(value as string)
          .hour(0)
          .min(0)
          .second(0)
          .millisecond(0)
          .toISOString()
      )}, lessThanOrEqualTo: ${literal(
        moment(value as string)
          .hour(23)
          .min(59)
          .second(59)
          .millisecond(999)
          .toISOString()
      )}}}`;

      return [simpleOp === 'notEqualTo' ? `{ not: ${equalTo} }` : equalTo];
    }

    if (isDateRangeFilter(filter)) {
      return [
        `{ or: [
          { ${getDateRangeGraphQLFilter(filter, property.mappedName)} }
          ${orNull ? `{ ${property.mappedName}: { equalTo: "" } }` : ''}
        ] }`
      ];
    }

    if (property.mappedName === 'jurisdictionId') {
      return [
        `{or: [
        {jurisdiction: {name: {${simpleOp}: ${literal(value)}}}},
        ${orNull ? `{jurisdiction: {name: {isNull: true}}}` : ''}
      ]}`
      ];
    }

    // status
    if (property.id === -46) {
      return [
        `{or: [
          {status: {${simpleOp}: [${(value as string[]).join(',')}]}},
          ${orNull ? `{status: {isNull: true}}` : ''}
        ]}`
      ];
    }

    // call disposition
    if (property.id === -53 || property.id === -54) {
      return [
        `{or: [
        {${property.mappedName}: {${simpleOp}: ${literalEnum(value)}}},
        ${orNull ? `{${property.mappedName}: {isNull: true}}` : ''}
      ]}`
      ];
    }

    return [
      `{or: [
        {${property.mappedName}: {${simpleOp}: ${literal(value)}}},
        ${orNull ? `{${property.mappedName}: {isNull: true}}` : ''}
      ]}`
    ];
  }
};

export const isCondition = (value: any, $properties: Property[]): value is Condition =>
  !!(
    value &&
    value.op &&
    value.property &&
    (!!value.value ||
      $properties!.find((p) => p.id === value.property.id && p.type === PropertyType.Date && p.isAdditional))
  );

export const mapConditionTreeToGraphQLFilter =
  (deep = 0, $properties: Property[] = []) =>
  (node: ConditionTree): string => {
    if (!node) {
      return '';
    }

    if (isCondition(node, $properties)) {
      const [filter] = propertyFilter(
        {
          op: node.op,
          val: node.value,
          col: node.property?.id,
          task: node.task,
          stage: node.stage
        },
        $properties
      );

      if (filter) {
        return filter;
      }
    }

    if (node.children) {
      return `${deep ? '{' : ''}${camelCase(node.logicOp)}: [
      ${node.children.map(mapConditionTreeToGraphQLFilter(deep + 1, $properties)).join(', ')}
    ]${deep ? '}' : ''}`;
    }

    return '';
  };

export const collectionIdsToNames = (collection: Identified[], fieldName = 'name') =>
  flow(
    defaultTo([]),
    map((id) => find({ id }, collection)),
    map(prop(fieldName)),
    join(', ')
  );

export const getPrimaryEmailPhone = (record: Project) => ({
  primaryEmail: record?.projectContacts?.[0]?.emails?.[0],
  primaryPhone: record?.projectContacts?.[0]?.phones?.[0]
});

const CUSTOM_PROPERTY_TEMPLATE_PADDING = -1000;

export const isTaskTemplateStandardProperty = (id: number) => id > CUSTOM_PROPERTY_TEMPLATE_PADDING;
export const convertFromTemplateMemberIdToPropertyId = (id: number) =>
  CUSTOM_PROPERTY_TEMPLATE_PADDING - id > 0 ? CUSTOM_PROPERTY_TEMPLATE_PADDING - id : id;

export const convertFromPropertyIdToTemplateMemberId = (id: number) =>
  id < 0 ? id : -id + CUSTOM_PROPERTY_TEMPLATE_PADDING;
