import {
  AnalyticsMeasureEntityType,
  AnalyticsSubmeasureType,
  AnalyticsWidgetType,
  DrilldownFilter,
  PredefinedWidgetFilterFieldId,
  WidgetSettings
} from '@features/Analytics/types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useWidgetDataPreview } from '@features/Analytics/useWidgetData';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { WorkOrderStatus } from '@components/WorkOrder/WorkOrderStatus';
import { Loader } from '@kit/ui/Loader';
import { useIntersectionObserver } from '@react-hookz/web/esm/useIntersectionObserver';
import { UserAvatar } from '@kit/components/UserAvatar';
import { PriorityIcon } from '@common/PriorityIcon';
import { TaskReport, TaskReportCondition, TaskReportFilter, TaskStatusEntity } from '@generated/types/graphql';
import { ProjectTitleChip } from '@components/Scheduler/components/ProjectTitleChip';
import { DateTime } from 'luxon';
import { LocalOffer } from '@material-ui/icons';
import { Popover } from '@kit/ui/Popover';
import { formatDate, formatDuration } from '@utils';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { Tooltip } from '@material-ui/core';
import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';
import {
  TableContainer,
  Table,
  WorkOrderTitleCell,
  NextPageLoaderWrapper,
  UserCell,
  ProjectChipContainer,
  LabelsContainer,
  LabelChip,
  Blurred,
  NoAccess,
  Description
} from './styled';
import { useWorkOrdersDynamicColumns } from './useWorkOrdersDynamicColumns';

interface Props {
  settings: WidgetSettings;
  drilldownFilter?: DrilldownFilter<TaskReportFilter, TaskReportCondition>;
}

export const WorkOrders = ({ settings, drilldownFilter }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { data, isFetchingNextPage, isFetching, fetchNextPage } = useWidgetDataPreview<
    TaskReport,
    TaskReportFilter,
    TaskReportCondition
  >(settings, drilldownFilter);

  const { data: statuses = [] } = useWorkOrderStatuses();

  const statusesMap = useMemo(() => {
    return statuses.reduce(
      (acc, status) => {
        acc[status.id] = status;

        return acc;
      },
      {} as Record<string, TaskStatusEntity>
    );
  }, [statuses]);

  const reports = useMemo(() => {
    return data?.pages.flatMap((page) => page.reports) ?? [];
  }, [data]);

  const totalCount = data?.pages[0]?.totalCount;

  const loadingRef = useRef<HTMLTableSectionElement>();

  const entry = useIntersectionObserver(loadingRef.current);

  const handleWorkOrderClick = useCallback(
    (report: TaskReport) => () => {
      window.open(`/${companyId}/scheduler/workOrders/${report.task.id}`, '_blank');
    },
    [companyId]
  );

  useEffect(() => {
    if (reports.length === 0 || isFetching) {
      return;
    }

    if (entry?.isIntersecting && reports.length < totalCount) {
      fetchNextPage();
    }
  }, [entry, reports, fetchNextPage, totalCount, isFetching]);

  const isVisitList = settings.measure?.entityType === AnalyticsMeasureEntityType.VISIT;

  const dynamicColumns = useWorkOrdersDynamicColumns(settings);

  const isTimeline = settings.widgetType === AnalyticsWidgetType.TIMELINE;

  if (isVisitList) {
    return (
      <TableContainer>
        <Table>
          <thead>
            <th>Visit date</th>
            <th>Visit number</th>
            <th>Work Order</th>
            <th>Work Order status</th>
            <th>Parent record</th>
            {dynamicColumns.map((column) => (
              <th key={column.name}>{column.name}</th>
            ))}
          </thead>
          <tbody>
            {reports.map((report, index) => {
              if (!report.task) {
                return (
                  <tr key={index} className="blur">
                    <td>
                      <Blurred>No access</Blurred>
                    </td>
                    <td colSpan={4 + dynamicColumns.length}>
                      <NoAccess>You don&apos;t have an access to this Work Order.</NoAccess>
                    </td>
                  </tr>
                );
              }

              return (
                <tr key={index} onClick={handleWorkOrderClick(report)}>
                  <td>
                    {DateTime.fromISO(report.visitStartDate, { zone: 'utc' })
                      .toLocal()
                      .toLocaleString(DateTime.DATETIME_SHORT)}
                  </td>
                  <td>
                    {report.task.taskVisitsByTaskId.findIndex((visit) => visit.id === report.visitId) + 1} /{' '}
                    {report.task.taskVisitsByTaskId.length}
                  </td>
                  <td>
                    <WorkOrderTitleCell>
                      <WorkOrderTypeIcon isField={report.task.isField} size="16px" />
                      <PriorityIcon priority={report.task.priority} size="16px" />
                      <div>
                        #{report.task.uid} · {report.task.title}
                      </div>
                    </WorkOrderTitleCell>
                  </td>
                  <td>
                    <WorkOrderStatus status={report.task.taskStatus} isArchived={report.task.isArchived} />
                  </td>
                  <td>
                    <ProjectChipContainer>
                      {report.task.project && <ProjectTitleChip target="_blank" project={report.task.project} />}
                    </ProjectChipContainer>
                  </td>

                  {dynamicColumns.map((column) => {
                    if (column.id === PredefinedWidgetFilterFieldId.WORK_ORDER_DESCRIPTION) {
                      return (
                        <td key={column.name}>
                          <Tooltip title={<div dangerouslySetInnerHTML={{ __html: column.getValue(report) }} />}>
                            <Description dangerouslySetInnerHTML={{ __html: column.getValue(report) }} />
                          </Tooltip>
                        </td>
                      );
                    }

                    return <td key={column.name}>{column.getValue(report)}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot ref={loadingRef}>
            <tr>
              {(isFetchingNextPage || isFetching) && (
                <td colSpan={5 + dynamicColumns.length}>
                  <NextPageLoaderWrapper>
                    <Loader size={48} />
                  </NextPageLoaderWrapper>
                </td>
              )}
            </tr>
          </tfoot>
        </Table>
      </TableContainer>
    );
  }

  const isSubmeasureColumnShown = settings.submeasureId;
  const isEntered = settings.submeasureTypeId === AnalyticsSubmeasureType.ENTERED;

  const totalColumns = 6 + (isTimeline ? 1 : 0) + (isSubmeasureColumnShown ? 1 : 0) + dynamicColumns.length;

  return (
    <TableContainer>
      <Table>
        <thead>
          <th>Name</th>
          <th>Status</th>
          {isTimeline && <th>Time in Status</th>}
          <th>Assignee</th>
          <th>Parent record</th>
          <th>Visits</th>
          <th>Labels</th>
          {isSubmeasureColumnShown && <th>{isEntered ? 'Entered date' : 'Exited date'}</th>}
          {dynamicColumns.map((column) => (
            <th key={column.name}>{column.name}</th>
          ))}
        </thead>
        <tbody>
          {reports.map((report, index) => {
            if (!report.task) {
              return (
                <tr key={index} className="blur">
                  <td>
                    <Blurred>No access</Blurred>
                  </td>
                  <td colSpan={totalColumns - 1}>
                    <NoAccess>You don&apos;t have an access to this Work Order.</NoAccess>
                  </td>
                </tr>
              );
            }

            return (
              <tr key={index} onClick={handleWorkOrderClick(report)}>
                <td>
                  <WorkOrderTitleCell>
                    <WorkOrderTypeIcon isField={report.task.isField} size="16px" />
                    <PriorityIcon priority={report.task.priority} size="16px" />
                    <div>
                      #{report.task.uid} · {report.task.title}
                    </div>
                  </WorkOrderTitleCell>
                </td>
                {isTimeline && (
                  <>
                    <td>
                      {statusesMap[report.timelineStatus] ? (
                        <WorkOrderStatus status={statusesMap[report.timelineStatus]} isArchived={false} />
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>{formatDuration(report.timelineStatusSpentTime)}</td>
                  </>
                )}
                {!isTimeline && (
                  <td>
                    <WorkOrderStatus status={report.task.taskStatus} isArchived={report.task.isArchived} />
                  </td>
                )}
                <td>
                  {report.task.assignee && (
                    <UserCell>
                      <UserAvatar user={report.task.assignee} />
                      {report.task.assignee?.firstName} {report.task.assignee?.lastName}
                    </UserCell>
                  )}
                </td>
                <td>
                  <ProjectChipContainer>
                    {report.task.project && <ProjectTitleChip target="_blank" project={report.task.project} />}
                  </ProjectChipContainer>
                </td>
                <td>{report.task.taskVisitsByTaskId.length}</td>
                <td>
                  <LabelsContainer>
                    {report.task.privilegedTaskLabels.length > 0 && (
                      <>
                        <LabelChip color={report.task.privilegedTaskLabels[0].label.color}>
                          <LocalOffer fontSize="small" />
                          <div>{report.task.privilegedTaskLabels[0].label.label}</div>
                        </LabelChip>

                        {report.task.privilegedTaskLabels.length > 1 && (
                          <Popover
                            content={
                              <div>
                                {report.task.privilegedTaskLabels.slice(1).map((label) => (
                                  <LabelChip color={label.label.color}>
                                    <LocalOffer fontSize="small" />
                                    <div>{label.label.label}</div>
                                  </LabelChip>
                                ))}
                              </div>
                            }
                          >
                            <div>{report.task.privilegedTaskLabels.length - 1} more</div>
                          </Popover>
                        )}
                      </>
                    )}
                    {report.task.privilegedTaskLabels.length === 0 && 'No labels'}
                  </LabelsContainer>
                </td>
                {isSubmeasureColumnShown && (
                  <td>
                    {isEntered &&
                      report.timelineStatusStartedAt &&
                      formatDate(report.timelineStatusStartedAt, 'MM/DD/YYYY hh:mm A')}
                    {!isEntered &&
                      report.timelineStatusEndedAt &&
                      formatDate(report.timelineStatusEndedAt, 'MM/DD/YYYY hh:mm A')}
                  </td>
                )}

                {dynamicColumns.map((column) => {
                  if (column.id === PredefinedWidgetFilterFieldId.WORK_ORDER_DESCRIPTION) {
                    return (
                      <td key={column.name}>
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: column.getValue(report) }} />}>
                          <Description dangerouslySetInnerHTML={{ __html: column.getValue(report) }} />
                        </Tooltip>
                      </td>
                    );
                  }

                  return <td key={column.name}>{column.getValue(report)}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot ref={loadingRef}>
          <tr>
            {(isFetchingNextPage || isFetching) && (
              <td colSpan={totalColumns}>
                <NextPageLoaderWrapper>
                  <Loader size={48} />
                </NextPageLoaderWrapper>
              </td>
            )}
          </tr>
        </tfoot>
      </Table>
    </TableContainer>
  );
};
