import deleteReq from '@services/api/base/delete';
import { Urls } from '@services/api/base/axios';
import get from '@services/api/base/get';
import put from '@services/api/base/put';
import post from '@services/api/base/post';
import { UpdateEmailAccountDTO } from '@services/api/types';
import { SendEmailDTO, EmailMessage, SendBulkEmailDto } from '@types';

export default {
  authorizeAccount: (companyId: number, email: string, redirectTo: string, alias: string, accountId?: number) =>
    get<string>(`${Urls.project}/email/auth`, { companyId, email, redirectTo, alias, accountId }),

  disconnectAccount: (accountId: number) => deleteReq(`${Urls.project}/email/account/${accountId}`),

  updateAccount: (accountId: number, dto: UpdateEmailAccountDTO) =>
    put(`${Urls.project}/email/account/${accountId}`, dto, { accountId }),

  sendEmail: (recordId: number, dto: SendEmailDTO, accountId: number) =>
    post<EmailMessage>(`${Urls.project}/email/message?projectId=${recordId}&accountId=${accountId}`, dto),
  sendBulkEmail: ({ companyId, dto }: { companyId: number; dto: SendBulkEmailDto }) =>
    post<{ status: string }>(`${Urls.project}/email/message-bulk?companyId=${companyId}`, dto)
};
