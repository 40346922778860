import React, { useCallback, useMemo, useState } from 'react';
import { Action, WorkflowEventType } from '@enums';
import { ZapIcon } from '@kit/ui/icons/Zap';
import { ChevronsUpIcon } from '@kit/ui/icons/ChevronsUp';
import { ChevronsDownIcon } from '@kit/ui/icons/ChevronsDown';
import { AlertCircle, Clipboard, RotateCw } from 'react-feather';
import { Tooltip } from '@material-ui/core';
import { useRetryExecuteAutomation } from '@hooks/automations/useRetryExecuteAutomation';
import { MailIcon } from '@kit/ui/icons/Mail';
import { CoperniqIcon } from '@kit/ui/icons/Coperniq';
import { Link } from '@reach/router';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { SmsIcon } from '@kit/ui/icons/Sms';
import { BriefcaseIcon } from '@kit/ui/icons/Briefcase';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { RecordType } from '@types';
import { cleanHtml } from '@utils/utils';
import {
  Clamped,
  Content,
  FailedInfoAndRetryContainer,
  FailedInfo,
  RetryButton,
  FeedItemIconContainer,
  GroupItemContainer,
  ItemActionButton,
  MetaInfo,
  MetaLabel,
  RetriedAt,
  CreatedAt,
  Actions,
  Title,
  TitleLeft,
  InlineAvatar,
  Container,
  BorderContainer,
  EmailFeedItemContainer,
  EmailFeedItemContent,
  CommentContent,
  CommentHeaderPart,
  CommentHeader,
  CommentHeaderTitle,
  EmailWrapper,
  EmailFeedItemHeader
} from './styled';
import { FeedItem, FeedItemProps } from './types';
import { BaseItemTemplate, getHumanFriendlyTimeAgo } from './BaseItemTemplate';
import { ENTITY_NAME_BY_RECORD_TYPE } from './constants';

export const AutomationAvatar = () => (
  <InlineAvatar>
    <CoperniqIcon size="24px" />
  </InlineAvatar>
);

interface EmailAndSmsProps {
  item: FeedItem;
  context: 'project' | 'client' | 'workOrder' | 'file';
  contextEntityId?: number;
}

const AutomationEmail = ({ item, context, contextEntityId }: EmailAndSmsProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [feedId] = useQueryParam(QueryParamsEnum.FeedId);

  const companyId = useAppSelector(selectWorkspaceId);

  const actionPayload = item.payload.action?.payload;

  const hasDetails = Boolean(actionPayload);

  const toggleExpanded = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const rawText = hasDetails ? cleanHtml(actionPayload.body || '') : '';

  const showRecordLink = context === 'client' && contextEntityId !== item.payload.project?.id;

  return (
    <EmailFeedItemContainer id={`feed_${item.id}`} isHighlighted={feedId && +feedId === item.id}>
      <BorderContainer>
        <div>
          <EmailFeedItemHeader>
            <FeedItemIconContainer color="#ECE3FF">
              <MailIcon color="#7256AC" size="12px" />
            </FeedItemIconContainer>
            <Title>
              <b>Email: {actionPayload?.subject || 'No subject'}</b>
            </Title>
          </EmailFeedItemHeader>
          <EmailFeedItemContent>
            <GroupItemContainer noRightPadding>
              <BorderContainer hideTopBorder>
                <CommentContent>
                  <CommentHeader>
                    <CommentHeaderPart verticalAlign="flex-start">
                      <AutomationAvatar />

                      <CommentHeaderTitle>
                        <div>
                          <b>Coperniq Automation</b>{' '}
                          <Link
                            to={`/${companyId}/workspace/automations/${item.payload.id}`}
                          >{`"${item.payload.name}"`}</Link>{' '}
                          sent email
                          {showRecordLink && (
                            <>
                              {` in ${ENTITY_NAME_BY_RECORD_TYPE[item.payload.project?.type || RecordType.PROJECT]} `}
                              <Link
                                to={`${window.location.pathname}/projects/${item.payload.project?.id}${window.location.search}`}
                              >
                                {item.payload.project?.title}
                              </Link>
                            </>
                          )}
                        </div>
                      </CommentHeaderTitle>
                    </CommentHeaderPart>
                    <CommentHeaderPart verticalAlign="center">
                      <CreatedAt>{getHumanFriendlyTimeAgo(item.createdAt)}</CreatedAt>
                      {hasDetails && (
                        <ItemActionButton onClick={toggleExpanded}>
                          {isExpanded ? <ChevronsUpIcon size="16px" /> : <ChevronsDownIcon size="16px" />}
                        </ItemActionButton>
                      )}
                    </CommentHeaderPart>
                  </CommentHeader>

                  <EmailWrapper>
                    {hasDetails && isExpanded && (
                      <MetaInfo>
                        <div>
                          <MetaLabel>From:</MetaLabel> <b>{actionPayload.from}</b>
                        </div>
                        <div>
                          <MetaLabel>To:</MetaLabel> <b>{actionPayload.to.join(', ')}</b>
                        </div>
                        {actionPayload.cc?.length > 0 && (
                          <div>
                            <MetaLabel>CC:</MetaLabel> <b>{actionPayload.cc.join(', ')}</b>
                          </div>
                        )}
                      </MetaInfo>
                    )}
                    {!isExpanded && <Clamped>{rawText}</Clamped>}
                    {isExpanded && <div dangerouslySetInnerHTML={{ __html: actionPayload.body }} />}
                  </EmailWrapper>
                </CommentContent>
              </BorderContainer>
            </GroupItemContainer>
          </EmailFeedItemContent>
        </div>
      </BorderContainer>
    </EmailFeedItemContainer>
  );
};

const AutomationSms = ({ item, context, contextEntityId }: EmailAndSmsProps) => {
  const actionPayload = item.payload.action?.payload;
  const companyId = useAppSelector(selectWorkspaceId);
  const [feedId] = useQueryParam(QueryParamsEnum.FeedId);

  const hasDetails = Boolean(actionPayload);

  const showRecordLink = context === 'client' && contextEntityId !== item.payload.project?.id;

  return (
    <Container id={`feed_${item.id}`} isHighlighted={feedId && +feedId === item.id}>
      <BorderContainer>
        <FeedItemIconContainer color="#EEF5E3">
          <SmsIcon color="#5F8425" size="16px" />
        </FeedItemIconContainer>

        <Content>
          <Title>
            <TitleLeft>
              <AutomationAvatar />
              <div>
                <b>Coperniq Automation</b>{' '}
                <Link to={`/${companyId}/workspace/automations/${item.payload.id}`}>{`"${item.payload.name}"`}</Link>{' '}
                sent an SMS
                {hasDetails && actionPayload.phone.length > 0 && ` to ${actionPayload.phone.join(', ')}`}
                {showRecordLink && (
                  <>
                    {` in ${ENTITY_NAME_BY_RECORD_TYPE[item.payload.project?.type || RecordType.PROJECT]} `}
                    <Link
                      to={`${window.location.pathname}/projects/${item.payload.project?.id}${window.location.search}`}
                    >
                      {item.payload.project?.title}
                    </Link>
                  </>
                )}
              </div>
            </TitleLeft>

            <Actions>
              <CreatedAt>{getHumanFriendlyTimeAgo(item.createdAt)}</CreatedAt>
            </Actions>
          </Title>
          <div>{hasDetails && <div dangerouslySetInnerHTML={{ __html: actionPayload.message }} />}</div>
        </Content>
      </BorderContainer>
    </Container>
  );
};

export const FailedInfoAndRetry = ({ item }: { item: FeedItem }) => {
  const isFailed = item.eventType === WorkflowEventType.FAILED_TO_EXECUTE;

  const { mutateAsync: retry } = useRetryExecuteAutomation();

  const handleRetryClick = useCallback(() => {
    retry({ feedItemId: item.id });
  }, [retry, item.id]);

  if (!isFailed) {
    return null;
  }

  return (
    <FailedInfoAndRetryContainer>
      <Tooltip title={item.payload?.errorMessage}>
        <FailedInfo>
          <AlertCircle size="16px" />
          <div>Automation failed.</div>
        </FailedInfo>
      </Tooltip>
      {item.retriedAt && <RetriedAt>Retried {getHumanFriendlyTimeAgo(item.retriedAt)}</RetriedAt>}
      {item.retryable && (
        <RetryButton onClick={handleRetryClick}>
          <RotateCw size="16px" />
          <div>Retry</div>
        </RetryButton>
      )}
    </FailedInfoAndRetryContainer>
  );
};

export const WorkflowFeedItem = ({ item, context, contextEntityId }: FeedItemProps) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const icon = useMemo(() => {
    switch (item.payload.action?.key ?? '') {
      case Action.ASSIGN_TASK:
      case Action.REPLACE_ASSIGNEE:
        return (
          <FeedItemIconContainer color="#CCEAE7">
            <Clipboard color="#00635A" size="12px" />
          </FeedItemIconContainer>
        );

      case Action.UPDATE_PROPERTY:
        return (
          <FeedItemIconContainer color="#F4E9EF">
            <BriefcaseIcon color="#9A6784" size="12px" />
          </FeedItemIconContainer>
        );

      default:
        return (
          <FeedItemIconContainer color="#DFDFE8">
            <ZapIcon color="#737382" size="12px" />
          </FeedItemIconContainer>
        );
    }
  }, [item]);

  const showRecordLink = context === 'client' && contextEntityId !== item.payload.project?.id;

  const { title, children } = useMemo(() => {
    switch (item.eventType) {
      case WorkflowEventType.WORKFLOW_EXECUTED:
        return {
          title: (
            <>
              <b>Coperniq Automation</b>{' '}
              <Link to={`/${companyId}/workspace/automations/${item.payload.id}`}>{`"${item.payload.name}"`}</Link>{' '}
              {item.payload.status === 'FAILED' ? 'failed to execute' : 'successfull executed'}
              {showRecordLink && (
                <>
                  {` in ${ENTITY_NAME_BY_RECORD_TYPE[item.payload.project?.type || RecordType.PROJECT]} `}
                  <Link
                    to={`${window.location.pathname}/projects/${item.payload.project?.id}${window.location.search}`}
                  >
                    {item.payload.project?.title}
                  </Link>
                </>
              )}
            </>
          ),
          children: null
        };

      case WorkflowEventType.EXECUTED_SUCCESSFULLY:
        return {
          title: (
            <>
              <b>Coperniq Automation</b>{' '}
              <Link to={`/${companyId}/workspace/automations/${item.payload.id}`}>{`"${item.payload.name}"`}</Link>{' '}
              successfully executed
              {showRecordLink && (
                <>
                  {` in ${ENTITY_NAME_BY_RECORD_TYPE[item.payload.project?.type || RecordType.PROJECT]} `}
                  <Link
                    to={`${window.location.pathname}/projects/${item.payload.project?.id}${window.location.search}`}
                  >
                    {item.payload.project?.title}
                  </Link>
                </>
              )}
            </>
          ),
          children: null
        };

      case WorkflowEventType.FAILED_TO_EXECUTE:
        return {
          title: (
            <>
              <b>Coperniq Automation</b>{' '}
              <Link to={`/${companyId}/workspace/automations/${item.payload.id}`}>{`"${item.payload.name}"`}</Link>{' '}
              failed to execute
              {showRecordLink && (
                <>
                  {` in ${ENTITY_NAME_BY_RECORD_TYPE[item.payload.project?.type || RecordType.PROJECT]} `}
                  <Link
                    to={`${window.location.pathname}/projects/${item.payload.project?.id}${window.location.search}`}
                  >
                    {item.payload.project?.title}
                  </Link>
                </>
              )}
            </>
          ),
          children: <FailedInfoAndRetry item={item} />
        };

      default:
        return {
          title: null,
          children: null
        };
    }
  }, [item, companyId, showRecordLink]);

  if (item.eventType === WorkflowEventType.EXECUTED_SUCCESSFULLY) {
    if (item.payload.action?.key === Action.SEND_EMAIL) {
      return <AutomationEmail item={item} context={context} contextEntityId={contextEntityId} />;
    }

    if (item.payload.action?.key === Action.SEND_SMS) {
      return <AutomationSms item={item} context={context} contextEntityId={contextEntityId} />;
    }
  }

  if (item.eventType === WorkflowEventType.FAILED_TO_EXECUTE) {
    if (item.payload.action?.key === Action.SEND_EMAIL) {
      return (
        <BaseItemTemplate
          id={item.id}
          context={context}
          authorIcon={<AutomationAvatar />}
          icon={
            <FeedItemIconContainer color="#D54855">
              <MailIcon color="#FFFFFF" size="12px" />
            </FeedItemIconContainer>
          }
          date={item.createdAt}
          title={
            <>
              Automation{' '}
              <Link to={`/${companyId}/workspace/automations/${item.payload.id}`}>{`"${item.payload.name}"`}</Link>{' '}
              failed to send email
              {showRecordLink && (
                <>
                  {` in ${ENTITY_NAME_BY_RECORD_TYPE[item.payload.project?.type || RecordType.PROJECT]} `}
                  <Link
                    to={`${window.location.pathname}/projects/${item.payload.project?.id}${window.location.search}`}
                  >
                    {item.payload.project?.title}
                  </Link>
                </>
              )}
            </>
          }
        >
          <FailedInfoAndRetry item={item} />
        </BaseItemTemplate>
      );
    }

    if (item.payload.action?.key === Action.SEND_SMS) {
      return (
        <BaseItemTemplate
          id={item.id}
          context={context}
          authorIcon={<AutomationAvatar />}
          icon={
            <FeedItemIconContainer color="#D54855">
              <SmsIcon color="#FFFFFF" size="12px" />
            </FeedItemIconContainer>
          }
          date={item.createdAt}
          title={
            <>
              <b>Coperniq Automation</b>{' '}
              <Link to={`/${companyId}/workspace/automations/${item.payload.id}`}>{`"${item.payload.name}"`}</Link>{' '}
              failed to send SMS
              {showRecordLink && (
                <>
                  {` in ${ENTITY_NAME_BY_RECORD_TYPE[item.payload.project?.type || RecordType.PROJECT]} `}
                  <Link
                    to={`${window.location.pathname}/projects/${item.payload.project?.id}${window.location.search}`}
                  >
                    {item.payload.project?.title}
                  </Link>
                </>
              )}
            </>
          }
        >
          <FailedInfoAndRetry item={item} />
        </BaseItemTemplate>
      );
    }
  }

  if (!title && !children) {
    return null;
  }

  return (
    <BaseItemTemplate
      id={item.id}
      context={context}
      icon={icon}
      date={item.createdAt}
      title={title}
      authorIcon={<AutomationAvatar />}
    >
      {children}
    </BaseItemTemplate>
  );
};
