import { useQuery } from 'react-query';
import { File, Project, ProjectStage } from '@generated/types/graphql';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { apiErrorHandler, integerIfFinite } from '@utils';
import { QueryParamsEnum, useQueryParam, useQueryParamMutation } from '@hooks/useQueryParam';
import { RecordType } from '@types';
import { useMemo } from 'react';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { orderBy, nth } from 'lodash';
import api from '@services/api/databankApi';
import { useRoutes } from '@hooks/useRoutes';
import { calculateFormProgress } from '@utils/files';
import { useCompanyProperties } from '@hooks/useCompanyProperties';

export enum PathType {
  STAGE = 'stage',
  FORM = 'form',
  PROJECT = 'project',
  FILE = 'file',
  MAIN = 'main'
}

export type Path = {
  id: number;
  name: string;
  type: PathType;
  projectType?: RecordType;
  file?: File;
};

export const useBreadCrumb = () => {
  const [stageIdString] = useQueryParam(QueryParamsEnum.StageId);
  const [projectIdString] = useQueryParam(QueryParamsEnum.ProjectId);
  const [fileIdString] = useQueryParam(QueryParamsEnum.FileId);
  const { setParams } = useQueryParamMutation();
  const stageId = useMemo(() => integerIfFinite(stageIdString), [stageIdString]);
  const fileId = useMemo(() => integerIfFinite(fileIdString), [fileIdString]);
  const projectId = useMemo(() => integerIfFinite(projectIdString), [projectIdString]);
  const { recordId } = useRoutes();
  const currentProjectId = useMemo(() => projectId ?? recordId, [projectId, recordId]);
  const { data: { id, type } = {} } = useRecordDetail(recordId!);

  const setPath = async (path: Omit<Path, 'name' | 'projectType'>, secondaryPath?: Omit<Path, 'name'>) => {
    if (path.type === PathType.PROJECT) {
      return setParams({
        [QueryParamsEnum.ProjectId]: path.id,
        [QueryParamsEnum.StageId]: undefined,
        [QueryParamsEnum.FileId]: undefined
      });
    }

    if (path.type === PathType.FILE) {
      return setParams({
        [QueryParamsEnum.ProjectId]: undefined,
        [QueryParamsEnum.StageId]: undefined,
        [QueryParamsEnum.FileId]: path.id
      });
    }

    if (path.type === PathType.STAGE) {
      return setParams({
        [QueryParamsEnum.ProjectId]: secondaryPath?.id,
        [QueryParamsEnum.StageId]: path.id,
        [QueryParamsEnum.FileId]: undefined
      });
    }

    return setParams({
      [QueryParamsEnum.ProjectId]: undefined,
      [QueryParamsEnum.StageId]: undefined,
      [QueryParamsEnum.FileId]: undefined
    });
  };

  const breadcrumb = useQuery(
    [ReactQueryKey.DatabankBreadcrumb, stageId, fileId, projectId],
    async () => {
      const path: Path[] = [
        {
          id,
          name: 'All documents',
          type: PathType.MAIN,
          projectType: type
        }
      ];

      if (fileId) {
        const { file } = await postGraphql<{ file: File }>(
          gql`
            query ($id: Int!) {
              file(id: $id) {
                id
                name
                type
                metaData
                downloadUrl
                annotations
                stage {
                  id
                  name
                }
                project {
                  id
                  title
                  type
                }
                type
                form {
                  id
                  name
                }
              }
            }
          `,
          {
            id: fileId
          }
        );

        if (file) {
          if (file.project && file.project.id !== id) {
            path.push({
              type: PathType.PROJECT,
              id: file.project.id,
              name: file.project.title ?? '',
              projectType: file.project.type as RecordType
            });
          }

          if (file?.stage) {
            path.push({
              type: PathType.STAGE,
              id: file.stage.id,
              name: file.stage.name
            });
          }

          path.push({
            type: PathType.FILE,
            id: file.id,
            name: file.name ?? '',
            file
          });
        }
      } else if (stageId || projectId) {
        if (projectId && projectId !== id) {
          const { project } = await postGraphql<{ project: Project }>(
            gql`
              query ($id: Int!) {
                project: project(id: $id) {
                  id
                  title
                  type
                }
              }
            `,
            {
              id: projectId
            }
          );

          if (project) {
            path.push({
              type: PathType.PROJECT,
              id: project.id,
              name: project.title ?? '',
              projectType: project.type as RecordType
            });
          }
        }

        if (stageId) {
          const { stage } = await postGraphql<{ stage: ProjectStage }>(
            gql`
              query ($id: Int!) {
                stage: projectStage(id: $id) {
                  id
                  name
                }
              }
            `,
            {
              id: stageId
            }
          );

          if (stage) {
            path.push({
              type: PathType.STAGE,
              id: stage.id,
              name: stage.name
            });
          }
        }
      }

      return path;
    },
    {
      enabled: !!id
    }
  );

  const setPathByPathIndex = async (index: number) => {
    const path = (breadcrumb.data ?? [])[index];
    if (path) {
      await setPath(path, path.type === PathType.STAGE ? (breadcrumb.data ?? [])[index - 1] : undefined);
    }
  };

  return {
    breadcrumb,
    setPath,
    currentPath: nth(breadcrumb.data ?? [], -1),
    setPathByPathIndex,
    stageId,
    fileId,
    projectId,
    mainId: id,
    currentProjectId,
    goBack: () =>
      breadcrumb.data?.length && breadcrumb.data.length > 1
        ? setPathByPathIndex(breadcrumb.data?.length - 2)
        : undefined
  };
};

export type StructureItem = Path & {
  file?: File & { form?: File['form'] & { progress: { total: number; filled: number } } };
  project?: Project;
  stage?: ProjectStage;
  createdBy?: File['createdByUser'];
  children: StructureItem[];
  updatedAt?: string;
};

export enum SortBy {
  NAME = 'name',
  LAST_MODIFIED = 'updatedAt',
  CREATED_BY = 'createdBy.firstName',
  FILE_SIZE = 'file.metaData.size'
}

const showOrder: { [key in PathType]?: number } = {
  [PathType.PROJECT]: 1,
  [PathType.STAGE]: 2
};

export const useDatabankStructure = (recordId: number, dependRecordOnly?: boolean, enabled = true) => {
  const { stageId, currentProjectId } = useBreadCrumb();
  const [search, setSearch] = useQueryParam(QueryParamsEnum.DatabankSearch);
  const [showArchivedString, setShowArchived] = useQueryParam(QueryParamsEnum.ShowArchived);
  const [sortBy, setSortBy] = useQueryParam(QueryParamsEnum.DatabankSortBy);
  const [sortOrder, setSortOrder] = useQueryParam(QueryParamsEnum.DatabankSortOrder);
  const { allProperties } = useCompanyProperties();

  const showArchived = useMemo(() => showArchivedString === '1', [showArchivedString]);
  const { data: targetProject } = useRecordDetail(currentProjectId!);

  const fileWrapper = (file: File) => ({
    file: {
      ...file,
      form: file.form
        ? {
            ...file.form,
            progress: calculateFormProgress(file.form.formLayouts, targetProject, allProperties)
          }
        : undefined
    },
    id: file.id,
    name: file.name,
    type: PathType.FILE,
    createdBy: file.createdByUser,
    updatedAt: file.updatedAt
  });

  const fetch = useQuery<StructureItem, undefined, StructureItem>(
    [ReactQueryKey.DatabankTree, stageId, currentProjectId, showArchived, search, targetProject, dependRecordOnly],
    async () => {
      try {
        if (!dependRecordOnly && stageId) {
          const { projectStage } = await postGraphql<{ projectStage: ProjectStage }>(
            gql`
              query ($projectId: Int!, $stageId: Int!, $showArchived: Boolean!, $search: String) {
                projectStage(id: $stageId) {
                  id
                  name
                  filesByStageId(
                    filter: {
                      isArchived: { in: [false, $showArchived] }
                      projectId: { equalTo: $projectId }
                      name: { includesInsensitive: $search }
                      privilege: { notEqualTo: "none" }
                    }
                  ) {
                    id
                    name
                    metaData
                    downloadUrl
                    type
                    annotations
                    projectId
                    isArchived
                    privilege
                    form {
                      id
                      formLayouts(filter: { type: { equalTo: "COLUMN" } }) {
                        column {
                          id
                          projectPropertyValuesByColumnId {
                            textValue
                          }
                          mappedName
                          projectColumnId
                          type
                        }
                      }
                    }
                    createdByUser {
                      id
                      firstName
                      lastName
                      avatarUrl
                    }
                    createdAt
                    updatedAt
                  }
                }
              }
            `,
            {
              showArchived,
              projectId: currentProjectId,
              search,
              stageId
            }
          );

          if (projectStage) {
            const { filesByStageId: files, ...stage } = projectStage;

            return {
              id: projectStage.id,
              name: projectStage.name,
              type: PathType.STAGE,
              stage,
              children: files.map(fileWrapper)
            };
          }

          return undefined;
        }

        const { project: projectRaw } = await postGraphql<{ project: Project }>(
          gql`
            query ($projectId: Int!, $showArchived: Boolean!, $search: String) {
              project(id: $projectId) {
                id
                title
                type
                blueprint {
                  blueprintProjectStages(
                    filter: { projectStage: { name: { includesInsensitive: $search } } }
                    orderBy: [POSITION_ASC]
                  ) {
                    projectStage {
                      id
                      name
                    }
                  }
                }
                projectsByParentProjectId(
                  filter: { isActive: { equalTo: true }, title: { includesInsensitive: $search } }
                  orderBy: [CREATED_AT_DESC]
                ) {
                  id
                  title
                  type
                  createdByUser {
                    id
                    firstName
                    lastName
                    avatarUrl
                  }
                  projectFilesConnection(
                    filter: {
                      isArchived: { in: [false, $showArchived] }
                      stageExists: true
                      privilege: { notEqualTo: "none" }
                    }
                  ) {
                    aggregates {
                      max {
                        updatedAt
                      }
                    }
                  }
                }
                projectFiles(
                  filter: {
                    name: { includesInsensitive: $search }
                    isArchived: { in: [false, $showArchived] }
                    stageExists: false
                    privilege: { notEqualTo: "none" }
                  }
                ) {
                  id
                  name
                  metaData
                  annotations
                  downloadUrl
                  type
                  projectId
                  isArchived
                  privilege
                  form {
                    id
                    formLayouts(filter: { type: { equalTo: "COLUMN" } }) {
                      column {
                        id
                        projectPropertyValuesByColumnId {
                          textValue
                        }
                        projectColumnId
                        mappedName
                        type
                      }
                    }
                  }
                  createdByUser {
                    id
                    firstName
                    lastName
                    avatarUrl
                  }
                  createdAt
                  updatedAt
                }
                projectFilesConnection(
                  filter: {
                    isArchived: { in: [false, $showArchived] }
                    stageExists: true
                    privilege: { notEqualTo: "none" }
                  }
                ) {
                  groupedAggregates(groupBy: [STAGE_ID]) {
                    keys
                    max {
                      updatedAt
                    }
                  }
                }
              }
            }
          `,
          {
            projectId: currentProjectId,
            ...(dependRecordOnly
              ? {
                  showArchived: false,
                  search: ''
                }
              : {
                  search,
                  showArchived
                })
          }
        );

        if (projectRaw) {
          const {
            projectsByParentProjectId: projects,
            blueprint,
            projectFiles: files,
            projectFilesConnection,
            ...project
          } = projectRaw;

          const stageIdToLastUpdated: { [id: number]: string } = projectFilesConnection.groupedAggregates.reduce(
            (acc, group) => ({
              ...acc,
              [group.keys[0]]: group.max.updatedAt
            }),
            {}
          );

          return {
            id: project.id,
            name: project.title,
            type: PathType.PROJECT,
            project,
            children: [
              ...projects.map((project) => ({
                project,
                id: project.id,
                name: project.title,
                type: PathType.PROJECT,
                createdBy: project.createdByUser,
                updatedAt: project.projectFilesConnection.aggregates.max.updatedAt
              })),
              ...(blueprint?.blueprintProjectStages ?? []).map(({ projectStage: stage = {} }) => ({
                stage,
                id: stage.id,
                name: stage.name ?? '',
                type: PathType.STAGE,
                updatedAt: stageIdToLastUpdated[stage.id]
              })),
              ...files.map(fileWrapper)
            ]
          };
        }

        return undefined;
      } catch (e) {
        throw apiErrorHandler('Error fetching databank structure', e);
      }
    },
    {
      enabled: !!targetProject && !!allProperties && enabled
    }
  );

  const dataSorted = useMemo(() => {
    if (fetch.data) {
      return {
        ...fetch.data,
        children: orderBy(fetch.data.children, sortBy, sortOrder === 'desc' ? 'desc' : 'asc')
          // shows files on the bottem, and project stage on the top
          .sort((a, b) => (showOrder[a.type] ?? 3) - (showOrder[b.type] ?? 3))
      };
    }

    return fetch.data;
  }, [sortBy, sortOrder, fetch.data]);

  return {
    ...fetch,
    data: dataSorted,
    setSearch,
    setShowArchived,
    showArchived,
    search,
    setSortBy,
    setSortOrder,
    sortBy,
    sortOrder,
    stageId
  };
};

export const useStorageInfo = () => {
  const { companyId } = useRoutes();

  return useQuery(
    [ReactQueryKey.StorageInfo, companyId],
    async () => (await api.fetchStorageInfo({ companyId })).data,
    {
      initialData: {
        used: 0,
        free: 0
      }
    }
  );
};
