import { alertShow } from '../../state/actions/alert/alertAction';
import {
  formLoginStart,
  formLoginFailed,
  googleLoginStart,
  googleLoginFailed,
  loginSuccess,
  companyRegisterStart,
  companyRegisterSuccess,
  companyRegisterFailed,
  generateResetPasswordTokenStart,
  generateResetPasswordTokenSuccess,
  generateResetPasswordTokenFailed,
  logOut,
  checkAuthTimeOut
} from '../../state/actions/authentication/authenticationAction';
import {
  formLoginUrl,
  googleLoginUrl,
  newRefreshTokenUrl,
  companyRegisterUrl,
  formLoginInviteConfirmUrl,
  getInviteCheckUrl
} from '../service.config';
import { getConfig } from '../service.utils';
import { navigate } from 'gatsby';
import authenticationApi from '../../services/api/authenticationApi';

export const isLoggedIn = () => {
  if (typeof window === 'undefined') return false;
  const userTokenString = localStorage.getItem('token');
  if (!userTokenString) return false;

  try {
    if (JSON.parse(userTokenString).accessToken?.token) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

export const formLogin = (data: { email: string; password: string }) => {
  return async (dispatch) => {
    dispatch(formLoginStart());
    try {
      const response = await fetch(formLoginUrl(), getConfig(JSON.stringify(data), 'POST'));
      const { status } = response;
      const responseData = await response.json();

      if (status === 200) {
        dispatch(loginSuccess({ ...responseData, method: 'form(email and password)' }));
      } else if (status === 401) {
        dispatch(formLoginFailed({ errorMessage: 'Email or password is wrong' }));
      }
    } catch (error) {
      console.log('Error sign in on form: ', error);
      dispatch(formLoginFailed(error));
    }
  };
};

export const inviteFormLogin = (data: { email: string; password: string; inviteToken: string; companyId: number }) => {
  return async (dispatch) => {
    dispatch(formLoginStart());
    try {
      const d = {
        email: data.email,
        password: data.password === null || data.password === '' ? undefined : data.password,
        token: data.inviteToken,
        companyId: data.companyId
      };
      const response = await fetch(formLoginInviteConfirmUrl(), getConfig(JSON.stringify(d), 'PUT'));
      const { status } = response;
      if (response.status === 200) {
        dispatch(formLogin(data));
      } else if (status === 400) {
        const responseData = await response.json();
        const { message = [] } = responseData;
        dispatch(
          generateResetPasswordTokenFailed({
            errorMessage: Array.isArray(message) ? message.join('\n') : message
          })
        );
      } else if (status === 403) {
        const responseData = await response.text();
        dispatch(
          generateResetPasswordTokenFailed({
            errorMessage: responseData
          })
        );
      }
    } catch (error) {
      console.log('Error sign in on form: ', error);
      dispatch(formLoginFailed(error));
    }
  };
};

const RESET_PASSWORD_ERROR_MAP = {
  'Token request interval is too short': 'You can request new one after one minute'
};

export const generateResetPasswordToken = (email: string) => {
  return async (dispatch) => {
    dispatch(generateResetPasswordTokenStart());
    try {
      const { data } = await authenticationApi.generateResetPasswordToken(email);
      if (data) {
        dispatch(generateResetPasswordTokenSuccess(data));
      } else {
        dispatch(
          generateResetPasswordTokenFailed({
            errorMessage: 'Something unexpected has happened, please try again.'
          })
        );
      }
    } catch (error) {
      const { status, data } = error?.response;
      console.log(error);
      if (status === 400) {
        dispatch(
          generateResetPasswordTokenFailed({
            errorMessage: RESET_PASSWORD_ERROR_MAP[data?.message] || data?.message
          })
        );
      } else {
        dispatch(generateResetPasswordTokenFailed(error));
      }
    }
  };
};

export const resetPassword = (email: string, token: string, password: string) => {
  return async (dispatch) => {
    dispatch(generateResetPasswordTokenStart());
    try {
      const { data } = await authenticationApi.resetPassword(email, token, password);
      if (data) {
        dispatch(generateResetPasswordTokenSuccess(data));
        dispatch(alertShow(['Password has successfully changed. You are now ready to login!'], 'success'));
        navigate('/authentication');
      } else {
        dispatch(
          generateResetPasswordTokenFailed({
            errorMessage: 'Something unexpected has happened, please try again.'
          })
        );
      }
    } catch (error) {
      const { data, status } = error?.response;
      if (status === 400) {
        const { message = [] } = data;
        dispatch(
          generateResetPasswordTokenFailed({
            errorMessage: Array.isArray(message) ? message.join('<br/>') : message
          })
        );
      } else if (status === 403) {
        dispatch(
          generateResetPasswordTokenFailed({
            errorMessage: data
          })
        );
      }
    }
  };
};

export const checkInviteValidity = (token: string, email: string, companyId: number, redirect: () => void) => {
  return (dispatch) => {
    fetch(getInviteCheckUrl(token, email, companyId), getConfig('', 'GET'))
      .then((response) => response.json())
      .then((result) => {
        if (!(result && result.isValid == true)) {
          redirect();
          dispatch(alertShow(['Invitation information is no longer valid.'], 'error'));
        }
      })
      .catch((error) => {
        redirect();
        console.log(error);
      });
  };
};

export const inviteGoogleLogin = (token: string, inviteToken: string) => {
  return async (dispatch) => {};
};

export const googleLogin = (token: string) => {
  return async (dispatch) => {
    dispatch(googleLoginStart());
    try {
      const response = await fetch(googleLoginUrl(token), getConfig('', 'GET'));
      const { status } = response;
      const responseData = await response.json();
      if (status === 200) {
        dispatch(loginSuccess({ ...responseData, method: 'google' }));
      } else if (status === 400) {
        dispatch(googleLoginFailed({ errorMessage: responseData.message }));
      }
    } catch (error) {
      console.log('Error sign in on google: ', error);
      dispatch(googleLoginFailed(error));
    }
  };
};

export const getNewRefreshToken = (refreshToken: string) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        newRefreshTokenUrl(refreshToken),
        getConfig(JSON.stringify({ refreshToken }), 'POST')
      );
      if ([200, 201].includes(response.status)) {
        const json = await response.json();

        // to accomodiete inaccuracy of settimeout and to prevent user make request before renewing expired token(60s)
        const safetyTime = +json.accessToken.expiresIn - 60 > 0 ? 60 : 0;
        dispatch(checkAuthTimeOut(+json.accessToken.expiresIn - safetyTime));
        dispatch(loginSuccess(json));
      } else {
        dispatch(logOut());
      }
    } catch (error) {
      console.log('Error get new refresh token: ', error);
    }
  };
};

export const registerCompany = (data: {
  companyName: string;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
}) => {
  return async (dispatch) => {
    dispatch(companyRegisterStart());
    try {
      const response = await fetch(companyRegisterUrl(), getConfig(JSON.stringify(data), 'POST'));
      if (response.status === 200) {
        const json = await response.json();

        dispatch(companyRegisterSuccess(json));
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.log('Error registering company: ', error);
      dispatch(companyRegisterFailed(error));
    }
  };
};
