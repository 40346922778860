import { defaultTheme } from '@themes';
import { makeStyles } from '@material-ui/core';
import { getColorWithOpacity } from '@styles';

type CheckboxProps = {
  size?: number;
};

const checkbox = (props: CheckboxProps) => {
  return {
    root: {
      width: props.size || '42px',
      height: props.size || '42px',
      padding: '0',
      color: defaultTheme.colors.green,
      '&:hover': {
        backgroundColor: getColorWithOpacity(defaultTheme.colors.white, 20)
      },
      '& + .MuiFormControlLabel-label': {
        font: `500 12px/1.25 ${defaultTheme.fonts.sans}`,
        color: defaultTheme.colors.black
      }
    },
    checked: {
      color: `${defaultTheme.colors.green} !important`,
      '&:hover': {
        backgroundColor: getColorWithOpacity(defaultTheme.colors.white, 20)
      }
    },
    disabled: {
      opacity: 0.5
    },
    indeterminate: {}
  };
};

export default checkbox;

export const useStylesCheckbox = (props: CheckboxProps = {}) => makeStyles(checkbox(props))();
