import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const InputWrapper = styled.div``;

const caption = css`
  margin-top: 4px;
  padding: 0;
  min-width: auto;
  font-size: 12px;
  font-weight: 400;

  a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Description = styled.div`
  ${caption};
  color: #828d9a;
`;

export const Error = styled.div`
  ${caption};
  color: #d54855;
`;

export const Label = styled.label`
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Asterisk = styled.span`
  color: #d54855;
`;
