import { defaults } from 'lodash';
import { BlueprintDTO, CreateBlueprintProjectStageDTO, CreateBlueprintTaskDTO, Search } from '@types';
import { ReactQueryKey } from '@enums';
import blueprintAPI from '@services/api/blueprintAPI';
import { useCRUD } from '@hooks/useCRUD';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiErrorHandler } from '@utils';
import { useRoutes, useAppSelector } from '@hooks';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { Blueprint } from '@generated/types/graphql';
import { selectWorkspaceId } from '@state/selectors';

const searchQueryKey = (many: boolean, companyId: number, search?: Partial<Search>) => [
  ReactQueryKey.WorkspaceBlueprints,
  companyId,
  many,
  'search',
  JSON.stringify(search || {})
];

export const useBlueprints = ($search?: Partial<Search> & { companyId?: never }) => {
  const search = defaults($search, {
    sortCol: 'createdAt'
  });
  const queryClient = useQueryClient();

  const companyId = useAppSelector(selectWorkspaceId);

  const { findQuery, updateMutation, createMutation, deleteMutation } = useCRUD<BlueprintDTO, { name: string }>({
    api: {
      find: (search) => blueprintAPI.findAll(companyId, search),
      create: (dto) => blueprintAPI.create(companyId, dto),
      update: (id, dto) => blueprintAPI.update(companyId, id, dto),
      remove: (id) => blueprintAPI.remove(companyId, id)
    },
    entityName: 'workflow',
    queryKey: searchQueryKey(false, companyId, search),
    getReadParams: () => ({ ...search, fetchAll: true }),
    initialFetch: !!companyId
  });

  const copyMutation = useMutation<BlueprintDTO, Error, { sourceId: number; dto: Partial<BlueprintDTO> }>(
    async ({ sourceId, dto }) => {
      try {
        return await blueprintAPI.copy(companyId, sourceId, dto);
      } catch (e) {
        throw apiErrorHandler('error copying blueprint', e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceBlueprints);
      }
    }
  );

  return {
    fetchAll: findQuery,
    update: updateMutation,
    create: createMutation,
    delete: deleteMutation,
    copy: copyMutation
  };
};

/**
 * @deprecated Use graphql version
 */
export const useBlueprintRest = (blueprintId: number) => {
  const { companyId } = useRoutes();

  const searchById: Partial<Search> = { filters: [{ col: 'id', op: '=', val: blueprintId }] };
  const fetchQuery = useQuery<BlueprintDTO>(
    searchQueryKey(true, companyId, searchById),
    async () => {
      try {
        const data = await blueprintAPI.findAll(companyId, searchById);

        return data.data.results[0];
      } catch (e) {
        throw apiErrorHandler('error fetching workflow', e);
      }
    },
    {
      refetchOnMount: false,
      enabled: !!blueprintId
    }
  );

  return {
    fetch: fetchQuery
  };
};

export const useBlueprint = (companyId: number, blueprintId?: number) =>
  useQuery(
    [ReactQueryKey.WorkspaceBlueprints, companyId, 'useBlueprint', blueprintId],
    async () => {
      try {
        return (
          await postGraphql<{ blueprint: Blueprint }>(
            gql`
              query ($blueprintId: Int!) {
                blueprint(id: $blueprintId) {
                  id
                  name
                  type
                  companyId
                  blueprintProjectStages(orderBy: [POSITION_ASC]) {
                    id
                    projectStage {
                      id
                      name
                      type
                      scope
                    }

                    blueprintTasks {
                      taskId
                    }
                  }
                }
              }
            `,
            { blueprintId }
          )
        ).blueprint;
      } catch (e) {
        throw apiErrorHandler('error fetching workflow', e);
      }
    },
    {
      enabled: !!companyId && !!blueprintId
    }
  );

export const useBlueprintMutations = (blueprintId: number) => {
  const queryClient = useQueryClient();

  const createProjectStageMutation = useMutation<void, Error, CreateBlueprintProjectStageDTO>(
    async (dto) => {
      try {
        await blueprintAPI.createProjectStage(blueprintId, dto);
      } catch (e) {
        throw apiErrorHandler('error creating workflow stage', e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceBlueprints);
        queryClient.invalidateQueries(ReactQueryKey.RecordStageList);
        queryClient.refetchQueries([ReactQueryKey.WorkflowList]);
      }
    }
  );

  const deleteProjectStageMutation = useMutation<void, Error, number>(
    async (stageId) => {
      try {
        await blueprintAPI.removeProjectStage(blueprintId, stageId);
      } catch (e) {
        throw apiErrorHandler('error removing workflow stage', e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceBlueprints);
        queryClient.invalidateQueries(ReactQueryKey.RecordStageList);

        queryClient.refetchQueries([ReactQueryKey.WorkflowList]);
      }
    }
  );

  const createTaskMutation = useMutation<void, Error, CreateBlueprintTaskDTO>(
    async (dto) => {
      try {
        await blueprintAPI.createTask(blueprintId, dto);
      } catch (e) {
        throw apiErrorHandler('error creating workflow work order', e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceBlueprints);
      }
    }
  );

  const deleteTaskMutation = useMutation<void, Error, number>(
    async (taskId) => {
      try {
        await blueprintAPI.removeTask(blueprintId, taskId);
      } catch (e) {
        throw apiErrorHandler('error removing workflow work order', e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceBlueprints);
      }
    }
  );

  return {
    createProjectStage: createProjectStageMutation,
    deleteProjectStage: deleteProjectStageMutation,
    createTask: createTaskMutation,
    deleteTask: deleteTaskMutation
  };
};
