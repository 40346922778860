import React, { useState } from 'react';
import { PropertiesGrid, PropertyContainer, PropertyName, PropertyValue } from './styled';
import { COLUMNS, COLUMNS_BY_ID, renderColumnPlain, SystemColumn } from '@features/SystemPortfolio/constants';
import { Widget, WidgetHeader, WidgetIcon, WidgetTitle } from '../styled';
import { InfoIcon } from '@kit/ui/icons/Info';
import { Box, Tooltip } from '@material-ui/core';
import { Edit2 } from 'react-feather';
import { RichTextField, useForm } from '@kit/components/Form';
import { useUpdateSystem } from '@hooks/systems/useUpdateSystem';
import { System } from '@generated/types/graphql';

interface Props {
  system: System;
}

export const NormalizedProperties = ({ system }: Props) => {
  const regularColumns = COLUMNS.filter((column) => !column.stats && column.id !== SystemColumn.notes);

  const notesColumn = COLUMNS_BY_ID[SystemColumn.notes];

  const { mutateAsync: update } = useUpdateSystem();

  const [showNotesEditor, setShowNotesEditor] = useState(false);

  const {
    form: { control },
    handleSubmit
  } = useForm<{ notes: string }>({
    onSubmit: async (values) => {
      await update({ id: system.id, dto: { notes: values.notes } });

      setShowNotesEditor(false);
    },
    defaultValues: {
      notes: system.notes
    }
  });

  return (
    <Widget size="large" isFullWidth>
      <WidgetHeader>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#DFDFE8">
            <InfoIcon size="16px" color="#737382" />
          </WidgetIcon>
          Properties
        </WidgetTitle>
      </WidgetHeader>

      <PropertiesGrid>
        {regularColumns.map(({ id, name }) => (
          <PropertyContainer key={id}>
            <PropertyName>{name}</PropertyName>
            <PropertyValue>
              <Tooltip title={renderColumnPlain(system, id)}>
                <span>{renderColumnPlain(system, id)}</span>
              </Tooltip>
            </PropertyValue>
          </PropertyContainer>
        ))}

        <PropertyContainer key={notesColumn.id} fullWidth>
          <PropertyName>
            <Box display="flex" alignItems="center" gridGap={8}>
              {notesColumn.name}
              <Edit2 size={16} color="#009688" style={{ cursor: 'pointer' }} onClick={() => setShowNotesEditor(true)} />
            </Box>
          </PropertyName>
          <PropertyValue>
            {showNotesEditor ? (
              <RichTextField name="notes" control={control} onBlur={handleSubmit} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: system.notes }} />
            )}
          </PropertyValue>
        </PropertyContainer>
      </PropertiesGrid>
    </Widget>
  );
};
