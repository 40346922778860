import React from 'react';
import { SelectFormik, SelectProps } from '@common/ui';
import { Column, RecordType } from '@types';
import { useCompanyProperties } from '@hooks/useCompanyProperties';

type PropertyTypeSelectProps = Omit<
  SelectProps<Column>,
  'options' | 'optionTitleKey' | 'optionIdKey'
> & { recordType?: RecordType };

export const PropertyTypeSelect: React.FC<PropertyTypeSelectProps> = ({
  recordType,
  ...props
}) => {
  const { editableColumns } = useCompanyProperties(recordType);

  return (
    <SelectFormik
      {...props}
      options={editableColumns || []}
      optionTitleKey="name"
      optionIdKey="id"
    />
  );
};
