import styled from 'styled-components';

export const TooltipProperty = styled.div`
  font: 12px/1.25 ${(props) => props.theme.fonts.sans};
  background-color: #57575f;
  color: ${({ theme }) => theme.colors.white};
  padding: 8px;
  max-width: 250px;
  line-break: anywhere;

  > p + p {
    &:empty {
      display: none;
    }

    margin-top: 10px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 1;

  svg {
    margin: 0 3px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.gray};

    &:hover,
    &:focus {
      color: ${(props) => props.theme.colors.green};
    }
  }
`;

export const WrapperStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const LinkValue = styled.a`
  color: #235dff;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;

  &:link,
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: #235dff;
    text-decoration: none;
  }
`;

export const LinkButton = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 0;
  color: #009688;
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;

  &:link,
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: #009688;
    text-decoration: none;
  }

  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    opacity: 1 !important;
    flex-shrink: 0;
  }
`;
