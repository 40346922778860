import colors from '../colors';
import fonts from '../fonts';
import getColorWithOpacity from '../Utils/getColorWithOpacity';

interface IProp {
  type?: string;
  color?: string;
}

const getBgColorByType = (props: IProp) => {
  const { type, color = colors.gray2 } = props;
  switch (type) {
    case 'project':
      return '#CCEAE7';
    case 'group-title':
      return color;
    default:
      return getColorWithOpacity(color, 20);
  }
};

const getColorByType = (props: IProp) => {
  const { type, color = colors.black } = props;
  switch (type) {
    case 'project':
      return colors.green;
    case 'group-title':
      return colors.white;
    default:
      return getColorWithOpacity(color, 80);
  }
};

export const wrapper = (props: IProp) => `
  display: inline-flex;
  align-items: center;

  width: auto;
  height: 24px;

  padding: 4px;

  background-color: ${getBgColorByType(props)};
  border-radius: 4px;

  font: 400 12px/1.25 ${fonts.sans};
  white-space: nowrap;
  color: ${getColorByType(props)};
`;

export const text = () => `
  max-width: -webkit-fill-available;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 4px;
`;

export const icon = (props: IProp) => `
  display: flex;
  min-width: ${props.size}px;
  width: ${props.size}px;
  height: ${props.size}px;
  color: ${props.color};
    
  svg {
    width: 100%;
    height: 100%;
    opacity: 0.8;
    fill: ${props.color};
  }
`;

export const user = () => `
  display: flex;
  align-items: center;
  
  > div {
    margin-right: 5px;
    margin-left: -5px;
  }
`;

export const remove = () => `
  min-width: 16px;
  width: 16px;
  height: 16px;

  margin: 0 0 0 5px;
  cursor: pointer;
  opacity: 0.6;

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

export default {
  wrapper,
  text,
  icon,
  remove,
  user
};
