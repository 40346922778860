import React from 'react';
import { XCircle } from 'react-feather';

import styled from 'styled-components';
import { checkbox, colors } from '../../../styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 40px;
`;
const StyledCheckbox = styled.div`
  ${checkbox}
`;

const Remove = styled(XCircle)`
  color: ${colors.gray};
  margin-left: auto;

  cursor: pointer;

  &:hover,
  &:focus {
    color: ${colors.green};
  }
`;

type CheckBox = {
  isChecked?: boolean;
  label?: string;
  onChange?: (event) => void;
  onClick?: (event) => void;
  onXClick?: (event) => void;
  disabled?: boolean;
};

/**
 * @deprecated
 */
const Checkbox: React.FC<CheckBox> = (props: CheckBox): JSX.Element => {
  const {
    isChecked = false,
    label = '',
    onChange = () => {},
    onClick = () => {},
    onXClick,
    styles,
    disabled
  } = props;
  return (
    <Wrapper style={styles}>
      <StyledCheckbox checked={isChecked} onClick={disabled ? undefined : onClick} disabled={disabled}>
        <input type="checkbox" onChange={disabled ? undefined : onChange} checked={isChecked} disabled={disabled} />
        <figure onClick={disabled ? undefined : onClick} />
        <label>{label}</label>
      </StyledCheckbox>
      {onXClick && <Remove size={20} onClick={onXClick} />}
    </Wrapper>
  );
};

export default Checkbox;
