import styled from 'styled-components';
import { HelpCircle } from 'react-feather';
import { defaultTheme } from '@themes';

export const inputLabel = (props: { bold?: boolean }) => {
  const { bold } = props;

  return {
    // .MuiInputLabel-root Styles applied to the root element.
    root: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '4px',
      fontWeight: bold ? 500 : 400,
      fontSize: '12px',
      lineHeight: '1.25',
      fontFamily: defaultTheme.fonts.sans,
      color: defaultTheme.colors.gray
    },
    // .Mui-focused Pseudo-class applied to the root element if focused={true}.
    focused: {},
    // .Mui-disabled Pseudo-class applied to the root element if disabled={true}.
    disabled: {},
    // .Mui-error Pseudo-class applied to the root element if error={true}.
    error: {},
    // .Mui-required Pseudo-class applied to the root element if required={true}.
    required: {},
    // .MuiInputLabel-asterisk Pseudo-class applied to the asterisk element.
    asterisk: {
      color: defaultTheme.colors.red
    },
    // .MuiInputLabel-formControl Styles applied to the root element if the component is a descendant of FormControl.
    formControl: {},
    // .MuiInputLabel-marginDense Styles applied to the root element if margin="dense".
    marginDense: {},
    // .MuiInputLabel-shrink Styles applied to the input element if shrink={true}.
    shrink: {},
    // .MuiInputLabel-animated Styles applied to the input element if disableAnimation={false}.
    animated: {},
    // .MuiInputLabel-filled Styles applied to the root element if variant="filled".
    filled: {},
    // .MuiInputLabel-outlined Styles applied to the root element if variant="outlined".
    outlined: {}
  };
};

export const RightSection = styled.div`
  margin-left: auto;
`;

export const TooltipText = styled.div`
  font: 400 13px/1.25 ${({ theme }) => theme.fonts.sans};
  margin-left: auto;

  p {
    margin-bottom: 5px;
  }
`;

export const Helper = styled(HelpCircle)`
  margin: 0 auto 0 5px;
  color: ${({ theme }) => theme.colors.gray};
  transition: 0.3s ease-out;

  &:hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;
