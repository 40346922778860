import React from 'react';

export const ArrowsThinIcon = ({ size = 24, color = '#DFDFE8', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M0.646446 3.64644C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.73079 4.34027 7.73079 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976309 4.7308 0.659727 4.53553 0.464464C4.34027 0.269202 4.02369 0.269202 3.82843 0.464464L0.646446 3.64644ZM25 3.5L1 3.5L1 4.5L25 4.5L25 3.5Z"
      fill={color}
    />
    <path
      d="M25.3536 20.3536C25.5488 20.1583 25.5488 19.8417 25.3536 19.6464L22.1716 16.4645C21.9763 16.2692 21.6597 16.2692 21.4645 16.4645C21.2692 16.6597 21.2692 16.9763 21.4645 17.1716L24.2929 20L21.4645 22.8284C21.2692 23.0237 21.2692 23.3403 21.4645 23.5355C21.6597 23.7308 21.9763 23.7308 22.1716 23.5355L25.3536 20.3536ZM25 19.5L1 19.5L1 20.5L25 20.5L25 19.5Z"
      fill={color}
    />
  </svg>
);
