import React from 'react';
import { Paperclip, Trash2 } from 'react-feather';
import styled from 'styled-components';

import { colors, fonts, device } from '@styles';
import Props from './types';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  border-radius: 4px;
  padding: 10px;

  font: 500 12px/1.25 ${fonts.sans};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    background-color: ${colors.white3};
  }

  > svg {
    margin-right: 10px;
  }
`;

const Size = styled.div`
  margin: 0 15px 0 auto;
  color: ${colors.gray};
`;

const Remove = styled.div`
  color: ${colors.gray};
  cursor: pointer;
  ${device.sm`
    opacity: 0;
  `}

  ${Wrapper}:hover &, ${Wrapper}:focus & {
    opacity: 1;
  }

  &:hover,
  &:focus {
    color: ${colors.green};
  }
`;

const FileListItem: React.FC<Props> = (props: Props) => {
  const { name, size, onRemove } = props;

  return (
    <Wrapper>
      <Paperclip size={20} color={colors.green} />
      {name}
      <Size>{(size / 1024 / 1024).toFixed(1)} MB</Size>
      <Remove>
        <Trash2 size="20" onClick={onRemove} />
      </Remove>
    </Wrapper>
  );
};

export default FileListItem;
