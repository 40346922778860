export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_TEAMS_START = 'FETCH_TEAMS_START';
export const FETCH_TEAMS_FAILED = 'FETCH_TEAMS_FAILED';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const ADD_FAV_PROJECT_SUCCESS = 'ADD_FAV_PROJECT_SUCCESS';
export const DELETE_FAV_PROJECT_SUCCESS = 'DELETE_FAV_PROJECT_SUCCESS';
export const UPDATE_KANBAN = 'UPDATE_KANBAN';
export const FETCH_KANBAN_START = 'FETCH_KANBAN_START';
export const FETCH_KANBAN_SUCCESS = 'FETCH_KANBAN_SUCCESS';
export const FETCH_KANBAN_FAILED = 'FETCH_KANBAN_FAILED';
export const UPDATE_KANBAN_START = 'UPDATE_KANBAN_START';
export const UPDATE_KANBAN_SUCCESS = 'UPDATE_KANBAN_SUCCESS';
export const UPDATE_KANBAN_FAILED = 'UPDATE_KANBAN_FAILED';
export const SET_KANBAN_WITH_ARCHIVED = 'SET_KANBAN_WITH_ARCHIVED';

export const ADD_USER = 'ADD_USER';
export const SET_SELECTED_USER_NEW_TEAM = 'SET_SELECTED_USER_NEW_TEAM';
export const SET_PUSHED_USER_NEW_TEAM = 'SET_PUSHED_USER_NEW_TEAM';
export const CLEAR_SELECTED_MEMBERS_NEW_TEAM =
  'CLEAR_SELECTED_MEMBERS_NEW_TEAM';
export const REMOVE_FROM_PUSHED_NEW_TEAM = 'REMOVE_FROM_PUSHED_NEW_TEAM';
export const REMOVE_FROM_SELECTED_NEW_TEAM = 'REMOVE_FROM_SELECTED_NEW_TEAM';
export const SEND_NEW_USER_INVITATION_START = 'SEND_NEW_USER_INVITATION_START';
export const SEND_NEW_USER_INVITATION_END = 'SEND_NEW_USER_INVITATION_END';
export const REMOVE_FORM_LABEL = 'REMOVE_FORM_LABEL';
export const FROM_BUILDER_UPDATE = 'FROM_BUILDER_UPDATE';
export const FORM_RESPONSE_UPDATE = 'FORM_RESPONSE_UPDATE';
export const FORM_BUILDER_FAILED = 'FORM_BUILDER_FAILED';
export const FORM_BUILDER_RESET = 'FORM_BUILDER_RESET';
export const USER_DATA_UPDATE = 'USER_DATA_UPDATE';
export const USER_DATA_UPDATE_CACHE = 'USER_DATA_UPDATE_CACHE';
export const FETCH_ACTIVITY_FEED_START = 'FETCH_ACTIVITY_FEED_START';
export const FETCH_ACTIVITY_FEED_SUCCESS = 'FETCH_ACTIVITY_FEED_SUCCESS';
export const FETCH_ACTIVITY_FEED_FAILED = 'FETCH_ACTIVITY_FEED_FAILED';
export const UPDATE_ACTIVITY_FEED_STATE = 'UPDATE_ACTIVITY_FEED_STATE';
export const FORM_LOGIN_START = 'FORM_LOGIN_START';
export const FORM_LOGIN_FAILED = 'FORM_LOGIN_FAILED';
export const GOOGLE_LOGIN_START = 'GOOGLE_LOGIN_START';
export const GOOGLE_LOGIN_FAILED = 'GOOGLE_LOGIN_FAILED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const UPDATE_ACCOUNT_SETTINGS = 'UPDATE_ACCOUNT_SETTINGS';
export const GENERATE_RESET_PASSWORD_TOKEN_START =
  'GENERATE_RESET_PASSWORD_TOKEN_START';
export const GENERATE_RESET_PASSWORD_TOKEN_SUCCESS =
  'GENERATE_RESET_PASSWORD_TOKEN_SUCCESS';
export const GENERATE_RESET_PASSWORD_TOKEN_FAILED =
  'GENERATE_RESET_PASSWORD_TOKEN_FAILED';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const RESET_PASSWORD_RESET_STATE = 'RESET_PASSWORD_RESET_STATE';
export const LOGOUT = 'LOGOUT';
export const AUTHENTICATION_UPDATE = 'AUTHENTICATION_UPDATE';
export const COMPANY_REGISTER_START = 'COMPANY_REGISTER_START';
export const COMPANY_REGISTER_SUCCESS = 'COMPANY_REGISTER_SUCCESS';
export const COMPANY_REGISTER_FAILED = 'COMPANY_REGISTER_FAILED';
export const REFRESH_TOKEN_TIMEOUT_UPDATE = 'REFRESH_TOKEN_TIMEOUT_UPDATE';
export const FETCH_COMPANY_USERS_START = 'FETCH_COMPANY_USERS_START';
export const FETCH_COMPANY_USERS_SUCCESS = 'FETCH_COMPANY_USERS_SUCCESS';
export const FETCH_COMPANY_USERS_FAILED = 'FETCH_COMPANY_USERS_FAILED';
export const UPDATE_USERS_SETTING = 'UPDATE_USERS_SETTING';
export const UPDATE_SINGLE_USER = 'UPDATE_SINGLE_USER';
export const MESSAGE_ALERT_SHOW = 'MESSAGE_ALERT_SHOW';
export const MESSAGE_ALERT_CLOSE = 'MESSAGE_ALERT_CLOSE';
export const MESSAGE_ALERT_CLEAR = 'MESSAGE_ALERT_CLEAR';
