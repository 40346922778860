import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import contactsAPI from '@services/api/contactsAPI';
import { ContactDTO, CreateContactDto, Paging, RecordType, Search, UpdateContactDto } from '@types';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { UseQueryResult } from 'react-query/types/react/types';
import { Contact, ContactsConnection, ContactsOrderBy, Project, StringListFilter } from '@generated/types/graphql';
import { apiErrorHandler } from '@utils';
import { Pagination } from '@common/Table';
import { sortBy, uniqBy } from 'lodash';
import { useToast } from './useToast';

export type ContactWithRelated = Contact & {
  contactAccounts: Project[];
  contactProjects: Project[];
  contactDeals: Project[];
};

export const useContact = (companyId: number, id: number) =>
  useQuery<ContactWithRelated>(
    [ReactQueryKey.WorkspaceContacts, companyId, `useContact-${id}`],
    async () => {
      try {
        return (
          await postGraphql<{ contact: ContactWithRelated }>(gql`
        query {
          contact(id: ${id}) {
            id, companyId, createdAt, emails, name, phones,
            createdByUser { id, email, firstName, lastName, avatarUrl },
            contactAccounts: contactProjects(filter: {type: {equalTo: "${RecordType.ACCOUNT}"}}) {
              id, title, type, accountType },
            contactProjects: contactProjects(filter: {type: {equalTo: "${RecordType.PROJECT}"}}) {
              id, title, type },
            contactDeals: contactProjects(filter: {type: {equalTo: "${RecordType.DEAL}"}}) {
              id, title, type }
          }
        }
      `)
        ).contact;
      } catch (e) {
        throw apiErrorHandler('Error fetching contact', e);
      }
    },
    {
      enabled: !!companyId && !!id
    }
  );

const mapSort = (search: Partial<Search>): ContactsOrderBy => {
  switch (search.sortCol) {
    case 'createdAt':
      return search.sortDesc ? ContactsOrderBy.CreatedAtDesc : ContactsOrderBy.CreatedAtAsc;
    case 'name':
      return search.sortDesc ? ContactsOrderBy.NameDesc : ContactsOrderBy.NameAsc;
    default:
      return ContactsOrderBy.NameDesc;
  }
};

export type ContactsSearch = Partial<Search> & {
  sortCol: 'createdAt' | 'name';
};

export const useContactsList = (
  companyId: number,
  search: ContactsSearch,
  paging: Pagination
): UseQueryResult<Paging<Contact>> => {
  const { search: searchFilter = '' } = search;

  const { perPage = 10, page = 1 } = paging;

  const variables = {
    companyId,
    first: perPage,
    offset: (page - 1) * perPage,
    orderBy: mapSort(search),
    search: searchFilter
  };

  return useQuery<Paging<Contact>>(
    [ReactQueryKey.WorkspaceContacts, companyId, `useContactsList-${JSON.stringify(search)}-${JSON.stringify(paging)}`],
    async () => {
      try {
        return (
          await postGraphql<{ contacts: Paging<Contact> }>(
            gql`
              query ($companyId: Int!, $first: Int!, $offset: Int!, $orderBy: ContactsOrderBy!, $search: String) {
                contacts: contactsConnection(
                  first: $first
                  offset: $offset
                  orderBy: [$orderBy]
                  filter: { companyId: { equalTo: $companyId }, searchString: { includesInsensitive: $search } }
                  condition: { withAccess: true }
                ) {
                  results: nodes {
                    id
                    companyId
                    createdAt
                    emails
                    name
                    phones
                    createdByUser {
                      id
                      email
                      firstName
                      lastName
                      avatarUrl
                    }
                    contactProjects {
                      id
                      title
                    }
                  }
                  total: totalCount
                }
              }
            `,
            variables
          )
        ).contacts;
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      enabled: !!companyId && !!search && !!paging,
      initialData: {
        results: [],
        total: 0
      },
      keepPreviousData: true
    }
  );
};

export const useDuplicateContacts = (
  companyId: number,
  candidateId: number | undefined,
  emails: string[],
  phones: string[]
) =>
  useQuery<Contact[]>(
    [
      ReactQueryKey.WorkspaceContacts,
      companyId,
      `useDuplicateContacts-${candidateId}-${JSON.stringify(emails)}-${JSON.stringify(phones)}`
    ],
    async () => {
      if (!emails.length && !phones.length) {
        return [];
      }

      const variables = {
        companyId,
        candidateId,
        emailsFilter: emails.length ? ({ notEqualTo: [], overlaps: emails } as Partial<StringListFilter>) : null,
        phonesFilter: phones.length ? ({ notEqualTo: [], overlaps: phones } as Partial<StringListFilter>) : null
      };

      try {
        return (
          await postGraphql<{ contacts: Contact[] }>(
            gql`
              query (
                $companyId: Int!
                $candidateId: Int
                $emailsFilter: StringListFilter
                $phonesFilter: StringListFilter
              ) {
                contacts(
                  filter: {
                    companyId: { equalTo: $companyId }
                    id: { notEqualTo: $candidateId }
                    or: [{ emails: $emailsFilter }, { phones: $phonesFilter }]
                  }
                  condition: { withAccess: true }
                ) {
                  id
                  name
                  emails
                  phones
                  companyId
                }
              }
            `,
            variables
          )
        ).contacts;
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      enabled: !!companyId && (!!emails.length || !!phones.length),
      initialData: [],
      keepPreviousData: true
    }
  );

/**
 * Returns first 10 contacts matching given title
 */
export const useContactsByQuery = (companyId: number, query: string) =>
  useQuery<Contact[]>(
    [ReactQueryKey.WorkspaceContacts, companyId, query],
    async () => {
      const variables = {
        companyId,
        query
      };

      try {
        return (
          await postGraphql<{ contactsConnection: ContactsConnection }>(
            gql`
              query ($companyId: Int!, $query: String) {
                contactsConnection(
                  filter: { companyId: { equalTo: $companyId }, searchString: { includesInsensitive: $query } }
                  first: 10
                ) {
                  nodes {
                    id
                    name
                    emails
                    phones
                    companyId
                    contactProjects {
                      id
                    }
                  }
                }
              }
            `,
            variables
          )
        ).contactsConnection.nodes;
      } catch (e) {
        throw apiErrorHandler('Error fetching contacts list', e);
      }
    },
    {
      enabled: !!companyId && !!query,
      initialData: [],
      keepPreviousData: true
    }
  );

type RelatedContactsRecord = {
  project: {
    contacts?: Contact[];
    children: { childrenContacts: Contact[] }[];
  };
};

export const useRelatedContacts = (companyId: number, recordId: number) =>
  useQuery(
    [ReactQueryKey.WorkspaceContacts, companyId, `useRelatedContacts-${recordId}`],
    async () => {
      try {
        const related = await postGraphql<RelatedContactsRecord>(
          gql`
            query ($recordId: Int!) {
              project(id: $recordId) {
                contacts: projectContacts {
                  id
                  companyId
                  name
                  emails
                  phones
                }
                children: projectsByParentProjectId {
                  childrenContacts: projectContacts {
                    id
                    companyId
                    name
                    emails
                    phones
                  }
                }
              }
            }
          `,
          { recordId }
        );

        return sortBy(
          uniqBy(
            [
              ...(related.project.contacts || []),
              ...(related.project.children?.flatMap((child) => child.childrenContacts) || [])
            ],
            'id'
          ),
          'id'
        );
      } catch (e) {
        throw apiErrorHandler('Error fetching contacts list', e);
      }
    },
    {
      enabled: !!companyId && !!recordId,
      initialData: []
    }
  );

export const useContactMutations = (companyId: number) => {
  const queryClient = useQueryClient();

  const { showSuccess, showError } = useToast();

  const createMutation = useMutation<ContactDTO, Error, { dto: CreateContactDto }>(
    async ({ dto }) => {
      try {
        return (await contactsAPI.create(companyId, dto)).data;
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceContacts);
        showSuccess('Contact created');
      }
    }
  );

  const updateMutation = useMutation<ContactDTO, Error, { id: number; dto: UpdateContactDto }>(
    async ({ id, dto }) => {
      try {
        return (await contactsAPI.update(companyId, id, dto)).data;
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceContacts);
        showSuccess('Contact updated');
      }
    }
  );

  const deleteMutation = useMutation<void, Error, number>(
    async (id) => {
      try {
        await contactsAPI.remove(companyId, id);
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceContacts);
        showSuccess('Contact deleted');
      }
    }
  );

  const inviteToPortal = useMutation<void, Error, number>(
    async (id) => {
      try {
        await contactsAPI.inviteToPortal(companyId, id);
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Invitation sent');
      }
    }
  );

  const createContactPreviewToken = useMutation<string, Error, number>(
    async (id) => {
      try {
        const { data } = await contactsAPI.getPreviewToken(companyId, id);

        return data.accessToken;
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      }
    }
  );

  return {
    create: createMutation,
    update: updateMutation,
    delete: deleteMutation,
    inviteToPortal,
    createContactPreviewToken
  };
};
