import styled from 'styled-components';

import { fonts } from '../../../styles';

type SizeProp = 'small' | 'big';

type ColorsProp = {
  bg: string;
  text: string;
  border?: string;
};

const mapUserSize = (size: SizeProp) => ({
  small: 20,
  big: 32,
}[size]) || 24;

const mapCompanyInitialsSize = (size: SizeProp) => ({
  small: 10,
  big: 20,
}[size]) || 14;

const mapCompanyInitialsFontSize = (size: SizeProp) => ({
  small: 4,
  big: 8,
}[size]) || 6.5;

const mapCompanyInitialsBorderRadius = (size: SizeProp) => ({
  small: 3,
  big: 5,
}[size]) || 4;

export const User = styled.div<{ bgUrl?: string; size?: 'small' | 'big'; colors: ColorsProp; noMargin?: boolean; }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: ${props => mapUserSize(props.size)}px;
  height: ${props => mapUserSize(props.size)}px;
  color: ${(props) => props.colors.text};
  text-transform: uppercase;
  font: 500 10px/1.25 ${fonts.sans};
  background-color: ${props => props.colors.bg};
  
  border: none;
  color: #fff;
  overflow: visible;

  margin-right: 5px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px
  }
`;

export const CompanyInitials = styled.span<{ size?: SizeProp; colors: ColorsProp; }>`
  position: absolute;
  right: -1px;
  bottom: -1px;

  display: flex;
  align-items: center;
  justify-content: center;
  
  width: ${props => mapCompanyInitialsSize(props.size)}px;
  height: ${props => mapCompanyInitialsSize(props.size)}px;
  color: ${(props) => props.colors.text};

  background: ${(props) => props.colors.bg};
  border-radius: ${props => mapCompanyInitialsBorderRadius(props.size)}px;
  
  border: 1px solid #fff;
  border-color: ${(props) => props.colors.border};
  font: 600 ${(props) => mapCompanyInitialsFontSize(props.size)}px/14px "Rubik";
  color: ${(props) => props.colors.text};
  text-transform: uppercase;
`;
