import { renderPropertyIcon } from '@common/Properties';
import { PropertyValue } from '@common/Properties/PropertyValue';
import { CopyButton } from '@common/ui';
import { ValueHoverableWrapper, ValueWrapper, Edit } from '@components/Project/Properties/styled';
import { DEFAULT_GROUP_ID, usePropertyGroups } from '@hooks/usePropertyGroup';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { PropertyType, RecordType } from '@types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntersectionObserver } from '@react-hookz/web';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { sortAndFilterStandardProperties } from '@utils/properties';
import { SearchBar } from '@common/SearchBar';
import { debounce, isEqual } from 'lodash';
import {
  Container,
  Content,
  Group,
  GroupTitle,
  PropertiesGrid,
  PropertyName,
  GroupsNav,
  GroupNavItem,
  EditableFieldContainer,
  PropertyContainer,
  ContentContainer,
  SearchContainer
} from './styled';
import { PropertyForm } from './PropertyForm';

interface Props {
  projectId: number;
}

export const useRecordWithProperties = (projectId: number) => {
  const { data: detail } = useRecordDetail(projectId, { refetchOnMount: false });

  return useMemo(() => {
    if (!detail || !detail.id) {
      return null;
    }

    return {
      projectId,
      projectDetail: detail,
      memberList: detail.projectMembers,
      projectProgress: 0
    };
  }, [detail, projectId]);
};

// TODO types
export const Property = ({ projectId, property }: any) => {
  const project = useRecordWithProperties(projectId);

  const { isLoading } = useAllCompaniesUsers();

  const { scopeToEditableColumns } = useCompanyProperties();
  const [isEdit, setIsEdit] = useState(false);

  const recordType = project.projectDetail.type as RecordType;

  const isEditable = scopeToEditableColumns[recordType]?.some((field) => field.id === property.id);

  const handleFinishEdit = useCallback(() => {
    setIsEdit(false);
  }, []);

  const isLink = property.type === PropertyType.Link;

  return (
    <PropertyContainer isFullWidth={property.mappedName === 'description'}>
      <PropertyName>{property.name}</PropertyName>

      {isEdit && !isLoading && <PropertyForm project={project} property={property} onFinishEdit={handleFinishEdit} />}

      {!isEdit && (
        <PropertyValue
          withTooltip
          noTitleInTooltip
          NameWrapper={({ children }) => children}
          project={project}
          property={property}
          // eslint-disable-next-line
          MainWrapper={(props) => {
            return (
              <EditableFieldContainer
                isReadOnly={!isEditable || isLink}
                onClick={isEditable ? () => setIsEdit(true) : undefined}
              >
                <ValueHoverableWrapper container>
                  {property.mappedName === 'description' ? (
                    <ValueWrapper {...props} style={{ fontWeight: 400 }}>
                      <div dangerouslySetInnerHTML={{ __html: project.projectDetail.description }} />
                    </ValueWrapper>
                  ) : (
                    <ValueWrapper
                      {...props}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 4,
                        fontWeight: 400
                      }}
                    >
                      {props.formattedValue
                        ? renderPropertyIcon({
                            property,
                            style: { width: 16, height: 16 }
                          })
                        : null}
                      {props.children}
                    </ValueWrapper>
                  )}
                  {isLink && <Edit onClick={() => setIsEdit(true)} size={20} />}
                  {!isLink && props.formattedValue ? <CopyButton value={props.formattedValue} size={20} /> : null}
                </ValueHoverableWrapper>
              </EditableFieldContainer>
            );
          }}
        />
      )}
    </PropertyContainer>
  );
};

const GroupProperties = memo(
  ({ projectId, tab, onEnter, containerRef }: any) => {
    const nodeRef = useRef();

    const observer = useIntersectionObserver(nodeRef, { root: containerRef.current, threshold: [0.5, 0.5] });

    useEffect(() => {
      if (observer?.isIntersecting) {
        onEnter(tab.id);
      }
    }, [tab.id, onEnter, observer?.isIntersecting]);

    return (
      <Group ref={nodeRef} id={`properties_group_${tab.id}`}>
        <GroupTitle>{tab.title}</GroupTitle>

        <PropertiesGrid>
          {tab.properties.map((property) => (
            <Property projectId={projectId} key={property.id} property={property} />
          ))}
        </PropertiesGrid>
      </Group>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.tab.properties, nextProps.tab.properties)
);

export const FullProperties = ({ projectId }: Props) => {
  const [searchValue, setSearchValue] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchValueChangeDebounced = useCallback(debounce(setSearchValue, 300), []);

  const containerRef = useRef<HTMLDivElement>(null);
  const [activeGroup, setActiveGroup] = useState<number | null>(null);
  const record = useRecordWithProperties(projectId);
  const { data: groups } = usePropertyGroups({ scope: RecordType.ACCOUNT });

  const tabs = useMemo(() => {
    if (!groups || !record) {
      return [];
    }

    return groups
      .map((group) => ({
        id: group.id,
        title: group.name,
        ...group,
        properties: (group.id === DEFAULT_GROUP_ID
          ? sortAndFilterStandardProperties(group.properties)
          : group.properties
        ).filter((property) =>
          searchValue.trim() ? property.name.toLowerCase().includes(searchValue.trim().toLowerCase()) : true
        )
      }))
      .filter((group) => group.properties.length > 0);
  }, [groups, record, searchValue]);

  useEffect(() => {
    if (tabs.length > 0) {
      setActiveGroup(tabs[0].id);
    }
  }, [tabs]);

  const handleNavItemClick = useCallback(
    (id: number) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();

      const group = document.getElementById(`properties_group_${id}`);

      if (!group) {
        return;
      }

      group.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });

      setActiveGroup(id);
    },
    []
  );

  const handleEnterGroup = useCallback((id: number) => {
    setActiveGroup(id);
  }, []);

  if (!record) {
    return null;
  }

  return (
    <Container>
      <SearchContainer>
        <SearchBar placeholder="Search properties..." onValueChange={handleSearchValueChangeDebounced} />
      </SearchContainer>
      <ContentContainer ref={containerRef}>
        <Content>
          {tabs.map((tab) => (
            <GroupProperties
              key={tab.id}
              tab={tab}
              onEnter={handleEnterGroup}
              projectId={record.projectId}
              containerRef={containerRef}
            />
          ))}
        </Content>
        <GroupsNav>
          {tabs.map((tab) => (
            <GroupNavItem key={tab.id} isActive={activeGroup === tab.id}>
              <a href={`#properties_group_${tab.id}`} onClick={handleNavItemClick(tab.id)}>
                {tab.title}
              </a>
            </GroupNavItem>
          ))}
        </GroupsNav>
      </ContentContainer>
    </Container>
  );
};
