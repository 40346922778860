import React, { useCallback, useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { File as FileIcon, Trash, Upload as UploadIcon } from 'react-feather';
import { Upload, UploadProps } from 'antd';
import FileListItemProps from '@common/AddFileForm/FileListItem/types';
import { useToast } from '@hooks/useToast';
import { colors } from '@styles';
import { CircularProgress } from '@material-ui/core';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { uploadSingleFile } from '@services/UploadServices/uploadService';
import { File as GqlFile } from '@generated/types/graphql';
import {
  DropZoneContainer,
  Header,
  Spinner,
  Thumbnail,
  FileRow,
  FileRowLeft,
  RemoveFileButton,
  ThumbnailContainer
} from './styled';
import { useControllerWithValidation } from '../../useControllerWithValidation';
import { FormField } from '../../FormField';
import { FormControl, FormInputPropsToOmit } from '../../types';

const { Dragger } = Upload;

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<{}, FormInputPropsToOmit>,
    FormControl<TFieldValues, TName> {
  maxFileMbSize?: number;
  isDisabled?: boolean;
  dropZoneHeight?: string;
  isMultiple?: boolean; // TODO: not really working
  onFileClick?: (fileId: number, allFileIds: number[]) => void;
  onUpload?: (file: File) => Promise<GqlFile>;
  onRemoved?: (fileId: number, currentValue: GqlFile[]) => void;
}

export const FileDropZoneField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  clearOnUnmount,
  maxFileMbSize = 350,
  isDisabled = false,
  isMultiple = false,
  dropZoneHeight = '80px',
  onUpload,
  onFileClick,
  onRemoved
}: Props<TFieldValues, TName>) => {
  const [uploadingList, setUploadingList] = useState<FileListItemProps[]>([]);
  const { showError } = useToast();
  const companyId = useAppSelector(selectWorkspaceId);

  const {
    field: { value = [], onChange, ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  const uploadFile = useCallback(
    async (file: File) => {
      setUploadingList((prev) => [...prev, file]);

      let result;

      if (onUpload) {
        result = await onUpload(file);
      } else {
        const formData = new FormData();
        formData.append('file', file);

        result = (await uploadSingleFile(formData, 'company', companyId)).data;
      }

      setUploadingList((prev) => prev.filter((item) => item !== file));

      onChange([...value, result]);
    },
    [value, companyId, onChange, onUpload]
  );

  const uploadProps: UploadProps = {
    multiple: isMultiple,
    showUploadList: false,
    beforeUpload: async (file) => {
      if (file.size / 1024 / 1024 > maxFileMbSize) {
        // 350mb
        showError(`Maximum file size is ${maxFileMbSize} MB`);

        return false;
      }

      uploadFile(file);

      return true;
    }
  };

  const handleRemove = useCallback(
    (file) => (e) => {
      e.stopPropagation();

      const newValue = value.filter((item) => item !== file);
      onChange(newValue);

      onRemoved?.(file.id, newValue);
    },
    [value, onChange, onRemoved]
  );

  return (
    <FormField name={name} label={label} error={error?.message} description={description}>
      <DropZoneContainer isDisabled={isDisabled} height={dropZoneHeight} data-testid={`field-${controlProps.name}`}>
        <Dragger {...uploadProps} disabled={isDisabled}>
          <Header>
            <UploadIcon size="16px" color={colors.green} />
            <span data-testid="addFileChooseFile">
              drag & drop a file here or <strong>browse</strong>
            </span>
          </Header>
        </Dragger>
      </DropZoneContainer>

      {(value || []).map((file) => (
        <FileRow
          onClick={
            onFileClick
              ? () => {
                  onFileClick(
                    file.id,
                    value.map((f) => f.id)
                  );
                }
              : undefined
          }
          isClickable={Boolean(onFileClick)}
          key={file.id}
        >
          <FileRowLeft
            href={file.downloadUrl}
            target="_blank"
            onClick={
              onFileClick
                ? (e) => {
                    e.preventDefault();
                  }
                : undefined
            }
          >
            <ThumbnailContainer>
              {file?.metaData?.thumbnailUrl ? (
                <Thumbnail url={file.metaData.thumbnailUrl} />
              ) : (
                <FileIcon size="16px" color="#9C9CAA" />
              )}
            </ThumbnailContainer>
            <div>{file.name}</div>
          </FileRowLeft>
          {!isDisabled && (
            <RemoveFileButton onClick={handleRemove(file)}>
              <Trash size="16px" />
            </RemoveFileButton>
          )}
        </FileRow>
      ))}

      {uploadingList.map((file) => (
        <FileRow key={file.id}>
          <FileRowLeft>
            <Spinner style={file.previewUrl ? { backgroundImage: `url(${file.previewUrl})` } : undefined}>
              <CircularProgress size={16} style={{ color: colors.green }} />
            </Spinner>
            <div>{file.name}</div>
          </FileRowLeft>
        </FileRow>
      ))}
    </FormField>
  );
};
