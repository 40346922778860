import {
  Form,
  FormValidationRules,
  useForm,
  SelectField,
  InputField,
  AddressField,
  RichTextField
} from '@kit/components/Form';
import React, { useMemo, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { selectCurrentUser, selectWorkspaceId } from '@state/selectors';
import { useAppSelector, useCompanyPropertiesWithoutRouter } from '@hooks';
import { useRecordsMutations, useRelatedRecords } from '@hooks/useRecords';
import { useContactMutations, useContactsByQuery } from '@hooks/useContacts';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { useBlueprints } from '@hooks/useBlueprints';

import { AccountType, RecordType, User } from '@types';
import { PropertyValueField } from '@features/Platform/Properties/PropertyValueField';
import { DeepPartial } from 'redux';
import { getCustomDTOValue, isButtonOrLink } from '@utils/properties';
import { isEmail, validateUrl } from '@utils/validations';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { ProjectColumnVisibility } from '@generated/types/graphql';
import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { CustomFields } from './CustomFields';
import { Sections, SectionTitle, Grid, ContactOptionTitle, ContactOptionMeta } from './styled';

interface FormValues {
  client: any;
  primaryContact: any; // TODO
  primaryContactPhone?: string;
  primaryContactEmail?: string;

  title: string;
  address: string;

  trades: any;
  projectSize: any;
  projectValue: any;
  workflow: any;

  ownerId: User;
  salesRepId: User;
  projectManagerId: User;

  description: string;
}

interface Props {
  onClose: () => void;
  initialValues?: DeepPartial<FormValues>;
  isPreview?: boolean;
}

export const ProjectForm = ({ initialValues, isPreview, onClose }: Props) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const companyId = useAppSelector(selectWorkspaceId);
  const {
    properties,
    scopeToEditableColumns,
    fetchQuery: { isFetched: propertiesLoaded }
  } = useCompanyPropertiesWithoutRouter(companyId, { recordType: RecordType.PROJECT });
  const {
    fetchAll: { data: workflows }
  } = useBlueprints();

  const additionalPropertiesById = useMemo(() => {
    return scopeToEditableColumns[RecordType.PROJECT]
      .filter((property) => property.isAdditional)
      .reduce((acc, property) => {
        acc[property.id] = property;

        return acc;
      }, {});
  }, [scopeToEditableColumns]);

  const customPropsValidations = useMemo(() => {
    return scopeToEditableColumns[RecordType.PROJECT].reduce((acc, property) => {
      if (property.isAdditional && property.internalVisibility === ProjectColumnVisibility.Required) {
        acc[`additional.${property.id}`] = {
          isRequired: true
        };
      }

      if (property.isAdditional && isButtonOrLink(property)) {
        if (!acc[`additional.${property.id}`]) {
          acc[`additional.${property.id}`] = {};
        }
        acc[`additional.${property.id}`].validate = validateUrl;
      }

      return acc;
    }, {});
  }, [scopeToEditableColumns]);

  const projectWorkflows = useMemo(
    () => workflows?.results.filter((workflow) => workflow.type === RecordType.PROJECT) ?? [],
    [workflows]
  );

  const {
    tradesProperty,
    projectSizeProperty,
    projectValueProperty,
    ownerIdProperty,
    salesRepIdProperty,
    projectManagerIdProperty
  } = useMemo(() => {
    return ['trades', 'projectSize', 'projectValue', 'ownerId', 'salesRepId', 'projectManagerId'].reduce(
      (acc, propertyId) => {
        const foundProperty = properties?.find((property) => property.mappedName === propertyId);

        if (!foundProperty) {
          return acc;
        }

        return {
          ...acc,
          [`${propertyId}Property`]: foundProperty
        };
      },
      {}
    );
  }, [properties]);

  const { create: createClient, update: updateClient } = useRecordsMutations(companyId, RecordType.ACCOUNT);
  const { create: createProject } = useRecordsMutations(companyId, RecordType.PROJECT);
  const { create: createContact } = useContactMutations(companyId);

  const [, debouncedClientInputValue, setClientInputValue] = useDebouncedState('', 300);

  const [, debouncedNameInputValue, setContactNameInput] = useDebouncedState('', 300);

  const { data: clients = [] } = useRelatedRecords(companyId, RecordType.ACCOUNT, debouncedClientInputValue);

  const { data: contacts = [] } = useContactsByQuery(companyId, debouncedNameInputValue);

  const postForm = async (values: FormValues) => {
    let contact = values.primaryContact;
    let { client } = values;

    if (values.primaryContact.id === 0) {
      contact = await createContact.mutateAsync({
        dto: {
          name: values.primaryContact.name.trim(),
          emails: values.primaryContactEmail ? [values.primaryContactEmail.trim()].filter(Boolean) : [],
          phones: values.primaryContactPhone ? [values.primaryContactPhone.trim()].filter(Boolean) : []
        }
      });
    }

    if (client.id === 0) {
      client = await createClient.mutateAsync({
        dto: {
          title: values.client.title.trim(),
          accountType: values.client.accountType,
          ownerId: values.ownerId?.id,
          address: values.address ? [values.address] : [],
          contacts: [{ contactId: contact.id }]
        }
      });
    }

    if (client && isEmpty(client.address)) {
      client = await updateClient.mutateAsync({
        id: client.id,
        dto: {
          address: [values.address]
        }
      });
    }

    const dto = {
      title: values.title.trim(),
      description: values.description,
      address: [values.address],
      ownerId: values.ownerId?.id ?? null,
      salesRepId: values.salesRepId?.id ?? null,
      projectManagerId: values.projectManagerId?.id ?? null,
      blueprintId: values.workflow?.id ?? null,
      type: RecordType.PROJECT,
      trades: values.trades,
      projectSize: values.projectSize,
      projectValue: values.projectValue,
      parentProjectId: client?.id,
      contacts: [{ contactId: contact.id }],
      additional: Object.keys(values.additional ?? {}).reduce((acc, propertyId) => {
        return {
          ...acc,
          [propertyId]: getCustomDTOValue(additionalPropertiesById[propertyId].type, values.additional[propertyId])
        };
      }, {})
    };

    await createProject.mutateAsync({ dto });

    onClose();
  };

  const defaultValues = {
    projectSize: 0,
    projectValue: 0,
    ownerId: currentUser,
    ...initialValues
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    defaultValues
  });

  const {
    formState: { isSubmitting },
    control,
    watch,
    setValue,
    reset
  } = form;

  useEffect(() => {
    if (propertiesLoaded) {
      reset({
        ...defaultValues,
        additional: scopeToEditableColumns[RecordType.PROJECT].reduce((acc, property) => {
          if (!property.isAdditional || !property.additional?.defaultValue) {
            return acc;
          }

          return {
            ...acc,
            [property.id]: property.additional.defaultValue
          };
        }, {})
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertiesLoaded]);

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (type !== 'change') {
        return;
      }

      const { client, primaryContact } = values;

      if (name === 'client') {
        if (client && client.id !== 0) {
          if (client.address[0]) {
            setValue('address', client.address[0]);
          }

          if (client.projectContacts[0]) {
            setValue('primaryContact', client.projectContacts[0]);
            setValue('primaryContactPhone', client.projectContacts[0].phones[0] ?? '');
            setValue('primaryContactEmail', client.projectContacts[0].emails[0] ?? '');
          }
        }

        if (client) {
          setValue('title', client.title);
        }

        if (!client) {
          setValue('address', '');
          setValue('primaryContact', '');
          setValue('primaryContactPhone', '');
          setValue('primaryContactEmail', '');
          setValue('title', '');
        }
      }

      if (name === 'primaryContact') {
        if (primaryContact && primaryContact.id !== 0) {
          setValue('primaryContactPhone', primaryContact.phones[0] ?? '');
          setValue('primaryContactEmail', primaryContact.emails[0] ?? '');
        }

        if (!primaryContact) {
          setValue('primaryContactPhone', '');
          setValue('primaryContactEmail', '');
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, setValue]);

  const primaryContact = watch('primaryContact');

  const isNewContact = primaryContact && primaryContact.id === 0;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      client: {
        isRequired: true
      },
      primaryContact: {
        isRequired: true
      },
      primaryContactEmail: {
        validate: (value) => {
          if (!value || !value.trim()) {
            return undefined;
          }

          return isEmail(value.trim()) ? undefined : 'Invalid email';
        }
      },
      title: {
        isRequired: true
      },
      address: {
        isRequired: true
      },
      trades: {
        isRequired: true
      },
      workflow: {
        isRequired: true
      },
      ...customPropsValidations
    }),
    [customPropsValidations]
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody width="700px">
        <Sections>
          <div>
            <SectionTitle>Primary contact</SectionTitle>

            <Grid>
              <SelectField
                label="Client"
                control={control}
                name="client"
                getOptionLabel={(option: any) => option.title}
                options={clients}
                isCreatable
                createButtonText={(inputValue: string) =>
                  inputValue ? `+ Create "${inputValue}" as new client` : '+ Create new client'
                }
                buildNewOption={(inputValue: string) => {
                  return { id: 0, title: inputValue, accountType: AccountType.RESIDENTIAL, address: [] };
                }}
                onInputChange={(e) => {
                  if (!e) {
                    return;
                  }
                  setClientInputValue(e.target?.value ?? '');
                }}
              />
              <SelectField
                label="Full name"
                control={control}
                name="primaryContact"
                placeholder="Search by name, email or phone"
                getOptionLabel={(option: User) => option.name}
                filterOptions={(options) => options}
                options={contacts}
                renderOption={(contact) => (
                  <div>
                    <ContactOptionTitle>{contact.name}</ContactOptionTitle>
                    <ContactOptionMeta>
                      {[contact.emails?.[0], contact.phones?.[0]].filter(Boolean).join(', ')}
                    </ContactOptionMeta>
                  </div>
                )}
                isCreatable
                createButtonText={(inputValue: string) =>
                  inputValue ? `+ Create "${inputValue}" as new contact` : '+ Create new contact'
                }
                buildNewOption={(inputValue: string) => {
                  return { id: 0, name: inputValue };
                }}
                onInputChange={(e) => {
                  if (!e) {
                    return;
                  }
                  setContactNameInput(e.target?.value ?? '');
                }}
              />

              <InputField
                disabled={primaryContact && !isNewContact}
                control={control}
                label="Phone"
                name="primaryContactPhone"
              />
              <InputField
                disabled={primaryContact && !isNewContact}
                control={control}
                label="Email"
                name="primaryContactEmail"
              />
            </Grid>
          </div>

          <div>
            <SectionTitle>Standard properties</SectionTitle>

            <Grid>
              <InputField control={control} label="Title" name="title" />

              {tradesProperty && (
                <PropertyValueField
                  label="Trades"
                  name={tradesProperty.isAdditional ? `additional.${tradesProperty.id}` : tradesProperty.mappedName}
                  property={tradesProperty}
                  control={control}
                />
              )}

              <AddressField label="Site address" name="address" control={control} />

              {projectSizeProperty && (
                <PropertyValueField
                  label="Project size"
                  name={
                    projectSizeProperty.isAdditional
                      ? `additional.${projectSizeProperty.id}`
                      : projectSizeProperty.mappedName
                  }
                  property={projectSizeProperty}
                  control={control}
                />
              )}

              <SelectField
                label="Workflow"
                name="workflow"
                control={control}
                options={projectWorkflows}
                getOptionLabel={(option: any) => option.name}
              />

              {projectValueProperty && (
                <PropertyValueField
                  label="Project value"
                  name={
                    projectValueProperty.isAdditional
                      ? `additional.${projectValueProperty.id}`
                      : projectValueProperty.mappedName
                  }
                  property={projectValueProperty}
                  control={control}
                />
              )}
            </Grid>
            <RichTextField label="Description" name="description" control={control} />
          </div>

          <div>
            <SectionTitle>Key members</SectionTitle>

            <Grid>
              {salesRepIdProperty && (
                <PropertyValueField
                  label="Sales rep"
                  name={
                    salesRepIdProperty.isAdditional
                      ? `additional.${salesRepIdProperty.id}`
                      : salesRepIdProperty.mappedName
                  }
                  property={salesRepIdProperty}
                  control={control}
                />
              )}
              {projectManagerIdProperty && (
                <PropertyValueField
                  label="Project manager"
                  name={
                    projectManagerIdProperty.isAdditional
                      ? `additional.${projectManagerIdProperty.id}`
                      : projectManagerIdProperty.mappedName
                  }
                  property={projectManagerIdProperty}
                  control={control}
                />
              )}
              {ownerIdProperty && (
                <PropertyValueField
                  label="Owner"
                  name={ownerIdProperty.isAdditional ? `additional.${ownerIdProperty.id}` : ownerIdProperty.mappedName}
                  property={ownerIdProperty}
                  control={control}
                />
              )}
            </Grid>
          </div>

          <CustomFields type={RecordType.PROJECT} control={control} />
        </Sections>
      </ModalBody>
      <ModalFooter>
        <Button disabled={isPreview} variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isPreview || isSubmitting} variant={ButtonVariant.Primary} type="submit">
          Create
        </Button>
      </ModalFooter>
    </Form>
  );
};
