import styled from 'styled-components';

export const Menu = styled.div`
  && {
    .MuiTypography-body2 {
      font:
        500 12px/14px Rubik,
        sans;
    }

    padding: 8px;
  }
`;

export const Trigger = styled.div<{ isHidden: boolean }>`
  cursor: ${({ isHidden }) => (isHidden ? 'default' : 'pointer')};
  display: flex;
`;
