import styled from 'styled-components';
import { colors, fonts, inputMui, selectMui } from '@styles';
import { Trash2 } from 'react-feather';
import { makeStyles } from '@material-ui/core';

export const useStylesInput = makeStyles(inputMui({
  height: '40px'
}));

export const useStylesSelect = makeStyles(selectMui({}));

export const AddButton = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  font: 500 12px/1.25 ${fonts.sans};
  color: ${colors.gray};
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  &:hover, &:focus {
    color: ${colors.green};
  }
`;

export const DefaultValue = styled.div`
  position: absolute;
  z-index: 10;
  top: 12px;
  left: 16px;
  font: 14px/1.25 ${fonts.sans};
  white-space: nowrap;
  max-width: calc(100% - 50px);
  text-transform: lowercase;
  overflow: hidden;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Field = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  font: 14px/1.25 ${fonts.sans};

  > div {
    width: 100%;
  }

`;

export const Menu = styled.div`
  padding: 15px;

  ul {
    padding: 0;
  }
`;

export const MenuItem = styled.li`
  display: flex;
  align-items: center;
  margin-top: 20px;

  &:hover, &:focus {
    background: none;
  }
`;

export const Placeholder = styled.div`
  position: absolute;
  z-index: 10;
  top: 12px;
  left: 16px;
  font: 300 14px/1.25  ${fonts.sans};
  color: ${colors.gray};
`;

export const Primary = styled.span`
  display: block;
  font-size: 12px;
  color: ${colors.gray};
  position: absolute;
  top: -20px;
`;

export const Remove = styled(Trash2)`
  margin-left: 10px;
  cursor: pointer;
  color: ${colors.gray};
  opacity: 0;

  ${MenuItem}:hover & {
    opacity: 1;
  }

  &:hover {
    color: ${colors.red};
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;
