import React from 'react';
import { Form, FormValidationRules, SelectField, useForm } from '@kit/components/Form';
import usePlacesAutocomplete, { Suggestion } from 'use-places-autocomplete';
import { uniq, upperFirst } from 'lodash';
import { PropertyType } from '@types';
import { useCompanyPropertiesMutations } from '@hooks/useCompanyProperties';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Body, Footer, Option } from './styled';
import { Site } from '../types';

interface Props {
  site?: Site;
  clientId: number;
  onClose: () => void;
}

interface FormValues {
  address: string;
}

const rules: FormValidationRules<FormValues> = {
  address: {
    isRequired: true
  }
};

const toSuggestion = (address: string): Suggestion => ({
  description: address,
  structured_formatting: {
    main_text: upperFirst(address)
  }
});

const toAddress = (suggestion: Suggestion): string => suggestion?.description || '';

export const SiteForm = ({ clientId, site, onClose }: Props) => {
  const isNew = !site;
  const { data: record } = useRecordDetail(clientId, { refetchOnMount: false });

  const {
    updateProjectProperty: { mutateAsync: updateProjectProperty }
  } = useCompanyPropertiesMutations();

  const {
    value: searchValue,
    setValue: setSearchValue,
    suggestions: { data, loading }
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ['us'] }
    }
  });

  const defaultSuggestion = toSuggestion(searchValue);

  const postForm = async (values: FormValues) => {
    const address = toAddress(values.address);

    if (isNew) {
      // just add new site to the client
      await updateProjectProperty({
        projectId: clientId,
        property: {
          id: -3,
          isAdditional: false,
          type: PropertyType.Text,
          mappedName: 'address'
        },
        newValue: uniq([...record.address, address])
      });
    } else {
      // update site address in all entities where it is used
      const recordsToUpdate: { id: number; address: string[] }[] = [];

      if (record.address.includes(site.address)) {
        recordsToUpdate.push({
          id: clientId,
          address: uniq([...record.address.filter((address) => address !== site.address), address])
        });
      }

      site.requests.forEach((request) => {
        if (request.address.includes(site.address)) {
          recordsToUpdate.push({
            id: request.id,
            address: uniq([...request.address.filter((address) => address !== site.address), address])
          });
        }
      });

      site.projects.forEach((project) => {
        if (project.address.includes(site.address)) {
          recordsToUpdate.push({
            id: project.id,
            address: uniq([...project.address.filter((address) => address !== site.address), address])
          });
        }
      });

      await Promise.all(
        recordsToUpdate.map(({ id, address }) =>
          updateProjectProperty({
            projectId: id,
            property: {
              id: -3,
              isAdditional: false,
              type: PropertyType.Text,
              mappedName: 'address'
            },
            newValue: address
          })
        )
      );
    }

    onClose();
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    defaultValues: site
      ? {
          address: site.address ? toSuggestion(site.address) : null
        }
      : undefined
  });

  const {
    formState: { isSubmitting, isDirty },
    control
  } = form;

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <SelectField
          label="Site address"
          getOptionSelected={(op, val) => op.description === val.description}
          getOptionLabel={(option) => option.description || ''}
          renderOption={(option) => (
            <Option>
              <strong>{option.structured_formatting.main_text}</strong>
              <small>{option.structured_formatting.secondary_text}</small>
            </Option>
          )}
          name="address"
          filterOptions={(options) => options}
          control={control}
          inputValue={searchValue}
          onInputChange={(_e, inputValue) => setSearchValue(inputValue)}
          loading={loading}
          options={(data.length && data) || (!data.length && searchValue && [defaultSuggestion]) || []}
        />
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting || !isDirty} variant={ButtonVariant.Primary} type="submit">
          {isNew ? 'Create' : 'Update'}
        </Button>
      </Footer>
    </Form>
  );
};
