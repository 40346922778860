import React from 'react';

export const CheckboxIndeterminate = () => (
  <svg
    width={20}
    height={20}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={20} height={20} rx="2" fill="#D54855" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7078 13.2673C14.7966 13.4813 14.7723 13.7132 14.6244 13.861L13.8658 14.6196C13.6564 14.8291 13.2783 14.7906 13.0213 14.5336L5.45454 6.96688C5.38371 6.89605 5.32948 6.816 5.29247 6.73282C5.20362 6.51884 5.22794 6.28695 5.37579 6.1391L6.13438 5.38054C6.34385 5.17107 6.72197 5.20956 6.97894 5.46651L14.5457 13.0332C14.6165 13.1041 14.6708 13.1841 14.7078 13.2673Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2673 5.29248C13.4813 5.20364 13.7132 5.22796 13.861 5.3758L14.6196 6.13439C14.8291 6.34386 14.7906 6.72198 14.5336 6.97895L6.96688 14.5457C6.89605 14.6165 6.816 14.6708 6.73282 14.7078C6.51884 14.7966 6.28695 14.7723 6.1391 14.6245L5.38054 13.8659C5.17107 13.6564 5.20956 13.2783 5.46651 13.0213L13.0332 5.45456C13.1041 5.38372 13.1841 5.32949 13.2673 5.29248Z" fill="white"/>
  </svg>
);
