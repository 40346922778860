import { DescriptionField } from '@common/index';
import { useFormikContext } from 'formik';
import React from 'react';
import { Plus } from 'react-feather';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { useClickOutside } from '@react-hookz/web';
import { EditableFieldContainer } from '../styled';
import { useInlineField } from '../useInlineField';

const EMPTY_TEXT = '<p><span class="ql-cursor">﻿</span></p>';

interface Props {
  isReadOnly?: boolean;
}

export const Description = ({ isReadOnly }: Props) => {
  const { isEdit, setIsEdit, setIsFocused, nodeRef } = useInlineField();
  const { values } = useFormikContext();

  useClickOutside(nodeRef, () => {
    if (isEdit) {
      setIsFocused(false);
    }
  });

  if (isEdit) {
    return (
      <div ref={nodeRef}>
        <DescriptionField
          name="description"
          // label="Description"
          data-testid="taskEditorFieldDescription"
          onFocus={() => {
            setIsFocused(true);
          }}
        />
      </div>
    );
  }

  if (!values.description || values.description.length === 0 || values.description === EMPTY_TEXT) {
    if (isReadOnly) {
      return <div>No description</div>;
    }

    return (
      <Button variant={ButtonVariant.Flat} size={ButtonSize.Small} isUpperCase={false} onClick={() => setIsEdit(true)}>
        <Plus size="16px" />
        Add a description
      </Button>
    );
  }

  return (
    <EditableFieldContainer isReadOnly={isReadOnly} onClick={isReadOnly ? undefined : () => setIsEdit(true)}>
      <div className="ql-snow">
        <div className="ql-editor" dangerouslySetInnerHTML={{ __html: values.description }} />
      </div>
    </EditableFieldContainer>
  );
};
