import styled from 'styled-components';
import { colors, scroll } from '@styles';

export const Title = styled.div`
  font: 500 20px/1.25 ${({ theme }) => theme.fonts.sans};
  color: #1d1d35;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow: auto;
  height: 100%;
  background: ${({ theme }) => theme.colors.white2};
  ${scroll};
`;

export const SlidePanel = styled.div`
  position: absolute;
  right: 0;
  top: 97px;
  bottom: 0;
  z-index: 5;
  background: ${colors.white};
  width: 686px;
  box-shadow: -16px 64px 200px 0px #1d1d3552;

  .separator {
    display: none;
  }

  .separator + * {
    margin-top: 24px;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 25px;
  left: -40px;
  z-index: 5;
  border-radius: 8px 0px 0px 8px;
  background: ${({ theme }) => theme.colors.white2};
  box-shadow: 0px -4px 10px -5px rgba(0, 150, 136, 0.16);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9c9caa;
  cursor: pointer;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: #000;
  }
`;
