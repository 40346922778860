import styled, { css } from 'styled-components';
import { X } from 'react-feather';

export const RemoveSelectedAccessOption = styled(X)`
  ${({ theme: { colors } }) => css`
    cursor: pointer;
    color: ${colors.gray};
  
    &:hover,
    &:focus {
      color: ${colors.green};
    }
  `}
`;

export const ItemName = styled.span`
  flex-grow: 1;
`;

export const SelectedAccessOption = styled.div`
  ${({ theme: { colors, fonts } }) => css`
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 8px;
    border-radius: 4px;
    margin-top: 4px;
    font: 500 12px/1 ${fonts.sans};
    background-color: ${colors.white}; 
    
    > svg, > div {
      margin-right: 4px;
    }
    
    > svg {
      margin-left: 4px;
    }
    
    ${RemoveSelectedAccessOption} {
      visibility: hidden;
    }
    
    &:hover, &:focus {
      background-color: ${colors.gray3};
      
      ${RemoveSelectedAccessOption} {
       visibility: visible;
      }  
    }
  `}
`;
