import React from 'react';

export const CheckboxOff = () => (
  <svg
    width={20}
    height={20}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 1H18V-1H2V1ZM19 2V18H21V2H19ZM18 19H2V21H18V19ZM1 18V2H-1V18H1ZM2 19C1.44772 19 1 18.5523 1 18H-1C-1 19.6569 0.343147 21 2 21V19ZM19 18C19 18.5523 18.5523 19 18 19V21C19.6569 21 21 19.6569 21 18H19ZM18 1C18.5523 1 19 1.44772 19 2H21C21 0.343146 19.6569 -1 18 -1V1ZM2 -1C0.343146 -1 -1 0.343147 -1 2H1C1 1.44772 1.44772 1 2 1V-1Z" fill="#778CA2"/>
  </svg>
);
