import React, { useMemo } from 'react';
import { TaskEventType } from '@enums';
import { WorkOrderStatus } from '@components/WorkOrder/WorkOrderStatus';
import { ArrowRight, Clipboard } from 'react-feather';
import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';
import { formatDate, isNoon } from '@utils/dates';
import { Link } from '@reach/router';
import { RecordType } from '@types';
import { CommentItem } from './CommentItem';
import { ValuesWithArrow, OldValue, FeedItemIconContainer, AddOrRemoveLabel } from './styled';
import { FeedItemProps } from './types';
import { BaseItemTemplate } from './BaseItemTemplate';
import { MemberList } from './MemberList';
import { AutomationAvatar } from './WorkflowFeedItem';
import { ENTITY_NAME_BY_RECORD_TYPE } from './constants';

export const WorkOrderFeedItem = ({ item, context, contextEntityId }: FeedItemProps) => {
  const { data: statuses = [] } = useWorkOrderStatuses();

  const { title, children } = useMemo(() => {
    const author = item.createdBy || { id: 0, firstName: item.userName, lastName: '', avatarUrl: item.userImage };

    const userName = author ? [author.firstName, author.lastName].join(' ') : '';

    const getWoNameWithPreposition = (preposition = '') =>
      context !== 'workOrder' ? (
        <>
          {' '}
          {preposition}{' '}
          <Link to={`${window.location.pathname}/workOrders/${item.payload.id}${window.location.search}`}>
            #{item.task?.uid ?? item.payload.uid}: {item.payload.name}
          </Link>
        </>
      ) : (
        ''
      );

    const getRecordNameWithPreposition = (preposition = '') =>
      context === 'client' && contextEntityId !== item.payload.project?.id ? (
        <>
          {' '}
          {preposition}
          {` ${ENTITY_NAME_BY_RECORD_TYPE[item.payload.project?.type ?? RecordType.PROJECT]} `}
          <Link to={`${window.location.pathname}/projects/${item.payload.project?.id}${window.location.search}`}>
            {item.payload.project?.title}
          </Link>
        </>
      ) : (
        ''
      );

    switch (item.eventType) {
      case TaskEventType.STATUS_MOVED: {
        const statusFrom = statuses.find((status) => status.id === item.payload.previousStatus);
        const statusTo = statuses.find((status) => status.id === item.payload.currentStatus);

        return {
          title: (
            <>
              <b>{userName}</b> updated <b>Status</b>
              {getWoNameWithPreposition('of')}
            </>
          ),
          children: (
            <ValuesWithArrow>
              {statusFrom ? <WorkOrderStatus status={statusFrom} /> : <div>{item.payload.previousStatus}</div>}
              <ArrowRight size="16px" />
              {statusTo ? <WorkOrderStatus status={statusTo} /> : <div>{item.payload.currentStatus}</div>}
            </ValuesWithArrow>
          )
        };
      }

      case TaskEventType.ASSIGNEE_ASSIGNED:
        return {
          title: (
            <>
              <b>{userName}</b> changed assignee
              {getWoNameWithPreposition('of')}
              {' to'}
            </>
          ),
          children: item.payload?.assignee ? <MemberList members={[item.payload.assignee]} /> : null
        };

      case TaskEventType.ASSIGNED: {
        const assignees = item.payload?.assignees ?? [];
        const prevAssignees = item.payload?.previousAssignees ?? [];

        const added = assignees.filter(
          (assignee) => !prevAssignees.some((prevAssignee) => prevAssignee.id === assignee.id)
        );
        const removed = prevAssignees.filter(
          (prevAssignee) => !assignees.some((assignee) => assignee.id === prevAssignee.id)
        );

        if (removed.length === 0 && added.length > 0) {
          return {
            title: (
              <>
                <b>{userName}</b> added collaborators{getWoNameWithPreposition('to')}
              </>
            ),
            children: <MemberList members={added} />
          };
        } else if (added.length === 0 && removed.length > 0) {
          return {
            title: (
              <>
                <b>{userName}</b> removed collaborators{getWoNameWithPreposition('from')}
              </>
            ),
            children: <MemberList members={removed} />
          };
        } else if (added.length > 0 && removed.length > 0) {
          return {
            title: (
              <>
                <b>{userName}</b> updated collaborators{getWoNameWithPreposition('for')}
              </>
            ),
            children: (
              <>
                <div>
                  <AddOrRemoveLabel>Added:</AddOrRemoveLabel>
                  <MemberList members={added} />
                </div>
                <br />
                <div>
                  <AddOrRemoveLabel>Removed:</AddOrRemoveLabel>
                  <MemberList members={removed} />
                </div>
              </>
            )
          };
        } else {
          return {
            title: null,
            children: null
          };
        }
      }

      case TaskEventType.CREATED:
        return {
          title: (
            <>
              <b>{userName}</b> created Work Order{getWoNameWithPreposition()}
              {getRecordNameWithPreposition('under')}
            </>
          ),
          children: null
        };

      case TaskEventType.COMPLETED:
        return {
          title: (
            <>
              <b>{userName}</b> completed {getWoNameWithPreposition()}
              {getRecordNameWithPreposition('under')}
            </>
          ),
          children: null
        };

      case TaskEventType.DELETED:
        return {
          title: (
            <>
              <b>{userName}</b> removed work order{getWoNameWithPreposition()}
              {getRecordNameWithPreposition('under')}
            </>
          ),
          children: null
        };

      case TaskEventType.UPDATED:
        return {
          title: (
            <>
              <b>{userName}</b> updated work order{getWoNameWithPreposition()}
            </>
          ),
          children: null
        };

      case TaskEventType.RESCHEDULED: {
        const {
          oldStartDate,
          oldEndDate,
          newStartDate,
          newEndDate,
          newStartDateAllDay,
          newEndDateAllDay,
          oldStartDateAllDay,
          oldEndDateAllDay
        } = item.payload;
        // allDay === undefined is for before allDay implementation, noon meant allDay
        const getDateFormat = (date: any, allDay: boolean) =>
          allDay || (typeof allDay === 'undefined' && isNoon(date)) ? 'MM/DD/YY' : 'MM/DD/YY hh:mm A';
        const formattedOldStartDate = formatDate(oldStartDate, getDateFormat(oldStartDate, oldStartDateAllDay));
        const formattedOldEndDate = formatDate(oldEndDate, getDateFormat(oldEndDate, oldEndDateAllDay));
        const formattedNewStartDate = formatDate(newStartDate, getDateFormat(newStartDate, newStartDateAllDay));
        const formattedNewEndDate = formatDate(newEndDate, getDateFormat(newEndDate, newEndDateAllDay));

        const startDateChanged = formattedOldStartDate !== formattedNewStartDate && (oldStartDate || newStartDate);
        const endDateChanged = formattedOldEndDate !== formattedNewEndDate && (oldEndDate || newEndDate);

        if (!startDateChanged && !endDateChanged) {
          // looks like backend sends this even if some other field changed
          return {
            title: (
              <>
                <b>{userName}</b> updated work order{getWoNameWithPreposition()}
              </>
            ),
            children: null
          };
        }

        if (startDateChanged) {
          return {
            title: (
              <>
                <b>{userName}</b> updated <b>Start date</b>
                {getWoNameWithPreposition('for')}
              </>
            ),
            children: (
              <ValuesWithArrow>
                <OldValue>{formattedOldStartDate || 'None'}</OldValue>
                <ArrowRight size="16px" />
                <div>{formattedNewStartDate || 'None'}</div>
              </ValuesWithArrow>
            )
          };
        }

        return {
          title: (
            <>
              <b>{userName}</b> updated <b>Due date</b>
              {getWoNameWithPreposition('for')}
            </>
          ),
          children: (
            <ValuesWithArrow>
              <OldValue>{formattedOldEndDate || 'None'}</OldValue>
              <ArrowRight size="16px" />
              <div>{formattedNewEndDate || 'None'}</div>
            </ValuesWithArrow>
          )
        };
      }
      case TaskEventType.VISIT_SCHEDULED: {
        const { scheduledVisit } = item.payload;

        const isRemoved = scheduledVisit?.removed === true;

        if (isRemoved) {
          return {
            title: (
              <>
                <b>{userName}</b> cancelled a visit at {formatDate(scheduledVisit.startDate, 'MM/DD/YY hh:mm A')}{' '}
                {getWoNameWithPreposition('for')}
              </>
            ),
            children: null
          };
        }

        if (scheduledVisit?.previousVisit) {
          return {
            title: (
              <>
                <b>{userName}</b> rescheduled a visit {getWoNameWithPreposition('for')}
              </>
            ),
            children: (
              <ValuesWithArrow>
                <OldValue>{formatDate(scheduledVisit.previousVisit.startDate, 'MM/DD/YY hh:mm A')}</OldValue>
                <ArrowRight size="16px" />
                <div>{formatDate(scheduledVisit.startDate, 'MM/DD/YY hh:mm A')}</div>
              </ValuesWithArrow>
            )
          };
        }

        return {
          title: (
            <>
              <b>{userName}</b> scheduled a visit {getWoNameWithPreposition('for')} at{' '}
              <b>{formatDate(scheduledVisit.startDate, 'MM/DD/YY hh:mm A')}</b>
            </>
          ),
          children: null
        };
      }
      default:
        return {
          title: null,
          children: null
        };
    }
  }, [item, context, statuses, contextEntityId]);

  if (item.eventType === TaskEventType.COMMENTED) {
    return <CommentItem item={item} context={context} contextEntityId={contextEntityId} />;
  }

  if (!title && !children) {
    return null;
  }

  const author = item.createdBy || { id: 0, firstName: item.userName, lastName: '', avatarUrl: item.userImage };

  const isAutomation = author.firstName === 'Coperniq' && author.lastName === 'Automation';

  return (
    <BaseItemTemplate
      id={item.id}
      context={context}
      author={isAutomation ? undefined : author}
      authorIcon={isAutomation ? <AutomationAvatar /> : undefined}
      icon={
        <FeedItemIconContainer color="#CCEAE7">
          <Clipboard color="#00635A" size="12px" />
        </FeedItemIconContainer>
      }
      date={item.createdAt}
      title={title}
    >
      {children}
    </BaseItemTemplate>
  );
};
