import { handleActions } from 'redux-actions';
import { merge } from 'lodash';

import { projectAdapter } from '@adapters/ProjectAdapters/ProjectAdapter';
import {
  GroupProp,
  Project,
  ProjectDetail,
} from '../../ProjectView/types';
import { ProjectViewReducer, SelectedProjectProp } from './types';
import * as actions from '../actions';

import { handleGroupsActions } from './groupsReducer';
import { projectViewInitialState } from './defaultValues';

// archive project
const archiveProjectSuccessReducer = (
  state: ProjectViewReducer
): ProjectViewReducer => ({
  ...state,
  isLoading: false
});

const archiveProjectRequestReducer = (
  state: ProjectViewReducer
): ProjectViewReducer => ({
  ...state,
  isLoading: true
});

const archiveProjectFailureReducer = (
  state: ProjectViewReducer
): ProjectViewReducer => ({
  ...state,
  isLoading: false
});

// delete project
type DeleteProjectSuccessResponse = { payload: number };

const deleteProjectSuccessReducer = (
  state: ProjectViewReducer,
  { payload: projectId }: DeleteProjectSuccessResponse
): ProjectViewReducer => {
  const favoriteProjectList = state.favoriteProjectList.filter(
    (f) => f.id !== projectId
  );
  return {
    ...state,
    isLoading: false,
    favoriteProjectList
  };
};

const deleteProjectRequestReducer = (
  state: ProjectViewReducer
): ProjectViewReducer => ({
  ...state,
  isLoading: true
});

const deleteProjectFailureReducer = (
  state: ProjectViewReducer
): ProjectViewReducer => ({
  ...state,
  isLoading: false
});

type UpdateProjectSuccess = { payload: ProjectDetail };
const updateSelectedProjectSuccessReducer = (
  state: ProjectViewReducer,
  { payload }: UpdateProjectSuccess
): ProjectViewReducer => {
  return {
    ...state,
    selectedProject: payload
  };
};

type ToggleFavSuccess = { payload: { isFavorite: boolean } };
const toggleFavOnSelectedProjectSuccessReducer = (
  state: ProjectViewReducer,
  { payload }: ToggleFavSuccess
): ProjectViewReducer => {
  const { selectedProject, favoriteProjectList: currentFavList } = state;
  const { isFavorite } = payload;

  let favoriteProjectList: any = [];
  if (isFavorite) {
    const { id, title, imageUrl, address } = selectedProject;
    favoriteProjectList = [{
      id,
      name: title,
      avatarUrl: imageUrl,
      description: address
    }, ...currentFavList];
  } else {
    favoriteProjectList = currentFavList?.filter((project) => project.id !== selectedProject.id);
  }
  return {
    ...state,
    selectedProject: {
      ...selectedProject,
      isFavorite
    },
    favoriteProjectList
  };
};

type DeleteSelectedProjectSuccess = { payload: { projectId: number } };
const deleteSelectedProjectSuccessReducer = (
  state: ProjectViewReducer,
  { payload }: DeleteSelectedProjectSuccess
): ProjectViewReducer => {
  const { favoriteProjectList: currentFavList, groups: currentGroups } = state;
  const { projectId } = payload;
  // remove project from favList
  const favoriteProjectList = currentFavList?.filter((project) => project.id !== projectId) ?? [];
  // remove project from group
  const groups = currentGroups.map((group) => {
    const groupCopy = { ...group };
    const newList = group.group?.list.filter((project) => project.projectId !== projectId) ?? [];
    groupCopy.group.list = newList;
    groupCopy.total = newList.length ?? 0;
    return groupCopy;
  });
  return {
    ...state,
    favoriteProjectList,
    groups
  };
};

const updateProjectAdditionalSuccessReducer = (
  state: ProjectViewReducer,
  { payload }
): ProjectViewReducer => {
  const { id, data } = payload;
  const { groups } = state;
  let groupIndex = -1;
  let projectIndex = -1;

  groups.forEach((g, index) => {
    g.group.list.forEach((item, i) => {
      if (String(item.projectId) === String(id)) {
        projectIndex = i;
        groupIndex = index;
      }
    });
  });

  const groupsCopy = [...groups];

  if (groupIndex > -1) {
    const item = groupsCopy[groupIndex].group.list[projectIndex];
    const adapted = projectAdapter(data);

    groupsCopy[groupIndex].group.list[projectIndex] = {
      ...item,
      ...adapted,
      membersList: item.membersList,
      projectDetail: {
        ...item.projectDetail,
        ...adapted.projectDetail,
        additional: merge(
          item.projectDetail.additional,
          adapted.projectDetail.additional
        ),
      },
      isLoading: false
    };
    groupsCopy[groupIndex].group.list = [...groupsCopy[groupIndex].group.list];
  }

  return {
    ...state,
    groups: groupsCopy
  };
};

// add favorite
const addFavProjectsRequestReducer = (
  state: ProjectViewReducer
): ProjectViewReducer => ({
  ...state,
  isLoadingFavoriteProject: true
});

const addFavProjectsSuccessReducer = (
  state: ProjectViewReducer
): ProjectViewReducer => ({
  ...state,
  isLoadingFavoriteProject: false
});

const addFavProjectsFailureReducer = (
  state: ProjectViewReducer
): ProjectViewReducer => ({
  ...state,
  isLoadingFavoriteProject: false
});

// delete favorite
const deleteFavProjectsRequestReducer = (
  state: ProjectViewReducer
): ProjectViewReducer => ({
  ...state,
  isLoadingFavoriteProject: true
});

type DeleteFavProjectsSuccessResponse = { payload: number };
const deleteFavProjectsSuccessReducer = (
  state: ProjectViewReducer,
  { payload }: DeleteFavProjectsSuccessResponse
): ProjectViewReducer => {
  const id = payload;
  const { favoriteProjectList, groups } = state;
  let groupIndex = -1,
    projectIndex = -1;
  groups.forEach((g: GroupProp, index) => {
    g.group.list.forEach((project: Project, pIndex) => {
      if (project.projectId === id) {
        groupIndex = parseInt(index);
        projectIndex = parseInt(pIndex);
      }
    });
  });

  const copyGroups = [...groups];
  if (groupIndex !== -1 && projectIndex !== -1) {
    const item = copyGroups[groupIndex].group.list[projectIndex];
    copyGroups[groupIndex].group.list[projectIndex] = {
      ...item,
      projectDetail: {
        ...item.projectDetail,
        isFavorite: false
      }
    };
  }

  return {
    ...state,
    groups: copyGroups,
    favoriteProjectList: favoriteProjectList.filter((item) => item.id !== id),
    isLoadingFavoriteProject: false
  };
};

const deleteFavProjectsFailureReducer = (
  state: ProjectViewReducer
): ProjectViewReducer => ({
  ...state,
  isLoadingFavoriteProject: false
});

// fetch single project
const fetchSingleProjectRequestReducer = (
  state: ProjectViewReducer
): ProjectViewReducer => ({
  ...state,
  fetchingOne: true
});

type FetchSingleProjectResponse = { payload: SelectedProjectProp };
const fetchSingleProjectSuccessReducer = (
  state: ProjectViewReducer,
  { payload }: FetchSingleProjectResponse
): ProjectViewReducer => {

  return {
    ...state,
    selectedProject: payload.project as unknown as SelectedProjectProp,
    fetchingOne: false
  };
};

const fetchSingleProjectFailureReducer = (
  state: ProjectViewReducer
): ProjectViewReducer => ({
  ...state,
  fetchingOne: false,
  selectedProject: {}
});

// update collapse state
type UpdateGroupCollapseStateResponse = {
  payload: { id: number; collapse: boolean; all: boolean };
};

const updateGroupCollapseStateReducer = (
  state: ProjectViewReducer,
  { payload }: UpdateGroupCollapseStateResponse
): ProjectViewReducer => {
  const { id, collapse, all } = payload;
  const { groups } = state;
  const newGroups = groups.map((item) => {
    if (all || item.group.id === id) {
      return {
        ...item,
        isCollapsed: collapse
      };
    }

    return item;
  });

  return {
    ...state,
    groups: newGroups
  };
};

const projectsViewReducer = handleActions(
  {
    [actions.archiveProjectSuccess]: archiveProjectSuccessReducer,
    [actions.archiveProjectRequest]: archiveProjectRequestReducer,
    [actions.archiveProjectFailure]: archiveProjectFailureReducer,
    [actions.deleteProjectSuccess]: deleteProjectSuccessReducer,
    [actions.deleteProjectRequest]: deleteProjectRequestReducer,
    [actions.deleteProjectFailure]: deleteProjectFailureReducer,
    [actions.addFavProjectsRequest]: addFavProjectsRequestReducer,
    [actions.addFavProjectsSuccess]: addFavProjectsSuccessReducer,
    [actions.addFavProjectsFailure]: addFavProjectsFailureReducer,
    [actions.deleteFavProjectsRequest]: deleteFavProjectsRequestReducer,
    [actions.deleteFavProjectsSuccess]: deleteFavProjectsSuccessReducer,
    [actions.deleteFavProjectsFailure]: deleteFavProjectsFailureReducer,
    [actions.fetchSingleProjectRequest]: fetchSingleProjectRequestReducer,
    [actions.fetchSingleProjectSuccess]: fetchSingleProjectSuccessReducer,
    [actions.fetchSingleProjectFailure]: fetchSingleProjectFailureReducer,
    [actions.updateSelectedProjectSuccess]: updateSelectedProjectSuccessReducer,
    [actions.toggleFavOnSelectedProjectSuccess]: toggleFavOnSelectedProjectSuccessReducer,
    [actions.deleteSelectedProjectSuccess]: deleteSelectedProjectSuccessReducer,
    ...handleGroupsActions,
  },
  projectViewInitialState
);

export default projectsViewReducer;
