import React, { PropsWithChildren } from 'react';
import { RefreshCw, Tag } from 'react-feather';
import { toString } from 'lodash';

import { stringToColor } from '@utils';
import { CoperniqColorsScheme } from '@styles';
import { IKanbanTag } from './type';
import { Wrapper, Icon, User, Text, Remove } from './styled';

const Label: React.FC<PropsWithChildren<IKanbanTag>> = (props) => {
  const {
    children,
    type = 'label',
    iconSize = 20,
    onClick = () => {},
    onRemove,
    className,
    color: $color,
  } = props;

  const color = $color || stringToColor(
    toString(children),
    CoperniqColorsScheme.colorValues
  );

  const renderRemove = () => {
    return <Remove onClick={onRemove} />;
  };

  return (
    <Wrapper onClick={onClick} type={type} color={color} className={className}>
      {type === 'label' && (
        <Icon size={iconSize} color={color}>
          <Tag />
        </Icon>
      )}
      {type === 'stage-label' && (
        <Icon size={iconSize}>
          <RefreshCw color={color} />
        </Icon>
      )}
      {type === 'people' ? (
        <User>{children}</User>
      ) : (
        <Text color={color}>{children}</Text>
      )}
      {onRemove && renderRemove()}
    </Wrapper>
  );
};

export default Label;
