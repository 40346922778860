import { FileChoser } from '@common/FileChoser';
import { FormField } from '@kit/components/Form/FormField';
import { FormControl, FormInputPropsToOmit } from '@kit/components/Form/types';
import { useControllerWithValidation } from '@kit/components/Form/useControllerWithValidation';
import React, { useCallback, useRef, useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { File as FileIcon, Upload as UploadIcon, XCircle } from 'react-feather';
import { CircularProgress } from '@material-ui/core';
import { uploadCompressedFile } from '@services/UploadServices/uploadService';
import Dropzone from 'react-dropzone';
import { noop } from 'lodash';
import { useToast } from '@hooks/useToast';
import { colors } from '@styles';
import { useDeepCompareEffect } from '@react-hookz/web';
import { FileProperties } from '@components/Project/DataBank/DataBankContent/FileList/FileProperties/FileProperties';
import { File as TFile } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import { selectIsInboxPage } from '@state/selectors';
import {
  DropZoneContainer,
  DropZoneHeader,
  FileChooseWrapper,
  FilesGrid,
  FilesScrollableContainer,
  ReferenceFileList,
  ReferenceFileRow,
  ReferenceThumbnail,
  ReferenceThumbnailContainer,
  ReferencesLabel,
  RemoveFileButton,
  Spinner,
  Thumbnail,
  ThumbnailContainer
} from './styled';
import { FileToLoad } from './FileToLoad';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof FileChoser>, FormInputPropsToOmit>,
    FormControl<TFieldValues, TName> {
  maxFileCount?: number;
  maxFileMbSize?: number;
  references?: File[];
  isMobilePreview?: boolean;
  formId: number;
  isFullScreen?: boolean;
  onFileClick?: (fileId: number) => void;
}

const isEqualById = (a: TFile | number, b: TFile | number) => {
  if (typeof a === 'number' && typeof b === 'number') {
    return a === b;
  }

  if (typeof a !== 'number' && typeof b !== 'number') {
    return a.id === b.id;
  }

  if (typeof a === 'number' && typeof b !== 'number') {
    return a === b.id;
  }

  if (typeof a !== 'number' && typeof b === 'number') {
    return a.id === b;
  }

  return false;
};

const isEqualArraysById = (a: (TFile | number)[], b: (TFile | number)[]) => {
  if (a.length !== b.length) {
    return false;
  }

  return a.every((item) => b.some((bItem) => isEqualById(item, bItem)));
};

export const FileField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  maxFileCount = 100,
  maxFileMbSize = 350,
  references = [],
  isMobilePreview = false,
  formId,
  isFullScreen,
  onFileClick
}: Props<TFieldValues, TName>) => {
  const isInboxPage = useAppSelector(selectIsInboxPage);

  const [uploadingList, setUploadingList] = useState<File[]>([]);
  const [selectedFile, setSelectedFile] = useState<TFile | null>(null);
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label);
  const { showError } = useToast();
  const [fileList, setFileList] = useState<TFile[]>(value ?? []);

  const valueRef = useRef(value);

  valueRef.current = value;

  useDeepCompareEffect(() => {
    if (isEqualArraysById(valueRef.current ?? [], fileList)) {
      return;
    }
    onChange(fileList);
  }, [fileList]);

  useDeepCompareEffect(() => {
    setFileList((prev) =>
      (value ?? []).map((item) => {
        const existingItem = prev.find((prevItem) => isEqualById(prevItem, item));

        if (existingItem) {
          return existingItem;
        }

        return item;
      })
    );
  }, [value]);

  const uploadFile = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append('file', file);

      const { data } = await uploadCompressedFile({
        formData,
        by: 'form',
        entityId: formId
      });

      return data;
    },
    [formId]
  );

  const handleRemove = useCallback(
    (file) => (e) => {
      e.stopPropagation();

      setFileList((prev) => prev.filter((item) => item.id !== file.id));
    },
    []
  );

  const handleFileLoaded = useCallback((file: TFile) => {
    setFileList((prev) => prev.map((item) => (item === file.id ? file : item)));
  }, []);

  const handleFilesChange = useCallback(
    async (files: File[]) => {
      if (files.length > maxFileCount) {
        showError(`Maximum file count is ${maxFileCount}`);

        return;
      }

      const filteredFiles = files.filter((file) => file.size / 1024 / 1024 <= maxFileMbSize);

      if (filteredFiles.length !== files.length) {
        showError(`Maximum file size is ${maxFileMbSize} MB`);
      }

      setUploadingList((prev) => [...prev, ...filteredFiles]);

      const results = await Promise.all(filteredFiles.map((file) => uploadFile(file)));

      setUploadingList((prev) => prev.filter((item) => !filteredFiles.some((file) => file === item)));
      setFileList((prev) => [...prev, ...results]);
    },
    [uploadFile, maxFileCount, maxFileMbSize, showError]
  );

  return (
    <FormField label={label} error={error?.message} description={description}>
      {selectedFile && (
        <FileProperties
          width="51%"
          height={isInboxPage ? 'calc(100vh - 48px - 64px)' : undefined}
          item={{ file: selectedFile }}
          container={{ children: (value || []).map((file) => ({ file })) }}
          onClose={() => setSelectedFile(undefined)}
          moveTargets={[]}
          onDelete={(file) => {
            handleRemove(file)();
            setSelectedFile(undefined);
          }}
          isFullScreen={isFullScreen}
        />
      )}
      {Boolean(references?.length) && (
        <div>
          <ReferencesLabel>References ({references.length})</ReferencesLabel>
          <ReferenceFileList>
            {references.map((file) => (
              <ReferenceFileRow key={file.id} href={file.downloadUrl} target="_blank">
                <ReferenceThumbnailContainer>
                  {file?.metaData?.thumbnailUrl ? (
                    <ReferenceThumbnail url={file.metaData.thumbnailUrl} />
                  ) : (
                    <FileIcon size="16px" color="#9C9CAA" />
                  )}
                </ReferenceThumbnailContainer>
                <div>{file.name}</div>
              </ReferenceFileRow>
            ))}
          </ReferenceFileList>
        </div>
      )}
      {!isMobilePreview && (
        <>
          <Dropzone onDrop={handleFilesChange}>
            {({ getRootProps, getInputProps }) => (
              <DropZoneContainer {...getRootProps()} data-testid={`field-${name}`}>
                <DropZoneHeader>
                  <input {...getInputProps()} />
                  <UploadIcon size="16px" color={colors.green} />
                  <span data-testid="addFileChooseFile">
                    drag & drop a file here or <strong>browse</strong>
                  </span>
                </DropZoneHeader>
              </DropZoneContainer>
            )}
          </Dropzone>

          {(value?.length > 0 || uploadingList.length > 0) && (
            <FilesScrollableContainer>
              <FilesGrid>
                {fileList.map((file) => {
                  if (typeof file === 'number') {
                    return <FileToLoad key={file} id={file} onLoaded={handleFileLoaded} />;
                  }

                  return (
                    <ThumbnailContainer
                      key={file.id}
                      onClick={() => {
                        onFileClick?.(file.id);
                      }}
                    >
                      {file?.metaData?.thumbnailUrl ? (
                        <Thumbnail url={file.metaData.thumbnailUrl} />
                      ) : (
                        <FileIcon size="16px" color="#9C9CAA" />
                      )}
                      <RemoveFileButton onClick={handleRemove(file)}>
                        <XCircle size="16px" />
                      </RemoveFileButton>
                    </ThumbnailContainer>
                  );
                })}

                {uploadingList.map((file) => (
                  <ThumbnailContainer key={file.id}>
                    <Spinner style={file.previewUrl ? { backgroundImage: `url(${file.previewUrl})` } : undefined}>
                      <CircularProgress size={16} style={{ color: colors.green }} />
                    </Spinner>
                  </ThumbnailContainer>
                ))}
              </FilesGrid>
            </FilesScrollableContainer>
          )}
        </>
      )}
      {isMobilePreview && (
        <FileChooseWrapper>
          <FileChoser
            onFormFilesChange={noop}
            values={value ?? ([] as File[])}
            config={{
              fileMaxCount: maxFileCount,
              fileSize: maxFileMbSize
            }}
            // isRequired={!!field?.column?.isRequired}
            multiple
            // onOpenGallery={(_, activeImageId) => showPreview(customField, activeImageId)}
            // formId={formId}
            // disabled={isReadOnly}
          />
        </FileChooseWrapper>
      )}
    </FormField>
  );
};
