import styled from 'styled-components';

export const Info = styled.div`
  font-family: 'Rubik', sans;
  color: #98a9bc;
  background-color: #fff;
  padding: 4px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ theme }) => theme.shadows.main};
  ${({ theme }) => theme.borders.main};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  max-width: 250px;

  &:first-child {
    color: #000;
  }

  a {
    color: #235dff;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:link {
      color: #235dff;
    }
  }

  > * {
    margin-left: 0;
  }
`;
