import React, { useMemo } from 'react';
import { MenuItem } from '@kit/components/ContextMenu';
import { Plus, Upload } from 'react-feather';
import { useModal } from '@common/PromiseModal';
import { useDocsAccess } from './useDocsAccess';
import { CreateFormForm } from '../Forms/CreateFormForm';
import { UploadFilesForm } from './UploadFilesForm';

export const useCreateDocMenuItems = (recordId: number) => {
  const { canCreateFile, canCreateForm } = useDocsAccess();
  const { openModal } = useModal();

  const createDocMenuItems = useMemo<MenuItem[]>(
    () =>
      [
        canCreateFile && {
          icon: <Upload color="#9C9CAA" size="16px" />,
          title: 'Upload files',
          onClick: () => {
            openModal<void>(({ onClose }) => <UploadFilesForm recordId={recordId} onClose={onClose} />, {
              title: 'Upload files'
            });
          }
        },
        canCreateForm && {
          icon: <Plus color="#9C9CAA" size="16px" />,
          title: 'Create Form',
          onClick: () => {
            openModal<void>(({ onClose }) => <CreateFormForm recordId={recordId} onClose={onClose} />, {
              title: 'Create Form'
            });
          }
        }
      ].filter(Boolean),
    [canCreateFile, canCreateForm, openModal, recordId]
  );

  return createDocMenuItems;
};
