import { UiSizes, UiSizesValues } from '@enums';
import { defaultTheme } from '@themes';

interface SelectProps {
  size?: UiSizes,
  noBorder?: boolean
}

const select = (props: SelectProps = {}) => {
  const {
    size = UiSizes.Medium,
    noBorder = false
  } = props;

  return {
    // .MuiSelect-root Styles applied to the select component root class.
    root: {
      display: 'flex',
      alignItems: 'center',
      height: `${UiSizesValues[size]}px`,
      padding: '0 10px',
      '& .MuiOutlinedInput-input': {
        color: 'green'
      },
      '&[aria-expanded="true"] ~ .MuiOutlinedInput-notchedOutline': {
        borderColor: defaultTheme.colors.green,
        borderWidth: noBorder ? '0' : '1px'
      },
      '&[aria-disabled="true"]': {
        cursor: 'not-allowed',
        pointerEvents: 'none',
      },
      '&.Mui-error:hover .MuiOutlinedInput-notchedOutline, &.Mui-error:focus .MuiOutlinedInput-notchedOutline': {
        borderColor: defaultTheme.colors.red,
        borderWidth: noBorder ? '0' : '1px'
      },
    },
    // .MuiSelect-select Styles applied to the select component select class.
    select: {
      font: `500 12px/1.25 ${defaultTheme.fonts.sans}`,
      textTransform: 'uppercase',
      backgroundColor: defaultTheme.colors.white,
      '&:focus': {
        backgroundColor: defaultTheme.colors.white,
        borderRadius: '4px'
      },
      '& > svg, & > img': {
        marginRight: '10px'
      }
    },
    // .MuiSelect-filled	Styles applied to the select component if variant="filled".
    filled: {},
    // .MuiSelect-outlined	Styles applied to the select component if variant="outlined".
    outlined: {
      transition: '0.3s ease-in-out',
      '& ~ .MuiOutlinedInput-notchedOutline': {
        borderColor: defaultTheme.colors.gray3,
        borderWidth: noBorder ? '0' : '1px'
      },
      '&:hover ~ .MuiOutlinedInput-notchedOutline, &:focus ~ .MuiOutlinedInput-notchedOutline': {
        borderColor: defaultTheme.colors.green,
        borderWidth: noBorder ? '0' : '1px'
      },
    },
    // .MuiSelect-selectMenu	Styles applied to the select component selectMenu class.
    selectMenu: {},
    // .Mui-disabled	Pseudo-class applied to the select component disabled class.
    disabled: {},
    // .MuiSelect-icon	Styles applied to the icon component.
    icon: {
      minWidth: '20px',
      width: '20px',
      color: defaultTheme.colors.gray
    },
    // .MuiSelect-iconOpen	Styles applied to the icon component if the popup is open.
    iconOpen: {},
    // .MuiSelect-iconFilled	Styles applied to the icon component if variant="filled".
    iconFilled: {},
    // .MuiSelect-iconOutlined	Styles applied to the icon component if variant="outlined".
    iconOutlined: {},
    // .MuiSelect-nativeInput	Styles applied to the underlying native input component.
    nativeInput: {}
  };
};

export default select;
