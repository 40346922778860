import { find } from 'lodash/fp';
import moment, { DurationInputArg2 } from 'moment';

type DateRangeSubtractToDate =
  | DateRangeSubtract.WEEK_TO_NOW
  | DateRangeSubtract.MONTH_TO_NOW
  | DateRangeSubtract.QUARTER_TO_NOW
  | DateRangeSubtract.YEAR_TO_NOW;

type DateRangeSubtractBase =
  | DateRangeSubtract.HOUR
  | DateRangeSubtract.DAY
  | DateRangeSubtract.WEEK
  | DateRangeSubtract.MONTH
  | DateRangeSubtract.YEAR;

export type DateRange =
  | {
      type: DateRangeType.DYNAMIC;
      subtract: DateRangeSubtractToDate;
    }
  | {
      type: DateRangeType.DYNAMIC;
      subtract: DateRangeSubtractBase;
      subtractAmount: number;
    }
  | {
      type: DateRangeType.FIXED;
      startDate: string;
      endDate: string;
    }
  | {
      type: DateRangeType.DYNAMIC;
      subtract: DateRangeSubtract.CUSTOM_TO_NOW;
      startDate: string;
    };

export enum DateRangeType {
  DYNAMIC = 'DYNAMIC',
  FIXED = 'FIXED'
}

export enum DateRangeSubtract {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  WEEK_TO_NOW = 'WEEK_TO_NOW',
  MONTH = 'MONTH',
  MONTH_TO_NOW = 'MONTH_TO_NOW',
  QUARTER_TO_NOW = 'QUARTER_TO_NOW',
  YEAR = 'YEAR',
  YEAR_TO_NOW = 'YEAR_TO_NOW',
  CUSTOM_TO_NOW = 'CUSTOM_TO_NOW'
}

export const SUBTRACT_TO_START_OF_ARGUMENT = {
  [DateRangeSubtract.WEEK_TO_NOW]: 'isoWeek',
  [DateRangeSubtract.MONTH_TO_NOW]: 'month',
  [DateRangeSubtract.QUARTER_TO_NOW]: 'quarter',
  [DateRangeSubtract.YEAR_TO_NOW]: 'year',
  [DateRangeSubtract.CUSTOM_TO_NOW]: 'custom_start'
} as Record<DateRangeSubtract, 'isoWeek' | 'month' | 'year' | 'quarter' | 'custom_start'>;

const ALIGN_TO_START_OF_DAY: DateRangeSubtract[] = Object.values(DateRangeSubtract).filter(
  (drs) => drs === DateRangeSubtract.HOUR
);

type DateRangeOption = { name: string; id: string; fieldValue: DateRange };

export const DATE_RANGE_OPTIONS: DateRangeOption[] = [
  {
    name: 'Last 24 hours',
    id: 'last_24_hours',
    fieldValue: {
      type: DateRangeType.DYNAMIC,
      subtract: DateRangeSubtract.HOUR,
      subtractAmount: 24
    }
  },
  {
    name: 'Last 7 days',
    id: 'last_7_days',
    fieldValue: {
      type: DateRangeType.DYNAMIC,
      subtract: DateRangeSubtract.DAY,
      subtractAmount: 7
    }
  },
  {
    name: 'Week to date',
    id: 'week_to_date',
    fieldValue: {
      type: DateRangeType.DYNAMIC,
      subtract: DateRangeSubtract.WEEK_TO_NOW
    }
  },
  {
    name: 'Last 30 days',
    id: 'last_30_days',
    fieldValue: {
      type: DateRangeType.DYNAMIC,
      subtract: DateRangeSubtract.DAY,
      subtractAmount: 30
    }
  },
  {
    name: 'Month to date',
    id: 'month_to_date',
    fieldValue: {
      type: DateRangeType.DYNAMIC,
      subtract: DateRangeSubtract.MONTH_TO_NOW
    }
  },
  {
    name: 'Last 90 days',
    id: 'last_90_days',
    fieldValue: {
      type: DateRangeType.DYNAMIC,
      subtract: DateRangeSubtract.DAY,
      subtractAmount: 90
    }
  },
  {
    name: 'Quarter to date',
    id: 'quarter_to_date',
    fieldValue: {
      type: DateRangeType.DYNAMIC,
      subtract: DateRangeSubtract.QUARTER_TO_NOW
    }
  },
  {
    name: 'Last 365 days',
    id: 'last_365_days',
    fieldValue: {
      type: DateRangeType.DYNAMIC,
      subtract: DateRangeSubtract.DAY,
      subtractAmount: 365
    }
  },
  {
    name: 'Year to date',
    id: 'year_to_date',
    fieldValue: {
      type: DateRangeType.DYNAMIC,
      subtract: DateRangeSubtract.YEAR_TO_NOW
    }
  },
  {
    name: 'Custom',
    id: 'custom',
    fieldValue: {
      type: DateRangeType.FIXED,
      startDate: '',
      endDate: ''
    }
  },
  {
    name: 'Custom to date',
    id: 'custom_to_date',
    fieldValue: {
      type: DateRangeType.DYNAMIC,
      subtract: DateRangeSubtract.CUSTOM_TO_NOW,
      startDate: ''
    }
  }
];

export const getStartEndByDateRange = (value: DateRangeOption['id'] | DateRange) => {
  // console.log('foobar call getStartEndByDateRange', value)

  let dateRange = value;

  if (typeof value === 'string') {
    dateRange = find({ id: value as any }, DATE_RANGE_OPTIONS)?.fieldValue;
    // console.log('foobar found dateRange', dateRange)
  }

  if (!dateRange) {
    return [undefined, undefined];
  }

  const isDynamic = dateRange.type === DateRangeType.DYNAMIC;
  const isToNow = Object.keys(SUBTRACT_TO_START_OF_ARGUMENT).includes(dateRange.subtract);

  const now = moment();

  if (isToNow && isDynamic) {
    let startDate;

    if (dateRange.subtract === DateRangeSubtract.CUSTOM_TO_NOW) {
      startDate = moment(dateRange.startDate).toISOString();
    } else {
      startDate = now.clone().startOf(SUBTRACT_TO_START_OF_ARGUMENT[dateRange.subtract]).toISOString();
    }
    const endDate = now.clone().toISOString();

    return [startDate, endDate];
  }

  if (isDynamic) {
    const startMoment = now
      .clone()
      .subtract(dateRange.subtractAmount, dateRange.subtract as unknown as DurationInputArg2);

    if (ALIGN_TO_START_OF_DAY.includes(dateRange.subtract)) {
      startMoment.startOf('day');
    }

    const startDate = startMoment.toISOString();
    const endDate = now.clone().toISOString();

    return [startDate, endDate];
  }

  if (dateRange?.type === DateRangeType.FIXED) {
    if (dateRange.startDate && dateRange.endDate) {
      return [moment(dateRange.startDate).toISOString(), moment(dateRange.endDate).toISOString()];
    }
  }

  return [undefined, undefined];
};

export const isDateRangeFilter = (value: any) => {
  return !!value?.val && find({ id: value.val }, DATE_RANGE_OPTIONS);
};

export const getDateRangeOptionNameById = (id: string) => find({ id }, DATE_RANGE_OPTIONS)?.name;
