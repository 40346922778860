import React from 'react';
import styled, { css } from 'styled-components';
import { slidebarArrowLeft as ArrowLeft, slidebarArrowRight as ArrowRight } from '@assets/svg';
import { Button as ButtonMui } from '@material-ui/core';
import { scroll } from '@styles';

export const ToggleButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin: 0 -10px;

  svg {
    display: block;
    color: ${(props) => props.theme.colors.gray2};
  }

  &:hover, &:hover {
    border-color: ${(props) => props.theme.colors.green};
    svg {
      color: ${(props) => props.theme.colors.green};
    }
  }
`;

interface ToggleButtonProps {
  isRight?: boolean;
  isCollapsed?: boolean;
}

const Button = styled(ButtonMui)`
  && {
    width: 30px;
    height: 30px;
    padding: 0;
    min-width: 30px;
    border-radius: 100%;
  }
`;

interface ToggleButtonProps {
  isRight?: boolean;
  isCollapsed?: boolean;
}

export const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
  const {
    isRight,
    isCollapsed,
    ...rest
  } = props;

  return (
    <ToggleButtonWrapper
      {...rest}
    >
      <Button>
        {isRight ? <img src={isCollapsed ? ArrowLeft : ArrowRight} alt="" /> :
        <img src={isCollapsed ? ArrowRight : ArrowLeft} alt="" /> }
      </Button>
    </ToggleButtonWrapper>
  );
};

export const Body = styled.div<{ isRight: boolean; }>`
  padding: 10px;
  width: 100%;
  overflow: auto;
  direction: ${(props) => (props.isRight ? 'ltr' : 'rtl')}; 
  
  ${scroll};
  
  > div {
    direction: ltr; 
  }
`;

export const TitleArea = styled.div`
  width: 100%;
  font: 500 14px/1.25 ${(props) => props.theme.fonts.sans};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 30px);
`;

export const Wrapper = styled.div<{ isCollapsed: boolean, isRight: boolean, width: number }>`
  position: absolute;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  min-width: ${(props) => props.width}px;
  width: ${(props) => props.width}px;
  height: 100%;
  transition: margin-left .3s ease-in-out, margin-right .3s ease-in-out;
  will-change: margin-left, margin-right;
  overflow: hidden;
  background: inherit;

  & ${Body} {
    position: relative;
    
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 0px;
      opacity: 0;
      background-color: inherit;
      z-index: 1;
      transition: opacity .6s ease-in-out;
      
      ${({ isRight }) => (isRight ? css`
        left: 0;
        margin-left: auto;
      ` : css`
        right: 0;
        margin-right: auto;
      `)}
    }
  }

  ${(props) => (props.isCollapsed ? css`
    & * {
      pointer-events: none;
    }
    
    & ${ToggleButtonWrapper} {
      pointer-events: initial;
    }
    
    & ${Body} {
      position: relative;
      padding: ${props.isRight ? '10px 10px 10px 30px' : '10px 30px 10px 10px'};
      
      &:after {
        width: 30px;
        opacity: 1;
      }
    }
  ` : '')}
    
  ${(props) => (props.isRight ? `
    right: 0;
    border-left: 1px solid ${props.theme.colors.gray3};
  ` : `
    left: 0;
    border-right: 1px solid ${props.theme.colors.gray3};
  `)}
 
  ${(props) => (props.isRight && props.isCollapsed ? `
    margin-right: -${props.width - 30}px;
  ` : '')};
  
  ${(props) => (!props.isRight && props.isCollapsed ? `
    margin-left: -${props.width - 30}px
  ` : '')};
`;

export const Header = styled.div<{
  isRight: boolean,
  isTitleRotatable: boolean,
  isCollapsed: boolean,
  width: number
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${(props) => (props.isRight ? 'row-reverse' : 'row')};
  padding: 10px;
  z-index: 2;
    
  ${({ isRight, isTitleRotatable, isCollapsed, width }) => {
    const collapsedLeft = (width / 2) - 30;
    const collapsedRight = (width / 2) + 30;
    if (isTitleRotatable) {
      if (isRight) {
        return css`
          ${TitleArea} {
            ${isCollapsed ? css`
              position: absolute;
              left: -${collapsedLeft}px;
              right: auto;
              top: ${collapsedRight}px;
              bottom: auto;
              width: ${width}px;
              height: auto;
              transform: rotate(-90deg);
              text-align: right;
            ` : null}
          }
          ${ToggleButtonWrapper} {
            ${isCollapsed ? css`
              position: absolute;
              left: 10px;
              top: 10px;
            ` : null}
          }
        `;
      } else {
        return css`
          ${TitleArea} {
            ${isCollapsed ? css`
              position: absolute;
              right: -${collapsedLeft}px;
              left: auto;
              top: ${collapsedRight}px;
              bottom: auto;
              width: ${width}px;
              height: auto;
              transform: rotate(-90deg);
              text-align: right;
            ` : null}
          }
          ${ToggleButtonWrapper} {
            ${isCollapsed ? css`
              position: absolute;
              right: 10px;
              top: 10px;
            ` : null}
          }
        `;
      }
    } else if (isRight) {
        return css`
          ${TitleArea} {}
          ${ToggleButtonWrapper} {}
        `;
      } else {
        return css`
          ${TitleArea} {}
          ${ToggleButtonWrapper} {}
        `;
      }
    }
  };
`;
