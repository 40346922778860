import styled from 'styled-components';
import { input, colors, fonts } from '@styles';
import { TableContainer, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

export const Input = styled.input<{ show: boolean }>`
  ${input};
  margin-right: 10px;
  display: ${(props) => (!props.show ? 'none' : 'block')};
`;

export const Title = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'inline-block' : 'none')};
  padding: 0 6px;
  border-radius: 4px;
  text-transform: none;
  font: 500 18px ${fonts.sans};
  word-break: break-all;
`;

export const TContainer = styled(TableContainer)`
  && {
    margin-top: 10px;
  }
`;

export const THead = styled(TableHead)``;

export const TBody = styled(TableBody)``;

export const TRow = styled(TableRow)`
  && {
    &:last-child {
      th,
      td {
        font-weight: 500;
      }
    }
  }
`;

export const TCell = styled(TableCell)`
  && {
    font: 14px/1.25 ${fonts.sans};
    color: ${colors.black};
    padding: 10px 12px;
    border: none;

    ${THead} & {
      font-weight: 500;
      border-bottom: 1px solid #ddd;
    }
  }
`;
