import { useRecordDetail } from '@hooks/useRecordDetail';
import React, { useCallback } from 'react';
import { CopyButton } from '@common/ui';
import { Link } from '@reach/router';
import { capitalize } from 'lodash';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { AccountIcon } from '@common/icons/Account';
import { MapPin } from 'react-feather';
import { getGeoLink } from '@utils/getGeoLink';
import moment from 'moment';
import { CustomProperties } from '@features/ClientPortfolio/Client/Overview/Properties/CustomProperties';
import { useModal } from '@common/PromiseModal';
import { ContactForm } from '@features/ClientPortfolio/Client/Overview/Stakeholders/ContactForm';
import { UserAvatar } from '@kit/components/UserAvatar';
import { PhoneNumber } from '@kit/ui/anchors/PhoneNumber';
import { EmailAddress } from '@kit/ui/anchors/EmailAddress';
import { formatMoney } from '@utils/utils';
import { Status } from '@features/ProjectPortfolio/components/Status';
import { getColorAndLabel } from '@features/ProjectPortfolio/components/Status/Status';
import {
  AddressAndIcon,
  Chips,
  Container,
  Copyable,
  EditableChipPlaceholder,
  EditableWrapper,
  InfoAddress,
  InfoBlock,
  InfoChip,
  PrimaryContactBlock,
  ProjectAccountInfoChip,
  ProjectContact,
  ProjectContactInfo,
  ProjectContactLabel,
  ProjectContacts,
  StaticPropertyValue,
  StatusContainer
} from './styled';
import { Description } from './EditableFields';
import { EditableField } from './EditableField';

interface Props {
  projectId: number;
}

export const Properties = ({ projectId }: Props) => {
  const { data: record, isLoading } = useRecordDetail(projectId);
  const { openModal } = useModal();

  const companyId = useAppSelector(selectWorkspaceId);
  const primaryContact = record?.projectContacts[0];

  const handleEditPrimaryContact = useCallback(() => {
    openModal<void>(
      ({ onClose }) => <ContactForm recordId={projectId} initialValues={primaryContact} onClose={onClose} />,
      { title: 'Edit Contact' }
    );
  }, [projectId, primaryContact, openModal]);

  if (isLoading || !record.id) {
    return null; // TODO
  }

  const primaryEmail = primaryContact?.emails?.[0];
  const primaryPhone = primaryContact?.phones?.[0];

  return (
    <Container>
      <InfoBlock>
        <Copyable>
          <AddressAndIcon>
            <InfoAddress href={getGeoLink(record.geoLocation, record.address)} target="_blank" rel="noreferrer">
              <MapPin size={13} />
              <div>{record.address?.[0] ?? ''}</div>
            </InfoAddress>
          </AddressAndIcon>

          <CopyButton value={record.address?.[0]} />
        </Copyable>

        <Chips>
          {record.account && (
            <Link to={`/${companyId}/clients/${record.account.id}`}>
              <ProjectAccountInfoChip isClickable isEditable={false}>
                <AccountIcon size={12} />
                <div>{record.account.title}</div>
              </ProjectAccountInfoChip>
            </Link>
          )}

          {record.account && (
            <ProjectAccountInfoChip isEditable={false}>
              <div>{capitalize(record.account.accountType)}</div>
            </ProjectAccountInfoChip>
          )}

          {record.jurisdiction && (
            <ProjectAccountInfoChip isEditable={false}>
              <div>{`AHJ: ${record.jurisdiction.name}`}</div>
            </ProjectAccountInfoChip>
          )}

          <EditableField project={record} propertyName="trades">
            <ProjectAccountInfoChip isEditable>
              {!record.trades?.length && <EditableChipPlaceholder>Select trades</EditableChipPlaceholder>}
              {record.trades?.length > 0 && <div>{capitalize(record.trades?.join(', '))}</div>}
            </ProjectAccountInfoChip>
          </EditableField>

          <EditableField project={record} propertyName="projectSize">
            <InfoChip>{record.projectSize} kW</InfoChip>
          </EditableField>
          <EditableField project={record} propertyName="projectValue">
            <InfoChip>
              <span className="infoChipLabel">
                <span className="infoChipCurrencySymbol">$&nbsp;</span>
              </span>

              {formatMoney(record.projectValue).replace('$', '')}
            </InfoChip>
          </EditableField>
        </Chips>

        {record.projectContacts.length > 0 && (
          <PrimaryContactBlock>
            <div>
              <ProjectContactLabel>Primary contact</ProjectContactLabel>
            </div>

            <ProjectContacts>
              <EditableWrapper onClick={handleEditPrimaryContact}>
                <Copyable>
                  <ProjectContact>{primaryContact.name}</ProjectContact>
                  <CopyButton value={primaryContact.name} />
                </Copyable>
              </EditableWrapper>

              {Boolean(primaryPhone) && (
                <Copyable>
                  <PhoneNumber value={primaryPhone} />
                  <CopyButton value={primaryPhone} />
                </Copyable>
              )}

              {Boolean(primaryEmail) && (
                <Copyable>
                  <EmailAddress value={primaryEmail} />
                  <CopyButton value={primaryEmail} />
                </Copyable>
              )}

              {(!primaryPhone || !primaryEmail) && <div />}
            </ProjectContacts>
          </PrimaryContactBlock>
        )}

        <ProjectContacts>
          <ProjectContact>
            <ProjectContactLabel>Owner</ProjectContactLabel>
            <EditableField project={record} propertyName="ownerId">
              <EditableWrapper>
                <ProjectContactInfo>
                  {record.owner?.id && (
                    <>
                      <UserAvatar user={record.owner} />
                      <span>{`${record.owner.firstName} ${record.owner.lastName}`}</span>
                    </>
                  )}

                  {!record.owner && '-'}
                </ProjectContactInfo>
              </EditableWrapper>
            </EditableField>
          </ProjectContact>

          <ProjectContact>
            <ProjectContactLabel>Sales rep</ProjectContactLabel>
            <EditableField project={record} propertyName="salesRepId">
              <EditableWrapper>
                <ProjectContactInfo>
                  {record.salesRep && (
                    <>
                      <UserAvatar user={record.salesRep} />
                      <span>{`${record.salesRep.firstName} ${record.salesRep.lastName}`}</span>
                    </>
                  )}
                  {!record.salesRep && '-'}
                </ProjectContactInfo>
              </EditableWrapper>
            </EditableField>
          </ProjectContact>

          <ProjectContact>
            <ProjectContactLabel>Project manager</ProjectContactLabel>
            <EditableField project={record} propertyName="projectManagerId">
              <EditableWrapper>
                <ProjectContactInfo>
                  {record.projectManager && (
                    <>
                      <UserAvatar user={record.projectManager} />
                      <span>{`${record.projectManager.firstName} ${record.projectManager.lastName}`}</span>
                    </>
                  )}

                  {!record.projectManager && '-'}
                </ProjectContactInfo>
              </EditableWrapper>
            </EditableField>
          </ProjectContact>
        </ProjectContacts>

        <div>
          <ProjectContactLabel>Description</ProjectContactLabel>
          <Description project={record} />
        </div>

        <ProjectContacts>
          <div>
            <ProjectContactLabel>Created at</ProjectContactLabel>
            <StaticPropertyValue>{moment(record.createdAt).format('MM/DD/YY hh:mma')}</StaticPropertyValue>
          </div>
          <div>
            <ProjectContactLabel>Last activity</ProjectContactLabel>
            <StaticPropertyValue>{moment(record.lastActivity).format('MM/DD/YY hh:mma')}</StaticPropertyValue>
          </div>
          <div>
            <ProjectContactLabel>Status</ProjectContactLabel>
            <EditableField project={record} propertyName="status">
              <EditableWrapper>
                <StatusContainer>
                  <Status status={record.status} />
                  {getColorAndLabel({ status: record.status }).label}
                </StatusContainer>
              </EditableWrapper>
            </EditableField>
          </div>
        </ProjectContacts>
      </InfoBlock>

      <CustomProperties recordId={projectId} />
    </Container>
  );
};
