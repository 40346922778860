import { colors, fonts, device } from '@styles';
import styled from 'styled-components';

export const FileDate = styled.div`
  color: ${colors.gray};
  font: 14px/1.25 ${fonts.sans};
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-align: right;
  
  max-width: 50px;
  margin: 0 10px;
  ${device.sm`
    max-width: 100%;
    margin: 0 20px 0 auto;
  `}
`;
